import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

  titulo = 'Ferramenta de Busca';
  descricao = 'Pesquise aqui o item de material ou serviço';

  ngOnInit(): void {

  }
  
  usuario = {
    name: 'Glauco Silva de Oliveira',
    displayName: 'Glauco',
    email:'glauco.oliveira@serpro.gov.br'
  };

  modules = [
    {
      name: 'Protótipo', displayName: 'Protótipo',
      iconUrl: 'https://image.flaticon.com/icons/svg/1373/1373087.svg',
      url: 'https://www.figma.com/proto/zBa29cK5S4KR5t3v2thBH2/Novo-Cat%C3%A1logo?node-id=1186%3A2872&scaling=min-zoom&page-id=1186%3A1685'
    },
    {
      name: 'SIASGNET', displayName: 'SIASGnet',
      iconUrl: 'https://image.flaticon.com/icons/png/512/1048/1048953.png',
      url: 'https://www.figma.com/proto/zBa29cK5S4KR5t3v2thBH2/Novo-Cat%C3%A1logo?node-id=295%3A4555&scaling=min-zoom&page-id=295%3A2736'
    },
  ];

}
