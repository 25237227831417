import { Component, Input, OnInit } from '@angular/core';
import { SessaoService } from 'src/app/service/sessao.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PedidoService } from '../../service/pedido.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-historico-analise-pedido',
  templateUrl: './historico-analise-pedido.component.html',
  styleUrls: ['./historico-analise-pedido.component.css']
})
export class HistoricoAnalisePedidoComponent implements OnInit {

  @Input() pedido: any;
  @Input() token: any;
  @Input() usrProfile: any;
  @Input() usrRoles:{[id:string]:boolean};


  chatArea: string;
  maxLengthChat: number= 5000;

  historico: any;

  constructor(
    private sessaoService: SessaoService,
    private messageService: MessageService,
    private service: PedidoService,
    private route: ActivatedRoute,
    private router: Router)
    {}

  ngOnInit(): void {
    this.chatArea='';
    this.recuperarMensagens();
  }

  clickEnviarMensagem(){
    if(this.chatArea) this.enviarMensagem(this.chatArea);
    this.chatArea="";
  }

  enviarMensagem(mensagem:string){
    const data = {
      "numeroPedido":this.pedido.numeroPedido,
      "mensagem":mensagem
    }
    this.service.salvaMensagemHistorico(data).subscribe(resp=>{
      this.recuperarMensagens();
    });
  }

  recuperarMensagens() {
    this.service.obterMensagensHistorico(this.pedido.numeroPedido)
      .subscribe(resp => {
        this.historico = resp;
      },
        error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro no carregamento dos dados'
        })
      });
  }


  dataFormatada(data: string) : string {
    var dt = new Date(data);
    return dt.toLocaleDateString("pt-BR") + ' - ' + dt.toLocaleTimeString("pt-BR", { hour: "2-digit", minute: "2-digit", hour12: false });;
  }

}
