<app-cabecalho-interno></app-cabecalho-interno>
<br /><br /><br />
<div class="container-lg">
  <div class="mb-4 mt-2" ><!-- cabeçalho -->
    <div class="row">
      <div class="col-8" id="cabecalho">
        <h2 class="mb-0" aria-label="Material">
          <span class="mouseover">Catálogo - Padrão descritivo de material</span>
        </h2>
        <h6 style="font-weight: 400;" class="mb-0 mt-1"> Use esta área para criar/editar padrão descritivo de material</h6>
      </div>
      <div class="col-4 text-right row" style="align-content: end;">
        <div class=" col-6 card border-0">
          <button class="br-button is-secondary"  type="button" (click)="voltarTelaInicial()">Voltar</button>
        </div>
        <div class="col-6 card border-0">
          <button class="br-button is-primary" type="button" (click)="criarMaterial()">Incluir </button>
        </div>
      </div>
    </div>
  </div>

  <hr/>

  <br-fieldset [collapsible]="true" fieldsetTitle="Pesquisar padrão descritivo de material" [collapsed]="expandCollapsed">
    <div class="row" >
      <div class="col-md-7 mt-2 mb-2" style="align-self: center;">
        <div class="br-input label">
          <input   [(ngModel)]="textoBusca" class="p-inputtext" placeholder="Digite aqui uma palavra para pesquisar " field="nome"
                  (keyup)="enterPesquisar($event)"/>
        </div>
      </div>
      <div class="col-md-3 mt-3">
        <div class="column">
          <div class="br-checkbox">
            <input id="somentePdm" name="somente-pdm" type="checkbox" aria-label="somente pdm" (click)="filtroAvancado('pdm')" [(ngModel)]="somentePdm" />
            <label for="somentePdm">Pesquisar somente PDM</label>
          </div>
          <div class="br-checkbox">
            <input id="somenteClasse" name="somente-classe" type="checkbox" aria-label="somente classe" (click)="filtroAvancado('classe')" [(ngModel)]="somenteClasse" />
            <label for="somenteClasse">Pesquisar somente Classe</label>
          </div>
          <!-- <div class="br-checkbox">
            <input id="somenteItem" name="somente-item" type="checkbox" aria-label="somente item" (click)="filtroAvancado('item')" [(ngModel)]="somenteItem" />
            <label for="somenteItem">Pesquisar somente item</label>
          </div> -->
        </div>
      </div>
      <div class="col-md-2 mt-3 card border-0" style="align-self: center;">
        <button (click)="pesquisar()" class="br-button primary  justify-self-end " type="button">Pesquisar</button>
      </div>
    </div>
  </br-fieldset>

  <hr/>

  <div class="mt-4" *ngIf="escondeValor != true" >
    <p-table
      [value]="materialTela"
      [rows]="10"
      [paginator]="true"
      responsiveLayout="scroll"
      [rowHover]="true"
      dataKey="id"
      [(first)]="first"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} entradas"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[5, 10, 25, 50, 100]"
    >
      <ng-template pTemplate="caption">
        <div class="row flex align-items-center justify-content-between mb-3">
          <div class="ml-3">
            <h5 class="m-0">Lista de padrões descritivos de material</h5>
          </div>
          <div class="br-input col-5">
            <input
              [(ngModel)]="filtroMaterial"
              (keyup)="filtrarMaterial()"
              placeholder="Digite uma palavra para filtrar o resultado"
            />
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="codigoPdm" class="col-2">
            Código PDM
            <p-sortIcon field="codigoPdm"></p-sortIcon>
          </th>
          <th pSortableColumn="codigoClasse" class="col-1">
            Classe
            <p-sortIcon field="codigoClasse"></p-sortIcon>
          </th>
          <th pSortableColumn="nomePdm" class="col-5">
            Padrão Descritivo de Material
            <p-sortIcon field="nomePdm"></p-sortIcon>
          </th>
          <th pSortableColumn="statusPDM" class="col-1">
            Ativo
            <p-sortIcon field="statusPDM"></p-sortIcon>
          </th>
          <th class="col-3 text-center">Ações</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-valor>
        <tr>
          <td class="col-2">
            <span *ngIf="valor.statusPDM  == true" style="color: black;">{{ valor.codigoPdm }}</span>
            <span *ngIf="valor.statusPDM  == false" style="color: #9f9a9a;">{{ valor.codigoPdm }}</span>
          </td>
          <td class="col-1">
            <span *ngIf="valor.statusPDM  == true" style="color: black;">{{ valor.codigoClasse }}</span>
            <span *ngIf="valor.statusPDM  == false" style="color: #9f9a9a;">{{ valor.codigoClasse }}</span>
          </td>
          <td class="col-5">
            <span *ngIf="valor.statusPDM  == true" style="color: black;">{{ valor.nomePdm }}</span>
            <span *ngIf="valor.statusPDM  == false" style="color: #9f9a9a;">{{ valor.nomePdm }}</span>
          </td>
          <td class="col-1 text-center">
            <span *ngIf="valor.statusPDM  == true" style="color: black;">{{ valor.statusPDM ? "Sim" : "Não" }}</span>
            <span *ngIf="valor.statusPDM  == false" style="color: #9f9a9a;">{{ valor.statusPDM ? "Sim" : "Não" }}</span>
          </td>
          <td class="col-3 text-center">
            <button
              class="br-button"
              type="button"
              aria-label="Botão de ação"
              pTooltip="Visualizar"
              (click)="visualizarPDM(valor)"
              tooltipPosition="top"
            >
              <i class="fas fa-eye" aria-hidden="true"></i>
            </button>
            <button
              class="br-button"
              type="button"
              pTooltip="Editar"
              (click)="editarPDM(valor)"
              tooltipPosition="top"
            >
              <i class="fas fa-edit" aria-hidden="true"></i>
            </button>
            <button
            class="br-button"
            type="button"
            aria-label="Botão de ação"
            pTooltip="Itens"
            (click)="itensPDM(valor)"
            tooltipPosition="top"

          >
            <i class="fas fa-box" aria-hidden="true"></i>
          </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div style="margin-bottom: 100px;">

  </div>

</div>
