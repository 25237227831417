import {AuthService} from '../security/services/auth-service.service';
import {Injectable} from '@angular/core';
import {EnvironmentUtilV2} from '../../environments/EnviromentUtilV2';


@Injectable({
  providedIn: 'root'
})
export class SessaoService {
  constructor(private authService: AuthService) {}

  // tslint:disable-next-line:typedef
  acessoNaoAutorizado() {
    this.authService.limparDadosSessaoUsuarioRedirecionar('/acesso-nao-autorizado');
  }

  // tslint:disable-next-line:typedef
  acessoNaoAutorizadoVaiParaLoginFake() {
    this.authService.limparDadosSessaoUsuarioRedirecionar('/login-fake');
  }
// tslint:disable-next-line:typedef
  irParaLoginAsp() {
    window.location.href = EnvironmentUtilV2.obterUrlBaseComprasnetAsp() + `/seguro/loginPortalUASG.asp`;
  }
// tslint:disable-next-line:typedef
  irParaComprasNetAspInicio() {
    window.location.href = EnvironmentUtilV2.obterUrlBaseComprasnetAsp();
  }
// tslint:disable-next-line:typedef
  irParaTrocaSenhaLoginAsp() {
    window.location.href = EnvironmentUtilV2.obterUrlBaseComprasnetAsp() + `/seguro/loginPortalUASG.asp?troca=sim`;
  }

  irParaAT() {
    this.authService.obterCnetId_Token_Governo().subscribe((cnetid: string) => {
      if (cnetid) {
        window.location.href = EnvironmentUtilV2.obterUrlBaseAreaTrabalho() + '/comprasnet-area-trabalho-web/seguro/governo/area-trabalho?cnet-id=' + cnetid;
      }
    });
  }
}
