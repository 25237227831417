import { MessageService } from 'primeng/api';
import { BuscaService } from './../../../service/busca-service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-manter-valor-caracteristica',
  templateUrl: './manter-valor-caracteristica.component.html',
  styleUrls: ['./manter-valor-caracteristica.component.css']
})
export class ManterValorCaracteristicaComponent implements OnInit {

  valorCaracteristicaApi: any;
  valorCaracteristicaTela: any = [];
  escondeValor: boolean = true;
  filtroValorCaracteristica: string;
  expandCollapsed: boolean = false;

  incluirNome: string = "";
  incluirDescricao: string = "";
  incluirComplemento: string = "";
  disableSave: boolean = true;
  existeNome: boolean = false;

  editarCodigo: string = "";
  editarNome: string = "";
  editarDescricao: string = "";
  editarComplemento: string = "";
  editarStatus: boolean = true;

  visualizaCodigo: string = "";
  visualizaNome: string = "";
  visualizaDescricao: string = "";
  visualizaComplemento: string = "";
  visualizaStatus: boolean = true;

  buscaValorCaracteristica: string = "";
  visible: boolean = false;
  first: any = 0;
  novoCodigo: string = "000000";
  ativos: boolean = false;

  modalRef1: BsModalRef;
  @ViewChild('valorCaracteristicaSucess', {static: false} ) valorCaracteristicaSucess: any;
  @ViewChild('valorCaracteristicaEditado', {static: false} ) valorCaracteristicaEditado: any;

  numMaxNome: number = 50;
  numMaxDescricaoIncluir: number = 200;
  numMaxDescricaoEditar:number = 200;
  numMaxComplementoIncluir:number = 1500;
  numMaxComplementoEditar:number = 1500;

  constructor(private router: Router, private buscaService: BuscaService, private modalService: BsModalService, private messageService: MessageService) { }

  ngOnInit(): void {
  }

  voltarTelaInicial(): void {
    this.router.navigate(['/arealogada']);
  }

  pesquisar(){
    let aux: string;
    this.first = 0;
    aux = this.buscaValorCaracteristica.trim();
    aux = aux.toUpperCase();
    if(aux != ""){
      this.buscaService.obterValoresCaracteristicaCompletoPDM(aux).subscribe(tmp => {
        this.valorCaracteristicaApi = tmp;
        this.valorCaracteristicaTela = tmp;
      });
    } else {
      this.buscaService.obterValoresCaracteristicaCompletoPDM("").subscribe(tmp =>{
        this.valorCaracteristicaApi = tmp;
        this.valorCaracteristicaTela = tmp;
      });
    }

    this.escondeValor = false;
  }

  filtrarValorCaracteristica(){
    this.ativos = false;
    let aux: string;
    this.first = 0;

    if (this.filtroValorCaracteristica !== undefined){
      aux = this.filtroValorCaracteristica.toUpperCase();
    } else {
      aux = '';
    }

    this.valorCaracteristicaTela = [];
    if(aux != ""){
      for( let i of this.valorCaracteristicaApi){
        if(i.codigoValorCaracteristica != null && i.nomeValorCaracteristica != null){
          if(i.codigoValorCaracteristica.toString().includes(aux) || i.nomeValorCaracteristica.toUpperCase().includes(aux)){
            this.valorCaracteristicaTela.push(i);
          }
        }
      }
    }
    else{
      this.resetaFiltroValorCaracteristica();
    }
  }

  somenteAtivos(){
    if(this.ativos == false){
      let auxArray = [];
      for(let i of this.valorCaracteristicaTela){
        if(i.statusValorCaracteristica == true){
          auxArray.push(i);
        }
      }
      this.valorCaracteristicaTela = auxArray;
    } else {
      this.filtrarValorCaracteristica();
    }
  }


  resetaFiltroValorCaracteristica(){
    this.first = 0;
    this.filtroValorCaracteristica = "";
    this.valorCaracteristicaTela = this.valorCaracteristicaApi;
  }

  salvarValorCaracteristica(): void{
    let modalOptions: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.visible = true;
    this.buscaService.salvarValorCaracteristicaPDM({
      nomeValorCaracteristica: this.incluirNome.trim(),
      statusValorCaracteristica: true,
      descricaoValorCaracteristica: this.incluirDescricao.trim(),
      complementoValorCaracteristica: this.incluirComplemento.trim()
    }).subscribe((tmp) => {
      this.visible = false;
      console.log(tmp.codigoValorCaracteristica);
      this.novoCodigo = tmp.codigoValorCaracteristica;
      this.incluirComplemento = "";
      this.incluirDescricao = "";
      this.incluirNome = "";
      this.modalRef1 = this.modalService.show(this.valorCaracteristicaSucess, modalOptions);
    }, erro => {
        this.visible = false;
        if(typeof(erro.error) == "string"){
          this.messageService.add({
            severity: 'error',
            summary: 'Erro ao salvar os dados no servidor: ' + erro.error
            });
        }else{
          this.messageService.add({
            severity: 'error',
            summary: 'Não foi possível se conectar com o serviço neste momento, por favor tente mais tarde.'
            });
        }
    });
  }

  pegarDados(tipo:string, codigo: any){
    if(tipo == "edita"){
      this.editarCodigo = codigo.codigoValorCaracteristica;
      this.editarNome = codigo.nomeValorCaracteristica;
      this.editarComplemento = codigo.complementoValorCaracteristica;
      this.editarDescricao = codigo.descricaoValorCaracteristica;
      this.editarStatus = codigo.statusValorCaracteristica;
      this.numMaxDescricaoEditar = 200;
      this.numMaxDescricaoEditar = this.numMaxDescricaoEditar - (this.editarDescricao.length);
      this.numMaxComplementoEditar = 1500;
      this.numMaxComplementoEditar = this.numMaxComplementoEditar - (this.editarComplemento.length);
    } else {
      this.visualizaCodigo = codigo.codigoValorCaracteristica;
      this.visualizaNome = codigo.nomeValorCaracteristica;
      this.visualizaComplemento = codigo.complementoValorCaracteristica;
      this.visualizaDescricao = codigo.descricaoValorCaracteristica;
      this.visualizaStatus = codigo.statusValorCaracteristica;
    }

  }

  enterPesquisar(evento: any){
    if(evento.key == "Enter"){
      this.pesquisar();
    }
  }

  editarValorCaracteristica(){
    let modalOptions: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.visible = true;
    this.buscaService.editarValoresCaracteristicaPDM({
      codigoValorCaracteristica: this.editarCodigo,
      nomeValorCaracteristica: this.editarNome.trim(),
      descricaoValorCaracteristica: this.editarDescricao.trim(),
      statusValorCaracteristica: this.editarStatus,
      complementoValorCaracteristica: this.editarComplemento.trim()
    }).subscribe(tmp => {
      this.visible = false;
      this.modalRef1 = this.modalService.show(this.valorCaracteristicaEditado, modalOptions);
    }, erro => {
      this.visible = false;
      if(typeof(erro.error) == "string"){
        this.messageService.add({
          severity: 'error',
          summary: 'Erro ao salvar os dados no servidor: ' + erro.error
          });
      }else{
        this.messageService.add({
          severity: 'error',
          summary: 'Não foi possível se conectar com o serviço neste momento, por favor tente mais tarde.'
          });
      }
    });
  }

  enableSave(){
    if(this.incluirNome == ""){
      this.disableSave = true;
    } else{
      this.disableSave = false;
    }
  }

  decrementaTexto(campo: string){

    if(campo == "nome"){
      this.numMaxNome = 50;
      this.numMaxNome = this.numMaxNome - (this.incluirNome.length);
    }

    if(campo == "descricaoIncluir"){
      this.numMaxDescricaoIncluir = 200;
      this.numMaxDescricaoIncluir = this.numMaxDescricaoIncluir - (this.incluirDescricao.length);
    }

    if(campo == "descricaoEditar"){
      this.numMaxDescricaoEditar = 200;
      this.numMaxDescricaoEditar = this.numMaxDescricaoEditar - (this.editarDescricao.length);
    }

    if(campo == "complementoIncluir"){
      this.numMaxComplementoIncluir = 1500;
      this.numMaxComplementoIncluir = this.numMaxComplementoIncluir - (this.incluirComplemento.length);
    }

    if(campo == "complementoEditar"){
      this.numMaxComplementoEditar = 1500;
      this.numMaxComplementoEditar = this.numMaxComplementoEditar - (this.editarComplemento.length);
    }

  }

  sairModalEditar(){
    this.modalRef1.hide();
    this.pesquisar();
  }

  sairModalSalvar(){
    window.location.reload();
  }

  limpaPesquisa(){
    this.incluirComplemento = "";
    this.incluirDescricao = "";
    this.incluirNome = "";
    this.escondeValor = true;
    this.buscaValorCaracteristica = "";
    this.valorCaracteristicaTela = [];
    this.filtroValorCaracteristica = "";
    this.existeNome = false;
    this.numMaxNome = 50;
    this.numMaxDescricaoIncluir = 200;
    this.numMaxComplementoIncluir = 1500;
  }

  validaNome(campo: any){
    let aux = this.incluirNome.trim();
    this.buscaService.validaNomeExistenteValorCaracteristica(aux).subscribe((tmp)=>{
      if(tmp > 0){
        this.existeNome = true;
        this.disableSave = true;
        this.incluirNome = "";
        campo.focus();
      }
      else {
        this.existeNome = false;
      }
    })
  }
}
