import {Component, DoCheck, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Item} from '../model/item.model';
import {ItemExportar} from '../model/itemPDF.model';
import {Router} from '@angular/router';
import 'jspdf-autotable';
import jspdf from 'jspdf';
import { ExportToCsv } from 'export-to-csv';
import { ConfirmationComponent,} from '@serpro/ngx-dsgovbr';
import { MenuItem, MessageService } from 'primeng/api';

@Component({
  selector: 'app-exibir-selecionados',
  template: `itens: {{itens}}`,
  templateUrl: './exibir-selecionados.component.html',
  styleUrls: ['./exibir-selecionados.component.css']
})
export class ExibirSelecionadosComponent implements OnInit {
  head = [['ID', 'NOME', 'TIPO']];
  head2: any;
  itenssaida: Item[] = [];
  itenssaidapdf: ItemExportar[] = [];
  formatos: Formato[];
  selecionado: Formato;
  items: MenuItem[];
  excluirId: number;
  itensExcluidos: Array<Item[]> = [];

  @Input() itens: Item[];
  @Output() atualizarLista = new EventEmitter<any>();

  @ViewChild('confirmExclusao', {static: true}) confirmExclusao: ConfirmationComponent;

  constructor(private router: Router,
              private messageService: MessageService
              ) {
    this.carregarFormatos();

  }

  carregarFormatos(): void {
    this.formatos = [
      {name: 'Exportar', code: '0'},
      {name: 'CSV', code: '1'},
      {name: 'PDF', code: '2'},
      {name: 'JSON', code: '3'},
      {name: 'TXT', code: '4'}
    ];
  }

  ngOnInit(): void {
    this.selecionado = new Formato();


    this.items = [
      {label: 'CSV', icon: 'pi pi-file-excel', command: () => {
          this.gerar_csv();
      }},
      {label: 'PDF', icon: 'pi pi-file-pdf', command: () => {
          this.gerar_pdf();
      }},
      {label: 'JSON', icon: 'pi pi-file-o', command: () => {
          this.gerar_json();
      }},
      {label: 'TXT', icon: 'pi pi-file', command: () => {
          this.gerar_txt();
      }}
    ];
  }

  excluir(id: number) {

    let itemExcluido;

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.itens.length; i++) {
      // tslint:disable-next-line:triple-equals
      if ( this.itens[i].id == id ){
       itemExcluido = this.itens.splice(i, 1);
       break;
      }
    }

    const saved = localStorage.getItem('itensExcluidos');
    if (saved != null){
      this.itensExcluidos = JSON.parse(saved);
    }
    if(itemExcluido != null){
      this.itensExcluidos.push(itemExcluido);
      localStorage.setItem('itensExcluidos', JSON.stringify(this.itensExcluidos));
    }


    localStorage.setItem('itensbusca', JSON.stringify(this.itens));
    this.atualizarLista.emit();
    this.messageService.add({
      severity: 'success',
      summary: 'Item excluído da lista.'
    });
  }

  openModal1(id: number){
    this.confirmExclusao.show(null);
    this.excluirId = id;
  }

  limpar(){

    const saved = localStorage.getItem('itensExcluidos');
    if (saved != null){
      this.itensExcluidos = JSON.parse(saved);
    }
    if(this.itens != null){
      this.itensExcluidos.push(this.itens);
      localStorage.setItem('itensExcluidos', JSON.stringify(this.itensExcluidos));
    }

    this.itens = new Array();
    localStorage.setItem('itensbusca', JSON.stringify(this.itens));
  }

  voltar(): void {
      this.router.navigate(['/busca']);
  }

  desfazer(){
    const saved = localStorage.getItem('itensExcluidos');
    if (saved != null){
      this.itensExcluidos = JSON.parse(saved);
      let itemExcluido = this.itensExcluidos.pop();

      if(itemExcluido != null){

        for (let item of itemExcluido){
          this.itens.push(item);
        }

        localStorage.setItem('itensExcluidos', JSON.stringify(this.itensExcluidos));
        localStorage.setItem('itensbusca', JSON.stringify(this.itens));
      }
    }
    this.atualizarLista.emit();
  }

  gerar_csv(): void {
    var data1 = dataAtualFormatada();

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'listadeitens'+data1,
      filename: 'listadeitens'+data1,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(this.convertToExportNovo());

    this.itenssaida = [];
  }

  gerar_pdf(): void {
    console.log(this.selecionado.code);
    var data1 = dataAtualFormatada();

    let item;
    let itemtmp;
    itemtmp = new ItemExportar();
      itemtmp.sequencial = 'Sequencial';
       itemtmp.id = 'ID';
          itemtmp.nome = 'Nome';
          itemtmp.tipo = 'Tipo';
          itemtmp.unidade = 'Unidade Medida';
          itemtmp.codigoNcm = 'NCM';
          itemtmp.aplicaMargemPreferencia = 'Margem Preferencia';
          this.itenssaidapdf.push(itemtmp);

    this.itenssaidapdf = this.itenssaidapdf.concat(this.convertToExportNovo());

    const doc = new jspdf();
    var img = new Image();
    img.src = 'assets/imagem/logo-compras-export.jpg';

    doc.addImage(img, 'JPG', 10, 10, 73, 10);
    doc.setFontSize(18);
    doc.text('Catálogo - Itens selecionados', 11, 38);
    doc.setFontSize(11);
    doc.setTextColor(100);

    (doc as any).autoTable({
      margin: {top: 45},
      head: this.head2,
      body: this.itenssaidapdf,
      title: 'listadeitens'+data1+'.pdf',
      theme: 'plain',
      didDrawCell: (data: { column: { index: any; }; }) => {
        console.log(data.column.index);
      }
    });

    // below line for Open PDF document in new tab
    doc.output('dataurlnewwindow');
    // below line for Download PDF document
    doc.save('listadeitens'+data1+'.pdf');
    this.itenssaidapdf = [];
  }

 gerar_json(): void {
    console.log(this.selecionado.code);
    var data1 = dataAtualFormatada();
    const link = document.createElement('a');
    link.download = 'listadeitens'+data1+'.json';
    const data = 'text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(this.convertToExport()));
    link.href = 'data:' + data;
    link.click();
  }

 gerar_txt(): void {
    console.log(this.selecionado.code);
    var data1 = dataAtualFormatada();

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'listadeitens'+data1,
      filename: 'listadeitens'+data1,
      useTextFile: true,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(this.convertToExportNovo());

    this.itenssaida = [];
 }
  customSort(event: any): void {
    event.data.sort((data1: any, data2: any) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (event.field === 'id'){
        // tslint:disable-next-line:radix
       value1 = parseInt(value1);
        // tslint:disable-next-line:radix
       value2 = parseInt(value2);
     }
      if (value1 == null && value2 != null) {
        result = -1;
      }
     else if (value1 != null && value2 == null) {
        result = 1;
 }
     else if (value1 == null && value2 == null) {
        result = 0;
 }
     else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
 }
     else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
 }

      return (event.order * result);
    });
  }

  convertToExport(): ItemExportar[]{

    let exportar: ItemExportar[] = [];
    let itemtmp;

    for(let i = 1; i <= this.itens.length; i++){
      itemtmp = new ItemExportar();
      itemtmp.sequencial = i.toString();
      itemtmp.id = this.itens[i-1].id.toString();
      itemtmp.nome = this.itens[i-1].nome;
      itemtmp.carrinhoNome = this.itens[i-1].carrinhoNome;
      itemtmp.carrinhoCaracteristicas = this.itens[i-1].carrinhoCaracteristicas?.replace("|", ",");
      itemtmp.carrinhoCaracteristicas = this.itens[i-1].carrinhoCaracteristicas?.replace("#", "");
      itemtmp.carrinhoCaracteristicas = this.itens[i-1].carrinhoCaracteristicas?.replace("&", "");
      itemtmp.tipo = this.itens[i-1].tipo;
      itemtmp.unidade = this.itens[i-1].unidade;
      itemtmp.unidade.nomeUnidadeMedida = this.itens[i-1].unidade.nomeUnidadeMedida;
      itemtmp.codigoNcm = this.itens[i-1].tipo == undefined ? "" : this.itens[i-1].codigoNcm;
      itemtmp.aplicaMargemPreferencia = this.itens[i-1].tipo == undefined ? "" : this.itens[i-1].aplicaMargemPreferencia;
      exportar.push(itemtmp);

    }

    return exportar;
  }

  convertToExportNovo(): ItemExportar[]{

    let exportar: ItemExportar[] = [];
    let itemtmp;

    for(let i = 1; i <= this.itens.length; i++){
      itemtmp = new ItemExportar();
      itemtmp.sequencial = i.toString();
      itemtmp.id = this.itens[i-1].id.toString();
      itemtmp.nome = this.itens[i-1].nome;
      itemtmp.tipo = this.itens[i-1].tipo;
      itemtmp.unidade = this.itens[i-1].unidade;
      itemtmp.unidade = this.itens[i-1].unidade.nomeUnidadeMedida;
      itemtmp.codigoNcm = this.itens[i-1].tipo == "SERVIÇO" ? "" : this.itens[i-1].codigoNcm;
      itemtmp.aplicaMargemPreferencia = this.itens[i-1].tipo == "SERVIÇO" ? "" : this.itens[i-1].aplicaMargemPreferencia;
      exportar.push(itemtmp);
    }

    return exportar;
  }

  ordenar(){
    localStorage.setItem('itens', JSON.stringify(this.itens));
    this.adicionarSerial();
  }

  adicionarSerial(){
    for(let i = 1; i <= this.itens.length; i++){
        this.itens[i-1].sequencial = i;
    }
  }
}

class Formato {
  code: string;
  name: string;
}

function dataAtualFormatada(){
    var data = new Date(),
        dia  = data.getDate().toString().padStart(2, '0'),
        mes  = (data.getMonth()+1).toString().padStart(2, '0'), //+1 pois no getMonth Janeiro começa com zero.
        ano  = data.getFullYear();
    return dia+mes+ano;
}
