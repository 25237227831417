import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BuscaService } from 'src/app/service/busca-service';

@Component({
  selector: 'app-visualizar-servico',
  templateUrl: './visualizar-servico.component.html',
  styleUrls: ['./visualizar-servico.component.css']
})
export class VisualizarServicoComponent implements OnInit {

  servicoParams: any;

  dadosBasicos: any;
  expandCollapsed: boolean = false;

  constructor(private router: Router, private buscaService: BuscaService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe(id => this.servicoParams = id);
    this.buscaService.obterDadosBasicosServicoPorCodigo(this.servicoParams.id).subscribe(resp => this.dadosBasicos = resp);
  }

  voltarTelaInicial(){
    this.router.navigate(['/buscaservico']);
  }

  editarServico(){
    this.router.navigate(['/manterservico', this.dadosBasicos.codigoServico]);
  }

  teste(){
    console.log(this.dadosBasicos);
  }

}
