<div class="template-base">
  <nav id="skip-links"><!-- Carregar Atalhos de Teclado aqui -->
    <div class="skip-links">...</div>

  </nav>

<!--  <app-cabecalho></app-cabecalho>-->
<p-toast position="top-center">
  <ng-template let-message pTemplate="message">
      <div class="row" style="flex: 1">
          <div class="col-12">
              <i class="fas fa-exclamation-triangle fa-lg mr-2" aria-hidden="true"></i>
              <strong class="mr-1">{{message.summary}}</strong>
              <span>{{message.detail}}</span>
          </div>
      </div>
  </ng-template>
</p-toast>

  <main >
    <router-outlet></router-outlet>
  </main>


  <!-- Carregar script do DSGov aqui -->
</div>
