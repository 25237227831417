<div class="modal fade" id="buscarunidademedida" style="z-index: 1100;">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-cpc" >
      <div class="modal-content" style="min-height: 1000px;">
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Selecionar a unidade de medida</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="fechar()"
        >
          &times;
        </button>
      </div>

      <div  class="container-lg"  >
        <div class="row mb-3 mt-3" style="justify-content: center;">
          <!--<div class="col-sm-2 col-lg-2">
            Buscar Unidade de Medida
          </div>!-->
          <div class="col-sm-6 col-lg-6">
            <div class="br-input">
              <input class="p-inputtext" type="text"  placeholder="Digite o nome da unidade  de medida para filtrar" [(ngModel)]="unidadeMedidaFiltro" (keyup)="filtrarUnidadeMedida()" />
            </div>
          </div>
          <div class="column">
            <div class="br-checkbox">
              <input id="somenteNomeUnidadeMedidaEmbalagem" name="somente-nome" type="checkbox" aria-label="somente nome" (click)="filtrarUnidadeMedida(true)" [(ngModel)]="somenteNomeUnidadeMedidaFiltroEmbalagem"/>
              <label for="somenteNomeUnidadeMedidaEmbalagem">Pesquisar somente nome</label>
            </div>
            <div class="br-checkbox">
              <input id="somenteSiglaUnidadeMedidaEmbalagem" name="somente-sigla" type="checkbox" aria-label="somente sigla" (click)="filtrarUnidadeMedida(undefined, true)" [(ngModel)]="somenteSiglaUnidadeMedidaFiltroEmbalagem"/>
              <label for="somenteSiglaUnidadeMedidaEmbalagem">Pesquisar somente sigla</label>
            </div>
          </div>
          <!--<div class="col-sm-2 col-lg-2 mb-3" style="align-self: center;">
            <button class="br-button secondary" type="reset" (click)="filtrarUnidadeMedida()">Buscar</button>
          </div>
          <div class="col-sm-2 col-lg-2 mb-3" style="align-self: center;">
            <button class="br-button secondary" type="reset" (click)="limparFiltroUnidadeMedida()">Limpar</button>
          </div>!-->
        </div>

        <p-table  [value]="unidadeFornecimentoTela" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
                  currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} " [rowsPerPageOptions]="[10,25,50]" [(first)]="first">
          <ng-template pTemplate="header">
            <tr class="row">
              <th pSortableColumn="nomeUnidadeFornecimento" class="col-sm-3 col-lg-3">Nome unidade de medida<p-sortIcon field="nomeUnidadeFornecimento"></p-sortIcon></th>
              <th pSortableColumn="descricaoUnidadeFornecimento" class="col-sm-4 col-lg-4">Descrição unidade de medida<p-sortIcon field="descricaoUnidadeFornecimento"></p-sortIcon></th>
              <th pSortableColumn="siglaUnidadeFornecimento" class="col-sm-3 col-lg-3">Sigla unidade de medida<p-sortIcon field="siglaUnidadeFornecimento"></p-sortIcon></th>
              <th class="col-sm-2 col-lg-2">Ações</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr class="row">
              <td class="col-sm-3 col-lg-3">{{item.nomeUnidadeFornecimento}}</td>
              <td class="col-sm-4 col-lg-4">{{item.descricaoUnidadeFornecimento}}</td>
              <td class="col-sm-3 col-lg-3">{{item.siglaUnidadeFornecimento}}</td>
              <td class="col-sm-2 col-lg-2">
                <button  type="button" class="br-button secondary  small "
                         (click)="escolherUnidadeFornecimento(
                           item.siglaUnidadeFornecimento,
                           item.nomeUnidadeFornecimento,
                           item.possuiEmbalagem
                           )" aria-label="Selecionar"
                         data-dismiss="modal"
                >Selecionar
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div> <!-- fim do mostrarUnidadeMedidas-->

    </div>
  </div>
</div>
