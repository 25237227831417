  <p-table #tabela [value]="itensPedidos" [rows]="page?.size" [first]="page?.number*page?.size" [paginator]="true" responsiveLayout="scroll"
  [rowHover]="true" dataKey="numeroPedido" sortfield="dataHoraAtualizacao" [sortOrder]="-1" [totalRecords]="page?.totalElements"
  [rowsPerPageOptions]="[10, 20, 50, 100]"
  currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" [showCurrentPageReport]="true"
  [lazy]="true" [loading]="loading" (onLazyLoad)="carregarPedidos($event)">


  <ng-template pTemplate="header">
  <tr>
      <th style="width: 08%;" *ngIf="aba !== 'MEUS_PEDIDOS' && aba !== 'PEDIDOS_DA_MINHA_UASG'" class="mx-auto" pSortableColumn="codigoUasgSolicitante">UASG<p-sortIcon field="codigoUasgSolicitante"></p-sortIcon></th>
      <th style="width: 09%;" class="mx-auto" pSortableColumn="numeroPedido">Número<p-sortIcon field="numeroPedido"></p-sortIcon></th>
      <th style="width: 10%;" pTooltip="Data de criação do pedido." class="mx-auto" pSortableColumn="dataHoraCriacao">Criado em<p-sortIcon field="dataHoraCriacao"></p-sortIcon></th>
      <th style="width: 11%;" pTooltip="Data de atualização do pedido." class="mx-auto" pSortableColumn="dataHoraAtualizacao">Atualização<p-sortIcon field="dataHoraAtualizacao"></p-sortIcon></th>
      <th style="width: 07%;" class="mx-auto" pSortableColumn="tipoPedido">Tipo<p-sortIcon field="tipoPedido"></p-sortIcon></th>
      <th style="width: 15%;" pSortableColumn="nomePedido">Nome<p-sortIcon field="nomePedido"></p-sortIcon></th>
      <th style="width: 16%;" *ngIf="aba !== 'PEDIDOS_ABERTOS' && aba !== 'DESIGNADOS_A_MIM' && aba !== 'DESIGNADOS_A_MINHA_UASG'" class="mx-auto" pSortableColumn="nomeCatalogador">Unidade Catalogadora<p-sortIcon field="nomeCatalogador"></p-sortIcon></th>
      <th style="width: 10%;" class="mx-auto" pSortableColumn="situacaoPedido">Situação<p-sortIcon field="situacaoPedido"></p-sortIcon></th>
      <th style="width: 09%;" class="mx-auto text-center">Ações</th>
  </tr>
  </ng-template>

  <ng-template pTemplate="body" let-itemPedido>
      <tr>
          <td *ngIf="aba !== 'MEUS_PEDIDOS' && aba !== 'PEDIDOS_DA_MINHA_UASG'">
              <span pTooltip="{{ itemPedido.nomeUasgSolicitante }}">{{itemPedido.codigoUasgSolicitante}}</span>
          </td>
          <td>
              <span>{{itemPedido.numeroPedido}}</span>
          </td>
          <td >
              <span>{{itemPedido.dataHoraCriacao}}</span>
          </td>
          <td >
            <span>{{itemPedido.dataHoraAtualizacao}}</span>
        </td>
          <td >
              <span>{{itemPedido.tipoPedido == 'S' ? 'Serviço' : 'Material'}}</span>
          </td>
          <td >
              <span class="reticencias nomeCatalogador" pTooltip="{{ itemPedido.nomePedido }}">{{itemPedido.nomePedido}}</span>
          </td>
          <td *ngIf="aba !== 'PEDIDOS_ABERTOS' && aba !== 'DESIGNADOS_A_MIM' && aba !== 'DESIGNADOS_A_MINHA_UASG'">
              <span class="nomeCatalogador" pTooltip="{{ itemPedido.nomeUasgCatalogador }}">{{itemPedido.nomeUasgCatalogador}}</span>              
          </td>
          <td>
              <span class="text-break">{{itemPedido.situacaoPedido}}</span>
          </td>
          <td class="text-center">
              <button class="br-button circle small text-overflow-descricao"
                  type="button"
                  data-target="#visualizarCotacao"
                  aria-label="Botão Visualizar"
                  pTooltip="Visualizar / Editar"
                  placement="top"
                  (click)="obtemPedidoPorId(itemPedido.numeroPedido)">
                  <i class="fas fa-eye" aria-hidden="true" ></i>
              </button>
          </td>
      </tr>
  </ng-template>
  </p-table>


