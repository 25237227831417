
 // para as integrações do des-cnetmobile.np.estaleiro.serpro.gov.br
 // o cabeçalho e auth serviçe
export class EnvironmentUtilV2 {

  public static obterUrlBase(): string {
    const href: string = window.location.href;
    if (href.indexOf('localhost') > 0){
      // return 'https://des-cnetmobile.np.estaleiro.serpro.gov.br';
      return  '/api';
    }
    if (href.indexOf('des-cnbs') > 0 || href.indexOf('des.cnbs') > 0){
      //return 'https://des-cnetmobile.np.estaleiro.serpro.gov.br';
      return 'https://des-cnetmobile.np.estaleiro.serpro.gov.br';
    }
//    if (href.indexOf('tre-cnbs') > 0 || href.indexOf('treinamento.catalogo') > 0){
    if (href.indexOf('tre.cnbs') > 0 || href.indexOf('treinamento.catalogo') > 0 || href.indexOf('tre-cnbs') > 0){
      return 'https://tre-cnetmobile.estaleiro.serpro.gov.br';

    }
//    if (href.indexOf('hom-cnbs') > 0){
    if (href.indexOf('hom.cnbs') > 0 || href.indexOf('hom-cnbs') > 0){
      return 'https://hom-cnetmobile.estaleiro.serpro.gov.br';
    }
    return 'https://cnetmobile.estaleiro.serpro.gov.br';
  }

  public static obterUrlBaseComprasnetAsp(): string {

    const href: string = window.location.href;
    if (href.indexOf('localhost') > 0){
      return 'http://area-trabalho.comprasnet.serpro';
    }
    // if (href.indexOf('des.cnbs') > 0){
    if (href.indexOf('des-cnbs') > 0 || href.indexOf('des.cnbs') > 0){
      return 'http://area-trabalho.comprasnet.serpro';
    }
//    if (href.indexOf('tre-cnbs') > 0 || href.indexOf('treinamento.catalogo') > 0){
    if (href.indexOf('tre.cnbs') > 0 || href.indexOf('treinamento.catalogo') > 0 || href.indexOf('tre-cnbs') > 0){
      return 'https://treinamento.comprasnet.gov.br';

    }
//    if (href.indexOf('hom-cnbs') > 0){
    if (href.indexOf('hom.cnbs') > 0 || href.indexOf('hom-cnbs') > 0){
      return 'https://hom.comprasnet.gov.br';
    }
    return 'https://www.comprasnet.gov.br';
  }

  // TODO: VERIFICA size=10 quando é feita a segunda chamada
  public static obterUrlBaseMenu(): string {
    const href: string = window.location.href;
    if (href.indexOf('localhost') > 0){
       return 'https://des-cnetmobile.np.estaleiro.serpro.gov.br/comprasnet-area-trabalho/v1/sistema/menu/?size=100&page=0';
      //return 'https://tes-cnetmobile.estaleiro.serpro.gov.br/comprasnet-area-trabalho/v1/sistema/menu/?size=100&page=0';
    }
    if (href.indexOf('des-cnbs') > 0 || href.indexOf('des.cnbs') > 0){
      //return 'https://des-cnetmobile.np.estaleiro.serpro.gov.br/comprasnet-area-trabalho/v1/sistema/menu/';
      return 'https://des-cnetmobile.np.estaleiro.serpro.gov.br/comprasnet-area-trabalho/v1/sistema/menu/?size=100&page=0';
    }
//    if (href.indexOf('tre-cnbs') > 0 || href.indexOf('treinamento.catalogo') > 0){
    if (href.indexOf('tre.cnbs') > 0 || href.indexOf('treinamento.catalogo') > 0 || href.indexOf('tre-cnbs') > 0){
      //return 'https://tre-cnetmobile.estaleiro.serpro.gov.br/comprasnet-area-trabalho/v1/sistema/menu/';
      return 'https://tre-cnetmobile.estaleiro.serpro.gov.br/comprasnet-area-trabalho/v1/sistema/menu/?size=100&page=0';
    }
//    if (href.indexOf('hom-cnbs') > 0){
    if (href.indexOf('hom.cnbs') > 0 || href.indexOf('hom-cnbs') > 0){
      //return 'https://hom-cnetmobile.estaleiro.serpro.gov.br/comprasnet-area-trabalho/v1/sistema/menu/';
      return 'https://hom-cnetmobile.estaleiro.serpro.gov.br/comprasnet-area-trabalho/v1/sistema/menu/?size=100&page=0';
    }
    //return 'https://cnetmobile.estaleiro.serpro.gov.br/comprasnet-area-trabalho/v1/sistema/menu/';
    return 'https://cnetmobile.estaleiro.serpro.gov.br/comprasnet-area-trabalho/v1/sistema/menu/?size=100&page=0';
  }

  public static obterUrlBaseMenuSistema(): string {
    const href: string = window.location.href;
    if (href.indexOf('localhost') > 0){

      return 'https://des-cnetmobile.np.estaleiro.serpro.gov.br/comprasnet-area-trabalho/v1/redirect/?itemmenusistema=';
    }
    if (href.indexOf('des-cnbs') > 0 || href.indexOf('des.cnbs') > 0){
      return 'https://des-cnetmobile.np.estaleiro.serpro.gov.br/comprasnet-area-trabalho/v1/redirect/?itemmenusistema=';
      //return 'https://tes-cnetmobile.estaleiro.serpro.gov.br/comprasnet-area-trabalho/v1/redirect/?itemmenusistema=';
    }
//    if (href.indexOf('tre-cnbs') > 0 || href.indexOf('treinamento.catalogo') > 0){
    if (href.indexOf('tre.cnbs') > 0 || href.indexOf('treinamento.catalogo') > 0 || href.indexOf('tre-cnbs') > 0){
      return 'https://tre-cnetmobile.estaleiro.serpro.gov.br/comprasnet-area-trabalho/v1/redirect/?itemmenusistema=';
    }
//    if (href.indexOf('hom-cnbs') > 0){
    if (href.indexOf('hom.cnbs') > 0 || href.indexOf('hom-cnbs') > 0){
      return 'https://hom-cnetmobile.estaleiro.serpro.gov.br/comprasnet-area-trabalho/v1/redirect/?itemmenusistema=';
    }
    return 'https://cnetmobile.estaleiro.serpro.gov.br/comprasnet-area-trabalho/v1/redirect/?itemmenusistema=';
  }

  public static obterUrlBaseAreaTrabalho(): string {
    const href: string = window.location.href;
    if (href.indexOf('localhost') > 0){
      return 'https://des-cnetmobile.np.estaleiro.serpro.gov.br';
    }
    if (href.indexOf('des.pesqpreco') > 0 || href.indexOf('des-pesqpreco') > 0){
      return 'https://des-cnetmobile.np.estaleiro.serpro.gov.br';
    }
    if (href.indexOf('tre.pesqpreco') > 0 || href.indexOf('tre-pesqpreco') > 0){
      return 'https://tre-cnetmobile.estaleiro.serpro.gov.br';

    }
    if (href.indexOf('hom.pesqpreco') > 0 || href.indexOf('hom-pesqpreco') > 0){
      return 'https://hom-cnetmobile.estaleiro.serpro.gov.br';
    }
    return 'https://cnetmobile.estaleiro.serpro.gov.br';
  }


  public static isDesenvolvimento(): boolean {
    return window.location.href.indexOf('des-cnetmobile') > 0;
    //return window.location.href.indexOf('tes-cnetmobile.estaleiro') > 0;
  }

  public static isHomologacao(): boolean {
    return window.location.href.indexOf('hom-cnetmobile') > 0;
  }

  public static isPrimeiroAcesso(): boolean {
    return window.location.href.indexOf('cnetid=') > 0;
  }
}
