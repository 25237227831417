import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentUtil } from 'src/environments/EnviromentUtil';
import { PedidoForm } from '../model/pedido-form.model';

@Injectable({
  providedIn: 'root'
})
export class PedidoService {

  endpoint: string;

  constructor(private httpClient: HttpClient) {
    this.endpoint = EnvironmentUtil.obterUrlBase();
  }

  /**
   * HASH é utilizado para identificar quando houve mudanças nos dados do pedido.
   */
  private hashPedido:string = '';

  setHash(pedido: PedidoForm) {
    this.hashPedido = JSON.stringify(pedido);
  }

  getHash() {
    return this.hashPedido;
  }

  notEqualsHash(pedido: PedidoForm) : boolean {
    return this.getHash() != JSON.stringify(pedido);
  }

  listarUasgs(): Observable<any[]>{
    const destino = this.endpoint + 'pedido/v1/uasg';
    let params = new HttpParams()
    params = params.set('somenteCatalogadoras','true');
    return this.httpClient.get<any[]>(destino, {params});
  }

  buscaUasgPorCodigo(codigoUasg:any, uasgList:any[]){
    return uasgList.find( (x:any) => x.codigoUasg===codigoUasg);
  }

  // Histórico Análise de pedidos
  salvaMensagemHistorico(mensagem: any): Observable<any> {
    const destino = this.endpoint + 'pedido/v1/mensagemPedidoHistorico';
    return this.httpClient.post<any>(destino, mensagem);
  }

  obterMensagensHistorico(num: any): Observable<any> {
    const destino = this.endpoint + 'pedido/v1/pedidoHistorico';
    let params = new HttpParams();
    params = params.append("numeroPedido", num);
    params = params.append("acao", "MENSAGEM");
    return this.httpClient.get<any>(destino, { params });
  };
}
