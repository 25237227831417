import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';
import {SplitterModule} from 'primeng/splitter';
import {TabViewModule} from 'primeng/tabview';
import {DataViewModule} from 'primeng/dataview';
import {PaginatorModule} from 'primeng/paginator';
import {MultiSelectModule} from 'primeng/multiselect';
import {RadioButtonModule} from 'primeng/radiobutton';
import {TableModule} from 'primeng/table';
import {BuscaSiasgnetLoteComponent} from './busca-siasgnet-lote/busca-siasgnet-lote.component';
import {DetalheMaterialSiasgnetLoteComponent} from './detalhe-material-siasgnet-lote/detalhe-material-siasgnet-lote.component';
import {DetalheServicoSiasgnetLoteComponent} from './detalhe-servico-siasgnet-lote/detalhe-servico-siasgnet-lote.component';
import {ExibirSelecionadosSiasgnetLoteComponent} from './exibir-selecionados-siasgnet-lote/exibir-selecionados-siasgnet-lote.component';
import {PaginaModule} from '../shared/pagina/pagina.module';
import {ListboxModule} from 'primeng/listbox';
import {GovBrAlertMessagesModule} from '@serpro/ngx-dsgovbr';
import {BuscaSiasgnetLoteRoutingModule} from './busca-siasgnet-lote-routing.module';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {GovBrModule} from "../dsgovbr.module";
import {DragDropModule} from '@angular/cdk/drag-drop';
import {OrderListModule} from 'primeng/orderlist';
import {PipeModule} from "../pipe/pipe.module";
import {TooltipModule} from 'primeng/tooltip';
import { SharedModule } from "../shared/shared.module";


@NgModule({
    declarations: [
        BuscaSiasgnetLoteComponent,
        DetalheMaterialSiasgnetLoteComponent,
        DetalheServicoSiasgnetLoteComponent,
        ExibirSelecionadosSiasgnetLoteComponent
    ],
    exports: [
        BuscaSiasgnetLoteComponent,
        DetalheMaterialSiasgnetLoteComponent,
        DetalheServicoSiasgnetLoteComponent,
        ExibirSelecionadosSiasgnetLoteComponent,
        BuscaSiasgnetLoteRoutingModule
    ],
    imports: [
        CommonModule,
        // HttpClientModule,
        FormsModule,
        DropdownModule,
        SplitterModule,
        TabViewModule,
        DataViewModule,
        PaginatorModule,
        MultiSelectModule,
        RadioButtonModule,
        TableModule,
        PaginaModule,
        ListboxModule,
        AutoCompleteModule,
        GovBrAlertMessagesModule,
        GovBrModule,
        DragDropModule,
        OrderListModule,
        PipeModule,
        TooltipModule,
        SharedModule
    ]
})
export class BuscaSiasgnetLoteModule { }
