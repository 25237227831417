<div class="modal fade" id="buscarvalorescaracteristica" style="z-index: 1100">
  <div class="modal-dialog modal-xl modal-dialog-centered modal-cpc">
    <div class="modal-content" style="min-height: 1000px">
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Selecionar os valores para característica</h4>

        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="fechar()"
        >
          &times;
        </button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="col-sm-12">
          <h4>
            {{ caracteristicasEscolhidaEmEdicao?.codigoCaracteristica }} -
            {{ caracteristicasEscolhidaEmEdicao?.nomeCaracteristica }}
          </h4>
        </div>
        <div class="row col-12">
          <div class="col-6">
            <div class="br-input">
              <label>Valor característica: </label>
              <input
                class="p-inputtext mb-2"
                type="text"
                [(ngModel)]="buscaNomeValorCaracteristicaEntrada"
                placeholder="Nome valor caracteristica"
                (keyup)="pressEnterValoresCaracteristicas($event)"
              />
            </div>
          </div>
          <div class="col-3">
            <div class="br-checkbox">
              <input
                id="somenteNomeValor"
                name="somenteNomeValor"
                type="checkbox"
                aria-label="somenteNome"
                (click)="marcador('','somenteNome','')"
                [(ngModel)]="somenteNome"
              />
              <label for="somenteNomeValor">Somente pelo nome</label>
            </div>
            <div class="br-checkbox">
              <input
                id="inicioTextoValor"
                name="inicioTextoValor"
                type="checkbox"
                aria-label="inicioTexto"
                (click)="marcador('inicioTexto','','')"
                [(ngModel)]="inicioTexto"
              />
              <label for="inicioTextoValor">Começa com</label>
            </div>
            <div class="br-checkbox">
              <input
                id="textoExatoValor"
                name="textoExatoValor"
                type="checkbox"
                aria-label="textoExato"
                (click)="marcador('','','textoExato')"
                [(ngModel)]="textoExato"
              />
              <label for="textoExatoValor">Resultado exato</label>
            </div>
          </div>
          <div class="col-3 mb-3" style="align-self: flex-end; text-align: center;">
            <button
              class="br-button primary"
              type="button"
              (click)="buscarValoresCaracteristica()"
              aria-label="Botão de ação"
            >
              Buscar
            </button>
            &nbsp;
            <app-manutencao-valor-caracteristica (atualizarListaAoSair)="salvarNovoValorCaracteristica($event)"></app-manutencao-valor-caracteristica>
          </div>
        </div>
        <div *ngIf="(valoresPdm | json) != (null | json)">
          <p-table
            [value]="valoresPdm"
            [paginator]="true"
            [rows]="10"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} "
            [rowsPerPageOptions]="[5, 10, 25, 50]"
            [(first)]="first"
          >
            <ng-template pTemplate="header" class="row">
              <tr>
                <th
                  pSortableColumn="codigoValorCaracteristica"
                  class="col-sm-3 col-lg-3"
                >
                  Codigo valor característica<p-sortIcon
                  field="codigoValorCaracteristica"
                ></p-sortIcon>
                </th>
                <th
                  pSortableColumn="nomeValorCaracteristica"
                  class="col-sm-3 col-lg-3"
                >
                  Nome valor característica<p-sortIcon
                  field="nomeValorCaracteristica"
                ></p-sortIcon>
                </th>
                <th class="col-sm-3 col-lg-3">Ação</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-valor class="row">
              <tr>
                <td class="col-sm-2 col-lg-2">
                  {{ valor.codigoValorCaracteristica }}
                </td>
                <td class="col-sm-2 col-lg-2">
                  {{ valor.nomeValorCaracteristica }}
                </td>
                <td class="col-sm-3 col-lg-3">
                  <button
                    type="button"
                    class="br-button secondary small"
                    aria-label="selecionar"
                    data-dismiss="modal"
                    (click)="
                      salvarValorCaracteristica(
                        valor.codigoValorCaracteristica,
                        valor.nomeValorCaracteristica,
                        caracteristicasEscolhidaEmEdicao.codigoCaracteristica
                      )
                    "
                  >
                    Selecionar
                  </button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer">
        <button
          type="button"
          class="br-button primary"
          data-dismiss="modal"
          (click)="fechar()"
        >
          <i class="fas fa-arrow-left" aria-hidden="true"> </i>Fechar
        </button>
      </div>
    </div>
  </div>
</div>
