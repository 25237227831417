import { Query } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { UsuarioStore, UsuarioState } from './usuario.store';

@Injectable({
  providedIn: 'root'
})
export class UsuarioQuery extends Query<UsuarioState> {
  usuario$ = this.select('usuario');
  tokenCarregado$ = this.select('tokenCarregado');

  constructor(protected store: UsuarioStore) {
    super(store);
  }

  getUsuario() {
    return this.getValue().usuario;
  }

  getTokenCarregado() {
    return this.getValue().tokenCarregado;
  }
}
