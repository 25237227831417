import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentUtil } from 'src/environments/EnviromentUtil';


@Injectable({
  providedIn: 'root'
})
export class PesquisaService {

  endpoint: string;

  constructor(private httpClient: HttpClient) {
    this.endpoint = EnvironmentUtil.obterUrlBase();
  }

  obterPedidosLegados(
    dataFimPedido: string,
    dataInicioPedido: string,
    situacao: string,
    tipoConsulta: string): Observable<any> {

    const destino = this.endpoint + 'pedido/v1/legado';
    let params = new HttpParams();
    params = params.append("dataFimPedido", dataFimPedido);
    params = params.append("dataInicioPedido", dataInicioPedido);
    params = params.append("situacao", situacao);
    params = params.append("tipoConsulta", tipoConsulta);
    return this.httpClient.get<any>(destino, { params });
  };

  obterPedidos(
    aba: string,
    page: string,
    size: string,
    sortField?: string,
    sortOrder?: number,
    filtro?: any
    ): Observable<any> {

    const destino = this.endpoint + 'pedido/v1';
    let params = new HttpParams();
    params = params.append("aba", aba);
    params = params.append("page", page);
    params = params.append("size", size);
    sortField && sortOrder ? params = params.append("sort", sortField + "," + (sortOrder < 1 ? 'DESC' : 'ASC')) : '';
    // filtro ? params = params.append("filtro", JSON.stringify(filtro)) : '';


    if (filtro) {
      Object.keys(filtro).forEach(function (key) {
          params = filtro[key] && filtro[key].length !== 0 ? params.append(key, filtro[key]) : params;
      });
    }

    return this.httpClient.get<any>(destino, { params });
  };


  obterPedidoLegadoPorNumero(num: any): Observable<any> {
    const destino = this.endpoint + 'pedido/v1/legado/' + num;
    return this.httpClient.get<any>(destino, { });
  }

  obterPedidoPorNumero(num: any): Observable<any> {
    const destino = this.endpoint + 'pedido/v1/' + num;
    return this.httpClient.get<any>(destino, { });
  };

  criarPedido(): Observable<any> {
    const destino = this.endpoint + 'pedido/v1/';
    return this.httpClient.post<any>(destino, { });
  }
}
