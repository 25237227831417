import { StringUtils } from './string-utils';

export class CpfCnpjUtils {
  // @ts-ignore
  static formatCpf(value): string {
    if (value === undefined || value === null) {
      return '';
    }
    value = value.toString();

    value = value.length < 11 ? '0000000000'.substr(0, 11 - value.length) + value : value;

    let ini = 0;
    if (value.length > 11) {
      ini = value.length - 11;
    }

    return `${value.substr(ini, 3)}.${value.substr(ini + 3, 3)}.${value.substr(ini + 6, 3)}-${value.substr(
      ini + 9,
      2
    )}`;
  }

  // static formatCnpj(value): string {
  //   if (value === undefined || value === null) {
  //     return value;
  //   }
  //
  //   value = value.toString();
  //
  //   value = value.length < 14 ? '0000000000000'.substr(0, 14 - value.length) + value : value;
  //
  //   return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  // }

  // static formatCpfCnpj(value): string {
  //   if (!value) {
  //     return null;
  //   }
  //
  //   return value.length <= 11 ? this.formatCpf(value) : this.formatCnpj(value);
  // }

  // static validateCpf(cpf) {
  //   cpf = cpf.toString();
  //
  //   if (cpf.length < 14) {
  //     cpf = this.formatCpf(StringUtils.unmask(cpf));
  //   }
  //
  //   if (cpf === '000.000.000-00') {
  //     return false;
  //   }
  //
  //   const re = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
  //
  //   if (re.test(cpf)) {
  //     const regex = /^(1{11}|2{11}|3{11}|4{11}|5{11}|6{11}|7{11}|8{11}|9{11})$/;
  //
  //     if (regex.test(cpf.replace(/\.|-/g, ''))) {
  //       return false;
  //     }
  //
  //     const digs = cpf.split('-')[1];
  //     const nums = cpf.split('-')[0].match(/\d/g);
  //
  //     return this.dvCpf(nums) === digs;
  //   } else {
  //     return false;
  //   }
  // }
  // private static dvCpf(cpf) {
  //   cpf = cpf.reverse();
  //   let v1 = 0;
  //   let v2 = 0;
  //
  //   for (let i = 0; i < 9; i++) {
  //     v1 = v1 + cpf[i] * (9 - (i % 10));
  //     v2 = v2 + cpf[i] * (9 - ((i + 1) % 10));
  //   }
  //
  //   v1 = (v1 % 11) % 10;
  //   v2 = ((v2 + v1 * 9) % 11) % 10;
  //
  //   return [v1, v2].join('');
  // }

  /**
   * @param tipo - 'F' - Fornecedor Fisico, formata CPF; 'J' - Fornecedor Juridico, formata CNPJ; Outro valor - Retorna sem formatação
   */
  // static identificadorFormatado(tipo: string, identificacao: string): string {
  //   if (tipo === 'F') {
  //     return CpfCnpjUtils.formatCpf(identificacao);
  //   }
  //   if (tipo === 'J') {
  //     return CpfCnpjUtils.formatCnpj(identificacao);
  //   }
  //   return identificacao;
  // }
}
