<div class="modal fade" id="templateSelecionarCpc" tabindex="-1" role="dialog" aria-labelledby="Classe" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-cpc">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Buscar classes de serviço</h4>
        <button type="button" class="close" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="container-lg">
        <div class="row pesquisa-cpc">
          <div class="br-input col-10">
              <input class="cpc-pesquisa-campo p-inputtext" #pesquisaClasse (keyup)="filtrarClasse(pesquisaClasse.value)" placeholder="Filtrar classe"/>
          </div>
        </div>
        <p-table [value]="classeFiltrada" [paginator]="paginarClasse" [rows]="rows" [rowsPerPageOptions]="[5, 10, 25, 50, 100]" responsiveLayout="scroll" [(first)]="first">
          <ng-template pTemplate="header">
            <tr class="row">
              <th pSortableColumn="codigoClasse" class="col-2" >Código<p-sortIcon field="codigoClasse"></p-sortIcon></th>
              <th pSortableColumn="nomeClasse" class="col-7">Nome<p-sortIcon field="nomeClasse"></p-sortIcon></th>
              <th class="col-3">Ação</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-classes>
            <tr class="row">
              <td class="col-2">{{ classes.codigoClasse }}</td>
              <td class="col-7">{{ classes.nomeClasse }}</td>
              <td class="col-3">
                <button
                  type="button"
                  class="br-button secondary small"
                  aria-label="Selecionar"
                  (click)="selectCPC(classes.codigoClasse, classes.nomeClasse)"
                  data-dismiss="modal"
                >
                  Selecionar
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
