import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Service } from 'src/app/service/service';

@Component({
  selector: 'app-busca-servico',
  templateUrl: './busca-servico.component.html',
  styleUrls: ['./busca-servico.component.css']
})
export class BuscaServicoComponent implements OnInit {

  filtroServico: string;
  first: any = 0;
  servicoTela: any = [];
  servicoApi: any = [];
  escondeValor: boolean = true;
  textoBusca: string;
  expandCollapsed: boolean = false;

  constructor(private router: Router, private service: Service) { }

  ngOnInit(): void {
    let pesquisa = sessionStorage.getItem('buscaServicoCatalogador');
    let index = sessionStorage.getItem('buscaServicoCatalogadorPagina')
    if(pesquisa != null){
      this.textoBusca = pesquisa;
      this.pesquisar();
      this.first = index;
      sessionStorage.removeItem('buscaServicoCatalogador');
      sessionStorage.removeItem('buscaServicoCatalogadorPagina')
    }
  }

  criarServico(){
    this.router.navigate(['/criarservico']);
  }

  editarServico(servico: any){
    sessionStorage.setItem('buscaServicoCatalogador', this.textoBusca);
    sessionStorage.setItem('buscaServicoCatalogadorPagina', this.first);
    this.router.navigate(['/manterservico', servico.codigoServico]);
  }

  visualizarServico(servico: any){
    sessionStorage.setItem('buscaServicoCatalogador', this.textoBusca);
    sessionStorage.setItem('buscaServicoCatalogadorPagina', this.first);
    this.router.navigate(['/visualizarservico', servico.codigoServico]);
  }

  voltarTelaInicial(){
    this.router.navigate(['/arealogada']);
  }

  filtrarServico(){
    let aux: string;
    this.first = 0;
    aux = this.filtroServico.trim();
    aux = aux.toUpperCase();
    this.servicoTela = [];
    if(aux != ""){
      for(let i of this.servicoApi){
        if(i.codigoServico != null && i.nomeServicoAcentuado != null){
           if(i.codigoServico.toString().includes(aux) || i.nomeServicoAcentuado.toUpperCase().includes(aux)){
             this.servicoTela.push(i);
           }
        }
      }
    } else {
      this.resetaFiltro();
    }
  }

  resetaFiltro(){
    this.first = 0;
    this.filtroServico = "";
    this.servicoTela = this.servicoApi;
  }

  pesquisar(){
    if(this.textoBusca != ""){
      this.first = 0;
      this.escondeValor = false;
      this.filtroServico = "";
      this.service.obterServicosPorPalavra(this.textoBusca, 'nao').subscribe((tmp)=>{
        this.servicoApi = tmp;
        this.servicoTela = tmp;
        console.log("TMP:" + JSON.stringify(tmp));
      });
    } else {
      this.escondeValor = true;
      this.servicoApi = [];
      this.servicoTela = [];
    }
  }

  enterPesquisar(evento: any){
    if(evento.key == "Enter"){
      this.pesquisar();
    }
  }
}
