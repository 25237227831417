<app-cabecalho-interno></app-cabecalho-interno>
<br /><br /><br />
<div class="container-lg">

  <div class="row">
    <div class="br-breadcrumb col-9">
      <ul class="crumb-list">
        <li class="crumb home">
          <a title="Área de Trabalho" (click)="irParaAT()">
            <span class="sr-only">Página inicial</span>
            <i class="icon fas fa-home"></i>
          </a>
        </li>
        <li class="crumb" data-active="active">
          <i class="icon fas fa-chevron-right"></i>
          <span>Pedidos</span>
        </li>
      </ul>
    </div>
  </div>


  <div class="br-divider"></div>
  <div class="mb-3 pt-0 mt-0"><!-- cabeçalho -->
    <div class="row">
      <div class="col-10" id="cabecalho">
        <h3 class="pb-0 mt-2 mb-2">Pedidos</h3>
        <p>Esta área é para visualização e edição dos pedidos.
          Para realizar um novo pedido é necessário iniciar uma busca no catálogo.</p>
      </div>

      <div *ngIf="isAdministrador() || isCatalogador()"><!-- https://www.primefaces.org/primeng/showcase/#/menu -->
        <button type="button" class="br-button primary mt-3 ml-5" style="height: 40px; width:158px; font-size:18px" id="botaocriar" pButton pRipple icon="pi " label="+ Criar/Editar "
                (click)="menu.toggle($event)" ></button>
        <p-menu #menu [popup]="true" [model]="itemsmenubotao"></p-menu>
      </div>

      <div *ngIf="!isSolicitante()"><!-- https://www.primefaces.org/primeng/showcase/#/menu -->
        <button type="button" class="br-button primary mt-3 ml-5" style="height: 40px; width:158px; font-size:18px" id="botaomenu" pButton pRipple icon="pi " label="Consultas"
                (click)="menu.toggle($event)" ></button>
        <p-menu #menu [popup]="true" [model]="itemsmenuconsultas"></p-menu>
      </div>

      <!-- <div *ngIf="isSolicitante()" style="text-align: end; align-self: center"> -->
      <div *ngIf="isSolicitante()">
        <button class="br-button is-primary mt-2 ml-6" type="button" (click)="irParaCatalogo()" pTooltip="Para solicitar um novo pedido de catalogação de item, clique aqui">
          Catálogo
        </button>
      </div>

    </div>
  </div>

  <div class="painel-pesquisa">
    <div class="row negrito mt-2">
      <div class="col-6">
        <span>Pesquisa de Pedido</span>
      </div>
    </div>
    <div class="row">
      <div class="br-input pl-2 col-11 label">
        <input id="filtro-item" class="p-inputtext" [(ngModel)]="filtro.textoPesquisa" placeholder="Pesquise pelo número ou nome ou uasg solicitante e pressione Enter"
          field="filtro.textoPesquisa" (keyup)="enterPesquisar($event)" [disabled]="loading()"/>
      </div>
      <div class="col-md-1">
        <button class="br-button circle small text-overflow-descricao" (click)="abrirModalFiltroPedido()" type="button"
          aria-label="Filtrar pedidos" pTooltip="Filtrar pedidos" placement="top" id="bt-tooltip" [disabled]="loading()">
          <i class="fas fa-filter pr-1" aria-hidden="true"></i>
        </button>
        <button *ngIf="filtroAtivado"
          class="br-button circle small fas fa-check is-default secondary indicador-filtro-ativo"  type="button" aria-label="Filtrar Pedidos"
          pTooltip="Existem filtros aplicados ao resultado." placement="top">
        </button>
      </div>
    </div>
  </div>

  <ng-template #modalFiltroPedido>
    <p-toast position="top-center">
      <ng-template let-message pTemplate="message">
          <div class="row" style="flex: 1">
              <div class="col-12">
                  <i class="fas fa-exclamation-triangle fa-lg mr-2" aria-hidden="true"></i>
                  <strong class="mr-1">{{message.summary}}</strong>
                  <span>{{message.detail}}</span>
              </div>
          </div>
      </ng-template>
    </p-toast>
    <div class="br-divider"></div>
    <div class="mb-3 pt-0 mt-0"><!-- cabeçalho -->
      <div class="row">
        <div class="col-11" id="cabecalho">
          <h4 class="pb-0 mt-2 mb-2 ml-2 negrito">Filtrar Pedidos</h4>
        </div>
        <div class="col-1">
         <button type="button" class="close mr-3 azul" data-dismiss="modal"
         (click)="fechaModalFiltro()">x</button>
        </div>
      </div>
      
      <div class="row">
        <div class="col-12" id="cabecalho">
          <h6 class="pb-0 mx-4 my-1">Por data de criação</h6>
        </div>
      </div>
      <div class="row pl-4 pr-4 pb-1">
        <div class="br-input col-6">
          <label for="filtro-data-ini">De</label>
          <input id="filtro-data-ini" class="p-inputtext" type="date" [(ngModel)]="filtro.dataInicio" placeholder="data inicial"
            field="filtroDataIni" />
        </div>
        <div class="br-input col-6">
          <label for="filtro-data-fim">Até</label>
          <input id="filtro-data-fim" class="p-inputtext" type="date" [(ngModel)]="filtro.dataFinal" placeholder="data final"
            field="filtroDataFim" />
        </div>
      </div>
      <div class="row">
        <div class="col-12" id="cabecalho">
          <h6 class="pb-0 mx-4 my-1">Por data de atualização</h6>
        </div>
      </div>
      <div class="row pl-4 pr-4 pb-1">
        <div class="br-input col-6">
          <label for="filtro-data-ini">De</label>
          <input id="filtro-data-ini" class="p-inputtext" type="date" [(ngModel)]="filtro.dataInicioAtualizacao" placeholder="data de atualização inicial"
            field="filtroDataIni" />
        </div>
        <div class="br-input col-6">
          <label for="filtro-data-fim">Até</label>
          <input id="filtro-data-fim" class="p-inputtext" type="date" [(ngModel)]="filtro.dataFinalAtualizacao" placeholder="data de atualização final"
            field="filtroDataFim" />
        </div>
      </div>

      <div class="row pl-4 pr-4 pb-1" *ngIf="!isSolicitante()">
        <div class="col-12">
          <label for="filtro-catalogador">Catalogador</label>
          <p-dropdown id="filtro-catalogador"
            optionValue="value" emptyMessage=" " emptyFilterMessage=" " [options]="catalogadorOptions"
            optionLabel="label" [filter]="true" filterBy="label" [showClear]="true"
            placeholder="Catalogador" [(ngModel)]="filtro.catalogador">
          </p-dropdown>
        </div>
      </div>
      <div class="row pl-4 pr-4 pb-1" *ngIf="!isSolicitante()">
        <div class="col-12">
          <label for="filtro-uasg-catalogadora">Unidade Catalogadora</label>
          <p-dropdown id="filtro-uasg-catalogadora"
            optionValue="value" emptyMessage=" " emptyFilterMessage=" " [options]="uasgCatalogadoraOptions"
            optionLabel="label" [filter]="true" filterBy="label" [showClear]="true"
            placeholder="Unidade Catalogadora" [(ngModel)]="filtro.uasgCatalogadora">
          </p-dropdown>
        </div>
      </div>
      <div class="row pl-4 pr-4 pb-1">
        <div class="col-12">
          <label for="filtro-situacao">Situação</label>
          <p-dropdown id="filtro-situacao"
            optionValue="value" emptyMessage=" " emptyFilterMessage=" " [options]="situacaoOptions"
            optionLabel="label" [filter]="true" filterBy="label" [showClear]="true"
            placeholder="Situação" [(ngModel)]="filtro.situacao">
          </p-dropdown>
        </div>
      </div>
      <div class="row pl-4 pr-4 pb-1" *ngIf="!isSolicitante()">
        <div class="col-5">
          <label for="filtro-uasg-solicitante">UASG Solicitante</label>
          <p-inputNumber id="filtro-uasg-solicitante" [mode]="'decimal'"
            [max]="999999" [min]="0" [minFractionDigits]="0" [maxFractionDigits]="0"
            [maxlength]="6" [useGrouping]="false"
            placeholder="UASG Solicitante" [(ngModel)]="filtro.uasgSolicitante">
          </p-inputNumber>
        </div>
        <div class="col-2"></div>
        <div class="col-4">
          <label for="filtro-numero-pedido">Pedido</label>
          <p-inputNumber id="filtro-numero-pedido" [mode]="'decimal'"
            [max]="999999" [min]="0" [minFractionDigits]="0" [maxFractionDigits]="0"
            [maxlength]="6" [useGrouping]="false"
            placeholder="Pedido" [(ngModel)]="filtro.numero">
          </p-inputNumber>
        </div>
        <div class="col-1"></div>
      </div>
      <div class="row">
        <div class="row col-8"></div>
        <div class="col-2" style="text-align: end; align-self: center">
          <button class="br-button is-secondary mt-2" type="button" (click)="limparFiltro()">
            Limpar
          </button>
        </div>
        <div class="col-2" style="text-align: end; align-self: center">
          <button class="br-button is-primary mt-2" type="button" (click)="aplicarFiltro(); fechaModalFiltro()">
            Aplicar
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <br-second-tab-set>
    <br-second-tab [name]="getAbaTitulo(PEDIDOS_ABERTOS)" [active]="!isSolicitante()" *ngIf="!isSolicitante()">
      <app-novas-tabelas-pedidos-catalogador #listaPedidosAbertos [aba]="PEDIDOS_ABERTOS"></app-novas-tabelas-pedidos-catalogador>
    </br-second-tab>
    <br-second-tab [name]="getAbaTitulo(DESIGNADOS_A_MIM)" *ngIf="!isSolicitante()">
      <app-novas-tabelas-pedidos-catalogador #listaDesignadosAMim [aba]="DESIGNADOS_A_MIM"></app-novas-tabelas-pedidos-catalogador>
    </br-second-tab>
    <br-second-tab [name]="getAbaTitulo(DESIGNADOS_A_MINHA_UASG)" *ngIf="!isSolicitante()">
      <app-novas-tabelas-pedidos-catalogador #listaDesignadosAMinhaUasg [aba]="DESIGNADOS_A_MINHA_UASG"></app-novas-tabelas-pedidos-catalogador>
    </br-second-tab>

    <br-second-tab [name]="getAbaTitulo(TODOS)" *ngIf="isAdministrador()">
      <app-novas-tabelas-pedidos-catalogador #listaTodos [aba]="TODOS"></app-novas-tabelas-pedidos-catalogador>
    </br-second-tab>

    <br-second-tab [name]="getAbaTitulo(MEUS_PEDIDOS)" [active]="isSolicitante()" *ngIf="isSolicitante()">
      <app-novas-tabelas-pedidos-catalogador #listaMeusPedidos [aba]="MEUS_PEDIDOS"></app-novas-tabelas-pedidos-catalogador>
    </br-second-tab>
    <br-second-tab [name]="getAbaTitulo(PEDIDOS_DA_MINHA_UASG)" *ngIf="isSolicitante()">
      <app-novas-tabelas-pedidos-catalogador #listaPedidosDaMinhaUasg [aba]="PEDIDOS_DA_MINHA_UASG"></app-novas-tabelas-pedidos-catalogador>
    </br-second-tab>
  </br-second-tab-set>
</div>
