import { PedidoService } from './../service/pedido.service';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessaoService } from 'src/app/service/sessao.service';
import { PesquisaService } from './pedido-catalogador.service';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { PedidoForm } from '../model/pedido-form.model';
import { AuthService } from 'src/app/security/services/auth-service.service';

@Component({
  selector: 'app-pedido',
  templateUrl: './pedido.component.html',
  styleUrls: ['./pedido.component.css']
})

export class PedidoComponent implements OnInit {

  token: any;
  usrProfile:any;
  usrRoles:{[id:string]:boolean};


  numeroPedido:any;
  tipoPedido: any;
  pedido: PedidoForm;
  idPDM: any;
  classePDM: any;

  index: number;
  escondeMenu:boolean = false;
  desabil: boolean = false;
  loading: boolean = false;
  enviaTipoTela: string = 'comprador';

  @ViewChild('BackAlert', {static: false }) BackAlert: any;

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private sessaoService: SessaoService,
    private service: PesquisaService,
    private pedidoService: PedidoService)
  {  }

  ngOnInit(): void {
    this.token = this.authService.token;
    if (this.token){
      this.usrProfile=this.authService.getUserProfile(this.token);
      this.usrRoles=this.authService.getUserRoles(this.token);
    }

    this.route.params.subscribe((params) => this.numeroPedido = params['numeroPedido']);
    if (this.numeroPedido) {
      this.service.obterPedidoPorNumero(this.numeroPedido).subscribe((pedido) => {
        this.pedido = pedido;
        this.pedidoService.setHash(this.pedido);
        this.changeIndex(0);
      }, (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro ao recuperar pedido. Tente novamente.'
        });
      });
    }
  }

  changeIndex(idx:number){
    // Caso o pedido esteja em rascunho, ele é salvo no momento de transitar entre a aba de edição de informações básicas e outras
    if((this.pedidoService.notEqualsHash(this.pedido)) && this.pedido.situacaoPedido === 'RASCUNHO' && this.index === 0 && idx != 0){
      this.patchPedido();
    }
    this.index=idx;
  }

  patchPedido() {
    this.service.alterarPedido(this.pedido).subscribe(pedido => {
      if (pedido){
        this.pedidoService.setHash(this.pedido);
        this.messageService.add({severity: 'success', summary: 'Rascunho atualizado com successo.', detail: ''});
      } else {
        this.messageService.add({severity: 'error', summary: 'Não foi possível salvar o rascunho do pedido.', detail: ''});
      }
      }, error => {
        this.messageService.add({severity: 'error', summary: 'Não foi possível salvar o rascunho do pedido.', detail: 'Entre em contato com área responsável ou tente mais tarde.'});
      }
    );
  }

  changeEstadoMenu(estado:any){
    this.escondeMenu = estado;
  }

  closeWindow() {
    window.close()
  }

  onClickFechar() {
    this.BackAlert.show();
  }

  carregarPedidosPorNumero(event?: LazyLoadEvent) {
    let numPedido = sessionStorage.getItem('numeroPedido');
    this.loading = true;
    this.service.obterPedidoPorNumero(numPedido)
      .subscribe(resp => {
        //this.dadosBasicos = resp;
        this.pedido = resp;
        this.loading = false;
      },
        error => {
          this.loading = false;
          this.messageService.add({
            severity: 'warn',
            summary: 'Erro no carregamento dos dados'
          });
      });
  }

  irParaAT() {
    this.sessaoService.irParaAT();
  }

  salvarEstado(){
    console.log("Clique em Salvar Estado");
  }

  voltar() {
    if(this.tipoPedido == 'M'){
      this.router.navigate(['detalhematerial']);
    }
    if(this.tipoPedido == 'S'){
      this.router.navigate(['detalheservico']);
    }
  }
}
