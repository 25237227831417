<!-- <p-table [value]="itensPedidos" [rows]="page?.size" [paginator]="true" responsiveLayout="scroll" [first]="page?.pageable?.offset" -->
  <p-table #tabela [value]="itensPedidos" [rows]=10 [paginator]="true" responsiveLayout="scroll"
  [rowHover]="true" dataKey="numero"
  [rowsPerPageOptions]="[10, 20, 50, 100]"
  currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" [showCurrentPageReport]="true"
  [lazy]="false" [loading]="loading"
  stateStorage="session" [stateKey]="listaStateSession"
  [globalFilterFields]="['numero', 'nomeMaterialServico', 'uasg', tituloCatalogador]"
  >


  <ng-template pTemplate="header">
  <tr>
      <th class="mx-auto" pSortableColumn="uasg">UASG<p-sortIcon field="uasg"></p-sortIcon></th>
      <th class="mx-auto" pSortableColumn="numero">Número<p-sortIcon field="numero"></p-sortIcon></th>
      <th class="mx-auto" pSortableColumn="data">Data<p-sortIcon field="data"></p-sortIcon></th>
      <th class="mx-auto" pSortableColumn="indicadorMaterialServico">Tipo<p-sortIcon field="indicadorMaterialServico"></p-sortIcon></th>
      <th class="mx-auto" pSortableColumn="nomeMaterialServico">Termo<p-sortIcon field="nomeMaterialServico"></p-sortIcon></th>
      <th *ngIf="sit != 'N'" class="mx-auto" pSortableColumn="nomeReponsavelAnalise">Catalogador<p-sortIcon field="nomeReponsavelAnalise"></p-sortIcon></th>
      <th class="mx-auto text-center">Ações</th>
  </tr>
  </ng-template>

  <ng-template pTemplate="body" let-itemPedido>
      <tr>
          <td>
              <span>{{itemPedido.uasg}}</span>
          </td>
          <td style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width: 150px;">
              <span>{{itemPedido.numero}}</span>
          </td>
          <td >
              <span>{{itemPedido.data.substring(6,8) + '/' +
                      itemPedido.data.substring(4,6) + '/' +
                      itemPedido.data.substring(0,4)}}</span>
          </td>
          <td >
              <span>{{itemPedido.indicadorMaterialServico == 'S' ? 'Serviço' : 'Material'}}</span>
          </td>
          <td >
              <span>{{itemPedido.nomeMaterialServico}}</span>
          </td>
          <td *ngIf="sit != 'N'">
              <span>{{itemPedido.nomeReponsavelAnalise}}</span>
          </td>
          <td class="text-center">
              <button class="br-button circle small text-overflow-descricao"
                  type="button"
                  data-target="#visualizarCotacao"
                  aria-label="Botão Visualizar"
                  tooltip="Visualizar"
                  placement="top"
                  (click)="obtemPedidoPorId(itemPedido.numero)">
                  <i class="fas fa-eye" aria-hidden="true" ></i>
              </button>
          </td>
      </tr>
  </ng-template>
  </p-table>


