<!-- Página: anexos-pedido-component.html -->

<div class="col">
<div class="row">
    <div class="col-md-6">
      <h3 class=" mt-0 mb-0 pt-3 pb-0">Anexos</h3>
      <p></p>
    </div>
</div>

<div class="br-divider mt-2"></div>

<!--<p class="mt-3">
  <p class="mt-3" style="font-size: 13px;"><strong>Informação.</strong> Clique no <b>campo abaixo para anexar arquivos</b> (imagens BMP, GIF, HEIC, HEIF, JPG, JPEG, PNG, SVG, TIFF e WEBP; documentos DOC, DOCX, HTML, ODB, ODC, ODF, ODG, ODP, ODS, ODT, PDF, PPT, PPTX, RTF, SXC, SXD, SXI, SXM, SXW, TXT, XHTML, XLS, XLSX e XML; e pacotes 7Z, RAR e ZIP). O tamanho máximo de cada arquivo é de 15MB.</p>
</p>-->
<!--<div class="col-12">-->
<div class="col-12 mt-3" *ngIf="podeAnexar()">
  <div class="br-message info" style="margin-bottom: 70px">
    <div class="icon">
      <i aria-hidden="true" class="fas fa-info-circle"></i>
    </div>
    <div class="content">
      <div class="message-body">
        <p style="font-size: 13px;"><strong>Informação.</strong> Clique no <b>campo abaixo para anexar arquivos</b> (imagens BMP, GIF, HEIC, HEIF, JPG, JPEG, PNG, SVG, TIFF e WEBP; documentos DOC, DOCX, HTML, ODB, ODC, ODF, ODG, ODP, ODS, ODT, PDF, PPT, PPTX, RTF, SXC, SXD, SXI, SXM, SXW, TXT, XHTML, XLS, XLSX e XML; e pacotes 7Z, RAR e ZIP). O tamanho máximo de cada arquivo é de 15MB.</p>
      </div>
    </div>
  </div>
</div>
<!--<div class="mt-6">-->
<div class="mt-6" *ngIf="podeAnexar()">
  <form #formRef="ngForm">
      <div class="row">
          <div class="col-sm-12">
            <br-file acceptedFiles={{acceptedFiles}} [minFileSize]="1" [maxFileSize]="15000000" [multiple]="true"
                label="" description="" required name="anexo2" [(ngModel)]="fileList">
            </br-file>
          </div>
      </div>

      <div class="row mt-2">
        <div class="col-sm-12">
          <button class="br-button primary float-right small" (click)="confirmarAnexo(formRef)" [attr.disabled]="fileList !=(null || undefined) ? null : 'disabled'" >
                <i aria-hidden="true" class="fa fa-plus"></i> Anexar
          </button>
          <button type="reset" class="br-button secondary float-right mr-3 small">Limpar</button>
        </div>
      </div>
  </form>
</div>

<label class="mt-4 col-12" *ngIf="anexos.length == 0" >Não há arquivos anexados</label>
<label class="mt-4 col-12" *ngIf="anexos.length > 0" >Lista de arquivos anexados</label>
<div class="row col-12">
  <p-table
    *ngIf="anexos.length > 0"
    [value]="anexos"
    [paginator]="true"
    [rows]="5"
    [rowsPerPageOptions]="[5, 10, 25, 50]"
    [totalRecords]="anexos.length"
    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
    [showCurrentPageReport]="true"
  >
    <ng-template pTemplate="header">
        <tr>
            <th class="col-2">Número</th>
            <th class="col-6">Nome</th>
            <th class="col-2">Tamanho</th>
            <th class="col-2">Ações</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-anexo let-rowIndex="rowIndex">
        <tr>
            <td class="col-2">
              {{rowIndex + 1}}
            </td>
            <td class="col-6">
              {{anexo.nomeArquivo}}
            </td>
            <td class="col-2">
              {{(anexo.tamanho / 1000).toLocaleString()}} KBs
            </td>
            <td class="col-2">

              <button type="button" class="br-button small circle" (click)="excluirAnexo.show()"
                tooltip="Excluir anexo"
                placement="bottom"
                aria-label="Excluir anexo"
                *ngIf="podeDeletar()">
                <i class="fas fa-trash" aria-hidden="true"></i>
              </button>

              <button type="button" class="br-button small circle" (click)="downloadAnexo(anexo)"
                tooltip="Baixar anexo"
                placement="bottom"
                aria-label="Baixar anexo">
                <i class="fas fa-download" aria-hidden="true"></i>
              </button>
            </td>
        </tr>
        <br-confirmation  #excluirAnexo popupTitle="Confirme a exclusão" acceptLabel="Sim" rejectLabel="Não"
          question="Deseja realmente excluir este anexo?" (accept)="deletarAnexo(anexo.idArquivo)">
        </br-confirmation>
    </ng-template>
  </p-table>
</div>

</div>
