<div class="modal fade" id="buscarclasse">
  <div class="modal-dialog modal-lg modal-cpc">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Buscar classe de material</h4>
        <button type="button" class="close" data-dismiss="modal">
          &times;
        </button>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <div class="row mb-3 mt-3" style="justify-content: center">
          <div class="col-sm-6 col-lg-6">
            <div class="br-input">
              <input
                id="classeentrada"
                class="p-inputtext"
                type="text"
                placeholder="Filtrar classe"
                [(ngModel)]="classeFiltro"
                (keyup)="filtrarClasse()"
              />
            </div>
          </div>
        </div>

        <p-table
          [value]="classesTela"
          [paginator]="true"
          [rows]="10"
          [showCurrentPageReport]="true"
          responsiveLayout="scroll"
          currentPageReportTemplate="Monstrando {first} a  {last} de  {totalRecords} "
          [rowsPerPageOptions]="[10, 25, 50]"
          [(first)]="first"
        >
          <ng-template pTemplate="header">
            <tr class="row">
              <th pSortableColumn="codigoGrupo" class="col-2">
                Grupo <p-sortIcon field="codigoGrupo"></p-sortIcon>
              </th>
              <th pSortableColumn="nomeGrupo" class="col-3">
                Descrição do grupo <p-sortIcon field="nomeGrupo"></p-sortIcon>
              </th>
              <th pSortableColumn="codigoClasse" class="col-1">
                Classe <p-sortIcon field="codigoClasse"></p-sortIcon>
              </th>
              <th pSortableColumn="nomeClasse" class="col-4">
                Descrição da classe<p-sortIcon field="nomeClasse"></p-sortIcon>
              </th>
              <th class="col-2">Ação</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-classes>
            <tr class="row">
              <td class="col-2">{{ classes.codigoGrupo }}</td>
              <td class="col-3">{{ classes.nomeGrupo }}</td>
              <td class="col-1">{{ classes.codigoClasse }}</td>
              <td class="col-4">{{ classes.nomeClasse }}</td>
              <td class="col-2">
                <button
                  type="button"
                  class="br-button secondary small"
                  data-dismiss="modal"
                  (click)="escolherClasse(classes.codigoClasse)"
                  aria-label="Selecionar"
                >
                  Selecionar
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
