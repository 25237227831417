<app-cabecalho-interno></app-cabecalho-interno>

<br /><br /><br />
<div class="container pl-0">
  <div class="row">
    <div class="col-1 menu" [hidden]="!escondeMenu">
      <div>
        <button class="br-button small circle hamburguer" type="button" aria-label="Menu" (click)="mudaEstadoMenu()"
          title="Expandir menu">
          <i class="fas fa-arrow-right" aria-hidden="true"></i>
        </button>
      </div>
      <hr />
      <div>
        <button [ngClass]="{'primary': index==0}" class="br-button small circle side-bar-content" type="button"
          aria-label="Menu" (click)="mudaIndex('info')" title="Informações Básicas"><i class="fas fa-pencil-alt"
            aria-hidden="true"></i>
        </button>
      </div>
      <div>
        <button [ngClass]="{'primary': index==1}" class="br-button small circle side-bar-content" type="button"
          aria-label="Menu" (click)="mudaIndex('historico')" title="Comunicação"><i class="fas fa-paper-plane"
            aria-hidden="true"></i>
        </button>
      </div>
    </div>

    <div class="col-3 pl-0 pr-0" style="background-color: #f8f8f8; " [hidden]="escondeMenu">
      <div class="row">
        <h4 class="col-9 ml-1">
          <i>Pedidos legados</i>
        </h4>
        <div class="col-2 text-right" style="padding: 7px 10px 0px 0px">
          <button class="br-button small circle" type="button" aria-label="Menu" (click)="mudaEstadoMenu()"
            title="Retrair menu"><i class="fas fa-arrow-left" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <hr>
      <div class="text-center" style="margin-top: -15px; margin-bottom: -15px;">

        <ul class="secao-lateral">
          <a (click)="mudaIndex('info')">
            <li [ngClass]="{'ativo': index==0}">
              <span>
                <i class="fas fa-pencil-alt" aria-hidden="true"></i> Informações Básicas
              </span>
              <i class="pi pi-chevron-right" aria-hidden="true"></i>
            </li>
          </a>
          <a (click)="mudaIndex('historico')">
            <li [ngClass]="{'ativo': index==1}">
              <span>
                <i class="fas fa-paper-plane" aria-hidden="true"></i> Comunicação
              </span>
              <i class="pi pi-chevron-right" aria-hidden="true"></i>
            </li>
          </a>
        </ul>
      </div>
    </div>


    <div [ngClass]="{'col-11': escondeMenu, 'col-9': !escondeMenu, 'corpo-texto-fechado': escondeMenu}">

      <div *ngIf="index == 0"> <!-- Informações básicas -->

        <div class="row">
          <!-- <div class="br-breadcrumb col-9">
            <ul class="crumb-list">
              <li class="crumb home">
                <a title="Área de Trabalho" (click)="irParaAT()">
                  <span class="sr-only">Página inicial</span>
                  <i class="icon fas fa-home"></i>
                </a>
              </li>
              <li class="crumb pr-2">
                <i class="icon fas fa-chevron-right"></i>
                <a href="javascript:void(0)" (click)="irParaPedidos()">Pedidos
                </a>
              </li>
              <li class="crumb" data-active="active">
                <i class="icon fas fa-chevron-right"></i>
                <span>Pedido Nº {{dadosBasicos?.numeroPedido}}</span>
              </li>
            </ul>
          </div> -->
        </div>

        <div class="br-divider"></div>

        <div class="row">
          <div class="col-md-6">
            <h3 class=" mt-0 mb-0 pt-3 pb-0">Informações Básicas</h3>
            <p></p>
          </div>

          <!-- <div class="col-md-6   text-right " style="align-self: center;">
            <button class="br-button is-secondary small ml-2 " type="button" (click)="voltar()">Voltar</button>
          </div> -->

          <div class="col-md-6   text-right " style="align-self: center;">
            <a href="javascript:void()" onclick="window.close()">Fechar</a>
          </div>

        </div>
        <div class="br-divider mt-2"></div>
        <div class="row mt-3">
          <div class="col-sm-4">
            <label>Número</label>
            <div style="font-weight: normal">
              {{dadosBasicos?.numeroPedido}}
            </div>
          </div>
          <div class="col-sm-4">
            <label>UASG</label>
            <div style="font-weight: normal">
              {{dadosBasicos?.codigoUasgResponsavel}} - {{dadosBasicos?.nomeUasgResponsavel}}
            </div>
          </div>
          <div class="col-sm-4">
            <label>Data do Pedido</label>
            <div style="font-weight: normal">
              <span>{{dadosBasicos?.dataPedido.substring(0,2) + '/' +
                dadosBasicos?.dataPedido.substring(2,4) + '/' +
                dadosBasicos?.dataPedido.substring(4,8)}}</span>
              <!-- <span class="ml-2" *ngIf="dadosBasicos?.atraso > 0">em atraso (</span>
              <span *ngIf="dadosBasicos?.atraso > 0">{{dadosBasicos?.atraso}}</span>
              <span *ngIf="dadosBasicos?.atraso > 0">) dias</span> -->
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-4">
            <label>Responsável</label>
            <div style="font-weight: normal">
              {{dadosBasicos?.nomeResponsavelPedido}}
            </div>
          </div>
          <div class="col-sm-4">
            <label>DDD</label>
            <div style="font-weight: normal">
              {{dadosBasicos?.codigoDddResponsavel}}
            </div>
          </div>
          <div class="col-sm-4">
            <label>Telefone</label>
            <div style="font-weight: normal">
              {{dadosBasicos?.numeroTelefoneResponsavel}}
            </div>
          </div>
        </div>
        <br>
        <div class="row mt-9">
          <div class="col-sm-4">
            <label>Email</label>
            <div style="font-weight: normal">
              {{dadosBasicos?.nomeEMailResponsavel}}
            </div>
          </div>
          <div class="col-sm-4">
            <label>Ramal</label>
            <div style="font-weight: normal">
              {{dadosBasicos?.numeroRamalResponsavel}}
            </div>
          </div>
        </div>
        <hr>

        <div class="row mt-3">
          <div class="col-sm-4">
            <label>Tipo</label>
            <div style="font-weight: normal">
              {{dadosBasicos?.nomeTipoMaterial}}
            </div>
          </div>
          <div class="col-sm-4">
            <label>Item</label>
            <div style="font-weight: normal">
              {{dadosBasicos?.nomeMaterialServico}}
            </div>
          </div>
          <div class="col-sm-4">
            <label>Situação</label>
            <div class="text-capitalize" style="font-weight: normal">
              {{dadosBasicos?.textoSituacao}}
            </div>
          </div>
        </div>
        <br>

        <div class="row mt-3">
          <div class="col-sm-4">
            <label>Exclusivo Central de Compras</label>
            <div style="font-weight: normal">
              {{dadosBasicos?.textoExclusivo}}
            </div>
          </div>
          <div class="col-sm-4">
            <label>Sustentável</label>
            <div style="font-weight: normal">
              {{dadosBasicos?.itemSustentavel}}
            </div>
          </div>
          <div class="col-sm-4">
            <label>Unidade de Fornecimento</label>
            <div style="font-weight: normal" *ngFor="let uf of dadosBasicos.nomeItem">
              {{uf}}
            </div>
          </div>
        </div>
        <hr>

        <div class="row mt-3">
          <div class="col-sm-12">
            <label>Características Físicas</label>
            <div style="font-weight: normal">
              {{dadosBasicos?.caracteristicasFisicas}}
            </div>
          </div>
        </div>
        <hr>

        <div class="row mt-3">
          <div class="col-sm-12">
            <label>Aplicação / Justificativa de Sustentabilidade do item</label>
            <div style="font-weight: normal">
              {{dadosBasicos?.aplicacao}}
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="index == 1">
        <app-historico-analise #secaoItens [modoVisualizacao]="this.desabil" [infoDaPesquisa]="dadosBasicos"
          [recebeTipoTela]="enviaTipoTela"></app-historico-analise>
      </div>
    </div>
  </div>
</div>
