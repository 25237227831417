<app-cabecalho-interno></app-cabecalho-interno> <br /><br /><br />
<div class="container-lg">
  <div class="mb-4 mt-2">
    <!-- cabeçalho -->
    <div class="row">
      <div class="col-8" id="cabecalho">
        <h2 class="mb-0" aria-label="Manter Unidade de Fornecimento">
          <span class="mouseover"
            >Catálogo - Unidade de fornecimento</span
          >
        </h2>
        <h6 style="font-weight: 400" class="mb-0 mt-1">
          Use esta área para criar/editar a Unidade de fornecimento
        </h6>
      </div>
      <div class="col-4 text-right row" style="align-items: end;">
        <div class="col-sm-6 card border-0">
          <button
            class="br-button is-secondary"
            type="button"
            (click)="voltarTelaInicial()"
          >
            Voltar
          </button>
        </div>
        <div class="col-sm-6 card border-0">
          <br-loading [visible]="visible" label="Salvando ..."></br-loading>
          <button
            class="br-button is-primary"
            type="button"
            data-toggle="modal"
            data-target="#incluirUnidadeFornecimento"
            (click)="limpaPesquisa()"
          >
            Incluir
          </button>
        </div>
      </div>
    </div>
  </div>

  <hr />

  <br-fieldset [collapsible]="true" fieldsetTitle="Pesquisar Unidade de Fornecimento" [collapsed]="expandCollapsed">
    <div class="row">
      <div class="col-10 mt-2 mb-2">
        <div class="br-input label">
          <input
            [(ngModel)]="buscaUnidadeFornecimento"
            (keyup)="enterPesquisar($event)"
            placeholder="Digite aqui uma palavra para pesquisar"
            field="nome"
          />
        </div>
      </div>
      <div class="col-md-2 mt-2 card border-0">
        <button
        (click)="pesquisar()"
        class="br-button primary justify-self-end"
        type="button"
        >
          Pesquisar
        </button>
      </div>
    </div>
  </br-fieldset>


  <hr />

  <div *ngIf="escondeValor != true">
    <p-table
      [value]="unidadeFornecimentoTela"
      [rows]="10"
      [paginator]="true"
      responsiveLayout="scroll"
      [rowHover]="true"
      dataKey="id"
      [(first)]="first"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} entradas"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[5, 10, 25, 50, 100]"
    >
      <ng-template pTemplate="caption">
        <div class="row flex align-items-center justify-content-between mb-3">
          <div class="br-input ml-3">
            <h5 class="m-0">Unidades de fornecimento</h5>
          </div>

          <div class="br-checkbox">
            <input id="somenteAtivos" name="somente-ativos" type="checkbox" aria-label="somente ativos" (click)="somenteAtivos()" [(ngModel)]="ativos"/>
            <label for="somenteAtivos">Mostrar somente unidades de fornecimento ativas</label>
          </div>

          <div class="br-input col-5">
            <input
              [(ngModel)]="filtroUnidadeFornecimento"
              (keyup)="filtrarUnidadeFornecimento()"
              placeholder="Digite uma palavra para filtrar o resultado"
            />
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="siglaUnidadeFornecimento">
            Sigla<p-sortIcon field="siglaUnidadeFornecimento"></p-sortIcon>
          </th>
          <th pSortableColumn="nomeUnidadeFornecimento">
            Nome <p-sortIcon field="nomeUnidadeFornecimento"></p-sortIcon>
          </th>
          <th pSortableColumn="possuiEmbalagem">
            Embalagem?<p-sortIcon field="possuiEmbalagem"></p-sortIcon>
          </th>
          <th pSortableColumn="statusUnidadeFornecimento">
            Ativo <p-sortIcon field="statusUnidadeFornecimento"></p-sortIcon>
          </th>
          <th>Ações</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-valor>
        <tr>
          <td>
            <span *ngIf="valor.statusUnidadeFornecimento  == true" style="color: black;" > {{ valor.siglaUnidadeFornecimento }}</span>
            <span *ngIf="valor.statusUnidadeFornecimento  == false" style="color: #9f9a9a" > {{ valor.siglaUnidadeFornecimento }}</span>
          </td>
          <td>
            <span *ngIf="valor.statusUnidadeFornecimento  == true" style="color: black;" >{{ valor.nomeUnidadeFornecimento }}</span>
            <span *ngIf="valor.statusUnidadeFornecimento  == false" style="color: #9f9a9a" >{{ valor.nomeUnidadeFornecimento }}</span>
          </td>
          <td>
            <span *ngIf="valor.statusUnidadeFornecimento  == true" style="color: black;" >{{ valor.possuiEmbalagem ? "Sim" : "Não" }}</span>
            <span *ngIf="valor.statusUnidadeFornecimento  == false" style="color: #9f9a9a;" >{{ valor.possuiEmbalagem ? "Sim" : "Não" }}</span>
          </td>
          <td>
            <span *ngIf="valor.statusUnidadeFornecimento  == true" style="color: black;" >{{ valor.statusUnidadeFornecimento ? "Sim" : "Não" }}</span>
            <span *ngIf="valor.statusUnidadeFornecimento  == false" style="color: #9f9a9a;" >{{ valor.statusUnidadeFornecimento ? "Sim" : "Não" }}</span>
          </td>
          <td>
            <button
              class="br-button"
              type="button"
              data-toggle="modal"
              data-target="#visualizarUnidadeFornecimento"
              aria-label="Botão de ação"
              pTooltip="Visualizar"
              (click)="pegarDados('visualiza', valor)"
              tooltipPosition="top"
            >
              <i class="fas fa-eye" aria-hidden="true"></i>
            </button>
            <button
              class="br-button"
              type="button"
              data-toggle="modal"
              data-target="#editarUnidadeFornecimento"
              pTooltip="Editar"
              (click)="pegarDados('edita', valor)"
              tooltipPosition="top"
            >
              <i class="fas fa-edit" aria-hidden="true"></i>
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div
    id="incluirUnidadeFornecimento"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="Classe"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-media">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Incluir unidade de fornecimento</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="container-lg">
          <div class="row mt-3">
            <div class="col-md-8">
              <div class="row">
                <label class="col-8">
                  Sigla da unidade de fornecimento<span class="asterisco">*</span>
                </label>
                <label class="col-4 texto-menor" style="text-align: end">
                  Caracteres restantes: <span>{{ numMaxSigla }}</span>
                </label>
              </div>
              <div *ngIf="existeSigla" >
                <label class="col-4" style="color: red;">
                  Sigla já cadastrada
                </label>
              </div>
              <div class="br-input">
                <input #campoSigla
                  (blur)="validaSigla(campoSigla)"
                  [(ngModel)]="incluirSigla"
                  placeholder="Digite a sigla da unidade de fornecimento"
                  (keydown)="enableSave()"
                  (keyup)="decrementaTexto('sigla')"
                  maxlength="7"
                />
              </div>
            </div>
            <div class="col-md-4 align-self-end" style="text-align: end">
              <div
                class="br-switch medium switch-basic"
                style="margin-left: 10px"
              >
                <input
                  id="possuiEmbalagem"
                  type="checkbox"
                  [(ngModel)]="incluirPossuiEmbalagem"
                  readonly
                />
                <label for="possuiEmbalagem">Possui embalagem</label>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <label *ngIf="!existeNome"  class="col-8">
              Nome da unidade de fornecimento<span class="asterisco">*</span>
            </label>
            <label *ngIf="existeNome"  class="col-5">
              Nome da unidade de fornecimento<span class="asterisco">*</span>
            </label>
            <label *ngIf="existeNome"  class="col-3" style="color: red;">
              Nome já cadastrado.
            </label>
            <label class="col-4 texto-menor" style="text-align: end"
              >Caracteres restantes: <span>{{ numMaxNome }}</span></label
            >
          </div>
          <div class="br-input">
            <input #campoNome
              (blur)="validaNome(campoNome)"
              [(ngModel)]="incluirNome"
              placeholder="Digite o nome da unidade de fornecimento"
              (keydown)="enableSave()"
              (keyup)="decrementaTexto('nome')"
              maxlength="20"
            />
          </div>
          <div class="row mt-3">
            <label class="col-8">Descrição da unidade de fornecimento</label>
            <label class="col-4 texto-menor" style="text-align: end"
              >Caracteres restantes:
              <span>{{ numMaxDescricaoIncluir }}</span></label
            >
          </div>
          <div class="br-textarea">
            <textarea
              [(ngModel)]="incluirDescricao"
              placeholder="Digite a descrição da unidade de fornecimento"
              (keyup)="decrementaTexto('descricaoIncluir')"
              maxlength="9550"
            ></textarea>
          </div>
          <!-- <div class="row mt-3">
            <label class="col-8">Código internacional da unidade de fornecimento</label>
            <label class="col-4 texto-menor" style="text-align: end"
              >Caracteres restantes: <span>{{ numMaxCodigoOtan }}</span></label
            >
          </div>
          <div class="br-input">
            <input
              [(ngModel)]="incluirCodigoOtan"
              placeholder="Digite o código internacional da unidade de fornecimento"
              (keyup)="decrementaTexto('codigoOtan')"
              maxlength="2"
            />
          </div>
          <div class="row mt-3">
            <label class="col-8">Nome internacional da unidade de fornecimento</label>
            <label class="col-4 texto-menor" style="text-align: end"
              >Caracteres restantes: <span>{{ numMaxNomeOtan }}</span></label
            >
          </div>
          <div class="br-input mb-5">
            <input
              [(ngModel)]="incluirNomeOtan"
              placeholder="Digite o nome internacional da unidade de fornecimento"
              (keyup)="decrementaTexto('nomeOtan')"
              maxlength="30"
            />
          </div> -->
        </div>
        <div class="modal-footer">
          <div>
            <button class="br-button secondary" data-dismiss="modal">
              Voltar
            </button>
          </div>
          <div>
            <button
              class="br-button primary"
              (click)="salvarUnidadeFornecimento()"
              data-dismiss="modal"
              [disabled]="disableSave"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    id="editarUnidadeFornecimento"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="Classe"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-media">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Editar unidade de fornecimento</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="container-lg">
          <div class="row">
            <div class="br-input mt-3 col-md-8">
              <label>Sigla da unidade de fornecimento</label>
              <input [(ngModel)]="editaSigla" disabled />
            </div>
            <div class="col-md-4 align-self-end" style="text-align: end">
              <div
                class="br-switch medium switch-basic mb-2"
                style="margin-left: 10px"
              >
                <input id="status" type="checkbox" [(ngModel)]="editaStatus" />
                <label for="status">Ativo</label>
              </div>

              <div
                class="br-switch medium switch-basic mb-2"
                style="margin-left: 10px"
              >
                <input
                  id="checkPossuiEmbalagem"
                  type="checkbox"
                  [(ngModel)]="editaPossuiEmbalagem"
                />
                <label for="checkPossuiEmbalagem">Possui Embalagem</label>
              </div>
            </div>
          </div>
          <div class="br-input mt-3">
            <label>Nome da unidade de fornecimento</label>
            <input [(ngModel)]="editaNome" disabled />
          </div>
          <div class="row mt-3">
            <label class="col-8">Descrição da unidade de fornecimento</label>
            <label class="col-4 texto-menor" style="text-align: end"
              >Caracteres restantes:
              <span>{{ numMaxDescricaoEditar }}</span></label
            >
          </div>
          <div class="br-textarea">
            <textarea
              [(ngModel)]="editaDescricao"
              placeholder="Digite a descrição da unidade de fornecimento"
              (keyup)="decrementaTexto('descricaoEditar')"
              maxlength="9550"
            ></textarea>
          </div>
          <!-- <div class="br-input mt-3">
            <label>Código internacional da unidade de fornecimento</label>
            <input [(ngModel)]="editaCodigoOtan" disabled />
          </div>
          <div class="br-input mt-3 mb-5">
            <label>Nome internacional da unidade de fornecimento</label>
            <input [(ngModel)]="editaNomeOtan" disabled />
          </div> -->
        </div>
        <div class="modal-footer">
          <div>
            <button class="br-button secondary" data-dismiss="modal">
              Voltar
            </button>
          </div>
          <div>
            <button
              class="br-button primary"
              (click)="editarUnidadeFornecimento()"
              data-dismiss="modal"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    id="visualizarUnidadeFornecimento"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="Classe"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-media">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Visualizar unidade de fornecimento</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="container-lg">
          <div class="row">
            <div class="br-input mt-3 col-md-8">
              <label>Sigla da unidade de fornecimento</label>
              <input [(ngModel)]="visualizaSigla" readonly />
            </div>
            <div class="col-md-4 align-self-end" style="text-align: end">
              <div
                class="br-switch medium switch-basic mb-2"
                style="margin-left: 10px"
              >
                <input
                  id="status"
                  type="checkbox"
                  [(ngModel)]="visualizaStatus"
                  readonly
                />
                <label for="status">Ativo</label>
              </div>
              <div
                class="br-switch medium switch-basic"
                style="margin-left: 10px"
              >
                <input
                  id="possuiEmbalagem"
                  type="checkbox"
                  [(ngModel)]="visualizaPossuiEmbalagem"
                  readonly
                />
                <label for="possuiEmbalagem">Possui embalagem</label>
              </div>
            </div>
          </div>
          <div class="br-input mt-3">
            <label>Nome da unidade de fornecimento</label>
            <input [(ngModel)]="visualizaNome" readonly />
          </div>
          <div class="br-textarea mt-3">
            <label>Descrição da unidade de fornecimento</label>
            <textarea
              [(ngModel)]="visualizaDescricao"
              placeholder="Digite a descrição da unidade de fornecimento"
              readonly
            ></textarea>
          </div>
          <!-- <div class="br-input mt-3">
            <label>Código internacional da unidade de fornecimento</label>
            <input [(ngModel)]="visualizaCodigoOtan" readonly />
          </div>
          <div class="br-input mt-3 mb-5">
            <label>Nome internacional da unidade de fornecimento</label>
            <input [(ngModel)]="visualizaNomeOtan" readonly />
          </div> -->
        </div>
        <div class="modal-footer">
          <div>
            <button class="br-button secondary" data-dismiss="modal">
              Voltar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #unidadeFornecimentoSucess>
    <div>
      <div class="modal-header">
        <h4 class="modal-title">Valor incluído com sucesso</h4>
        <button type="button" class="close" (click)="sairModalSalvar()">
          &times;
        </button>
      </div>
      <div class="container-lg">
        <div
          class="flex justify-content-center mb-5 mt-5"
          style="text-align: center"
        >
          <label>Unidade de fornecimento incluida com sucesso.</label><br />
          <label>A sigla da unidade de fornecimento é: {{ novaSigla }}</label>
        </div>
      </div>
      <div class="modal-footer">
        <div>
          <button class="br-button secondary" (click)="sairModalSalvar()">
            Voltar
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #unidadeFornecimentoEditado>
    <div>
      <div class="modal-header">
        <h4 class="modal-title">Unidade de fornecimento editada com sucesso</h4>
        <button type="button" class="close" (click)="sairModalEditar()">
          &times;
        </button>
      </div>
      <div class="container-lg">
        <div
          class="flex justify-content-center mb-5 mt-5"
          style="text-align: center"
        >
          <label>Unidade de fornecimento editada com sucesso.</label>
        </div>
      </div>
      <div class="modal-footer">
        <div>
          <button class="br-button secondary" (click)="sairModalEditar()">
            Voltar
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
