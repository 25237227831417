import { MessageService } from 'primeng/api';
import { Component, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DesignarPedidoService } from '../designar-pedido/designar-pedido.service';

@Component({
  selector: 'app-designar-pedido',
  templateUrl: './designar-pedido.component.html',
  styleUrls: ['./designar-pedido.component.css']
})
export class DesignarPedidoComponent implements OnInit {

  @Input() numeroPedido: any;
  @Input() estadoSelecionado: string;
  @Input() currentCpfCatalogador:string;
  @Input() currentCodigoUasg:number;

  public selectedDesignado: any;
  public tipoSelectedDesignado: string;

  public uasgList: any;
  public catalogadorList: any;
  public activeList: any;



  constructor(
    private messageService: MessageService,
    private service:DesignarPedidoService, private route:ActivatedRoute) { }

  ngOnInit(): void {

  }

  ngOnChanges(){
    if(this.estadoSelecionado==='ABERTO') this.selectDesignadoList('uasg');
    else if(this.estadoSelecionado==='DESIGNADO') this.selectDesignadoList('catalogador');
  }
  
  selectDesignadoAtual(tipoDesignado:string){
    let idx=-1;
    if (tipoDesignado=='catalogador') idx = this.activeList.findIndex( (x:any) => x.cpfCatalogador===this.currentCpfCatalogador);
    else if (tipoDesignado==='uasg') idx = this.activeList.findIndex( (x:any) => x.codigoUasg===this.currentCodigoUasg);

    this.selectedDesignado=this.activeList[idx];
    
  }

  selectDesignadoList(tipoDesignado:string){
    // seleciona a lista ativa (lista de catalogadores ou de uasg)
    if(tipoDesignado==='catalogador'){
      this.tipoSelectedDesignado='CATALOGADOR';
      if(this.catalogadorList===undefined) {
        this.service.listarCatalogadores().subscribe( (list)=>{
          this.catalogadorList=list.map( (item:any)=>{
            item.displayName = `${item.nomeCatalogador} - ${item.cpfCatalogador}`;
            item.value = item.cpfCatalogador;
            return item;
          })
          this.activeList=this.catalogadorList;
          this.selectDesignadoAtual('catalogador');
        })
      } else {this.activeList=this.catalogadorList; this.selectDesignadoAtual('catalogador');;}
    }
    else if(tipoDesignado==='uasg'){
      this.tipoSelectedDesignado='UASG';
      if(this.uasgList===undefined){
        this.service.listarUasgs().subscribe( (list)=>{
          this.uasgList=list.map( (item:any)=>{
            item.displayName = `${item.nomeDesignacao}`;
            item.value = item.codigoUasg;
            return item;
          })
          this.activeList=this.uasgList;
          this.selectDesignadoAtual('uasg');
        }) 
      } else {this.activeList=this.uasgList; this.selectDesignadoAtual('uasg');}
    }

  }

  designar(){
    let data = {
      "numeroPedido": this.numeroPedido,
      "cpfCatalogador":'',
      "codigoUasgCatalogador":''
    }
    // caso esteja sendo designado para UASG
    if(this.estadoSelecionado=="ABERTO"){
      data['codigoUasgCatalogador']=this.selectedDesignado.codigoUasg;
    }
    // caso esteja sendo designado para catalogador
    else if(this.estadoSelecionado=="DESIGNADO"){
      data['cpfCatalogador']=this.selectedDesignado.cpfCatalogador;
    }


    if(confirm(`Executar a designação?\nPedido número ${this.numeroPedido}\n${this.selectedDesignado.displayName}`)) {

      this.service.designarPedido(data).subscribe( (resp)=>{
        window.location.reload();
      }, error => {
      this.messageService.add({severity: 'error', summary: 'Não foi possível alterar o pedido.', detail: 'Entre em contato com área responsável ou tente mais tarde.'});
      })

    } else{
      this.messageService.add({severity: 'error', summary: 'Pedido não designado.', detail: 'Cancelado pelo usuário'});
    }

  }

}
