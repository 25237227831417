import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CabecalhoComponent } from './cabecalho/cabecalho.component';
import {CabecalhoInternoComponent} from './cabecalho-interno/cabecalho-interno.component';
import {ComprasnetCabecalhoGovernoModule} from '@serpro/comprasnet-cabecalho-governo';
import {GovBrHeaderModule} from "@serpro/ngx-dsgovbr";
import {ComprasnetHubAcessoSistemasModule} from "@serpro/comprasnet-hub-acesso-sistemas";

@NgModule({
  declarations: [
    CabecalhoComponent, CabecalhoInternoComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ComprasnetCabecalhoGovernoModule,
    GovBrHeaderModule,
    ComprasnetHubAcessoSistemasModule

  ],
  exports: [CabecalhoComponent, CabecalhoInternoComponent],
})
export class PaginaModule {}
