import { BuscaService } from './../../../service/busca-service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-manter-unidade-fornecimento',
  templateUrl: './manter-unidade-fornecimento.component.html',
  styleUrls: ['./manter-unidade-fornecimento.component.css']
})
export class ManterUnidadeFornecimentoComponent implements OnInit {

  unidadeFornecimentoApi: any = [];
  unidadeFornecimentoTela: any = [];
  escondeValor: boolean = true;
  filtroUnidadeFornecimento: string = "";
  buscaUnidadeFornecimento: string = "";
  expandCollapsed: boolean = false;

  incluirSigla: string = "";
  incluirNome: string = "";
  incluirDescricao: string = "";
  incluirPossuiEmbalagem: boolean = true;
  disableSave: boolean = true;
  existeSigla: boolean = false;
  existeNome: boolean = false;

  editaSigla: string;
  editaNome: string;
  editaDescricao: string;
  editaStatus: boolean;
  editaPossuiEmbalagem: boolean;
  editaCodigoOtan: string;
  editaNomeOtan: string;

  visualizaSigla: string;
  visualizaNome: string;
  visualizaDescricao: string;
  visualizaStatus: boolean;
  visualizaPossuiEmbalagem: boolean;

  first: any = 0;
  visible: boolean = false;
  novaSigla: string = "000000";
  ativos: boolean = false;

  modalRef1: BsModalRef;
  @ViewChild('unidadeFornecimentoSucess', {static: false} ) unidadeFornecimentoSucess: any;
  @ViewChild('unidadeFornecimentoEditado', {static: false} ) unidadeFornecimentoEditado: any;

  numMaxSigla: number = 7;
  numMaxNome: number = 20;
  numMaxDescricaoIncluir: number = 9550;
  numMaxDescricaoEditar: number = 9550;

  constructor(private router: Router, private buscaService: BuscaService, private modalService: BsModalService, private messageService: MessageService) { }

  ngOnInit(): void {
  }

  voltarTelaInicial(): void {
    this.router.navigate(['/arealogada']);
  }

  pesquisar(){
    let aux: string;
    this.first = 0;
    aux = this.buscaUnidadeFornecimento;
    if(aux != ""){
      aux = this.buscaUnidadeFornecimento.trim();
      aux = this.buscaUnidadeFornecimento.toUpperCase();
      this.buscaService.obterUnidadeFornecimentoCompletoPDM(aux).subscribe(tmp => {
        this.unidadeFornecimentoApi = tmp;
        this.unidadeFornecimentoTela = tmp;
      });
    } else {
      this.buscaService.obterUnidadeFornecimentoCompletoPDM("").subscribe(tmp =>{
        this.unidadeFornecimentoApi = tmp;
        this.unidadeFornecimentoTela = tmp;
      });
    }
    console.log(this.unidadeFornecimentoApi)
    this.escondeValor = false;
  }

  filtrarUnidadeFornecimento(){
    this.ativos = false;
    let aux: string;
    this.first = 0;

    if (this.filtroUnidadeFornecimento !== undefined){
      aux = this.filtroUnidadeFornecimento.trim();
      aux = this.filtroUnidadeFornecimento.toUpperCase();
    } else {
      aux = '';
    }

    this.unidadeFornecimentoTela = [];
    if(aux != ""){
      for( let i of this.unidadeFornecimentoApi){
        if(i.siglaUnidadeFornecimento != null && i.nomeUnidadeFornecimento != null){
          if(i.siglaUnidadeFornecimento.toString().includes(aux) || i.nomeUnidadeFornecimento.toUpperCase().includes(aux)){
            this.unidadeFornecimentoTela.push(i);
          }
        }
      }
    }
    else{
      this.resetaFiltroUnidadeFornecimento();
    }
  }

  somenteAtivos(){
    if(this.ativos == false){
      let auxArray = [];
      for(let i of this.unidadeFornecimentoTela){
        if(i.statusUnidadeFornecimento == true){
          auxArray.push(i);
        }
      }
      this.unidadeFornecimentoTela = auxArray;
    } else {
      this.filtrarUnidadeFornecimento();
    }
  }


  resetaFiltroUnidadeFornecimento(){
    this.first = 0;
    this.filtroUnidadeFornecimento = "";
    this.unidadeFornecimentoTela = this.unidadeFornecimentoApi;
  }

  enterPesquisar(evento: any){
    if(evento.key == "Enter"){
      this.pesquisar();
    }
  }

  pegarDados(tipo:string, codigo: any){
    if(tipo == "edita"){
      this.editaSigla = codigo.siglaUnidadeFornecimento;
      this.editaNome = codigo.nomeUnidadeFornecimento;
      this.editaDescricao = codigo.descricaoUnidadeFornecimento;
      this.editaStatus = codigo.statusUnidadeFornecimento;
      this.editaPossuiEmbalagem = codigo.possuiEmbalagem;
      this.editaCodigoOtan = codigo.codigoOtan;
      this.editaNomeOtan = codigo.nomeOtan;
      this.numMaxDescricaoEditar = 9550;
      this.numMaxDescricaoEditar = this.numMaxDescricaoEditar - (this.editaDescricao.length);
    } else {
      this.visualizaSigla = codigo.siglaUnidadeFornecimento;
      this.visualizaNome = codigo.nomeUnidadeFornecimento;
      this.visualizaDescricao = codigo.descricaoUnidadeFornecimento;
      this.visualizaStatus = codigo.statusUnidadeFornecimento;
      this.visualizaPossuiEmbalagem = codigo.possuiEmbalagem;
      // this.visualizaCodigoOtan = codigo.codigoOtan;
      // this.visualizaNomeOtan = codigo.nomeOtan;
    }
  }

  salvarUnidadeFornecimento(){
    this.visible = true;
    let modalOptions: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.buscaService.salvarUnidadeFornecimentoPDM({
      siglaUnidadeFornecimento: this.incluirSigla.trim(),
      nomeUnidadeFornecimento: this.incluirNome.trim(),
      descricaoUnidadeFornecimento: this.incluirDescricao.trim(),
      possuiEmbalagem: this.incluirPossuiEmbalagem,
      statusUnidadeFornecimento: true,
      codigoOtan: "",
      nomeOtan: ""
    }).subscribe((tmp) => {
      this.visible = false;
      console.log('sucesso');
      console.log(tmp.siglaUnidadeFornecimento);
      this.novaSigla = tmp.siglaUnidadeFornecimento;
      this.incluirSigla = "";
      this.incluirNome = "";
      this.incluirDescricao = "";
      this.incluirPossuiEmbalagem = true;
      // this.incluirCodigoOtan = "";
      // this.incluirNomeOtan = "";
      this.modalRef1 = this.modalService.show(this.unidadeFornecimentoSucess, modalOptions);
    },
      erro => {
        this.visible = false;
        if(typeof(erro.error) == "string"){
          this.messageService.add({
            severity: 'error',
            summary: 'Erro ao salvar os dados no servidor: ' + erro.error
            });
        }else{
          this.messageService.add({
            severity: 'error',
            summary: 'Não foi possível se conectar com o serviço neste momento, por favor tente mais tarde.'
            });
        }
    });
  }

  editarUnidadeFornecimento(){
    let modalOptions: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.visible = true;
    this.buscaService.editarUnidadeFornecimentoPDM({
      siglaUnidadeFornecimento: this.editaSigla.trim(),
      nomeUnidadeFornecimento: this.editaNome.trim(),
      descricaoUnidadeFornecimento: this.editaDescricao.trim(),
      possuiEmbalagem: this.editaPossuiEmbalagem,
      statusUnidadeFornecimento: this.editaStatus,
      codigoOtan: this.editaCodigoOtan,
      nomeOtan: this.editaNomeOtan
    }).subscribe(tmp => {
      this.visible = false;
      this.modalRef1 =
      this.modalService.show(this.unidadeFornecimentoEditado, modalOptions)
    }, erro => {
      this.visible = false;
      if(typeof(erro.error) == "string"){
        this.messageService.add({
          severity: 'error',
          summary: 'Erro ao salvar os dados no servidor: ' + erro.error
          });
      }else{
        this.messageService.add({
          severity: 'error',
          summary: 'Não foi possível se conectar com o serviço neste momento, por favor tente mais tarde.'
          });
      }
    });
  }

  enableSave(){
    if(this.incluirNome == "" || this.incluirSigla == ""){
      this.disableSave = true;
    } else{
      this.disableSave = false;
    }
  }

  decrementaTexto(campo: string){
    if(campo == "sigla"){
      this.numMaxSigla = 7;
      this.numMaxSigla = this.numMaxSigla - (this.incluirSigla.length);
    }

    if(campo == "nome"){
      this.numMaxNome = 20;
      this.numMaxNome = this.numMaxNome - (this.incluirNome.length);
    }

    if(campo == "descricaoIncluir"){
      this.numMaxDescricaoIncluir = 9550;
      this.numMaxDescricaoIncluir = this.numMaxDescricaoIncluir - (this.incluirDescricao.length);
    }

    if(campo == "descricaoEditar"){
      this.numMaxDescricaoEditar = 9550;
      this.numMaxDescricaoEditar = this.numMaxDescricaoEditar - (this.editaDescricao.length);
    }

    // if(campo == "codigoOtan"){
    //   this.numMaxCodigoOtan = 2;
    //   this.numMaxCodigoOtan = this.numMaxCodigoOtan - (this.incluirCodigoOtan.length);
    // }

    // if(campo == "nomeOtan"){
    //   this.numMaxNomeOtan = 30;
    //   this.numMaxNomeOtan = this.numMaxNomeOtan - (this.incluirNomeOtan.length);
    // }
  }

  sairModalEditar(){
    this.modalRef1.hide();
    this.pesquisar();
  }

  sairModalSalvar(){
    window.location.reload();
  }

  limpaPesquisa(){
    // this.incluirCodigoOtan = "";
    this.incluirDescricao = "";
    this.incluirNome = "";
    // this.incluirNomeOtan = "";
    this.incluirPossuiEmbalagem = false;
    this.incluirSigla = "";
    this.escondeValor = true;
    this.buscaUnidadeFornecimento = "";
    this.unidadeFornecimentoTela = [];
    this.filtroUnidadeFornecimento = "";
    this.numMaxSigla = 7;
    this.numMaxNome = 20;
    this.numMaxDescricaoIncluir = 9550;
    this.existeNome = false;
    this.existeSigla = false;
    // this.numMaxCodigoOtan = 2;
    // this.numMaxNomeOtan = 30;
  }

  validaSigla(campo: any){
    let aux = this.incluirSigla.trim();
    this.buscaService.validaSiglaExistenteUnidadeFornecimento(aux).subscribe((tmp)=>{
      if(tmp > 0){
        this.existeSigla = true;
        this.disableSave = true;
        this.incluirSigla = "";
        campo.focus();
      }
      else {
        this.existeSigla = false;
      }
    })
  }

  validaNome(campo: any){
    let aux = this.incluirNome.trim();
    this.buscaService.validaNomeExistenteUnidadeFornecimento(aux).subscribe((tmp)=>{
      if(tmp > 0){
        this.existeNome = true;
        this.disableSave = true;
        this.incluirNome = "";
        campo.focus();
      }
      else {
        this.existeNome = false;
      }
    })
  }

}
