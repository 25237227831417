import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../security/services/auth-service.service';
import {SessaoService} from '../../service/sessao.service';

@Component({
  selector: 'app-sessao-encerrada',
  templateUrl: './sessao-encerrada.component.html',
  styleUrls: ['./sessao-encerrada.component.css']
})
export class SessaoEncerradaComponent implements OnInit {

  constructor(private sessaoService: SessaoService) { }

  ngOnInit(): void {
  }

  // tslint:disable-next-line:typedef
  redirecionarParaLogin() {
    this.sessaoService.irParaLoginAsp();
  }

  // tslint:disable-next-line:typedef
  irParaComprasNetAspInicio() {
    this.sessaoService.irParaComprasNetAspInicio();
  }
}
