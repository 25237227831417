export class Constantes {
  static readonly DEFAULT_PAGE_SIZE = 10;
  static readonly MSG_CHAT_PAGE_SIZE = 5;
  static readonly STORAGE_REFRESHTOKEN = 'refreshToken';
  static readonly STORAGE_ACCESSTOKEN = 'accessToken';
 //  static readonly STORAGE_TOKEN = 'token';
  static readonly IP_FIXO = '192.168.1.1';

  static readonly LABELS_CALENDAR_PTBR = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    monthNames: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar',
    hourFormat: '24',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Sem'
  };
}
