import {environment} from './environment';

export class EnvironmentUtil {

  public static obterUrlAplicacao(): string {
    const href: string = window.location.href;
    if (href.indexOf('localhost') > 0){
      return window.location.origin;
    }
    if (href.indexOf('des-cnbs') > 0 || href.indexOf('des.cnbs') > 0){
      return 'https://des-cnbs.np.estaleiro.serpro.gov.br/cnbs-web';
    }
    if (href.indexOf('tre.cnbs') > 0 || href.indexOf('treinamento.catalogo') > 0 || href.indexOf('tre-cnbs') > 0){
      return 'https://treinamento.catalogo.compras.gov.br/cnbs-web';
    }
    if (href.indexOf('hom.cnbs') > 0 || href.indexOf('hom-cnbs') > 0){
      return 'https://hom-cnbs.estaleiro.serpro.gov.br/cnbs-web';
    }
    return 'https://catalogo.compras.gov.br/cnbs-web';
  }

  public static obterUrlBase(): string {

    const href: string = window.location.href;

    if (href.indexOf('localhost') > 0){
      //return 'http://localhost:8080/cnbs-api/'; // descomentar se quiser forçar api local
      return 'https://des-cnbs.np.estaleiro.serpro.gov.br/cnbs-api/';
    }
    if (href.indexOf('des-cnbs') > 0 || href.indexOf('des.cnbs') > 0) {
      return 'https://des-cnbs.np.estaleiro.serpro.gov.br/cnbs-api/';
    }
    if (href.indexOf('tre.cnbs') > 0 || href.indexOf('treinamento.catalogo') > 0 || href.indexOf('tre-cnbs') > 0){
      return 'https://tre-cnbs.estaleiro.serpro.gov.br/cnbs-api/';
    }
//     if (href.indexOf('tre-cnbs') > 0 || href.indexOf('treinamento.catalogo') > 0){
//       return 'https://tre-cnbs.estaleiro.serpro.gov.br/cnbs-api/';
//     }
    if (href.indexOf('hom.cnbs') > 0 || href.indexOf('hom-cnbs') > 0){
      return 'https://hom-cnbs.estaleiro.serpro.gov.br/cnbs-api/';
    }
//     if (href.indexOf('hom-cnbs') > 0){
//       return 'https://hom-cnbs.estaleiro.serpro.gov.br/cnbs-api/';
//     }
    return 'https://cnbs.estaleiro.serpro.gov.br/cnbs-api/';
  }

  public static isLocalhost(): boolean {
    return window.location.href.indexOf('127.0.0.1:') > 0 || window.location.href.indexOf('localhost:') > 0;
  }

  public static isLoginFakeAtivo(): boolean {
    //return !EnvironmentUtil.isProducao() && !EnvironmentUtil.isTreinamento();
    return !EnvironmentUtil.isProducao();
  }

  private static isProducao(): boolean {
    return (window.location.href.indexOf('https://cnbs.estaleiro.serpro.gov.br') > 0
      || window.location.href.indexOf('https://catalogo.compras.gov.br') > 0);
  }

  private static isTreinamento(): boolean {
    //return (window.location.href.indexOf('tre-cnbs') > 0
    return (window.location.href.indexOf('tre.cnbs') > 0
      || window.location.href.indexOf('treinamento.catalogo') > 0
      || window.location.href.indexOf('tre-cnbs') > 0);
  }

  public static isDesenvolvimento(): boolean {
    return window.location.href.indexOf('des-cnbs') > 0;
  }

  public static isHomologacao(): boolean {
    //return window.location.href.indexOf('hom-cnbs') > 0;
    return window.location.href.indexOf('hom.cnbs') > 0;
  }
}





