import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'busca'},
  {
    path: 'busca',
    loadChildren: () => import('./busca/busca.module').then((m) => m.BuscaModule)
  },
  {
    path: 'buscasiasgnet',
    loadChildren: () => import('./busca-siasgnet/busca-siasgnet.module').then((m) => m.BuscaSiasgnetModule)
  },
  {
    path: 'buscasiasgnetlote',
    loadChildren: () => import('./busca-siasgnet-lote/busca-siasgnet-lote.module').then((m) => m.BuscaSiasgnetLoteModule)
  },
  {
    path: 'acesso-nao-autorizado',
    loadChildren: () => import('./shared/components/acesso-nao-autorizado/acesso-nao-autorizado.module').then(m => m.AcessoNaoAutorizadoModule)
  },

];





@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
