<div class="container-lg">
    <div class="row pesquisa mt-3 " >
      <div class="col-12 mt-2 mb-2">
        <div class="br-input label">
          <p-autoComplete #hint  [suggestions]="results"
                          (keyup)="liberarPesquisa($event)"  (keyup.enter)="pesquisarEnter($event)"
                          placeholder="Digite aqui o material ou serviço a ser pesquisado" field="nome"
                          scrollHeight="500px">
<!--            <ng-template  let-item pTemplate="item">-->
<!--              <div (click)="buscaEscolhido(item.codigo, item.tipo, item.nome)" >-->
<!--                {{item.tipo}} - {{item.nome}}-->
<!--              </div>-->
<!--            </ng-template>-->
          </p-autoComplete>

          <button (click)="pesquisar()" style="position: absolute; z-index: 1; margin-left: -40px; margin-top: 8px;"
                  class="br-button circle small" type="button" aria-label="Ícone ilustrativo">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
    </div>
</div>



