import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BuscaComponent} from './busca/busca.component';
import {DetalheMaterialComponent} from './detalhe-material/detalhe-material.component';
import {DetalheServicoComponent} from './detalhe-servico/detalhe-servico.component';
import {ExibirSelecionadosComponent} from './exibir-selecionados/exibir-selecionados.component';


const routes: Routes = [
  { path: '', component: BuscaComponent},
  { path: 'detalhematerial', component: DetalheMaterialComponent },
  { path: 'detalheservico', component: DetalheServicoComponent },
  { path: 'exibirselecionados', component: ExibirSelecionadosComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class BuscaRoutingModule{}
