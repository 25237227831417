import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { FiltroService } from 'src/app/arealogada/service/filtro.service';
import { ABA, FiltroPedido } from '../area-catalogador.component';
import { PesquisaService } from '../pesquisa.service';
import { EnvironmentUtil } from 'src/environments/EnviromentUtil';

@Component({
  selector: 'app-novas-tabelas-pedidos-catalogador',
  templateUrl: './tabela-pedidos.component.html',
  styleUrls: ['./tabela-pedidos.component.css']
})
export class NovasTabelasPedidosCatalogadorComponent implements OnInit {

  @Input()
  aba: string;

  url: any;

  filtro: FiltroPedido;

  loading: boolean = false;
  itensPedidos: any;
  page: any;

  @ViewChild('tabela') tabela: Table;

  constructor(private service: PesquisaService,
    private messageService: MessageService,
    private router: Router,
    private filtroService: FiltroService) { }

  ngOnInit(): void {
    this.filtroService.filtroAlterado$.subscribe(filtro => {
      this.filtro = filtro; this.carregarPedidos(null);
    });
  }

  getAbaTitulo(aba: string): string {
    return aba === ABA.DESIGNADOS_A_MIM ? 'Designados a mim' :
      aba === ABA.DESIGNADOS_A_MINHA_UASG ? 'Designados a minha Unidade' :
      aba === ABA.PEDIDOS_ABERTOS ? 'Pedidos abertos' :
      aba === ABA.TODOS ? 'Todos os pedidos' :
      aba === ABA.MEUS_PEDIDOS ? 'Meus Pedidos' :
      aba === ABA.PEDIDOS_DA_MINHA_UASG ? 'Pedidos da minha Unidade' :
      '';
  }

  carregarPedidos(event?: any) {
    this.loading = true;
    const abaMsg = this.getAbaTitulo(this.aba);
    const page = event ? (event.first / event.rows).toFixed(0) : '0';
    const rows = event ? event.rows : 10;
    const sortField = event ? event.sortField : null;
    const sortOrder = event ? event.sortOrder : null;
    this.service.obterPedidos(this.aba, page, rows, sortField, sortOrder, this.filtro)
      .subscribe(resp => {
        resp ? this.itensPedidos = resp.content : this.itensPedidos = [];
        this.page = resp;
        this.loading = false;
      },
        error => {
          this.itensPedidos = [];
          this.loading = false;
          this.messageService.add({
            severity: 'warn',
            summary: 'Erro no carregamento de ' + abaMsg
          });
        });
  }
  obtemPedidoPorId(id: any) {
    this.router.navigate([]).then(result => {
       window.open(EnvironmentUtil.obterUrlAplicacao() + '/pedido/' + id, '_blank'); });
  }

}
