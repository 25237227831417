<!-- Menu  -->
<div class="col menu h-100" [hidden]="!escondeMenu">
  <div>
    <button class="br-button small circle hamburguer" type="button" aria-label="Menu" (click)="mudaEstadoMenu()"
      title="Expandir menu">
      <i class="fas fa-arrow-right" aria-hidden="true"></i>
    </button>
  </div>
  <hr />
  <div>
    <button [ngClass]="{'primary': index==0}" class="br-button small circle side-bar-content" type="button"
      aria-label="Menu" (click)="mudaIndex('info')" title="Informações Básicas"><i class="fas fa-pencil-alt"
        aria-hidden="true"></i>
    </button>
  </div>
  <div>
    <button [ngClass]="{'primary': index==3}" class="br-button small circle side-bar-content" type="button"
      aria-label="Menu" (click)="mudaIndex('anexos')" title="Anexos"><i class="fas fa-paperclip"
        aria-hidden="true"></i>
    </button>
  </div>
  <div>
    <button [ngClass]="{'primary': index==1}" class="br-button small circle side-bar-content" type="button"
      aria-label="Menu" (click)="mudaIndex('historicoAnalise')" title="Comunicação"><i class="fas fa-paper-plane"
        aria-hidden="true"></i>
    </button>
  </div>
  <div *ngIf="usrProfile!=='SOLICITANTE'">
    <button [ngClass]="{'primary': index==2}" class="br-button small circle side-bar-content" type="button"
      aria-label="Menu" (click)="mudaIndex('historicoPedido')" title="Histórico Pedido"><i class="fas fa-history"
        aria-hidden="true"></i>
    </button>
  </div>
</div>

<div class="col pl-0 pr-0 h-100" style="background-color: #f8f8f8; " [hidden]="escondeMenu">
  <div class="row">
    <h4 class="col-9 ml-1">
      <i>Pedido</i>
    </h4>
    <div class="col-2 text-right" style="padding: 7px 10px 0px 0px">
      <button class="br-button small circle" type="button" aria-label="Menu" (click)="mudaEstadoMenu()"
        title="Retrair menu"><i class="fas fa-arrow-left" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <hr>
  <div class="text-center" style="margin-top: -15px; margin-bottom: -15px;">

    <ul class="secao-lateral">

      <a (click)="mudaIndex('info')">
        <li [ngClass]="{'ativo': index==0}">
          <span>
            <i class="fas fa-pencil-alt" aria-hidden="true"></i> Informações Básicas
          </span>
          <i class="pi pi-chevron-right" aria-hidden="true"></i>
        </li>
      </a>

      <a (click)="mudaIndex('anexos')">
        <li [ngClass]="{'ativo': index==3}">
          <span>
            <i class="fas fa-paperclip" aria-hidden="true"></i> Anexos
          </span>
          <i class="pi pi-chevron-right" aria-hidden="true"></i>
        </li>
      </a>

      <a (click)="mudaIndex('historicoAnalise')">
        <li [ngClass]="{'ativo': index==1}">
          <span>
            <i class="fas fa-paper-plane" aria-hidden="true"></i> Comunicação
          </span>
          <i class="pi pi-chevron-right" aria-hidden="true"></i>
        </li>
      </a>

      <div *ngIf="usrProfile !=='SOLICITANTE'">
        <a (click)="mudaIndex('historicoPedido')">
          <li [ngClass]="{'ativo': index==2}">
            <span>
              <i class="fas fa-history" aria-hidden="true"></i> Histórico Pedido
            </span>
            <i class="pi pi-chevron-right" aria-hidden="true"></i>
          </li>
        </a>
      </div>

    </ul>
  </div>
</div>
<!-- Fim Menu  -->
