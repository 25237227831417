export class Item {
  id: number;
  nome: string;
  descricao: string;
  unidade: any;
  tipo: any;
  carrinhoNome?: any;
  carrinhoCaracteristicas?: any;
  sequencial?: number;
  itemSustentavel?: any;
  codigoNcm?:any;
  aplicaMargemPreferencia?:boolean;
}
