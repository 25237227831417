  <div class="container-lg">

    <hr>

    <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
      <h5 class="m-0 p-0" aria-label="Itens Selecionados">Itens Selecionados <div class="br-tag count danger"><span>{{ itens.length }}</span>
      </div></h5>

      <div class="flex-container">
        <div *ngIf="itensExcluidos.length > 0" style="margin-right: 15px; margin-top: 5px;">
          <button class="br-button secondary small" type="button" (click)="desfazer()" aria-label="Desfazer">Desfazer</button>
        </div>
        <div style="margin-right: 15px; margin-top: 5px;">
          <button type="button" (click)="continuar()" class="br-button secondary small" aria-label="Continuar Pesquisando">Continuar Pesquisando</button>
        </div>
        <div style="margin-right: 15px; margin-top: 5px;">
          <button type="button" (click)="clean.show()" class="br-button secondary small" aria-label="Nova Pesquisa">Nova Pesquisa</button>
          <br-confirmation #clean popupTitle="Confirmação de exclusão" acceptLabel="Sim" rejectLabel="Não"
            question="Essa ação apagará sua pesquisa atual. Deseja realmente limpar o carrinho?"
            (accept)="novaPesquisa()">
          </br-confirmation>
        </div>
        <div style="margin-right: 15px; margin-top: 5px;">
          <button type="button" (click)="adiciona_siasgnet()" class="br-button primary  small"  aria-label="Adicionar ao Siasgnet"
            tooltipPosition="top">Adicionar ao Siasgnet</button>
        </div>
      </div>
    </div>

    <hr>

     <span style="font-size: 16px; color: #9f9a9a;">* Característica Inativa</span>
    <div class="row mb-4">
      <div class="col-12">
        <p-orderList [value]="itens" [dragdrop]="true" controlsPosition="right" (onReorder)="ordenar()">
          <ng-template pTemplate="header">
            <div class="flex-container" style="align-items:center;">
              <div class="col-1">Tipo </div>
              <div class="col-1">Código </div>
              <div class="col-6">Nome </div>
              <div class="col-1"><span pTooltip="Nomenclatura Comum do Mercosul (NCM)<br><br>Regulamentado pelo Decreto N<sup>o</sup> 11.890, de 22 de Janeiro de 2024" 
                tooltipPosition="bottom" [escape]="false">NCM</span></div>
              <div class="col-2">Unidade Selecionada</div>
              <div class="col-1" style="text-align: center;"> Ação </div>
            </div>
          </ng-template>
          <ng-template pTemplate="item" let-item>
            <div class="flex-container" style="align-items:center;">
              <div class="col-1">
                <div *ngIf="item.tipo =='MATERIAL'">
                  <div class="br-tag text support-12 m-0">
                    <span style="font-size: 12px">MATERIAL
                    </span>
                  </div>
                </div>
                <div *ngIf="item.tipo =='SERVIÇO'">
                  <div class="br-tag text support-10 m-0">
                    <span style="font-size: 12px">SERVIÇO
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-1">{{item.id}} <span *ngIf="item.sustentavel == true" class="fas fa-leaf small"  pTooltip="Sustetável"></span></div>
              <div class="col-6">
                <div *ngIf="item.tipo === 'MATERIAL'">
                  <td class="col-5"><b>{{item.carrinhoNome}} </b><br>
                    <div  *ngFor="let categoria of item.carrinhoCaracteristicas?.split('|')">
                          <span *ngIf=" categoria.charAt(0) == '#' ">
                            <p class="pb-0 mb-0">
                             <span style="color: black;">{{categoria.substring(1, categoria.indexOf(":")+1)}}
                                {{categoria.substring(categoria.indexOf(":")+1)}}
                             </span>
                            </p>
                          </span>
                          <span *ngIf=" categoria.charAt(0) == '&' ">
                            <p class="pb-0 mb-0">
                               <span style="color: #9f9a9a;">{{categoria.substring(1, categoria.indexOf(":")+1)}}
                                  {{categoria.substring(categoria.indexOf(":")+1)}}
                                </span>
                            </p>
                          </span>
                    </div>
                  </td>
                </div>
                <div *ngIf="item.tipo === 'SERVIÇO'">
                  <td class="col-5"><strong>{{item.nome}}</strong></td>
                </div>
              </div>
              <div class="col-1">{{item.codigoNcm | ncmPipe}} <span></span></div>
              <div class="col-2">{{ item.unidade.nomeUnidadeMedida }}</div>
              <!--
              <div *ngIf="item.tipo === 'SERVIÇO'" class="col-2">{{item.nomeUnidadeMedida}}</div>
              <div *ngIf="item.tipo === 'MATERIAL'" class="col-2">{{item.nomeUnidadeFornecimento}}</div>
              -->
              <div class="col-1" style="text-align: center;">
                <button type="button" class="br-button secondary circle small" (click)="openModal1(item.id)" aria-label="Ícone Ilustrativo"><i
                    class="fas fa-trash-alt"></i></button>
              </div>
            </div>

          </ng-template>
          <br-confirmation #confirmExclusao popupTitle="Confirmação de exclusão" acceptLabel="Sim" rejectLabel="Não"
            question="Deseja realmente excluir esse item?" (accept)="excluir(excluirId)">
          </br-confirmation>
        </p-orderList>
      </div>
    </div>
