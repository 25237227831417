export class ValorCaracteristica {
 //  valor: string;
  nome: string;
  ativo: boolean;
  codigoValorCaracteristica: string;
  codigoCaracteristica: string;
}


export class UnidadeMedidaPDM {
  siglaUnidadeMedida?: string;
  nomeUnidadeMedida?: string;
  descricaoUnidadeMedida?: string;
  statusUnidade?: boolean;
  possuiEmbalagem?: boolean;
}

export class CaracteristicaPdm {
  id?: string;
  codigoCaracteristica?: string;
  nomeCaracteristica?: string;
  detalheCaracteristica: string;
  statusCaracteristica: boolean;
  ativo: boolean;
  obrigatorio: boolean;
  valoresCaracteristica: ValorCaracteristica[];
  unidadesMedida: UnidadeMedidaPDM[];


  constructor() {
    this.valoresCaracteristica = [];
    this.unidadesMedida = [];
  }

}
