import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
// import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';
import {SplitterModule} from 'primeng/splitter';
import {TabViewModule} from 'primeng/tabview';
import {DataViewModule} from 'primeng/dataview';
import {PaginatorModule} from 'primeng/paginator';
import {MultiSelectModule} from 'primeng/multiselect';
import {RadioButtonModule} from 'primeng/radiobutton';
import {TableModule} from 'primeng/table';
import {TooltipModule} from 'primeng/tooltip';
import { TagModule } from 'primeng/tag';

import {BuscaComponent} from './busca/busca.component';
import {DetalheMaterialComponent} from './detalhe-material/detalhe-material.component';
import {DetalheServicoComponent} from './detalhe-servico/detalhe-servico.component';
import {ExibirSelecionadosComponent} from './exibir-selecionados/exibir-selecionados.component';
import {PaginaModule} from '../shared/pagina/pagina.module';
import {ListboxModule} from 'primeng/listbox';
import {GovBrAlertMessagesModule} from '@serpro/ngx-dsgovbr';
import {GovBrModule} from '../dsgovbr.module';
import {BuscaRoutingModule} from './busca-routing.module';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {SidebarModule} from 'primeng/sidebar';
import {SplitButtonModule} from 'primeng/splitbutton';
import {SlideMenuModule} from 'primeng/slidemenu';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {OrderListModule} from 'primeng/orderlist';
import { SharedModule } from "../shared/shared.module";



@NgModule({
    declarations: [
        BuscaComponent,
        DetalheMaterialComponent,
        DetalheServicoComponent,
        ExibirSelecionadosComponent
    ],
    exports: [
        BuscaRoutingModule,
        ExibirSelecionadosComponent,
        BuscaComponent,
    ],
    imports: [
        CommonModule,
        // HttpClientModule,
        FormsModule,
        DropdownModule,
        SplitterModule,
        TabViewModule,
        DataViewModule,
        PaginatorModule,
        MultiSelectModule,
        RadioButtonModule,
        TableModule,
        PaginaModule,
        ListboxModule,
        GovBrAlertMessagesModule,
        GovBrModule,
        AutoCompleteModule,
        SidebarModule,
        SplitButtonModule,
        SlideMenuModule,
        TooltipModule,
        TagModule,
        DragDropModule,
        OrderListModule,
        SharedModule
    ]
})
export class BuscaModule { }
