import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Servico } from 'src/app/busca/model/servico.model';
import {ActivatedRoute, Router} from '@angular/router';
import {Service} from '../../service/service';
import {Material} from '../model/material.model';
import {Item} from '../model/item.model';
import {Pai} from '../model/pai.model';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-detalhe-servico-siasgnet',
  templateUrl: './detalhe-servico-siasgnet.component.html',
  styleUrls: ['./detalhe-servico-siasgnet.component.css']
})
export class DetalheServicoSiasgnetComponent implements OnInit {
  @Output() aoVoltar = new EventEmitter<any>();
  duplicado: any;
  idServico: any;
  nomeServico: any;
  unidMedida: any [] = [];
  classificaContabil: any [] = [];
  selected: any;
  item: Item;
  itens: Item[] = [];

  @Input()
  servico: any;

  constructor(private route: ActivatedRoute,
              private service: Service, private router: Router,
              private messageService: MessageService) { }

  ngOnInit(): void {

    this.idServico = this.servico.id;
    this.nomeServico = this.servico.nome;

    this.pesquisar();
    this.getItens();
  }

  pesquisar(): void {
   // this.service.obterUnidadeMedida(this.idServico).subscribe(unidadeMedida => this.unidMedida = unidadeMedida);
    this.service.obterUnidadeMedida(this.idServico).subscribe(unidadeMedida => {
      this.unidMedida = unidadeMedida ;
      if (unidadeMedida){
        for (const tmp of unidadeMedida) {
          this.unidMedida.push();
        }
      }
      if (this.unidMedida.length === 1){
        this.selected = this.unidMedida[0];
      }
    });
    this.service.obterClassificacaoContabilPorCodigo(this.idServico).subscribe(classContabil => this.classificaContabil = classContabil);
  }

  escolher(): void {

    if (this.selected == null){
      this.messageService.add({severity: 'warn', summary: 'Selecione a unidade de fornecimento.', detail: ''});

      return;
    }
    const pai = new Pai();
    this.item = new Item();
    this.item.id = this.idServico;
    this.item.nomeUnidadeMedida = this.selected.nomeUnidadeMedida;
    this.item.siglaUnidadeMedida = this.selected.siglaUnidadeMedida;

    this.item.type = 'S';
    this.item.sustentavel = false;
    this.item.descricaoDetalhada = this.nomeServico;
    this.item.descricao = this.nomeServico;

    pai.item = this.item;
    console.log(JSON.stringify(pai.item));
    window.opener.postMessage(pai , '*');
    window.close();
  }

  getItens(): void{
    const saved = localStorage.getItem('itens');
    if (saved != null){
      this.itens = JSON.parse(saved);
    }
  }
  voltar(): void {
    // this.router.navigate(['/buscasiasgnet']);
    this.aoVoltar.emit();
  }

}

