
<div class="container-lg container mt-3" >
  <p-tabView [(activeIndex)]="indexpainel" *ngIf="servicos?.length > 0 || materiais?.length > 0">

    <p-tabPanel header="Serviços" *ngIf="servicos?.length > 0"  ><!-- painel do serviçco -->
      <div class="row">


          <div class="col-md-3 col-sm-12 card " style="margin-left: -4px; margin-right: 4px;" *ngIf="servicos?.length > 0">
            <div><h6 class="mb-1"><i class="fas fa-sliders-h mr-1"></i>Filtros</h6></div>
            <div><h6 class="text-uppercase mb-2" style="font-size: 12px;"> Grupos de Serviços</h6></div>
            <hr>
            <div class="pt-1 pb-1">
              <p-listbox [listStyle]="{'max-height':'650px'}"  [options]="gruposServico" [(ngModel)]="selectedGrupo" optionLabel="name"
                         (onClick)="grupoEscolhido($event)">
                <ng-template let-itens pTemplate="item">
                  <div *ngIf="itens.name == 'TODOS OS GRUPOS'"><STRONG>{{itens.name}}</STRONG></div>
                  <div *ngIf="itens.name != 'TODOS OS GRUPOS'">{{itens.name}}</div>
                </ng-template>
              </p-listbox>
            </div>
          </div>


          <div class="col-md-9 col-sm-12 card" style="margin-left: 4px; margin-right: -4px;" *ngIf="servicos?.length > 0">
            <div class="col-md-12">
              <div style="display: flex; flex-direction: row; align-items: center;">
                 <h6 class="p-1 m-0" style="font-size: 14px;">Foram encontrados {{ servicos?.length }} serviços para os filtros selecinados </h6>
<!--                <div class="br-tag interaction-select small" *ngIf="selectedGrupo?.name">-->
<!--                  <span class="texto-overflow">{{ selectedGrupo?.name }}</span>-->
<!--                  <i class="fas fa-times" aria-hidden="true" (click)="limparGrupo()"></i>-->
<!--                </div>-->
              </div>
              <p-table [value]="servicos"  (sortFunction)="customSort($event)" [customSort]="true" [paginator]="paginarServico" [rows]="rows"
                       [rows]="10" [rowsPerPageOptions]="[5,10,25,50,100]" >
                <ng-template pTemplate="header">
                  <tr class="row">
                    <th class="col-2" pSortableColumn="codigoGrupo" >Grupo <p-sortIcon field="codigoGrupo"></p-sortIcon></th>
                    <th class="col-2" pSortableColumn="codigoServico" >Serviço <p-sortIcon field="codigoServico"></p-sortIcon></th>
                    <th class="col-6" pSortableColumn="descricaoServicoAcentuado" >Descrição <p-sortIcon field="descricaoServicoAcentuado"></p-sortIcon></th>
                    <th class="col-2" pSortableColumn="statusServico">Ações <p-sortIcon field="statusServico"></p-sortIcon></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-servicos >
                  <tr class="row">
                    <td class="col-2">{{servicos.codigoGrupo}}   </td>
                    <td class="col-2">{{servicos.codigoServico}}  </td>
                    <td class="col-6">{{ servicos.descricaoServicoAcentuado }}</td>
                    <td class="col-2">
                      <button  type="button" class="br-button  secondary  small" aria-label="Selecionar"
                              (click)="detalheServico(servicos.codigoServico, servicos.descricaoServicoAcentuado)"  >
                        Selecionar
                      </button>

                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
        <div>
          <div class="col-12 card" *ngIf="servicos?.length <= 0">
            <div class="d-flex justify-content-center">
              <div class="row " *ngIf="!servicos?.length">
                <div class="col-12 ">
                  <div style="display: flex; flex-direction: column; align-items: center;">
                    <img class="imagem align-content-center" src="assets/imagem/empty.svg" width="250" alt="Imagem de Busca"/>
                    <h5>Nenhum serviço a ser exibido. </h5>
                    Utilize o campo de busca para pesquisar materiais ou serviços e visualizar as opções.
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
    </p-tabPanel><!-- fim dp painel do serviçco -->


    <p-tabPanel header="Materiais" *ngIf="materiais?.length > 0" ><!-- painel do material -->
      <div class="row" *ngIf="materiais?.length > 0">

        <!-- filtro -->
        <div class="col-md-3 col-sm-12 card" style="margin-left: -4px; margin-right: 4px;">
          <div><h6 class="mb-1"><i class="fas fa-sliders-h mr-1"></i>Filtros</h6></div>
          <div><h6 class="text-uppercase mb-2" style="font-size: 12px;"> Classes de  Materiais</h6></div>
          <hr>
          <div class="pt-1 pb-1">
            <p-listbox [listStyle]="{'max-height':'650px'}"  [options]="classesMaterial" [(ngModel)]="selectedClasse" optionLabel="name" (onClick)="classeEscolhida($event)">
              <ng-template let-itens pTemplate="item">
                  <div *ngIf="itens.name == 'TODAS AS CLASSES'"><STRONG>{{itens.name}}</STRONG></div>
                  <div *ngIf="itens.name != 'TODAS AS CLASSES'">{{itens.name}}</div>
              </ng-template>
            </p-listbox>
          </div>
        </div>


        <div class="col-md-9 col-sm-12 card" style="margin-left: 4px; margin-right: -4px;">
          <div style="display: flex; flex-direction: column;" *ngIf="materiais?.length > 0">
              <h6 class="p-1 m-0" style="font-size: 14px;">Foram encontrados {{ materiais?.length }} pdms para os filtros selecionados</h6>
          </div>
          <p-table [value]="materiais" [paginator]="paginarMaterial" [rows]="rows"  (sortFunction)="customSort($event)" [customSort]="true"
                     [rows]="10" [rowsPerPageOptions]="[5,10,25,50,100]">
            <ng-template pTemplate="header">
                <tr class="row">
                  <th class="col-2" pSortableColumn="codigoClasse" >Classe <p-sortIcon field="codigoClasse"></p-sortIcon></th>
                  <th class="col-2" pSortableColumn="codigoPdm" >Código <p-sortIcon field="codigoPdm"></p-sortIcon></th>
                  <th class="col-6" pSortableColumn="nomePdm" >Padrão Descritivo de Material<p-sortIcon field="nomePdm"></p-sortIcon></th>
                  <th class="col-2" pSortableColumn="statusPDM">Ações <p-sortIcon field="statusPDM"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-materiais >
                <tr class="row">
                  <td class="col-2">{{materiais.codigoClasse}}  </td>
                  <td class="col-2">{{materiais.codigoPdm}}  </td>
                  <td class="col-6">{{ materiais.nomePdm }}</td>
                  <td class="col-2">
                    <button  type="button" class="br-button  secondary  small" aria-label="Selecionar"
                            (click)="detalheMaterial(materiais.codigoPdm, materiais.nomePdm, null)"  >
                      Selecionar
                    </button>

                  </td>
                </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </p-tabPanel><!-- fim painel do material -->

  </p-tabView>
</div>

<div class="container-lg">
  <div class="row" >

  </div>
</div>
