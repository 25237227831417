import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentUtil } from 'src/environments/EnviromentUtil';

@Injectable({
  providedIn: 'root'
})
export class PesquisaService {

  endpoint: string;

  constructor(private httpClient: HttpClient) {
    this.endpoint = EnvironmentUtil.obterUrlBase();
  }

  obterPedidosLegados(
    dataFimPedido: string,
    dataInicioPedido: string,
    situacao: string,
    tipoConsulta: string): Observable<any> {

    const destino = this.endpoint + 'pedido/v1/legado';
    let params = new HttpParams();
    params = params.append("dataFimPedido", dataFimPedido);
    params = params.append("dataInicioPedido", dataInicioPedido);
    if (situacao) {
       params = params.append("situacao", situacao);
    }
    params = params.append("tipoConsulta", tipoConsulta);
    return this.httpClient.get<any>(destino, { params });
  };

  obterPedidoLegadoPorNumero(num: any): Observable<any> {
    const destino = this.endpoint + 'pedido/v1/legado/' + num;
    return this.httpClient.get<any>(destino, { });
  };

  criarPedido(informacoesIniciais: any): Observable<any> {
    const destino = this.endpoint + 'pedido/v1/';
    return this.httpClient.post<any>(destino, informacoesIniciais);
  }

  alterarPedido(informacoesPedido: any): Observable<any> {
    const destino = this.endpoint + 'pedido/v1/';
    return this.httpClient.patch<any>(destino, informacoesPedido);
  }

  obterPedidoPorNumero(num: any): Observable<any> {
    const destino = this.endpoint + 'pedido/v1/' + num;
    return this.httpClient.get<any>(destino, { });
  };

  obterHistoricoPedido(num: any): Observable<any> {
    const destino = this.endpoint + 'pedido/v1/pedidoHistorico';
    let params = new HttpParams();
    params = params.append("numeroPedido", num);
    // params = params.append("acao", acao);
    return this.httpClient.get<any>(destino, { params });
  };
}
