import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Service } from 'src/app/service/service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { BuscaService } from 'src/app/service/busca-service';

@Component({
  selector: 'app-manter-item-material',
  templateUrl: './manter-item-material.component.html',
  styleUrls: ['./manter-item-material.component.css'],
})
export class ManterItemMaterialComponent implements OnInit {
  caracteristica: any;
  params: any;
  novoItem: any = false;
  visible: boolean = false;
  filtro: Array<any>;
  itensPdmApi: any;
  itensPdmTela: any;
  caracteristicaObrigatorio: any = 0;
  ativoCriar: any = true;
  suspensoCriar: any = false;
  eccCriar: any = false;
  aplicaMargemPreferencia: any = false;
  sustentavelCriar: any = false;
  ncmCriar: string = '';
  escolhidoEditar: any;
  codNovo: any;
  codEditar: any;
  modalRef1: BsModalRef;
  modalRef2: BsModalRef;
  modalRef3: BsModalRef;
  codigoNovoItem: any;
  valorFiltroCaracterisiticaArray: any = [];
  itensAtivos: number = 0;
  itensSuspensos: number = 0;
  itensInativos: number = 0;
  menuFiltrado: any = [];
  responsiveOptions: any;
  ativos: boolean = false;


  @ViewChild('criarItemMaterial', { static: false }) criarItemMaterial: any;
  @ViewChild('confirmNew', { static: false }) criarConfimacao: any;
  @ViewChild('criarItemSucess', { static: false }) criarItemSucess: any;

  @ViewChild('editarItemMaterial', { static: false }) editarItemMaterial: any;
  @ViewChild('confirmEdit', { static: false }) editarConfimacao: any;
  @ViewChild('editarItemSucess', { static: false }) editarSucesso: any;

  constructor(
    private router: Router,
    private service: Service,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private modalService: BsModalService,
    private buscaService: BuscaService
  ) {}

  ngOnInit(): void {
    this.responsiveOptions = [
      {
          breakpoint: '1024px',
          numVisible: 4,
          numScroll: 4
      },
      {
          breakpoint: '768px',
          numVisible: 3,
          numScroll: 3
      },
      {
          breakpoint: '560px',
          numVisible: 2,
          numScroll: 2
      }
  ];
    let aux = sessionStorage.getItem('filtro');
    this.filtro = [];
    if(aux != null){
      this.filtro = JSON.parse(aux);
      sessionStorage.removeItem('filtro');
    }
    this.params = {
      id: this.route.snapshot.paramMap.get('id'),
      nomePdm: this.route.snapshot.paramMap.get('nomePdm')
    };
    this.buscaService
      .obterCaracteristicasPorPDMSiglaAtiva(this.params.id)
      .subscribe((caracteristicas) => {
        if (caracteristicas) {
          this.caracteristica = caracteristicas;
          if (caracteristicas.length === 0) {
            this.messageService.add({
              severity: 'warn',
              summary: 'Este Material não possui Características Ativas.',
              detail: '',
            });
          }
          if(this.filtro.length > 0){
            this.carregaFiltroInicio();
          }
          for (let i of caracteristicas) {
            i.valorCaracteristica.sort(
              (a: { nomeValorCaracteristica: string; }, b: { nomeValorCaracteristica: string; }) =>
              (a.nomeValorCaracteristica < b.nomeValorCaracteristica) ? -1 : 1);

            if (i.caracteristicaObrigatoria == true) {
              this.caracteristicaObrigatorio =
                this.caracteristicaObrigatorio + 1;
            }

            // for criado para tirar os valores de caracteristicas inativas(falso)
            for (let ind = 0; ind < i.valorCaracteristica.length; ind++){
              if (!i.valorCaracteristica[ind].statusValorCaracteristicaPdm){
                i.valorCaracteristica.splice(ind,1);
                ind--;
              }
            }

            for (const valor of i.valorCaracteristica) {
              valor.valorConcat = valor.codigoValorCaracteristica;
              valor.valorLabel = valor.nomeValorCaracteristica;
              if (valor.siglaUnidadeMedida) {
                valor.valorLabel = valor.valorLabel + ' ' + valor.siglaUnidadeMedida;
                valor.valorConcat = valor.valorConcat + '¬' + valor.siglaUnidadeMedida;
              }
            }
          }
        }
      });
    this.service
      .obterItemMaterialCaracateríticasValorPorPDMSemFiltro(this.params.id)
      .subscribe((resp) => {
        if (resp) {
          this.itensPdmApi = resp;
          this.itensPdmTela = resp;
          if(this.filtro.length > 0){
            this.filtrarItens();
          } else {
            this.atualizarContagem();
          }
        }
      });
  }

  limparNcm() {
    if (this.escolhidoEditar && this.escolhidoEditar.codigoNcm) {
      let s: string = this.escolhidoEditar.codigoNcm;
      s = s.replace(/\D/g,'');
      this.escolhidoEditar.codigoNcm = s.length > 8 ? s.substring(0, 8) : s;
    }
  }

  limparNcmCriar() {
    if (this.ncmCriar) {
      let s: string = this.ncmCriar;
      s = s.replace(/\D/g,'');
      this.ncmCriar = s.length > 8 ? s.substring(0, 8) : s;
    }

  }

  atualizarContagem() {
    this.itensAtivos = 0;
    this.itensInativos = 0;
    // this.itensSuspensos = 0;
    for (const i of this.itensPdmTela) {
      if (i.statusItem == true && i.itemSuspenso == false) {
        this.itensAtivos = this.itensAtivos + 1;
      }
      if (i.statusItem == false){
        this.itensInativos = this.itensInativos + 1;
      }
      if (i.statusItem == true && i.itemSuspenso == true){
        this.itensInativos = this.itensInativos + 1;
      }
      // if (i.itemSuspenso) {
      //   this.itensSuspensos = this.itensSuspensos + 1;
      // }
    }
  }

  voltarTelaInicial(): void {
    if(this.modalRef3 != undefined){
      this.modalRef3.hide();
    }
    this.router.navigate(['/buscamaterial']);
  }

  editarPdm(){
    this.router.navigate(['/mantermaterial', this.params.id]);
  }

  permanecerPagina(){
    this.modalRef3.hide();
    sessionStorage.setItem('filtro', JSON.stringify(this.filtro));
    window.location.reload();
  }

  mudarFiltro(
    evento: any,
    codigoCaracteristica: any,
    obrigatorio: any,
    nomeCaracteristica: any,
  ) {
    let codigoCaracConcat = [];
    let filtroAux = this.filtro;
    let aux = {
      codigoCaracteristica: codigoCaracteristica,
      codigoValorCaracteristica: null,
      siglaUnidadeMedida: null,
      nome: nomeCaracteristica,
      caracteristicaObrigatoria: obrigatorio,
    };
    if (evento.value == null) {
      filtroAux = filtroAux.filter(
        (x: { codigoCaracteristica: any }) => x.codigoCaracteristica !== codigoCaracteristica
      );
    } else {
      codigoCaracConcat = evento.value.split('¬');
      if (
        filtroAux.some((x: { codigoCaracteristica: any }) => x.codigoCaracteristica == codigoCaracteristica)
      ) {
        for (const i of filtroAux) {
          if (i.codigoCaracteristica == codigoCaracteristica) {
            i.codigoValorCaracteristica = codigoCaracConcat[0];
            i.siglaUnidadeMedida = codigoCaracConcat[1];
            if(i.siglaUnidadeMedida == undefined){
              i.siglaUnidadeMedida = null;
            }
          }
        }
      } else {
        codigoCaracConcat = evento.value.split('¬');
        aux.codigoValorCaracteristica = codigoCaracConcat[0];
        aux.siglaUnidadeMedida = codigoCaracConcat[1];
        if(aux.siglaUnidadeMedida == undefined){
          aux.siglaUnidadeMedida = null;
        }
        this.filtro.push(aux);
      }
    }
    this.filtro = filtroAux;
    this.filtrarItens();
  }

  filtrarItens() {
    let auxArray: any = [];
    let arrayResultado: any = [];
    let obrigatorio: any = 0;
    let apareceBotao = true;
    this.ativos = false;
    this.novoItem = false;
    this.itensPdmTela = [];
    auxArray = this.itensPdmApi;
    for (let i of this.filtro) {
      for (let j of auxArray) {
        for (let k of j.buscaItemCaracteristica) {
          if (
            i.codigoCaracteristica == k.codigoCaracteristica &&
            i.codigoValorCaracteristica == k.codigoValorCaracteristica &&
            i.siglaUnidadeMedida == k.siglaUnidadeMedida
          ) {
            arrayResultado.push(j);
          }
        }
      }
      auxArray = [];
      auxArray = arrayResultado;
      arrayResultado = [];
      if (i.caracteristicaObrigatoria == true) {
        obrigatorio = obrigatorio + 1;
      }
    }

    for(let i of auxArray){
      if(i.buscaItemCaracteristica.length == this.filtro.length){
        apareceBotao = false;
        this.novoItem = false;
      }
    }

    this.itensPdmTela = auxArray;

    this.adicionarNome();
    this.atualizarContagem();
    this.ordenaCaracteristica();

    if (this.itensPdmTela.length == 0) {
      if (this.caracteristicaObrigatorio == obrigatorio && this.filtro.length > 0) {
        this.novoItem = true;
      }
    } else {
      if(apareceBotao == true && (this.caracteristicaObrigatorio == obrigatorio && this.filtro.length > 0)) {
        this.novoItem = true;
      }
    }

    this.menuFiltrado = []
    for(let i of this.filtro){
      let aux = {
        codigo: null,
        nome: ""
      };
      aux.codigo = i.codigoCaracteristica;
      if(i.siglaUnidadeMedida != null){
        aux.nome = i.nomeValorCaracteristica + " " + i.siglaUnidadeMedida;
      }
      else {
        aux.nome = i.nomeValorCaracteristica;
      }

      this.menuFiltrado = [...this.menuFiltrado, aux];
    }

  }

  resetaVariaveisCriar(){
    this.suspensoCriar = false;
    this.sustentavelCriar = false;
    this.eccCriar = false;
    this.ativoCriar = true;
    this.ncmCriar = '';
    this.aplicaMargemPreferencia = false;
    this.abrirCriarItem();
  }

  adicionarNome(){
    for(let i of this.filtro){
      for(let j of this.caracteristica){
        if(i.codigoCaracteristica == j.codigoCaracteristica){
          for(let k of j.valorCaracteristica){
            if(i.codigoValorCaracteristica == k.codigoValorCaracteristica){
              i.nomeValorCaracteristica = k.nomeValorCaracteristica;
              i.numeroCaracteristica = j.numeroCaracteristica;
            }
          }
        }
      }
    }
  }

  ordenaCaracteristica(){
    let filtroAux = this.filtro.sort((a: any, b: any) => (a.numeroCaracteristica < b.numeroCaracteristica) ? -1 : 1);
    this.filtro = filtroAux;
  }

  escolherEditar(item: any){
    this.escolhidoEditar = JSON.parse(JSON.stringify(item));
    this.abrirEditarItem();
  }

  abrirEditarItem(){
    if(this.modalRef2 != undefined){
      this.modalRef2.hide();
    }
    let modalOptions: ModalOptions = {
      class: 'modal-lg'
    };
    this.modalRef1 = this.modalService.show(this.editarItemMaterial, modalOptions);
  }

  abrirConfirmacaoEditar(){
    this.modalRef1.hide();
    let valido = this.validacaoSalvarItem()
    if (valido) {
      let modalOptions: ModalOptions = { class: 'modal-lg' };
      this.modalRef2 = this.modalService.show(this.editarConfimacao, modalOptions);
    }
  }

  fecharEditarItem(){
    this.modalRef1.hide();
  }

  salvarEditarItem(){
    this.modalRef2.hide();
    this.visible = true;
    this.buscaService.editarItemMaterial(this.escolhidoEditar).subscribe((resp)=>{
      this.codEditar = resp;
      this.visible = false;
      let modalOptions: ModalOptions = {
        class: 'modal-lg',
        backdrop: 'static',
        keyboard: false
      };
      this.modalRef3 = this.modalService.show(this.editarSucesso, modalOptions);
    }, erro => {
      this.visible = false;
      this.messageService.add({
        severity: 'error',
        summary: 'Erro ao salvar a edição do item de material. '+ erro.error,
        detail: '',
      });
    });
  }

  validacaoSalvarItem() : Boolean {
    let valido = true;
    if (!this.escolhidoEditar.codigoNcm) {
      this.escolhidoEditar.aplicaMargemPreferencia = false;
    }

    if (this.escolhidoEditar.codigoNcm?.length === 1) {
      this.messageService.add({
        severity: 'error',
        summary: 'NCM necessita de pelo menos 2 digitos.',
        detail: '',
      });
      valido = false;
    }

    return valido;
  }

  abrirCriarItem(){
    if(this.modalRef2 != undefined){
      this.modalRef2.hide();
    }
    let modalOptions: ModalOptions = {
      class: 'modal-lg'
    };
    this.modalRef1 = this.modalService.show(this.criarItemMaterial, modalOptions);
  }

  abrirConfirmacaoCriar(){
    this.modalRef1.hide();
    let modalOptions: ModalOptions = {
      class: 'modal-lg'
    };
    this.modalRef2 = this.modalService.show(this.criarConfimacao, modalOptions);
  }

  fecharCriarItem(){
    this.modalRef1.hide();
  }

  salvarNovoItem(){
    this.modalRef2.hide();
    this.visible = true;
    let novoItem = {
      codigoPdm: this.params.id,
      itemExclusivoUasgCentral: this.eccCriar,
      itemSuspenso: this.suspensoCriar,
      itemSustentavel: this.sustentavelCriar,
      listaIncluirItemMaterialCaracteristica: this.filtro,
      statusItem: this.ativoCriar,
      sequencialItem: 0,
      codigoNcm: this.ncmCriar,
      aplicaMargemPreferencia: this.aplicaMargemPreferencia
    }
    this.buscaService.novoItemMaterial(novoItem).subscribe((resp)=>{
      this.codNovo = resp;
      this.visible = false;
      this.codigoNovoItem = resp;
      let modalOptions: ModalOptions = {
        class: 'modal-lg',
        backdrop: 'static',
        keyboard: false
      };
      this.modalRef3 = this.modalService.show(this.criarItemSucess, modalOptions);
    }, erro => {
      this.visible = false;
      this.messageService.add({
        severity: 'error',
        summary: 'Erro ao criar um novo item de material. '+ erro.error,
        detail: '',
      });
    });
  }

  carregaFiltroInicio(){
    this.valorFiltroCaracterisiticaArray = [];
    for(let i of this.filtro){
      if(i.siglaUnidadeMedida == undefined || i.siglaUnidadeMedida == null){
        this.valorFiltroCaracterisiticaArray[i.codigoCaracteristica] = i.codigoValorCaracteristica;
      }
      else{
        this.valorFiltroCaracterisiticaArray[i.codigoCaracteristica] = i.codigoValorCaracteristica + "¬" + i.siglaUnidadeMedida;
      }
    }
  }

  excluirValorFiltro(codigoCaracteristica: any){
    let filtroAux: any = this.filtro;
    let menuAux: any = this.menuFiltrado;
    for(let i of this.filtro){
      if(i.codigoCaracteristica == codigoCaracteristica){
        this.valorFiltroCaracterisiticaArray[i.codigoCaracteristica] = "";
        this.filtro = filtroAux.filter(
          (x: { codigoCaracteristica: any }) => x.codigoCaracteristica !== codigoCaracteristica
        );
        this.menuFiltrado = menuAux.filter(
          (x: { codigo: any }) => x.codigo !== codigoCaracteristica
        );
      }
    }
    this.filtrarItens();
  }

  somenteAtivos(){
    if(this.ativos == false){
      let auxArray = [];
      for(let i of this.itensPdmTela){
        if(i.statusItem == true && i.itemSuspenso == false){
          auxArray.push(i);
        }
      }
      this.itensPdmTela = auxArray;
    } else {
      this.filtrarItens();
    }
  }

}
