<app-cabecalho-interno></app-cabecalho-interno> <br /><br /><br />

<div class="container-lg container">

  <div class="mb-4 mt-2" ><!-- cabeçalho -->
    <div class="row">
      <div class="col-10" id="cabecalho">
        <h2 class="mb-0" aria-label="Manter Material">
          <span class="mouseover">Catálogo - Criar / Editar item de material</span>
        </h2>
        <h6 style="font-weight: 400;" class="mb-0 mt-1"> Use esta área para Criar / Editar um item de material</h6>
      </div>
      <div class="col-2 mt-4 text-right" >
      </div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-4 card" style="margin-left: -4px; margin-right: 4px">
      <div class="row">
        <h6 class="col-10">PDM: {{params.id}} - {{params.nomePdm}}</h6>
        <button
          class="br-button col-1"
          type="button"
          (click)="editarPdm()"
        >
          <i class="fas fa-edit	" aria-hidden="true"></i>
        </button>
      </div>

      <h6 class="mb-2" style="font-size: 12px">Características</h6>

      <div *ngFor="let carac of caracteristica" >
        <div *ngIf="carac.valorCaracteristica.length > 0 && carac.statusCaracteristica == true" class="mb-4" >
          <label>
            {{ carac.nomeCaracteristica }}
            <span style="font-size: 80%">
              <i>
                {{ carac.caracteristicaObrigatoria ? "- obrigatória" : "" }}
              </i>
            </span>
          </label>
          <p-dropdown
            (onChange)="mudarFiltro(
              $event,
              carac.codigoCaracteristica,
              carac.caracteristicaObrigatoria,
              carac.nomeCaracteristica)"
            [(ngModel)]="valorFiltroCaracterisiticaArray[carac.codigoCaracteristica]"
            optionValue="valorConcat" emptyMessage=" " emptyFilterMessage=" "
            [options]="carac.valorCaracteristica" optionLabel="valorLabel" [filter]="true"
            filterBy="valorLabel" [showClear]="false" placeholder="{{carac.nomeCaracteristica}}">
          </p-dropdown>
        </div>
      </div>

    </div>

    <div class="col-8 card" style="margin-right: -4px; margin-left: 4px">
      <div class="row">
        <div class="col-10" >
          <p-carousel [value]="menuFiltrado" [numVisible]="4" [numScroll]="4" [circular]="false" [responsiveOptions]="responsiveOptions">
            <ng-template pTemplate="header">
                <label>Quantidade de filtros: {{this.filtro? this.filtro.length: "0"}}</label>
            </ng-template>
            <ng-template let-menu pTemplate="item">
                <div class="">
                    <div class="">
                        <div class="">
                            <label class="p-mb-1 menuItem">{{menu.nome}}
                              <button
                                class="br-button lixeira"
                                type="button"
                                (click)="excluirValorFiltro(menu.codigo)" >
                                  <i class="fas fa-trash" aria-hidden="true"></i>
                              </button>
                            </label>
                        </div>
                    </div>
                </div>
            </ng-template>
          </p-carousel>
        </div>
        <div style="text-align: end">
          <button
            class="br-button is-secondary"
            type="button"
            (click)="voltarTelaInicial()"
          >
            Voltar
          </button>
        </div>
      </div>

      <div class="row" *ngIf="itensPdmTela?.length">
        <div class="col-8">
          <h6 style="font-size: 14px; margin-top: 0px">
            <span>Foram encontrados: {{ itensAtivos }} ativo(s)</span><span *ngIf="!ativos">, {{ itensInativos }} inativo(s)/suspenso(s)​</span>
          </h6>
        </div>

        <span class="br-checkbox col-4">
          <input id="somenteAtivos" name="somente-ativos" type="checkbox" aria-label="somente ativos" (click)="somenteAtivos()" [(ngModel)]="ativos"/>
          <label for="somenteAtivos">Mostrar somente itens ativos</label>
        </span>

        <div class="col-12" style="text-align: end; align-self: center" *ngIf="novoItem == true">
          <button
            class="br-button is-primary"
            type="button"
            (click)="resetaVariaveisCriar()"
          >
            Adicionar
          </button>
        </div>
        <!-- <span style="font-size: 16px; color: #9f9a9a">* Inativos</span> -->
      </div>
      <div *ngIf="itensPdmTela?.length == '' && !novoItem">
        <h6 style="font-size: 14px; margin-top: 0px">
          Não foram encontrados itens de material com base no filtro
        </h6>
        <span style="font-size: 12px; color: #9f9a9a"
          >Preencha todos os filtro obrigatórios para adicionar um novo
          item</span
        >
      </div>
      <div class="row" *ngIf="itensPdmTela?.length == '' && novoItem">
        <div class="col-9">
          <h6 style="font-size: 14px; margin-top: 0px">
            Não foram encontrados itens de material com base no filtro
          </h6>
          <span style="font-size: 12px; color: #9f9a9a"
            >Clique no botão de adicionar para criar o novo item</span
          >
        </div>
        <div class="col-3" style="text-align: end; align-self: center">
          <button
            class="br-button is-primary"
            type="button"
            (click)="resetaVariaveisCriar()"
          >
            Adicionar
          </button>
        </div>
      </div>
      <p-table class="mt-2" [value]="itensPdmTela" [autoLayout]="true">
        <ng-template pTemplate="header">
          <tr class="row">
            <th class="col-2" pSortableColumn="codigoItem">
              Código <p-sortIcon field="codigoItem"></p-sortIcon>
            </th>
            <th class="col-6" pSortableColumn="nomePdm">
              Nome do Material <p-sortIcon field="nomePdm"></p-sortIcon>
            </th>
            <th class="col-2" pSortableColumn="codigoNcm">
              <span pTooltip="Nomenclatura Comum do Mercosul (NCM)<br><br>Regulamentado pelo Decreto N<sup>o</sup> 11.890, de 22 de Janeiro de 2024"
                tooltipPosition="bottom" [escape]="false">NCM</span> <p-sortIcon field="codigoNcm"></p-sortIcon>
            </th>
            <th class="col-2" pSortableColumn="statusItem">
              Ação <p-sortIcon field="statusItem"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr class="row">
            <td class="col-2">
              <div *ngIf="item.statusItem == true && item.itemSuspenso == false">
                <span
                  style="color: black"
                >
                  {{ item.codigoItem }}
                  <span
                    *ngIf="item.itemSustentavel == true"
                    class="fas fa-leaf small"
                    pTooltip="Sustentável"
                  ></span>
                </span>
              </div>
              <div *ngIf="(item.statusItem == false) || (item.statusItem == true && item.itemSuspenso == true)">
                  <span class=""

                    style="color: #9f9a9a"
                  >
                    {{ item.codigoItem }}

                    <span
                      *ngIf="item.itemSustentavel == true"
                      class="fas fa-leaf small"
                      pTooltip="Sustentável"
                    ></span>
                  </span>
                  <br>
                  <span class="">
                    <button class="btn inativo"
                      *ngIf="item.statusItem === false"
                      pTooltip="Inativo"
                    >I</button>
                    <button class="btn suspenso"
                      *ngIf="item.itemSuspenso"
                      pTooltip="Suspenso"
                    >S</button>
                  </span>
              </div>
            </td>
            <td class="col-6">
              <span
                *ngIf="item.statusItem == true && item.itemSuspenso == false"
                style="color: black"
              >
                <label
                  ><b>{{ item.nomePdm }}</b></label
                >
              </span>
              <span
                *ngIf="item.statusItem == false || item.itemSuspenso == true"
                style="color: #9f9a9a"
              >
                <label
                  ><b>{{ item.nomePdm }}</b></label
                >
              </span>

              <span *ngFor="let itemCacac of item.buscaItemCaracteristica">
                <div *ngIf="itemCacac.statusCaracteristica == true">
                  <p class="pb-0 mb-0">
                    <span style="color: black"
                      >{{ itemCacac.nomeCaracteristica }}:
                      {{ itemCacac.nomeValorCaracteristica }}
                      {{ itemCacac.siglaUnidadeMedida }}</span
                    >
                  </p>
                </div>
                <div *ngIf="itemCacac.statusCaracteristica === false">
                  <p class="pb-0 mb-0">
                    <span style="color: #9f9a9a"
                      >{{ itemCacac.nomeCaracteristica }}:
                      {{ itemCacac.nomeValorCaracteristica }}
                      {{ itemCacac.siglaUnidadeMedida }}</span
                    >
                  </p>
                </div>
                <!-- <div *ngIf="itemCacac.statusCaracteristica == false">
                  <p class="pb-0 mb-0">
                    <span style="text-transform: capitalize; color: #9f9a9a"
                      >* {{ itemCacac.nomeCaracteristica | lowercase }}:
                      {{ itemCacac.nomeValorCaracteristica | lowercase }}
                      {{ itemCacac.siglaUnidadeMedida | lowercase }}</span
                    >
                  </p>
                </div> -->
              </span>
            </td>
            <td class="col-2">
              <span style="color: black">
                {{ item.codigoNcm | ncmPipe}}
              </span>
            </td>
            <td class="col-2">
              <button
                type="button"
                class="br-button secondary small"
                aria-label="Adicionar"
                (click)="escolherEditar(item)"
              >
                Editar
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

  <!-- Modals -->

  <br-loading [visible]="visible" label="Salvando ..."></br-loading>

  <!-- <br-confirmation
    #confirmNew
    popupTitle="Confirma a inclusão"
    acceptLabel="Sim"
    rejectLabel="Não"
    question="Deseja realmente incluir este item de material?"
    (accept)="salvarNovoItem()"
    (reject)="abrirCriarItem()"
  >
  </br-confirmation>

  <br-confirmation
    #criarItemSucess
    popupTitle="Item de material incluido com sucesso"
    acceptLabel="Permanecer"
    rejectLabel="Voltar"
    question="Código do item: {{codNovo}}"
    (accept)="permanecerPagina()"
    (reject)="voltarTelaInicial()"
  >
  </br-confirmation> -->

  <ng-template #criarItemSucess>
    <div>
      <div class="modal-header">
        <h4 class="modal-title">Criado com sucesso</h4>
      </div>
      <div class="container-lg">
        <div class="mt-4">
          <label class="row">Item de material criado com sucesso</label>
          <div class="row mt-2">
            <label>Item {{codNovo}} - <b>{{params.nomePdm}}</b></label>
          </div>
        </div>
        <div class="row">
          <div class="col-7 mt-3 mb-5">
            <label>Características</label>
            <div *ngFor="let item of filtro">
              <div class="row">
                <label>
                  <b>{{ item.nome }}:</b> {{ item.nomeValorCaracteristica }} {{item.siglaUnidadeMedida}}
                </label>
              </div>
            </div>
            <div class="row">
              <label>
                <b>NCM:</b> {{ncmCriar | ncmPipe}}
              </label>
            </div>
          </div>
          <div class="col-5 mt-3">
            <div>
              <label>Ativo: {{ativoCriar? 'Sim':'Não'}}</label>
            </div>
            <div>
              <label>Suspenso: {{suspensoCriar? 'Sim':'Não'}}</label>
            </div>
            <div>
              <label>Item sustentável: {{sustentavelCriar? 'Sim':'Não'}}</label>
            </div>
            <div>
              <label>Exclusivo central compras: {{eccCriar? 'Sim':'Não'}}</label>
            </div>
            <div>
              <label>Aplica Margem de Preferência: {{aplicaMargemPreferencia? 'Sim':'Não'}}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <button
            type="button"
            class="br-button secondary small"
            aria-label="Não"
            style="margin-right: 20px"
            (click)="voltarTelaInicial()"
          >
            Voltar
          </button>
          <button
            type="button"
            class="br-button primary small"
            aria-label="Sim"
            style="margin-right: 20px"
            (click)="permanecerPagina()"
          >
            Permanecer
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #confirmNew>
    <div>
      <div class="modal-header">
        <h4 class="modal-title">Confirma criação</h4>
      </div>
      <div class="container-lg">
        <div class="mt-4">
          <label class="row">Deseja realmente criar este item de material?</label>
          <div class="row mt-2">
            <label><b>{{params.nomePdm}}</b></label>
          </div>
        </div>
        <div class="row">
          <div class="col-7 mt-3 mb-5">
            <label>Características</label>
            <div *ngFor="let item of filtro">
              <div class="row">
                <label>
                  <b>{{ item.nome }}:</b> {{ item.nomeValorCaracteristica }} {{item.siglaUnidadeMedida}}
                </label>
              </div>
            </div>
            <div class="row">
              <label>
                <b>NCM:</b> {{ncmCriar | ncmPipe}}
              </label>
            </div>
          </div>
          <div class="col-5 mt-3">
            <div>
              <label>Ativo: {{ativoCriar? 'Sim':'Não'}}</label>
            </div>
            <div>
              <label>Suspenso: {{suspensoCriar? 'Sim':'Não'}}</label>
            </div>
            <div>
              <label>Item sustentável: {{sustentavelCriar? 'Sim':'Não'}}</label>
            </div>
            <div>
              <label>Exclusivo central compras: {{eccCriar? 'Sim':'Não'}}</label>
            </div>
            <div>
              <label>Aplica Margem de Preferência: {{aplicaMargemPreferencia? 'Sim':'Não'}}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <button
            type="button"
            class="br-button secondary small"
            aria-label="Não"
            style="margin-right: 20px"
            (click)="abrirCriarItem()"
          >
            Não
          </button>
          <button
            type="button"
            class="br-button primary small"
            aria-label="Sim"
            style="margin-right: 20px"
            (click)="salvarNovoItem()"
          >
            Sim
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #criarItemMaterial>
    <div>
      <div class="modal-header">
        <h4 class="modal-title">Novo Item de material</h4>
      </div>
      <div class="container-lg">
        <div class="row mt-4">
          <label><b>{{params.nomePdm}}</b></label>
        </div>
        <div class="row">
          <div class="col-7 mt-3">
            <label>Características</label>
            <div *ngFor="let item of filtro">
              <div class="row">
                <label>
                  <b>{{ item.nome }}:</b> {{ item.nomeValorCaracteristica }} {{item.siglaUnidadeMedida}}
               </label>
              </div>
            </div>
            <div class="row">
              <label>
                <b>NCM:</b><input class="ml-2" type="text" pInputText [(ngModel)]="ncmCriar" (keyup)="limparNcmCriar()" [maxlength]="12"/>
              </label>
            </div>
          </div>
          <div class="col-5 mt-3">
            <div
              class="br-switch col-12 mb-2"
              style="justify-content: flex-end"
            >
              <input id="ativoCriar" type="checkbox" [(ngModel)]="ativoCriar" />
              <label for="ativoCriar">Ativo</label>
            </div>
            <div
              class="br-switch col-12 mb-2"
              style="justify-content: flex-end"
            >
              <input
                id="suspensoCriar"
                type="checkbox"
                [(ngModel)]="suspensoCriar"
              />
              <label for="suspensoCriar">Suspenso</label>
            </div>
            <div
              class="br-switch col-12 mb-2"
              style="justify-content: flex-end"
            >
              <input
                id="sustentavelCriar"
                type="checkbox"
                [(ngModel)]="sustentavelCriar"
              />
              <label for="sustentavelCriar">Item sustentável</label>
            </div>
            <div
              class="br-switch col-12 mb-3"
              style="justify-content: flex-end"
            >
              <input id="eccCriar" type="checkbox" [(ngModel)]="eccCriar" />
              <label for="eccCriar">Exclusivo central compras</label>
            </div>
            <div
              class="br-switch col-12 mb-3"
              style="justify-content: flex-end"
            >
              <input id="aplicaMargemPreferencia" type="checkbox" [(ngModel)]="aplicaMargemPreferencia" />
              <label for="aplicaMargemPreferencia">Aplica Margem de Preferência</label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <button
            type="button"
            class="br-button primary small"
            aria-label="Adicionar"
            style="margin-right: 20px"
            (click)="abrirConfirmacaoCriar()"
          >
            Salvar
          </button>
          <button
            type="button"
            class="br-button secondary small"
            aria-label="Fechar"
            style="margin-right: 20px"
            (click)="fecharCriarItem()"
          >
            Sair
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- <br-confirmation
    #confirmEdit
    popupTitle="Confirma a edição"
    acceptLabel="Sim"
    rejectLabel="Não"
    question="Deseja realmente editar este item de material?"
    (accept)="salvarEditarItem()"
    (reject)="abrirEditarItem()"
  >
  </br-confirmation> -->

  <!-- <br-confirmation
    #editarItemSucess
    popupTitle="Item de material editado com sucesso"
    acceptLabel="Permanecer"
    rejectLabel="Voltar"
    question="Código do item: {{codEditar}}"
    (accept)="permanecerPagina()"
    (reject)="voltarTelaInicial()"
  >
  </br-confirmation> -->

  <ng-template #editarItemSucess>
    <div>
      <div class="modal-header">
        <h4 class="modal-title">Sucesso edição</h4>
      </div>
      <div class="container-lg">
        <div class="mt-4">
          <label class="row">Item de material editado com sucesso</label>
          <div class="row mt-2">
            <label>Item {{escolhidoEditar.codigoItem}} - <b>{{escolhidoEditar.nomePdm}}</b></label>
          </div>
        </div>
        <div class="row">
          <div class="col-7 mt-3 mb-5">
            <label>Características</label>
            <div *ngFor="let item of escolhidoEditar.buscaItemCaracteristica">
              <div class="row">
                <label>
                  <b>{{ item.nomeCaracteristica }}:</b> {{ item.nomeValorCaracteristica }} {{item.siglaUnidadeMedida}}
                </label>
              </div>
            </div>
            <div class="row">
              <label>
                <b>NCM:</b> {{escolhidoEditar.codigoNcm | ncmPipe}}
              </label>
            </div>
          </div>
          <div class="col-5 mt-3">
            <div>
              <label>Ativo: {{escolhidoEditar.statusItem? 'Sim':'Não'}}</label>
            </div>
            <div>
              <label>Suspenso: {{escolhidoEditar.itemSuspenso? 'Sim':'Não'}}</label>
            </div>
            <div>
              <label>Item sustentável: {{escolhidoEditar.itemSustentavel? 'Sim':'Não'}}</label>
            </div>
            <div>
              <label>Exclusivo central compras: {{escolhidoEditar.itemExclusivoUasgCentral? 'Sim':'Não'}}</label>
            </div>
            <div>
              <label>Aplica Margem de Preferência: {{escolhidoEditar.aplicaMargemPreferencia? 'Sim':'Não'}}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <button
            type="button"
            class="br-button secondary small"
            aria-label="Não"
            style="margin-right: 20px"
            (click)="voltarTelaInicial()"
          >
            Voltar
          </button>
          <button
            type="button"
            class="br-button primary small"
            aria-label="Sim"
            style="margin-right: 20px"
            (click)="permanecerPagina()"
          >
            Permanecer
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #confirmEdit>
    <div>
      <div class="modal-header">
        <h4 class="modal-title">Confirma edição</h4>
      </div>
      <div class="container-lg">
        <div class="mt-4">
          <label class="row">Deseja realmente editar este item de material?</label>
          <div class="row mt-2">
            <label>Item {{escolhidoEditar.codigoItem}} - <b>{{escolhidoEditar.nomePdm}}</b></label>
          </div>
        </div>
        <div class="row">
          <div class="col-7 mt-3 mb-5">
            <label>Características</label>
            <div *ngFor="let item of escolhidoEditar.buscaItemCaracteristica">
              <div class="row">
                <label>
                  <b>{{ item.nomeCaracteristica }}:</b> {{ item.nomeValorCaracteristica }} {{item.siglaUnidadeMedida}}
                </label>
              </div>
            </div>
            <div class="row">
              <label>
                <b>NCM:</b> {{escolhidoEditar.codigoNcm | ncmPipe}}
              </label>
            </div>
          </div>
          <div class="col-5 mt-3">
            <div>
              <label>Ativo: {{escolhidoEditar.statusItem? 'Sim':'Não'}}</label>
            </div>
            <div>
              <label>Suspenso: {{escolhidoEditar.itemSuspenso? 'Sim':'Não'}}</label>
            </div>
            <div>
              <label>Item sustentável: {{escolhidoEditar.itemSustentavel? 'Sim':'Não'}}</label>
            </div>
            <div>
              <label>Exclusivo central compras: {{escolhidoEditar.itemExclusivoUasgCentral? 'Sim':'Não'}}</label>
            </div>
            <div>
              <label>Aplica Margem de Preferência: {{escolhidoEditar.aplicaMargemPreferencia? 'Sim':'Não'}}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <button
            type="button"
            class="br-button secondary small"
            aria-label="Não"
            style="margin-right: 20px"
            (click)="abrirEditarItem()"
          >
            Não
          </button>
          <button
            type="button"
            class="br-button primary small"
            aria-label="Sim"
            style="margin-right: 20px"
            (click)="salvarEditarItem()"
          >
            Sim
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #editarItemMaterial>
    <div>
      <div class="modal-header">
        <h4 class="modal-title">Editar Item de material</h4>
      </div>
      <div class="container-lg">
        <div class="row mt-4">
          <label>Item {{escolhidoEditar.codigoItem}} - <b>{{escolhidoEditar.nomePdm}}</b></label>
        </div>
        <div class="row">
          <div class="col-7 mt-3">
            <label>Características</label>
            <div *ngFor="let item of escolhidoEditar.buscaItemCaracteristica">
              <div class="row">
                <label>
                  <b>{{ item.nomeCaracteristica }}:</b> {{ item.nomeValorCaracteristica }} {{item.siglaUnidadeMedida}}
                </label>
              </div>
            </div>
            <div class="row">
              <label>
                <b>NCM:</b><input class="ml-2" type="text" pInputText [(ngModel)]="escolhidoEditar.codigoNcm" (keyup)="limparNcm()" [maxlength]="12"/>
              </label>
            </div>
          </div>
          <div class="col-5 mt-3">
            <div
              class="br-switch col-12 mb-2"
              style="justify-content: flex-end"
            >
              <input id="ativoEditar" type="checkbox" [(ngModel)]="escolhidoEditar.statusItem" />
              <label for="ativoEditar">Ativo</label>
            </div>
            <div
              class="br-switch col-12 mb-2"
              style="justify-content: flex-end"
            >
              <input
                id="suspensoEditar"
                type="checkbox"
                [(ngModel)]="escolhidoEditar.itemSuspenso"
              />
              <label for="suspensoEditar">Suspenso</label>
            </div>
            <div
              class="br-switch col-12 mb-2"
              style="justify-content: flex-end"
            >
              <input
                id="sustentavelEditar"
                type="checkbox"
                [(ngModel)]="escolhidoEditar.itemSustentavel"
              />
              <label for="sustentavelEditar">Item sustentável</label>
            </div>
            <div
              class="br-switch col-12 mb-3"
              style="justify-content: flex-end"
            >
              <input id="eccEditar" type="checkbox" [(ngModel)]="escolhidoEditar.itemExclusivoUasgCentral" />
              <label for="eccEditar">Exclusivo central compras</label>
            </div>
            <div
              class="br-switch col-12 mb-3"
              style="justify-content: flex-end"
            >
              <input id="aplicaMargemPreferencia" type="checkbox" [(ngModel)]="escolhidoEditar.aplicaMargemPreferencia" [disabled]="!escolhidoEditar.codigoNcm">
              <label for="aplicaMargemPreferencia">Aplica Margem de Preferência</label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <button
            type="button"
            class="br-button secondary small"
            aria-label="Voltar"
            style="margin-right: 20px"
            (click)="fecharEditarItem()"
          >
            Voltar
          </button>
          <button
            type="button"
            class="br-button primary small"
            aria-label="Adicionar"
            style="margin-right: 20px"
            (click)="abrirConfirmacaoEditar()"
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
