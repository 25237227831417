import { EntityState, Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { UsuarioRepresentation } from '../model/usuario';

export interface UsuarioState extends EntityState<UsuarioRepresentation> {
  usuario: UsuarioRepresentation;
}

export function createInitialState(): UsuarioState {

  return {
    tokenCarregado: false,
    // @ts-ignore
    usuario: undefined
  };
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'usuario', idKey: 'login' })
export class UsuarioStore extends Store<UsuarioState> {
  constructor() {
    super(createInitialState());
  }

  setTokenCarregado(tokenCarregado: boolean) {
    this.update({ tokenCarregado });
  }

  setUsuario(usuario: UsuarioRepresentation) {
    this.update({ usuario });
  }
  setIsLoginProcessado(isLoginProcessado: boolean) {
    // console.log('UsuarioStore -> isLoginProcessado', isLoginProcessado);
    this.update({ isLoginProcessado });
  }
}
