<app-cabecalho-interno></app-cabecalho-interno>
<br /><br /><br />
<div class="container-lg">
      <div class="mb-4 mt-2" ><!-- cabeçalho -->
        <div class="row">
            <div class="col-10" id="cabecalho">
              <h2 class="mb-0" aria-label="Manter Material">
                <span class="mouseover">Catálogo - Incluir padrão descritivo de material</span>
              </h2>
              <h6 style="font-weight: 400;" class="mb-0 mt-1"> Use esta área para criar o padrão descritivo de material</h6>
            </div>
            <div class="col-2 mt-4 text-right" >
            </div>
        </div>
      </div>

      <div  class=" card border-0">
        <div class="row ">
          <div class="col-sm-8  card border-0"></div>
          <div class="col-sm-2 card border-0">
            <button class="br-button is-secondary"  type="button" (click)="voltarTelaInicial()">Voltar</button>
          </div>
          <div class="col-sm-2 card border-0">
            <br-loading [visible]="visible" label="Salvando ..."></br-loading>
            <button class="br-button is-primary" type="button" (click)="confirmCont.show()" >Salvar </button>
          </div>
        </div>

        <br-confirmation  #confirmCont popupTitle="Confirme a inclusão" acceptLabel="Sim" rejectLabel="Não"
                       question="Deseja realmente incluir este padrão descritivo de material?" (accept)="salvarPDM()">
        </br-confirmation>

        <br-confirmation  #PdmSucess popupTitle="PDM criado com sucesso!" acceptLabel="Novo PDM" rejectLabel="Voltar"
                       question="Código do padrão descritivo de material: {{codigoNovoPdm}}" (accept)="zerarTudo()" (reject)="voltarTelaInicial()">
        </br-confirmation>

      </div>
      <br/>

      <hr />

      <br-fieldset [collapsible]="true" fieldsetTitle="DADOS BÁSICOS" [collapsed]="expandCollapsed">
            <div class="row ">
              <div class="col-sm-9">
                <div class="br-input">
                  <label >Classe: {{classeEscolhida.codigoClasse}}</label><label class="asterisco">*</label>
                  <input  class="p-inputtext" type="text" [(ngModel)]="classeEscolhida.nomeClasse" placeholder="Escolha uma classe" readonly/>

<!--                  <button class="br-button" type="button" (click)="abrirBuscaClasse()" data-toggle="modal" data-target="#buscarclasse"-->
<!--                          aria-label="Botão de ação"><i class="fas fa-search" aria-hidden="true" ></i>-->
<!--                  </button>-->
<!--                  <button class="br-button" type="button"  data-toggle="modal" data-target="#buscarclasse"-->
<!--                          aria-label="Botão de ação"><i class="fas fa-search" aria-hidden="true" ></i>-->
<!--                  </button>-->
                </div>
              </div>

              <div class="col-sm-3" style="text-align: center;">
                <button class="br-button secondary mb-3 mt-5 mx-auto"  type="button" data-toggle="modal" data-target="#buscarclasse"
                        (click)="openModalClasse()"
                >
                  Buscar classe
                </button>
              </div>
            </div>
            <div class="row ">
              <div class=" col-sm-12  mb-3">
                <div class="br-input">
                  <label for="pdm"> Nome do padrão descritivo de material (PDM):</label><label class="asterisco">*</label><label class="texto-direita">Caracteres restantes: <span>{{numMaxPDM}}</span></label>
                  <input  class="p-inputtext" type="text" #PDM name="PDM" id="pdm"  placeholder="Digite o nome do PDM" [(ngModel)]="nomePdm" maxlength="100"
                          (keyup)="pressEnterPDM($event, PDM.value)" (blur)="trimTextOnBlur(PDM)"/>
                </div>
              </div>
            </div>
            <div>
              <label>Conjunto material</label><label class="asterisco">*</label>
            </div>
            <div class="row" style="margin-bottom: 15px;">
              <div class="br-input col-sm-9">
                <input
                  type="text" class="p-inputtext"
                  name="Conjunto Material"
                  placeholder="Selecione um conjunto"
                  readonly
                  value = {{conjuntoMaterial?.nomeConjunto}}
                />
              </div>
              <button
                class="br-button mx-auto secondary"

                type="button"
                data-toggle="modal"
                data-target="#conjuntoMaterial"
              >
                Buscar conjuntos
              </button>
            </div>
      </br-fieldset>
      <hr />

<br-second-tab-set>
  <br-second-tab
    name="Características"
    [active]="true"
  >
    <div class="row col-12 mt-4">
      <div class="col-10">
        <h6>Lista de características</h6>
      </div>
      <div class="col-2" style="align-self: flex-end; text-align: end; margin-bottom: 15px;">
        <button class="br-button secondary" type="button" data-toggle="modal"
          [attr.data-target]="validateCaracteristicas" (click)="openModalCaracteristica()">
          Adicionar
        </button>
      </div>
    </div>
    <div class="row" *ngIf="(caracteristicasEscolhidaArray | json) != ([] | json)" >
      <p-table class="col-12 col-sm-12 col-lg-12" [value]="caracteristicasEscolhidaArray" [reorderableColumns]="true" responsiveLayout="scroll">
        <ng-template pTemplate="header">
          <tr >
            <th  class="col-1">Ordem</th>
            <th  class="col-1">Código</th>
            <th  class="col-2">Nome</th>
            <th  class="col-1">Ativo</th>
            <th  class="col-2">Obrigatório</th>
            <th class="col-4" >Ação</th>
            <th class="col-1">Ordenar</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-index="rowIndex">
          <tr [pReorderableRow]="index" >
            <td class="col-1">
              <span>{{index + 1}}</span>
            </td>
            <td class="col-1 ">{{item.codigoCaracteristica}}</td>
            <td class="col-2 ">{{item.nomeCaracteristica}}</td>
            <td class="col-1 ">
              <br-switch [(ngModel)]="item.ativo"></br-switch>
            </td>
            <td class="col-2">
              <br-switch [(ngModel)]="item.obrigatorio"></br-switch>
            </td>
            <td class="col-4">
              <div class="row">
                <button class="br-button"
                        type="button"
                        (click)="editarCaracteristica(item.codigoCaracteristica)"
                        aria-label="Botão de ação"
                        data-toggle="modal"
                        data-target="#editarvalores"
                        pTooltip="Valores"
                        tooltipPosition="top">
                        <i class="fas fa-calculator" aria-hidden="true" ></i>
                </button>
                <button class="br-button"
                        type="button"
                        (click)="editarUnidadeMedida(item.codigoCaracteristica)"
                        aria-label="Botão de ação"
                        data-toggle="modal"
                        data-target="#editarunidademedida"
                        pTooltip="Unidade de medida"
                        tooltipPosition="top">
                      <i class="fas fa-ruler-combined" aria-hidden="true" ></i>
                </button>
                <p-confirmDialog header="Confirmar exclusão" icon="far fa-question-circle" key="{{item.codigoCaracteristica}}"></p-confirmDialog>
                <button class="br-button"
                        type="button"
                        (click)="excluirCaracteristica(item.codigoCaracteristica)"
                        pTooltip="Excluir"
                        tooltipPosition="top">
                  <i class="fas fa-trash" aria-hidden="true"></i>
                </button>
              </div>
            </td>
            <td class="col-1">
              <span class="pi pi-bars" [pReorderableRowHandle]="rowIndex"></span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </br-second-tab>
    <br-second-tab
      name="Alias/ Palavra-chave"
    >
      <div class="row mt-4 mb-4">
        <div class="br-input col-sm-10">
          <h6> Lista de alias/ palavra-chave:<label class="texto-direita" style="margin: 0px;">Caracteres restantes: <span>{{numMaxAlias}}</span></label></h6>

          <input
            class="p-inputtext"
            type="text"
            placeholder="Digite o alias / palavra-chave"
            [(ngModel)]="alias"
            (keyup)="pressEnterAlias($event, alias)"
            maxlength="25"
          />
        </div>
        <div class="col-2" style="align-self: flex-end; text-align: end">
          <button
            class="br-button secondary"
            type="button"
            (click)="adicionaListAlias()"
          >
            Adicionar
          </button>
        </div>
      </div>
      <div *ngFor="let alias of aliasArray" class="row">
        <div class="col-2"></div>
        <label class="col-5">{{ alias }}</label>
        <p-confirmDialog
          header="Confirmar exclusão"
          icon="far fa-question-circle"
          key="{{ alias }}"
        ></p-confirmDialog>
        <button
          class="br-button col-sm-2"
          type="button"
          (click)="removeListAlias(alias)"
        >
          Excluir
        </button>
      </div>
    </br-second-tab>
    <br-second-tab
      name="Natureza da despesa"
    >
      <div class="row mb-4 mt-4">
        <div class="br-input col-sm-10">
          <h6>Lista de natureza da despesa<span class="asterisco">*</span></h6>
          <p-inputMask
            mask="999999/99"
            [(ngModel)]="naturezaDespesa"
            placeholder="Digite uma natureza da despesa para adicionar"
            (keyup)="pressEnterNatureza($event)"
          ></p-inputMask>
        </div>
        <div class="col-2" style="align-self: flex-end; text-align: end">
          <button
            class="br-button secondary"
            type="button"
            (click)="adicionaListNatureza()"
          >
            Adicionar
          </button>
        </div>
      </div>
      <div *ngFor="let natureza of naturezaDespesaArray" class="row">
        <div class="col-lg-1"></div>
        <label class="col-sm-6">{{ natureza }}</label>
        <p-confirmDialog header="Confirmar exclusão" icon="far fa-question-circle" key="{{natureza}}"></p-confirmDialog>
        <button class="br-button col-sm-2" type="button" (click)="removeListNatureza(natureza)">
          Excluir
        </button>
      </div>

    </br-second-tab>
    <br-second-tab
      name="Unidade de fornecimento"
    >
      <div class="col-12 row mt-4">
        <h6 class="col-10">Lista de unidade de fornecimento<label class="asterisco">*</label></h6>
        <div class="col-2" style="align-self: flex-end; text-align: end;">
          <button class="br-button secondary mb-3 mx-auto"
          type="button"
          [attr.data-target]="validateUnidadeFornecimento"
          data-toggle="modal"
          (click)="openFormUnidadeFornecimento()" >Adicionar</button>
        </div>
      </div>
      <div class="row" *ngIf="(unidadesFornecimentoPDM | json) != ([] | json)" >
        <p-table class="col-12 col-sm-12 col-lg-12"  [value]="unidadesFornecimentoPDM">
          <ng-template pTemplate="header" class="row">
            <tr >
              <th class="col-sm-1 col-lg-1">Ordem</th>
              <th class="col-sm-3 col-lg-3">Sigla unidade de fornecimento</th>
              <th class="col-sm-3 col-lg-3">Capacidade unidade de fornecimento</th>
              <th class="col-sm-3 col-lg-3">Sigla unidade de medida</th>
              <th class="col-sm-1 col-lg-1">Ações</th>

            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item class="row">
            <tr >
              <td class="col-sm-1 col-lg-1">{{item.index}}</td>
              <td class="col-sm-3 col-lg-3">{{item.siglaUnidadeFornecimento}}</td>
              <td class="col-sm-3 col-lg-3">{{item.capacidadeUnidadeFornecimento? item.capacidadeUnidadeFornecimento : '--'}}</td>
              <td class="col-sm-3 col-lg-3">{{item.siglaUnidadeMedida? item.siglaUnidadeMedida : '--'}}</td>
              <td class="col-sm-1 col-lg-1">
                <p-confirmDialog header="Confirmar exclusão" icon="far fa-question-circle" key="{{item.index}}UnidadeFornecimento" #teste></p-confirmDialog>
                <button class="br-button" type="button" (click)="excluirUnidadeFornecimento(item.index, teste)" pTooltip="Excluir" tooltipPosition="top">
                  <i class="fas fa-trash" aria-hidden="true"></i>
                </button>
              </td>

            </tr>
          </ng-template>
        </p-table>
      </div>
    </br-second-tab>
  </br-second-tab-set>
  <div class="footer"></div>



  <!--<hr /> -->
   <!--a partir daqui começa os modais -->

    <app-selecionar-classe
      (onClasseSelecionada)="escolherClasse($event)"
    ></app-selecionar-classe>

    <app-selecionar-caracteristica
      (onCaracteristicaSelecionada)="escolherCaracteristica($event)"
    ></app-selecionar-caracteristica>

    <app-selecionar-unidade-medida
      [unidadeMedidaEmEdicao]="caracteristicasEscolhidaEmEdicao"
      (onUnidadeMedidaSelecionada)="escolherUnidadeMedidaSelecionada($event)"
    ></app-selecionar-unidade-medida>

    <app-selecionar-valores-caracteristica
      [caracteristicasEmEdicao]="caracteristicasEscolhidaEmEdicao"
      (onSalvarValorCaracteristica)="salvarValorCaracteristica($event)"
    ></app-selecionar-valores-caracteristica>

    <app-selecionar-conjunto
      (conjuntoMaterial)="escolherConjunto($event)"
      [classeEscolhida]="classeEscolhida.codigoClasse"
    ></app-selecionar-conjunto>

    <app-adicionar-unidade-fornecimento
      (resultado)="adicionarUnidadeFornecimento($event)"
    ></app-adicionar-unidade-fornecimento>

    <div class="modal fade" id="editarvalores" style="max-height: 80%">
      <div class="modal-dialog modal-lg modal-cpc modal-dialog-centered" >
        <div class="modal-content">
          <div class="container-lg">
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">Valores para característica</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <!-- Modal body -->
            <div class="modal-body ">
                  <div class="row mb-3 mt-3">
                    <div class="col-sm-12  col-lg-12">
                      <label > Valores para características</label>
                    </div>
                  </div>

                  <div class="row mb-3 mt-3">
                    <div class="col-sm-2  col-lg-2">
                      <div>{{caracteristicasEscolhidaEmEdicao?.nomeCaracteristica}}</div>
                      <div>{{caracteristicasEscolhidaEmEdicao?.codigoCaracteristica}}</div>
                    </div>
                    <div class="col-6">
<!--                      <label *ngIf="validaValoresCaracteristica == true" style="text-align: center; font-size: 10px;" >Número máximo de valores de característica: 40</label>-->
                      <label *ngIf="validaValoresCaracteristica == false" style="color: red; text-align: center; font-size: 10px;">Número máximo de valores de característica atingido.</label>
                    </div>
                    <div class="col-sm-4 col-lg-4">
                      <button type="button" class="br-button secondary"   data-toggle="modal" [attr.data-target]="validateValoresCaracteristicaModalOpen" (click)="limparValoresPDM()">
                        <i class="fas fa-plus-circle" aria-hidden="true" > </i>Adicionar
                      </button>
                      &nbsp;
                      <button class="br-button primary"  type="button" data-dismiss="modal">
                        <i class="fas fa-arrow-left" aria-hidden="true" > </i>Fechar
                      </button>
                    </div>

                  </div>
                  <div *ngIf="(caracteristicasEscolhidaEmEdicao?.valoresCaracteristica | json) != ([] | json)">
                    <p-table [value]="caracteristicasEscolhidaEmEdicao?.valoresCaracteristica"
                             [scrollable]="true" scrollHeight="300px"
                             [rowHover]="true"
                             responsiveLayout="scroll"
                    >
                      <ng-template pTemplate="header" class="row">
                        <tr >
                          <th  class="col-sm-1 col-lg-1">Código</th>
                          <th  class="col-sm-2 col-lg-2">Valor de característica</th>
                          <th  class="col-sm-2 col-lg-2">Ativo</th>
                          <th  class="col-sm-2 col-lg-2">Ação</th>

                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-valor class="row">
                        <tr>
                          <td class="col-sm-1 col-lg-1">{{valor.codigoValorCaracteristica}}</td>
                          <td class="col-sm-2 col-lg-2">{{valor.nome}}</td>
                          <td class="col-sm-2 col-lg-2">{{valor.ativo? "Sim" : "Não"}}</td>
                          <td class="col-sm-2 col-lg-2">
                            <p-confirmDialog header="Confirmar exclusão" icon="far fa-question-circle" key="{{valor.codigoValorCaracteristica}}"></p-confirmDialog>
                            <button  type="button" class="br-button secondary  small "
                                     aria-label="excluir"
                                     (click)="excluirValorCaracteristica(valor.codigoValorCaracteristica)"
                            >Excluir
                            </button>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </div>
                <!--
                <div class="modal-footer">
                  <div class="col-sm-2 col-lg-2">
                    <button class="br-button primary"  type="button" data-dismiss="modal">
                      <i class="fas fa-arrow-left" aria-hidden="true" > </i>Fechar
                    </button>
                  </div>
                </div>
                -->
            </div> <!--  fecha <div *ngIf="mostrarEditarCaracteristicas" -->
        </div>
      </div>
    </div>

    <div class="modal fade" id="editarunidademedida" style="max-height: 80%">
      <div class="modal-dialog modal-lg modal-cpc modal-dialog-centered" >
        <div class="modal-content">
          <div class="container-lg">
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">Unidades de medida</h4>
              <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
              <div class="row mb-3 mt-3">
                <div class="col-3">
                  <div>Nome: {{caracteristicasEscolhidaEmEdicao?.nomeCaracteristica}}</div>
                  <div>Código: {{caracteristicasEscolhidaEmEdicao?.codigoCaracteristica}}</div>
                </div>
                <div class="col-6">
  <!--                <label *ngIf="validaUnidadeMedida == true" style="text-align: center; font-size: 10px;" >Número máximo de unidades de medida: 40</label>-->
  <!--                <label *ngIf="validaUnidadeMedida == false" style="color: red; text-align: center; font-size: 10px;">Número máximo de unidades de medida atingido.</label>-->
                </div>
  <!--              <div class="col-sm-2 col-lg-2 card mb-3 ">-->
  <!--                <button class="br-button "  type="button" (click)="voltarListarCaracteristicas()">-->
  <!--                  <i class="fas fa-arrow-left" aria-hidden="true" > </i>Voltar-->
  <!--                </button>-->
  <!--              </div>-->
                <div class="col-3  mb-3">
                  <button type="button" class="br-button secondary" data-toggle="modal" [attr.data-target]="validateUnidadeMedidaOpenModal"
                          (click)="carregarUnidadesMedida()">
                    <i class="fas fa-plus-circle " aria-hidden="true" > </i>Adicionar
                  </button>
                </div>

              </div>
              <div *ngIf="(caracteristicasEscolhidaEmEdicao?.unidadesMedida | json) != ([] | json)">
                <p-table [value]="caracteristicasEscolhidaEmEdicao?.unidadesMedida"
                        [scrollable]="true" scrollHeight="400px">
                  <ng-template pTemplate="header" class="row">
                    <tr >
                      <th  class="col-sm-3 col-lg-3">Sigla</th>
                      <th  class="col-sm-4 col-lg-4">Nome</th>
                      <th  class="col-sm-3 col-lg-3">Ação</th>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-unidade class="row">
                    <tr>
                      <td class="col-sm-3 col-lg-3">{{unidade.siglaUnidadeMedida}}</td>
                      <td class="col-sm-4 col-lg-4">{{unidade.nomeUnidadeMedida}}</td>
                      <td class="col-sm-3 col-lg-3">
                        <p-confirmDialog header="Confirmar exclusão" icon="far fa-question-circle" key="{{unidade.siglaUnidadeMedida}}"></p-confirmDialog>
                        <button  type="button" class="br-button secondary  small "
                                aria-label="excluir"
                                (click)="excluirUnidadeMedidaValor(unidade.siglaUnidadeMedida)"
                        >Excluir
                        </button>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="br-button primary" data-dismiss="modal">
                <i class="fas fa-arrow-left" aria-hidden="true" > </i>Fechar</button>
            </div>
          </div> <!--  fecha <div *ngIf="mostrarEditarCaracteristicas" -->
        </div>
      </div>
    </div>


</div>

