import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';
import {SplitterModule} from 'primeng/splitter';
import {TabViewModule} from 'primeng/tabview';
import {DataViewModule} from 'primeng/dataview';
import {PaginatorModule} from 'primeng/paginator';
import {MultiSelectModule} from 'primeng/multiselect';
import {RadioButtonModule} from 'primeng/radiobutton';
import {TableModule} from 'primeng/table';

import {BuscaSiasgnetComponent} from './busca-siasgnet/busca-siasgnet.component';
// import { DetalheMaterialComponent } from './detalhe-material/detalhe-material.component';
// import { DetalheServicoSiasgnetComponent } from './detalhe-servico-siasgnet/detalhe-servico-siasgnet.component';
import {DetalheServicoSiasgnetComponent} from './detalhe-servico-siasgnet/detalhe-servico-siasgnet.component';
import {BuscaSiasgnetRoutingModule} from './busca-siasgnet-routing.module';

import {ListboxModule} from 'primeng/listbox';
import {GovBrAlertMessagesModule} from '@serpro/ngx-dsgovbr';
import {GovBrModule} from '../dsgovbr.module';
import {DetalheMaterialSiasgnetComponent} from './detalhe-material-siasgnet/detalhe-material-siasgnet.component';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {SidebarModule} from 'primeng/sidebar';
import {SplitButtonModule} from 'primeng/splitbutton';
import {PipeModule} from "../pipe/pipe.module";
import {TooltipModule} from 'primeng/tooltip';
import { ErroComponent } from './erro/erro.component';
import { SharedModule } from "../shared/shared.module";

@NgModule({
    declarations: [
        BuscaSiasgnetComponent,
        DetalheServicoSiasgnetComponent,
        DetalheMaterialSiasgnetComponent,
        ErroComponent
        // DetalheMaterialSiasgnetComponent,
    ],
    exports: [
        BuscaSiasgnetComponent,
        BuscaSiasgnetRoutingModule
    ],
    imports: [
        CommonModule,
        // HttpClientModule,
        FormsModule,
        DropdownModule,
        SplitterModule,
        TabViewModule,
        DataViewModule,
        PaginatorModule,
        MultiSelectModule,
        RadioButtonModule,
        TableModule,
        ListboxModule,
        GovBrAlertMessagesModule,
        GovBrModule,
        AutoCompleteModule,
        SidebarModule,
        SplitButtonModule,
        PipeModule,
        TooltipModule,
        SharedModule
    ]
})
export class BuscaSiasgnetModule { }

