import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BuscaService } from 'src/app/service/busca-service';

@Component({
  selector: 'app-selecionar-unidade-medida-form',
  templateUrl: './selecionar-unidade-medida-form.component.html',
  styleUrls: ['./selecionar-unidade-medida-form.component.css']
})
export class SelecionarUnidadeMedidaFormComponent implements OnInit {
  unidadeMedidaFiltro: String;
  unidadeFornecimentoTela: any;
  first = 0;
  unidadeFornecimentoPdmApi: any;
  somenteNomeUnidadeMedidaFiltro: boolean = false;
  somenteSiglaUnidadeMedidaFiltro: boolean = false;
  caracteristicasEscolhidaArray: any;

  @Input("unidadeMedidaEmEdicao") caracteristicasEscolhidaEmEdicao: any;

  @Output() onUnidadeMedidaSelecionada: EventEmitter<any> = new EventEmitter<any>();

  constructor(private buscaService: BuscaService) { }

  ngOnInit(): void {
      this.caracteristicasEscolhidaArray = [];
      this.buscaService.obterUnidadeFornecimentoPdmPossuiEmbalagem('false').subscribe(z => {this.unidadeFornecimentoPdmApi = z; this.limparFiltroUnidadeMedida()});
  }

  limparFiltroUnidadeMedida(): void {
    this.first = 0;
    this.unidadeMedidaFiltro = '';
    this.unidadeFornecimentoTela = [];
    this.unidadeFornecimentoTela = this.unidadeFornecimentoPdmApi.slice();
  }

  escolherUnidadeFornecimento(siglaUnidadeFornecimento: any, nomeUnidadeFornecimento: any, possuiEmbalagem: any): void {

    let aux2 = {
     siglaUnidadeMedida: siglaUnidadeFornecimento,
     nomeUnidadeMedida: nomeUnidadeFornecimento,
     embalagemUnidadeMedida: possuiEmbalagem
    }
    this.onUnidadeMedidaSelecionada.emit(aux2);
    }

    filtrarUnidadeMedida(atualizaNome?: boolean, atualizaSigla?: boolean): void {
      if(atualizaNome != undefined){
        this.somenteNomeUnidadeMedidaFiltro = ! this.somenteNomeUnidadeMedidaFiltro;
        if(this.somenteNomeUnidadeMedidaFiltro == true){
          this.somenteSiglaUnidadeMedidaFiltro = false;
        }
      }
      if(atualizaSigla != undefined){
        this.somenteSiglaUnidadeMedidaFiltro = !this.somenteSiglaUnidadeMedidaFiltro;
        if(this.somenteSiglaUnidadeMedidaFiltro == true){
          this.somenteNomeUnidadeMedidaFiltro = false
        }
      }
      console.log(this.somenteNomeUnidadeMedidaFiltro);
      console.log(this.somenteSiglaUnidadeMedidaFiltro)
      if(this.somenteNomeUnidadeMedidaFiltro == true && this.somenteSiglaUnidadeMedidaFiltro == true){
        this.first = 0;
        this.unidadeFornecimentoTela = [];
        for (const tmp of this.unidadeFornecimentoPdmApi) {
          if (tmp.nomeUnidadeFornecimento.toUpperCase().includes((this.unidadeMedidaFiltro.toUpperCase())) || tmp.siglaUnidadeFornecimento.toUpperCase().includes((this.unidadeMedidaFiltro.toUpperCase()))){
            this.unidadeFornecimentoTela.push(tmp);
          }
        }
      }
      else {
        if(this.somenteNomeUnidadeMedidaFiltro == false && this.somenteSiglaUnidadeMedidaFiltro == true){
          this.first = 0;
          this.unidadeFornecimentoTela = [];
          for (const tmp of this.unidadeFornecimentoPdmApi) {
            if (tmp.siglaUnidadeFornecimento.toUpperCase().includes((this.unidadeMedidaFiltro.toUpperCase()))){
              this.unidadeFornecimentoTela.push(tmp);
            }
          }
        } else {
          if(this.somenteNomeUnidadeMedidaFiltro == true && this.somenteSiglaUnidadeMedidaFiltro == false){
            this.first = 0;
            this.unidadeFornecimentoTela = [];
            for (const tmp of this.unidadeFornecimentoPdmApi) {
              if (tmp.nomeUnidadeFornecimento.toUpperCase().includes((this.unidadeMedidaFiltro.toUpperCase()))){
                this.unidadeFornecimentoTela.push(tmp);
              }
            }
          } else {
            this.first = 0;
            this.unidadeFornecimentoTela = [];
            for (const tmp of this.unidadeFornecimentoPdmApi) {
              if (tmp.nomeUnidadeFornecimento.toUpperCase().includes((this.unidadeMedidaFiltro.toUpperCase())) || tmp.siglaUnidadeFornecimento.toUpperCase().includes((this.unidadeMedidaFiltro.toUpperCase())) || tmp.descricaoUnidadeFornecimento.toUpperCase().includes((this.unidadeMedidaFiltro.toUpperCase()))){
                this.unidadeFornecimentoTela.push(tmp);
              }
            }
          }
        }
      }
    }

}
