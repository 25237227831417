import { BuscaService } from './../../../../service/busca-service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-selecionar-conjunto',
  templateUrl: './selecionar-conjunto.component.html',
  styleUrls: ['./selecionar-conjunto.component.css']
})
export class SelecionarConjuntoComponent implements OnInit {

  conjuntoMaterialApi: any;
  conjuntoMaterialTela: any;
  conjuntoMaterialFiltro: string;
  first = 0;

  @Input() classeEscolhida: any;
  @Output() conjuntoMaterial: EventEmitter<any> = new EventEmitter<any>();

  constructor(private buscaService: BuscaService) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.buscaService.obterConjuntoMaterial(this.classeEscolhida).subscribe(
      resp => {
        console.log(this.classeEscolhida)
        this.conjuntoMaterialApi = resp;
        this.limpar();
      }
    );
  }	


  limpar(){
    this.conjuntoMaterialFiltro = '';
    this.conjuntoMaterialTela = this.conjuntoMaterialApi;
    this.first = 0;
  }

  filtrarConjuntoMaterial(): void {
    this.conjuntoMaterialTela = [];
    for (const tmp of this.conjuntoMaterialApi) {
      if(tmp.nomeConjunto.toUpperCase().includes((this.conjuntoMaterialFiltro.toUpperCase())) || tmp.codigoConjunto.toString().toUpperCase().includes((this.conjuntoMaterialFiltro.toUpperCase()))){
        this.conjuntoMaterialTela.push(tmp);
      }
    }
    this.first = 0;
  }

  escolherConjuntoMaterial(item: any){
    this.conjuntoMaterial.emit(item);
    this.limpar();
  }
}
