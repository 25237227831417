import { Component, Input, OnInit } from '@angular/core';
import { SessaoService } from 'src/app/service/sessao.service';
import { PesquisaService } from '../pedido-catalogador.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HistoricoPedido } from '../../model/historico-pedido.model';
import { MessageService } from 'primeng/api';
import { PedidoForm } from '../../model/pedido-form.model';

@Component({
  selector: 'app-historico-pedido',
  templateUrl: './historico-pedido.component.html',
  styleUrls: ['./historico-pedido.component.css']
})
export class HistoricoPedidoComponent implements OnInit {

  @Input() pedido: PedidoForm;
  @Input() token: any;
  @Input() usrProfile: any;
  @Input() usrRoles:{[id:string]:boolean};

  numeroPedido:any;
  historico: HistoricoPedido[] = [];

  constructor(
    private sessaoService: SessaoService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private pedidoService: PesquisaService)
    {}


  ngOnInit(): void {
    this.route.params.subscribe((params) => this.numeroPedido = params['numeroPedido']);
    this.getHistoricoPedido();
  }

  getHistoricoPedido() {
    this.pedidoService.obterHistoricoPedido(this.numeroPedido)
      .subscribe(resp => {
        this.historico = resp;
      },
        error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro no carregamento dos dados'
        })
      });
  }

  irParaAT() {
    this.sessaoService.irParaAT();
  }

  irParaPedidos() {
    this.router.navigate(['areacomprador']);
  }

  dataFormatada(data: string) : string {
    var dt = new Date(data);
    return dt.toLocaleDateString("pt-BR") + ' - ' + dt.toLocaleTimeString("pt-BR", { hour: "2-digit", minute: "2-digit", hour12: false });;
  }

}
