<div class="container-lg">
  <div class="container-lg">
    <div class="row text-right">
      <div class="col-8">
        <button type="button" *ngIf="incluirNovoItem" class="br-button primary  small" aria-label="Criar Novo Item"
          (click)="enviarNovoItem2()"> Criar Novo Item</button>
      </div>
    </div>
  </div>

  <br-confirmation #confirmCont popupTitle="Nenhum Item Encontrado com as características escolhidas"
    acceptLabel="SIM, criar novo item" rejectLabel="Não criar novo item."
    question="Deseja escolher outras características OU Criar um Novo Item com as características escolhidas?"
    (accept)="IniciaNovoItem()">
  </br-confirmation>


  <div class="row" style="padding-top: 20px">
    <div class="col-md-4 col-sm-12 card" style="margin-left: -4px; margin-right: 4px;">
      <h5>{{pdm.descricao}}</h5>
      <div class="row">
        <div class="col-6">
          <div class="br-tag text support-12 m-0" style="width: 80px;" >
            <span style="font-size: 12px">MATERIAL</span>
          </div>
        </div>
<!--        <div class="col-6">Limpar Filtros-->
<!--          <button class="br-button secondary circle small mt-3 mt-sm-0 ml-sm-3"  (click)="limparFiltros()"-->
<!--                  type="button" aria-label="Ícone ilustrativo">-->
<!--            <i class="fas fa-trash" aria-hidden="true"></i>-->
<!--          </button>-->
<!--        </div>-->
      </div>
      <hr class="mb-0 mt-2">
      <h6 class="text-uppercase mb-2" style="font-size: 12px;"> Unidade de Fornecimento</h6>

      <div>
        <label>Selecione uma unidade de fornecimento</label>
          <select [(ngModel)]="unidadeFornecimentoEscolhida" >
<!--            <select (change)="escolherUnidade($event)">-->
            <option><span></span></option>
<!--              <option   *ngFor="let unidade of unidadesFornecimento; let i = index" [value]="i">-->
              <option   *ngFor="let unidade of unidadesFornecimento" [ngValue]="unidade">
                {{unidade.nomeUnidadeFornecimento}}
                {{unidade.capacidadeUnidadeMedida ? unidade.capacidadeUnidadeMedida : ''}}
                {{unidade.nomeUnidadeMedida ? unidade.nomeUnidadeMedida: ''  }}
              </option>
          </select>
      </div>
      <hr class="mb-0 mt-4">
      <div>
        <h6 class="text-uppercase mb-2" style="font-size: 12px;"> Características</h6>
      </div>

      <div *ngFor="let carac of caracteristica" >
        <div *ngIf="carac.valorCaracteristica.length > 0 && carac.statusCaracteristica == true" class="mb-4">
          <label>{{carac.nomeCaracteristica}}
            <span class="" style="font-size:80%;">
              <i>{{carac.caracteristicaObrigatoria ? ' - obrigatória' : '' }}</i>
            </span>
          </label>
          <select (change)="mudarFiltro($event, carac.codigoCaracteristica, carac.numeroCaracteristica)">
            <option value="0"> {{carac.nomeCaracteristica}}</option>
            <option *ngFor="let valor of carac.valorCaracteristica"
              value="{{valor.codigoValorCaracteristica}}|{{valor?.siglaUnidadeMedida}} ">
              {{valor.nomeValorCaracteristica}} {{valor?.siglaUnidadeMedida}}
            </option>
          </select>
        </div>
      </div>

      <hr class="mb-0 mt-2">
      <div>
        <div><h6 class="text-uppercase mb-2" style="font-size: 12px;"> Natureza da Despesa</h6></div>
        <p *ngFor="let item of classificacaoContabil;" style="text-align: right; padding-right: 50px; line-height: 0.4"> {{item}}</p>
      </div>
      <hr class="mb-0 mt-2">
    </div>


    <div class="col-md-8 col-sm-12 card" style="margin-left: 4px; margin-right: -4px;">
      <div class="row text-right">
        <div class="col-12">
          <button type="button" class="br-button secondary small" (click)="voltar()" style="margin-right: 5px;"
            aria-label="Voltar">Voltar</button>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-7">
          <h6 style="font-size: 14px; margin-top: 0px">
            <span>Foram encontrados: {{ itensAtivos }} ativo(s)</span><span *ngIf="ativos">, {{ itensInativos }} suspenso(s)​</span>
          </h6>
        </div>

        <span class="br-checkbox col-5" style="text-align: end; align-self: center">
          <input id="somenteAtivos" name="somente-ativos" type="checkbox" aria-label="somente ativos" (change)="somenteAtivos()" [(ngModel)]="ativos"/>
          <label for="somenteAtivos">Mostrar também itens suspensos</label>
        </span>
      </div>

      <p-table [value]="itensSelecionadosTela">
        <ng-template pTemplate="header">
          <tr class="row">
            <th class="col-2" pSortableColumn="codigoItem">Código <p-sortIcon field="codigoItem"></p-sortIcon></th>
            <th class="col-5" pSortableColumn="nomePdm">Nome do Material <p-sortIcon field="nomePdm"></p-sortIcon></th>
            <th class="col-2" pSortableColumn="codigoNcm">
              <span pTooltip="Nomenclatura Comum do Mercosul (NCM)<br><br>Regulamentado pelo Decreto N<sup>o</sup> 11.890, de 22 de Janeiro de 2024" 
                tooltipPosition="bottom" [escape]="false">NCM</span> <p-sortIcon field="codigoNcm"></p-sortIcon>
            </th>
            <th class="col-3" pSortableColumn="itemSuspenso">Ação<p-sortIcon *ngIf="ativos" field="itemSuspenso"></p-sortIcon></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr class="row">
            <td class="col-2"> {{item.codigoItem}} <span *ngIf="item.itemSustentavel == true" class="fas fa-leaf small"  pTooltip="Sustetável"></span></td>
            <td class="col-5"> <label><b>{{item.nomePdm}}</b></label>
              <span *ngFor="let itemCacac of item.buscaItemCaracteristica">
<!--               <div *ngIf="itemCacac.statusCaracteristica == true "> - JMD - Conforme relato 3229865 - 07/06/2022 -->
                <div>
                  <p class="pb-0 mb-0">
                    <span style="color: black;">{{itemCacac.nomeCaracteristica | lowercase}}:
                     {{itemCacac.nomeValorCaracteristica |lowercase}}  {{itemCacac.siglaUnidadeMedida | lowercase}}</span>
                  </p>
                </div>
<!--                JMD - Conforme relato 3229865 - 07/06/2022-->
<!--                <div *ngIf="itemCacac.statusCaracteristica == false ">-->
<!--                  <p class="pb-0 mb-0">-->
<!--                    <span style="text-transform: capitalize; color: #9f9a9a;">{{itemCacac.nomeCaracteristica | lowercase}}:-->
<!--                      {{itemCacac.nomeValorCaracteristica |lowercase}} {{itemCacac.siglaUnidadeMedida | lowercase}}</span>-->
<!--                  </p>-->
<!--                </div>-->
              </span>
            </td>
            <td class="col-2">
              <span style="color: black">
                {{ item.codigoNcm | ncmPipe }}
              </span>
            </td>
            <td class="col-3">
              <button *ngIf="item.statusItem == true && item.itemSuspenso == false" type="button" class="br-button secondary small"
                aria-label="Adicionar" (click)="escolher(item.codigoItem)">
                Adicionar
              </button>
              <button *ngIf="item.statusItem == true && item.itemSuspenso == true" type="button" class="br-button secondary  small "   (click)="escolher(item.codigoItem)" aria-label="Inativo" disabled>
                Suspenso
              </button>
              <button *ngIf="item.statusItem == false" type="button" class="br-button secondary small"
                aria-label="Inativo" (click)="escolher(item.codigoItem)" disabled>
                Inativo
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<ng-template #mensagemImpedimento>
  <div class="row" style="flex: 1">
    <div class="col-12">
      <div style="text-align:center;font-size: medium;">
        <br>
        <div>Caso o item desejado não seja encontrado após o preenchimento de todas as características, esclarecemos</div>
        <div>que o usuário deverá observar o exposto no tópico "8.2 Incluir Pedido de Catalogação de Item no</div>
        <div>CATMAT ou CATSER (p. 378)" do Manual CATMAT e CATSER disponível nesse
        <b><i><a href="https://www.gov.br/compras/pt-br/centrais-de-conteudo/manuais/manual-siasg/manual-siasg-catalogo/manual-catmat-e-catser-2020.pdf"
          target="_blank">
          link.</a></i></b></div>
        <div>O pedido deve ser feito por meio do HOD e o usuário precisa possuir perfil adequado.</div>
        <br>
        <button (click)="fecharModalMensagemImpedimento()">Fechar</button><p></p>
      </div>
    </div>
  </div>
</ng-template>
