import { Component, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { MessageService } from 'primeng/api';
import { BuscaService } from 'src/app/service/busca-service';

@Component({
  selector: 'app-manutencao-valor-caracteristica',
  templateUrl: './manutencao-valor-caracteristica.component.html',
  styleUrls: ['./manutencao-valor-caracteristica.component.css']
})
export class ManutencaoValorCaracteristicaComponent implements OnInit {

  @Output() atualizarListaAoSair: EventEmitter<any> = new EventEmitter<any>();

  incluirNome: string = "";
  incluirDescricao: string = "";
  incluirComplemento: string = "";
  disableSave: boolean = true;
  existeNome: boolean = false;

  visible: boolean = false;

  novoCodigo: string = "000000";

  modalRef1: BsModalRef;
  // @ViewChild('valorCaracteristicaSucess', {static: false} ) valorCaracteristicaSucess: any;

  numMaxNome: number = 50;
  numMaxDescricaoIncluir: number = 200;
  numMaxDescricaoEditar:number = 200;
  numMaxComplementoIncluir:number = 1500;
  numMaxComplementoEditar:number = 1500;

  valorCaracteristica: any = null;

  isModalShown: boolean = false;

  constructor(private router: Router, private buscaService: BuscaService, private modalService: BsModalService, private messageService: MessageService) { }

  ngOnInit(): void {
  }

  salvarValorCaracteristica(): void{
    let modalOptions: ModalOptions = {
      backdrop : 'static',
      keyboard : false,
      focus: true
    };
    this.visible = true;
    this.buscaService.salvarValorCaracteristicaPDM({
      nomeValorCaracteristica: this.incluirNome.trim(),
      statusValorCaracteristica: true,
      descricaoValorCaracteristica: this.incluirDescricao.trim(),
      complementoValorCaracteristica: this.incluirComplemento.trim()
    }).subscribe((tmp) => {
      this.visible = false;
      this.valorCaracteristica = tmp;
      this.novoCodigo = tmp.codigoValorCaracteristica;
      this.incluirComplemento = "";
      this.incluirDescricao = "";
      this.incluirNome = "";
      this.isModalShown = true;
      // this.modalRef1 = this.modalService.show(this.valorCaracteristicaSucess, modalOptions);
    }, erro => {
        this.visible = false;
        if(typeof(erro.error) == "string"){
          this.messageService.add({
            severity: 'error',
            summary: 'Erro ao salvar os dados no servidor: ' + erro.error
            });
        }else{
          this.messageService.add({
            severity: 'error',
            summary: 'Não foi possível se conectar com o serviço neste momento, por favor tente mais tarde.'
            });
        }
    });
  }

  enableSave(){
    if(this.incluirNome == ""){
      this.disableSave = true;
    } else{
      this.disableSave = false;
    }
  }

  decrementaTexto(campo: string){

    if(campo == "nome"){
      this.numMaxNome = 50;
      this.numMaxNome = this.numMaxNome - (this.incluirNome.length);
    }

    if(campo == "descricaoIncluir"){
      this.numMaxDescricaoIncluir = 200;
      this.numMaxDescricaoIncluir = this.numMaxDescricaoIncluir - (this.incluirDescricao.length);
    }

    if(campo == "complementoIncluir"){
      this.numMaxComplementoIncluir = 1500;
      this.numMaxComplementoIncluir = this.numMaxComplementoIncluir - (this.incluirComplemento.length);
    }

  }

  sairModalSalvar(){
    this.atualizarListaAoSair.emit(this.valorCaracteristica);
    //this.modalRef1.hide();
  }

  limpar(){
    this.incluirComplemento = "";
    this.incluirDescricao = "";
    this.incluirNome = "";
    this.existeNome = false;
    this.numMaxNome = 50;
    this.numMaxDescricaoIncluir = 200;
    this.numMaxComplementoIncluir = 1500;
  }

  validaNome(campo: any){
    let aux = this.incluirNome.trim();
    this.buscaService.validaNomeExistenteValorCaracteristica(aux).subscribe((tmp)=>{
      if(tmp > 0){
        this.existeNome = true;
        this.disableSave = true;
        this.incluirNome = "";
        campo.focus();
      }
      else {
        this.existeNome = false;
      }
    })
  }

}
