import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BuscaService } from 'src/app/service/busca-service';

@Component({
  selector: 'app-selecionar-classe',
  templateUrl: './selecionar-classe.component.html',
  styleUrls: ['./selecionar-classe.component.css']
})
export class SelecionarClasseComponent implements OnInit {

  first: number = 0;
  classeFiltro: string;
  classesTela: any = [];
  classesApi: any = [];

  @Output() onClasseSelecionada: EventEmitter<any> = new EventEmitter<any>();

  constructor(private buscaService: BuscaService) { }

  ngOnInit(): void {
    this.buscaService.obterClassesPDM().subscribe(classesTmp => {this.classesApi = classesTmp; this.limparFiltroClass()});
  }

  limparFiltroClass(): void {
    this.first = 0;
    this.classesTela = [];
    this.classeFiltro = '';
    this.classesTela = this.classesApi.slice();
  }

  escolherClasse(codigoClasse: number): void {
    console.log('classe escolhida ', codigoClasse);
    for (const tmp of this.classesTela) {
      if ( tmp.codigoClasse === codigoClasse){
       this.onClasseSelecionada.emit(tmp);
      }
    }
  }

  filtrarClasse(): void {
    this.first = 0;
    console.log('filtrarClasse', this.classeFiltro);
    this.classesTela = [];
    for (const tmp of this.classesApi) {
      if (tmp.nomeClasse.toUpperCase().includes((this.classeFiltro.toUpperCase())) ||
          tmp.codigoClasse.toString().includes((this.classeFiltro)) ||
          tmp.codigoGrupo.toString().includes((this.classeFiltro)) ||
          tmp.nomeGrupo.toUpperCase().includes((this.classeFiltro.toUpperCase()))){
        console.log(tmp.nomeClasse);
        this.classesTela.push(tmp);
      }
    }
  }

}
