<div class="modal fade" id="buscarunidadefornecimentototal" >
    <div class="modal-dialog modal-lg modal-cpc" >
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Selecionar a unidade de fornecimento total</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>

        <div  class="container-lg"  >
          <div class="row mb-3 mt-3" style="justify-content: center;">
            <div class="col-sm-6 col-lg-6 mb-3">
              <div class="br-input">
                <input class="p-inputtext" type="text" placeholder="Digite o nome da unidade de fornecimento para filtrar" [(ngModel)]="unidadeFornecimentoFiltro" (keyup)="filtrarUnidadeFornecimento()" />
              </div>
            </div>
            <div class="column">
              <div class="br-checkbox">
                <input id="somenteNomeUnidadeFornecimentoGeral" name="somente-nome" type="checkbox" aria-label="somente nome" (click)="filtrarUnidadeFornecimento(true)" [(ngModel)]="somenteNomeUnidadeFornecimentoFiltro"/>
                <label for="somenteNomeUnidadeFornecimentoGeral">Pesquisar somente nome</label>
              </div>
              <div class="br-checkbox">
                <input id="teste" name="somente-sigla" type="checkbox" aria-label="somente sigla" (click)="filtrarUnidadeFornecimento(undefined, true)" [(ngModel)]="somenteSiglaUnidadeFornecimentoFiltro"/>
                <label for="teste">Pesquisar somente sigla</label>
              </div>
            </div>
          </div>

          <p-table  [value]="unidadeFornecimentoTela" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} " [rowsPerPageOptions]="[10,25,50]" [(first)]="first">
            <ng-template pTemplate="header">
              <tr class="row">
                <th pSortableColumn="nomeUnidadeFornecimento" class="col-sm-2 col-lg-2">Nome unidade de fornecimento<p-sortIcon field="nomeUnidadeFornecimento"></p-sortIcon></th>
                <th pSortableColumn="descricaoUnidadeFornecimento" class="col-sm-4 col-lg-4">Descrição unidade de fornecimento<p-sortIcon field="descricaoUnidadeFornecimento"></p-sortIcon></th>
                <th pSortableColumn="possuiEmbalagem" class="col-sm-2 col-lg-2">Possui embalagem<p-sortIcon field="possuiEmbalagem"></p-sortIcon></th>
                <th pSortableColumn="siglaUnidadeFornecimento" class="col-sm-2 col-lg-2">Sigla unidade de fornecimento<p-sortIcon field="siglaUnidadeFornecimento"></p-sortIcon></th>
                <th class="col-sm-2 col-lg-2">Ações</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr class="row">
                <td class="col-sm-2 col-lg-2">{{item.nomeUnidadeFornecimento}}</td>
                <td class="col-sm-4 col-lg-4">{{item.descricaoUnidadeFornecimento}}</td>
                <td class="col-sm-2 col-lg-2">{{item.possuiEmbalagem? "Sim" : "Não"}}</td>
                <td class="col-sm-2 col-lg-2">{{item.siglaUnidadeFornecimento}}</td>
                <td class="col-sm-2 col-lg-2">
                  <button  type="button" class="br-button secondary  small "
                           (click)="escolherUnidadeFornecimento(item)" 
                           aria-label="Selecionar"
                           data-dismiss="modal"
                  >Selecionar
                  </button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>

      </div>
    </div>
  </div>