import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {AutoComplete} from 'primeng/autocomplete';
import {BuscaService} from '../../../service/busca-service';
import {Service} from '../../../service/service';



@Component({
  selector: 'app-busca-component',
  templateUrl: './busca-component.component.html',
  styleUrls: ['./busca-component.component.css']
})
export class BuscaComponentComponent implements OnInit {

  @Output() aoChegarResultado = new EventEmitter<any>();

  display: boolean;
  textoBusca: any;
  results: any;
  itensAtualizado: any;
  servicosRetornados: any ;
  materiaisRetornados: any  ;



  @ViewChild('hint', {static: true}) hint: AutoComplete;
  private palavrapesquisada: any;
  constructor(private buscaService: Service, private service: Service) { }

  ngOnInit(): void {

  }
  voltarDadoAoChamador(): void{
    console.log('voltar dados ao chamador');
    this.aoChegarResultado.emit({
      resultadoServico: this.servicosRetornados,
      resultadoMaterial: this.materiaisRetornados

    });
  }

  voltaTelaInicial(): void {

  }

  getItensAtualizado(): void {

  }

  searchHint(event: any): void {
    // console.log(event.query);
    if ( event.query.length >= 2){
      this.service.obterPDMeServicoPorPalavra(event.query).subscribe(data => {
        const x = {
          codigo: '',
          tipo: 'Todos',
          nome: event.query
        };
        //  console.log(data);
        data.push(x);
        this.results = data;
      });
    }
  }

  async pesquisarEnter(event: any): Promise<any> {
    this.palavrapesquisada   = event.target.value;
    console.log(this.palavrapesquisada);
    if (this.palavrapesquisada.length >= 2) {
      setTimeout(() => this.hint.hide(), 200);
      await this.pesquisar();
    }
  }

  async pesquisar(): Promise<any> {
    if (isNaN(this.palavrapesquisada)) { // se  é pesquisa por texto
      console.log(' pesquisar');
      await this.obterServicosPorPalavra(this.palavrapesquisada);
      await this.obterPDMPorPalavra(this.palavrapesquisada);
    }else{
      console.log('implementa pesquisa por código, vai entra na tela de edição? ', this.palavrapesquisada);
    }

    this.voltarDadoAoChamador();
  }

  liberarPesquisa(event: any): void {
    // console.log(event.target.value);
    const teste = event.target.value;
    if (teste.length >= 2){
     // this.isDisabled = false;
    }else{
     //  this.isDisabled = true;
    }
  }

  async buscaEscolhido(codigo: any, tipo: any, nome: any): Promise<any> {
    console.log('busca escolhido' + codigo + 'tipo ' + tipo + 'nome ' + nome);
    // console.log(tipo);
    this.palavrapesquisada = nome;
   // this.esconderTodos();
    // this.resetArrays();
    if (tipo === 'M'){ // material
      // console.log('material');
      localStorage.setItem('Id', codigo);
      localStorage.setItem('Nome', nome); // this.router.navigate(['/detalhematerialsiasgnet/']);
      // this.tela1 = false;
      // this.detalheMaterialVisivel = true;
      this.palavrapesquisada = '';
    }
    if (tipo  === 'S'){ // servico
      console.log('serviço === >' + codigo);
      this.service.obterServicoPorCodigo(codigo).subscribe(servico => {
        if (servico) {
          this.servicosRetornados.push(servico);
         //  this.carregarGrupoServico(servico);
        }
      }, (error) => console.error(error));
      this.palavrapesquisada = '';
    }
    if (tipo  === 'Todos') { // servico
      await this.pesquisar();
      //  this.texto = '';
    }
    //  this.texto = '';

  }

  async obterServicosPorPalavra(texto: string): Promise <any> {
    return new Promise((resolve, reject) => {
      this.buscaService.obterServicosPorPalavra(texto).subscribe(servico => {
        if (servico) {
          let tmp;
          this.servicosRetornados = new Array();
          for (tmp of servico) {

            this.servicosRetornados.push(tmp);
          }
        }
        resolve('ok');
      }, (error) => console.error(error));
    });
  }

  async obterPDMPorPalavra(texto: string): Promise <any> {
    console.log(texto);
    return new Promise((resolve, reject) => {
      this.buscaService.obterPDMPorPalavra(texto).subscribe(material => {
        if (material) {
          let tmp;
          this.materiaisRetornados = new Array();
          for (tmp of material) {
            this.materiaisRetornados.push(tmp);
          }
          resolve('ok');
        }
      }, (error) => console.error(error));
    });
  }

  async obterServicoPorCodigo(): Promise <any> {
    console.log(this.palavrapesquisada);
    return new Promise((resolve, reject) => {
      this.service.obterServicoPorCodigo(this.palavrapesquisada).subscribe(servico => {
        if (servico) {
          this.servicosRetornados.push(servico);
          // this.carregarGrupoServico(servico);
        }
        resolve(this.servicosRetornados.length);
      }, (error) => console.error(error));
    });
  }

  async obterMaterialPorCodigo(): Promise <any> {
    console.log(this.palavrapesquisada);
    let quantidade = 0;
    return new Promise((resolve, reject) => {
      this.service.obterMaterialPorCodigo(this.palavrapesquisada).subscribe(materialRetorno => {
        if (materialRetorno) {
          localStorage.setItem('codigo_escolhido', this.palavrapesquisada);
         //  this.detalheMaterialVisivel = true;
         //  this.tela1 = false;
          quantidade = 1 ;
        }
        resolve(quantidade);
      }, (error) => console.error(error));
    });
  }

// todo: em desenvolvimento - novos
  async existeObterServicoPorCodigo(): Promise <any> {
    let quantidade = 0;
    return new Promise((resolve, reject) => {
      this.service.obterServicoPorCodigo(this.palavrapesquisada).subscribe(servico => {
        if (servico) {
          quantidade = 1;
        }
        resolve(quantidade);
      }, (error) => console.error(error));
    });
  }

  async existeObterMaterialPorCodigo(): Promise <any> {
    console.log(this.palavrapesquisada);
    let quantidade = 0;
    return new Promise((resolve, reject) => {
      this.service.obterMaterialPorCodigo(this.palavrapesquisada).subscribe(materialRetorno => {
        if (materialRetorno) {
          quantidade = 1 ;
        }
        resolve(quantidade);
      }, (error) => console.error(error));
    });
  }
  // private async obterMateriaServicoPorCodigo(): Promise<any> {
  //   switch (this.filtro1.code) {
  //     case 1:
  //       this.messageService.add({severity: 'info', summary: 'Busca por código.', detail: 'Para busca por código, escolha material ou serviço.'});
  //       break;
  //     case 2:
  //       localStorage.setItem('codigo_escolhido', this.texto);
  //       // this.router.navigate(['/detalhematerialsiasgnet/']);
  //       this.detalheMaterialVisivel = true;
  //       this.tela1 = false;
  //       break;
  //     case 3:
  //       await this.service.obterServicoPorCodigo(this.texto).subscribe(servico => {
  //         if (servico) {
  //           this.servicos.push(servico);
  //           this.servicosRetornados.push(servico);
  //           this.carregarGrupoServico(servico);
  //           this.tela1 = true;
  //         }
  //       }, (error) => console.error(error));
  //       break;
  //   }
  // }



}
