<app-cabecalho-interno></app-cabecalho-interno>
<div class="container-lg p-2">
  <div class="row my-5 mt-5 pt-4">
    <div class="col-md-8 mt-4 pr-5 erro-text">
      <div class="main-text">
        <p>Sessão Encerrada</p>
      </div>
      <div class="secondary-text pr-5">
        <p>
          A sua sessão foi encerrada.
        </p>
      </div>
      <div>
        <div>
          <p>Feche a janela do seu navegador ou utilize o botão abaixo para efetuar o login novamente.</p>
        </div>
        <div class="campo col-10">
          <button class="br-button is-primary" tooltip="Efatuar Login" type="button" (click)="redirecionarParaLogin()">
            Efetuar Login
          </button>
        </div>
      </div>
    </div>

    <div class="col-md-4 mt-1 imagem-erro">
      <div>
        <img class="img-erro" src="./assets/imagens/sessao-expirada.svg" alt="imagem de erro" />
      </div>
    </div>
  </div>

  <div class="row my-4"></div>
  <div class="buttons-auxiliary my-4 text-center" style="background-color: #f8f8f9;">

    <button class="br-button m-2" type="button" (click)="irParaComprasNetAspInicio()">
      <i class="fas fa-home" aria-hidden="true"></i><span>Ir para Página Principal do Compras.gov.br</span>
    </button>
    <button
      class="br-button m-2"
      type="button"
      onclick="window.location.href='https://www.gov.br/compras/pt-br/canais_atendimento/central-de-atendimento'"
    >
      <i class="fas fa-comment-dots" aria-hidden="true"></i><span>Posso Ajudar?</span>
    </button>
  </div>
</div>
