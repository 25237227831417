import { TipoUsuarioEnum } from './../model/tipo-usuario-enum';
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Observer, throwError} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Constantes} from '../../shared/utils/constantes-utils';
import {SessaoUsuarioRepresentation} from '../model/sessao-usuario-representation';
import jwtDecode from 'jwt-decode';
import * as moment from 'moment';
import {UsuarioRepresentation} from '../model/usuario';
import {UsuarioStore} from '../state/usuario.store';
import {catchError, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {EnvironmentUtilV2} from '../../../environments/EnviromentUtilV2';
import { Mnemonicos } from '../model/mnemonicos.enum';



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // @ts-ignore
  private usuarioLogado: UsuarioRepresentation = null;

  private usuarioEndpoint = '/comprasnet-usuario';
  // baseUrl = EnvironmentUtilV2.obterUrlBaseAreaTrabalho()  + this.usuarioEndpoint;

  baseUrl = EnvironmentUtilV2.obterUrlBase()  + this.usuarioEndpoint;
 // baseUrl = 'https://des-cnetmobile.estaleiro.serpro.gov.br' + this.usuarioEndpoint;
  tokenCarregadoJaInformado = false;

  // @ts-ignore
  private retokenRealizadoSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public retokenRealizado$ = this.retokenRealizadoSubject.asObservable();

  constructor( private http: HttpClient, private usuarioStore: UsuarioStore, private router: Router) { }

  private armazenarDadosToken(sessaoUsuario: SessaoUsuarioRepresentation): boolean {
    const tokenAnterior = this.token;


   //  localStorage.setItem(Constantes.STORAGE_TOKEN, sessaoUsuario.token);
    localStorage.setItem(Constantes.STORAGE_ACCESSTOKEN, sessaoUsuario.accessToken);
    localStorage.setItem(Constantes.STORAGE_REFRESHTOKEN, sessaoUsuario.refreshToken);

   // const expiresAt = this.getExpiration(sessaoUsuario.token);
   // const payload = this.getTokenPayload(sessaoUsuario.token);
    const expiresAt = this.getExpiration(sessaoUsuario.accessToken);
    const payload = this.getTokenPayload(sessaoUsuario.accessToken);
    // console.log('payload token: ', JSON.stringify(payload));
    // @ts-ignore
    console.log('exp token: ', expiresAt.format('DD-MM-YYYY HH:mm'));
    if (!tokenAnterior || tokenAnterior !== this.token) {
      return true;
    } else {
      return false;
    }
  }

  public isAutenticado() {
     const token = this.token;
     // @ts-ignore
    const expiration = this.getExpiration(token);
     if (expiration) {
       return moment().isBefore(expiration);
     }
    return false;
  }

  public get token(): string | null {

   // const token = localStorage.getItem(Constantes.STORAGE_TOKEN);
    const token = localStorage.getItem(Constantes.STORAGE_ACCESSTOKEN);
    if (token && !this.tokenCarregadoJaInformado) {
      this.tokenCarregadoJaInformado = true;
      this.usuarioStore.setTokenCarregado(true);
    }
    return token;
  }

  // tslint:disable-next-line:typedef
  public logout(redirecionarPara: string ) {
    console.log('authService.logout');
    this.tokenCarregadoJaInformado = false;
    console.log('vou remover token');
    // localStorage.removeItem(Constantes.STORAGE_TOKEN);
    localStorage.removeItem(Constantes.STORAGE_ACCESSTOKEN);
    localStorage.removeItem(Constantes.STORAGE_REFRESHTOKEN);
    // localStorage.clear();  só quiser limnpar
    // @ts-ignore
    this.usuarioStore.setUsuario(null);
    this.usuarioStore.setTokenCarregado(false);

    if (redirecionarPara) {
       this.router.navigate([redirecionarPara]);
    }
  }
  // tslint:disable-next-line:typedef
  public logoutUsuario() {
    // @ts-ignore
    const httpHeaders: HttpHeaders = new HttpHeaders({Authorization: this.token});
    this.http
      .put<any>(`${this.baseUrl}/v2/usuario/logout/`, null,  { headers: httpHeaders })
      .pipe(
        catchError((error: any) => {
          console.log('AuthService -> realizarLogout -> catchError ', error);
          return throwError(error);
        })
      )
      .subscribe();
  }

   public criarSessao(json: any): Observable<string> {
    console.log('AuthService criarSessao');
   // return this.http.post<string>(`${this.baseUrl}/internal/v2/sessao`, json);
    return this.http.post<string>(`${this.baseUrl}/internal/v2/sessao/governo`, json);
  }

  public obterCnetId(idSessao: string): Observable<string> {
    console.log('AuthService obterCnetId');
    const ipLocal = Constantes.IP_FIXO;
   //  return this.http.get<string>(`${this.baseUrl}/internal/v2/sessao/${idSessao}/ip/${ipLocal}/cnet-id`, {
    return this.http.get<string>(`${this.baseUrl}/internal/v2/sessao/governo/${idSessao}/ip/${ipLocal}/cnet-id`, {
      responseType: 'text' as 'json'
    });
  }

  // public obterToken(cnetId: string): Observable<boolean> {
  //   return new Observable((observer: Observer<boolean>) => {
  //     this.http.get<SessaoUsuarioRepresentation>(`${this.baseUrl}/v2/usuario/token/${cnetId}`).subscribe(
  //       sessaoUsuarioResponse => {
  //         this.armazenarDadosToken(sessaoUsuarioResponse);
  //         observer.next(this.isAutenticado());
  //         observer.complete();
  //       },
  //       err => {
  //         observer.next(false);
  //         observer.complete();
  //         throw err;
  //       }
  //     );
  //   });
  // }

  // tslint:disable-next-line:typedef
  public setIsLoginProcessado(isLoginProcessado = false) {
   //  console.log('AuthService setIsLoginProcessado');
    this.usuarioStore.setIsLoginProcessado(isLoginProcessado);
  }

  public obterTokenV2(cnetId: string): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      // this.http.get<SessaoUsuarioRepresentation>(`${this.baseUrl}/v2/usuario/token/${cnetId}`).subscribe(
      this.http.get<SessaoUsuarioRepresentation>(`${this.baseUrl}/v2/sessao/governo/usuario/token/${cnetId}`).subscribe(
        sessaoUsuarioResponse => {
          console.log('token chegou ', sessaoUsuarioResponse.accessToken);
          this.armazenarDadosToken(sessaoUsuarioResponse);
          observer.next(this.isAutenticado());
          observer.complete();
        },
        err => {
          observer.next(false);
          observer.complete();
          throw err;
        }
      );
    });
  }



  private getExpiration(token: string) {
    const payload = this.getTokenPayload(token);
    let expiresAt = null;
    if (payload) {
      expiresAt = moment.unix(payload.exp);
    }
    return expiresAt;
  }


  public getTokenPayload(token: string): JWTPayload {
    let payload;
    if (token) {
      payload = jwtDecode(token) as JWTPayload;
    }
    // @ts-ignore
    return payload;
  }

  public obterCnetId_Token_Governo(): Observable<string> {
    let params = new HttpParams();
    // @ts-ignore
    params = params. set("Authorization", this.token);
    // @ts-ignore
    let cab = new HttpHeaders().append('Authorization', this.token);
    return this.http.get<string>(`${this.baseUrl}/v2/sessao/governo/usuario/cnet-id`, {
      headers: cab,
      responseType: 'text' as 'json'
    });
  }


  public obterCnetId_Token(): Observable<string> {
    let params = new HttpParams();
    // @ts-ignore
    params = params. set("Authorization", this.token);
    // @ts-ignore
    let cab = new HttpHeaders().append('Authorization', this.token);
    return this.http.get<string>(`${this.baseUrl}/v2/usuario/cnet-id`, {
      headers: cab,
      responseType: 'text' as 'json'
    });
  }
  hasMnemonicosCadastrador(mnemonicos: string[]): boolean {
    let  retorno = false; // TODO: ajustar para testar todos de perfil
    retorno = mnemonicos.includes(Mnemonicos.CATUASG); //TODO: Retirar Comentário quando mnemônico estiver OK
    // retorno = mnemonicos.includes('ALTPDM');
    // if (retorno !== true){
    //  retorno = mnemonicos.includes('ALTSERVICO');
    // }
    return retorno;
  }
  hasMnemonicosAdministrador(mnemonicos: string[]): boolean {
    let  retorno = false; // TODO: ajustar para testar todos de perfil
    retorno = mnemonicos.includes(Mnemonicos.CATADM);
    return retorno;
  }
  hasMnemonicosAnalisador(mnemonicos: string[]): boolean {
    let  retorno = false; // TODO: ajustar para testar todos de perfil
    retorno = mnemonicos.includes(Mnemonicos.ANALISAPED);
    return retorno;
  }

  getUserProfile(token: string): string{
    const payload = this.getTokenPayload(token);
    if(this.hasMnemonicosCadastrador(payload.mnemonicos)) return 'CATALOGADOR'
    if(this.hasMnemonicosAdministrador(payload.mnemonicos)) return 'ADMINISTRADOR'
    if(this.hasMnemonicosAnalisador(payload.mnemonicos)) return 'ANALISADOR'
    return 'SOLICITANTE'
  }

  getUserRoles(token:string):{[id:string]:boolean}{
    const userProfile = this.getUserProfile(token);

    if( userProfile==='ADMINISTRADOR' ) return {'ADMINISTRADOR':true, 'CATALOGADOR':true, 'ANALISADOR':true}
    if( userProfile==='CATALOGADOR' ) return {'ADMINISTRADOR':false, 'CATALOGADOR':true, 'ANALISADOR':true}
    if( userProfile==='ANALISADOR' ) return {'ADMINISTRADOR':false, 'CATALOGADOR':false, 'ANALISADOR':true}
    return {'ADMINISTRADOR':false, 'CATALOGADOR':false, 'ANALISADOR':false}
  }


  // hasMnemonicos(expectedMnemonicos: string[], mnemonicos: string[]): boolean {
  //   let retorno = true;

  //   if (expectedMnemonicos && expectedMnemonicos.length > 0) {
  //     if (mnemonicos && mnemonicos.length > 0) {
  //       expectedMnemonicos.forEach(m => {
  //         if (!mnemonicos.includes(m)) {
  //           retorno = false;
  //         }
  //       });
  //     } else {
  //       retorno = false;
  //     }
  //   } else {
  //     retorno = true;
  //   }

  //   return retorno;
  // }

  hasAnyExpectedMnemonicos(expectedMnemonicos: string[], mnemonicos: string[]): boolean {
    let retorno = false;

    if (expectedMnemonicos && expectedMnemonicos.length > 0) {
      if (mnemonicos && mnemonicos.length > 0) {
        expectedMnemonicos.forEach(m => {
          if (mnemonicos.includes(m)) {
            retorno = true;
          }
        });
      } else {
        retorno = false;
      }
    } else {
      retorno = true;
    }

    return retorno;
  }

  public get refreshtoken(): string {
    // @ts-ignore
    return localStorage.getItem(Constantes.STORAGE_REFRESHTOKEN);
  }

  // tslint:disable-next-line:typedef
  public isRefreshTokenValido() {
    // console.log('isRefreshTokenValido', this.refreshtoken );
    const refreshtoken = this.refreshtoken;
    const expiration = this.getExpiration(refreshtoken);
    if (expiration) {
      return moment().isBefore(expiration);
    }
    return false;
  }

  // tslint:disable-next-line:typedef
  public isAutorizado(expectedRole: string, expectedMnemonicos: string[]) {
    const token = this.token;
    // @ts-ignore
    const payload = this.getTokenPayload(token);
    if (payload) {
      const tipoUsuario = payload.tipo;
      let acessoLiberado = (!expectedRole || tipoUsuario === expectedRole) && this.hasAnyExpectedMnemonicos(expectedMnemonicos, payload.mnemonicos);
      // let acessoLiberado = !expectedRole;
      // if (!acessoLiberado && tipoUsuario === expectedRole) {
      //   acessoLiberado = true;
      // } else if (!acessoLiberado
      //   && expectedRole === TipoUsuarioEnum.CATALOGADOR
      //   && tipoUsuario === TipoUsuarioEnum.GOVERNO
      //   && this.hasMnemonicos(expectedMnemonicos, payload.mnemonicos)) {
      //     acessoLiberado = true;
      // }
      return acessoLiberado;
    }
    return false;
  }

  // tslint:disable-next-line:typedef
  public isAutenticadoAutorizado(expectedRole: string, expectedMnemonicos: string[]) {
    if (this.isAutenticado() && this.isAutorizado(expectedRole, expectedMnemonicos)) {
      return true;
    }
    return false;
  }

  // tslint:disable-next-line:typedef
  public retoken() {
    console.log('retoken');
    // return this.http.get<SessaoUsuarioRepresentation>(`${this.baseUrl}/v2/usuario/retoken`).pipe(
    return this.http.get<SessaoUsuarioRepresentation>(`${this.baseUrl}/v2/sessao/governo/retoken`).pipe(
      tap(sessaoUsuarioResponse => {
          console.log('sessaoUsuarioResponse');
          const tokenAtualizado = this.armazenarDadosToken(sessaoUsuarioResponse);
          if (tokenAtualizado) {
           this.retokenRealizadoSubject.next(true);
          }
        }),
        catchError((error: any) => {
          this.logout('sessao-encerrada');
          return throwError(error);
        })
      );
  }



  public obterDadosUsuarioLogado(): Observable<UsuarioRepresentation> {
    console.log('AuthService.obterDadosUsuarioLogado ---> ');
    return this.http
      .get<UsuarioRepresentation>(`${this.baseUrl}/v1/usuario/`)
      .pipe(
        tap(usuario => {
          // console.log('obteve DadosUsuarioLogado' + JSON.stringify(usuario));
          this.usuarioLogado = new UsuarioRepresentation(usuario); // cópia local dos dados do usuário logado
          if (this.usuarioLogado.uasg.codigo && !this.usuarioLogado.uasg.numero) {
            this.usuarioLogado.uasg.numero = this.usuarioLogado.uasg.codigo;
          }
          this.usuarioStore.setUsuario(this.usuarioLogado);
        })
      );
  }

  // public obterDadosUsuarioLogado2(): Observable<UsuarioRepresentation> {
  //   console.log('obterDadosUsuarioLogado2');
  //   let params = new HttpParams();
  //   // @ts-ignore
  //   params = params. set('Authorization', 'Bearer' + this.token);
  //   // @ts-ignore
  //   let cab = new HttpHeaders().append('Authorization', this.token);
  //   return this.http.get<UsuarioRepresentation>(`${this.baseUrl}/v1/usuario/`, {
  //     headers: cab,
  //     responseType: 'text' as 'json'
  //   });
  // }



  public getUsuarioLogado(): UsuarioRepresentation {
    return this.usuarioLogado;
  }

  // tslint:disable-next-line:typedef
  // @ts-ignore
  // tslint:disable-next-line:typedef
  public limparDadosSessaoUsuarioRedirecionar(redirecionarPara: string = null) {
    console.log('limparDadosSessaoUsuarioRedirecionar');
    this.tokenCarregadoJaInformado = false;
    localStorage.removeItem(Constantes.STORAGE_ACCESSTOKEN);
    // localStorage.removeItem(Constantes.STORAGE_TOKEN);
    localStorage.removeItem(Constantes.STORAGE_REFRESHTOKEN);
    // @ts-ignore
    this.usuarioStore.setUsuario(null);
    this.usuarioStore.setTokenCarregado(false);
    if (redirecionarPara) {
      console.log('redirecionarPara');
      this.router.navigate([redirecionarPara]).then(() => {
        window.location.reload();
      });
    }
  }

  public obterTokenV1(cnetId: string): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http
        .post<SessaoUsuarioRepresentation>(`${this.baseUrl}/v1/usuario/login`, {
          chaveSessaoUsuario: cnetId
        })
        .subscribe(
          sessaoUsuarioResponse => {
            console.log('sessaoUsuarioResponse', sessaoUsuarioResponse);
            this.armazenarDadosToken(sessaoUsuarioResponse);
            observer.next(this.isAutenticado());
            observer.complete();
          },
          err => {
            observer.next(false);
            observer.complete();
            throw err;
          }
        );
    });
  }



}

interface JWTPayload {
  tipo: string;
  numero_uasg: number;
  tipo_empresa: string;
  identificacao_empresa: string;
  exp: number;
  mnemonicos: string[];
}
