import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../security/services/auth-service.service';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import {EnvironmentUtilV2} from '../../environments/EnviromentUtilV2';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  constructor(private authService: AuthService,  private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('TokenInterceptor.intercept ');
    // console.log('url: ' + request.url );

    const token = this.authService.token;
    const refreshtoken = this.authService.refreshtoken;
    if (token && !this.ehURLEspecial(request)) {
      // console.log('if (token && !this.ehURLEspecial(request)) ');
      if(this.authService.isRefreshTokenValido()){
        request = this.addToken(request, token);
      }
    }
    if (refreshtoken && this.isURLRetoken(request)) {
      request = this.addToken(request, refreshtoken);
    }
    // @ts-ignore
    if (this.isURLAreaTrabalho(request)){
      // https://stackoverflow.com/questions/50856471/how-to-change-http-req-url-using-angular-6-interceptor
      // console.log('isURLAreaTrabalho------------------------------>');
      // console.log(request.url);
      // tslint:disable-next-line:max-line-length
      // request.url.replace('/api/comprasnet-usuario/internal/v2/sessao', 'https://des-cnetmobile.estaleiro.serpro.gov.br');
     // https://des-cnetmobile.estaleiro.serpro.gov.br/comprasnet-area-trabalho/v1/sistema/menu/?size=100&page=0
      // const dupReq = request.clone({ url: 'https://des-cnetmobile.estaleiro.serpro.gov.br/comprasnet-area-trabalho/v1/sistema/menu/' });
      const dupReq = request.clone({ url: EnvironmentUtilV2.obterUrlBaseMenu() });
      return next.handle(dupReq);

    }

    if (this.isURLAreaTrabalhoRedirect(request)){
      // https://stackoverflow.com/questions/50856471/how-to-change-http-req-url-using-angular-6-interceptor
      // console.log('isURLAreaTrabalhoRedirect------------------------------>');
      // console.log(request.url);
      let url = request.url;
      const sistema = url.split("=");
     // const dupReq = request.clone({ url: 'https://des-cnetmobile.estaleiro.serpro.gov.br/comprasnet-area-trabalho/v1/redirect/?itemmenusistema='+sistema[1] });
      const dupReq = request.clone({ url: EnvironmentUtilV2.obterUrlBaseMenuSistema() + sistema[1] });
      return next.handle(dupReq);
    }

    // @ts-ignore
    return next.handle(request).pipe(catchError(error => {
        if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403) && !this.ehURLEspecial(request)) {
          return this.handle401Error(request, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  // tslint:disable-next-line:typedef
  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
  private ehURLEspecial(request: HttpRequest<any>) {
    if (this.isURLLogin(request)
      || this.isURLRetoken(request)
      // || this.isURLSessao(request)
    ) {
      return true;
    }
    return false;
  }

  private isURLRetoken(request: HttpRequest<any>) {
    if (request.url.includes('retoken')) {
      return true;
    }
    return false;
  }

  private isURLLogin(request: HttpRequest<any>) {
    if (request.url.includes('login')) {
      return true;
    }
    return false;
  }
  private isURLSessao(request: HttpRequest<any>) {
    if (request.url.includes('sessao')) {
      return true;
    }
    return false;
  }
  private isURLToken(request: HttpRequest<any>) {
    if (request.url.includes('token')) {
      return true;
    }
    return false;
  }


  private isURLAreaTrabalho(request: HttpRequest<any>) {
    if (request.url.includes('comprasnet-area-trabalho/v1/sistema/menu/')) {
      return true;
    }
    return false;
  }

    private isURLAreaTrabalhoRedirect(request: HttpRequest<any>) {
      if (request.url.includes('comprasnet-area-trabalho/v1/redirect/')) {
        return true;
      }
      return false;
    }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.retoken().pipe(
        switchMap((response: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(response.token);
          return next.handle(this.addToken(request, response.token));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addToken(request, token));
        })
      );
    }
  }


}
