import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-menu-pedido',
  templateUrl: './menu-pedido.component.html',
  styleUrls: ['./menu-pedido.component.css'],
})
export class MenuPedidoComponent implements OnInit {

  public index: number = 0;
  @Output() changeIndexEvent = new EventEmitter<number>();
  @Output() changeEstadoMenuEvent = new EventEmitter<boolean>();
  @Input() usrProfile: any;

  escondeMenu:boolean=false;

  @ViewChild('secaoItens') secaoItens: any;

  constructor() { }


  ngOnInit(): void {
    this.changeEstadoMenuEvent.emit(false);
  }

  mudaEstadoMenu(){
    this.escondeMenu =  !this.escondeMenu;
    this.changeEstadoMenuEvent.emit(this.escondeMenu);
  }

  mudaIndex(tipo: string){
    if(tipo == 'info'){
      this.index = 0;
    }
    if(tipo == 'anexos'){
      this.index = 3;
    }
    if(tipo == 'historicoAnalise'){
      this.index = 1;
    }
    if(tipo == 'historicoPedido'){
      this.index = 2;
    }

    this.changeIndexEvent.emit(this.index);
  }

}
