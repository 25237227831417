export class ItemExportar {
  sequencial: string;
  id: string;
  nome: string;
  descricao: string;
  unidade: any;
  tipo: any;
  carrinhoNome: any;
  carrinhoCaracteristicas: any;
  codigoNcm?: any;
  aplicaMargemPreferencia?: any;
}
