<!-- Página: historico-pedido-component.html -->
<div class="col">
  <div class="row">
      <div class="col-md-6">
        <h3 class=" mt-0 mb-0 pt-3 pb-0">Histórico Pedido</h3>
        <p></p>
      </div>
  </div>

  <div class="br-divider mt-2"></div>

  <br>
  <div *ngFor="let hist of historico" style="margin-left:7px;">
    <p>
      <span *ngIf="usrProfile==='SOLICITANTE' && hist.codigoUasg===null" class="blue">{{hist.nomeUsuario}} - </span>
      <span *ngIf="usrProfile==='SOLICITANTE' && hist.codigoUasg" class="blue">{{hist.nomeUasg}} - </span>
      <span *ngIf="usrProfile!=='SOLICITANTE'" class="blue">{{hist.nomeUsuario}} - </span>
      {{ dataFormatada(hist.dataHoraRegistro) }}
      <br>
      <span>{{ hist.mensagem}}</span>
    </p>
  </div>


  <div>
    <legend class="text-blue-warm-vivid-70">Resumo</legend>
    <p class="my-0"><label>Número:</label> {{pedido.numeroPedido}}</p>
    <p class="my-0"><label>UASG:</label> {{pedido.codigoUasgSolicitante}}</p>
    <p class="my-0"><label>Situação Atual:</label> {{pedido.situacaoPedido}}</p>
    <p class="my-0"><label>Responsável:</label> {{pedido.nomeSolicitante}}</p><br>
    <p class="my-0"><label>Nome do Material:</label> {{pedido.nomePedido}}</p>
    <p class="my-0"><label>Tipo:</label> {{pedido.tipoPedido}}</p><br>
    <label>Detalhes do pedido:</label>
    <p class="my-0 ml-1" *ngFor="let i of pedido.detalhePedido?.split('\n')">{{i}}</p><br>
    <label>Links de Referência:</label> {{pedido.linkReferencia}}
  </div>
</div>
