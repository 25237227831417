import { Component, OnInit, ViewChild} from '@angular/core';
import {BuscaService} from '../../../service/busca-service';
import {ClasseMaterial} from '../../model/classe-material';
import {CaracteristicaPdm, UnidadeMedidaPDM, ValorCaracteristica} from '../../model/caracteristica-pdm';
import {Router} from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-criar-material',
  templateUrl: './criar-material.component.html',
  styleUrls: ['./criar-material.component.css'],
  providers: [ConfirmationService]
})
export class CriarMaterialComponent implements OnInit {

  constructor(private buscaService: BuscaService, private modalService: BsModalService,  private router: Router, private messageService: MessageService, private confirmationService: ConfirmationService,) {
  }
  material: any;
  nomePdm: string;
  classesApi: ClasseMaterial[];
  classeFiltro: string;
  unidadeMedidaFiltro: string;
  unidadeMedidaGeralFiltro: string;
  classesTela: ClasseMaterial[];
  classeEscolhida = new ClasseMaterial();
  mostrarBuscaClasse = false;
  caracteristicasApi: CaracteristicaPdm[];
  caracteristicasEscolhidaArray: CaracteristicaPdm[];
  unidadesFornecimentoPDM:any = [];
  unidadeFornecimentoPdmApi: any;
  unidadeFornecimentoTela: any;
  unidadeFornecimentoGeralTela: any;
  unidadeFornecimentoPdmApiGeral: any;
  caracteristicasEscolhidaNomes = '';
  caracteristicasEscolhidaEmEdicao: any;
  textoAbrirFecharClassse = 'Abrir';
  textoAbrirFecharCaracteristica = 'Abrir';
  mostrarBuscarCaracteristicas = false;
  mostrarManterCaracteristicas = false;
  mostrarEditarCaracteristicas = false;
  valoresPdm: any;
  fiig: string;
  buscaNomeValorCaracteristicaEntrada: any;
  value: any;
  pageSize: 20;
  filtroClasse = '';
  siglaUnidadeMedida: any;
  siglaUnidadeFornecimento: any;
  capacidadeUnidadeFornecimento: any;
  unidadeTrue: boolean = true;
  alias: string = "";
  aliasArray: any = [];
  naturezaDespesa: string = "";
  naturezaDespesaArray: any = [];
  conjuntoMaterialApi: any;
  conjuntoMaterialTela: any;
  conjuntoMaterial: any;
  conjuntoMaterialFiltro: string;
  first = 0;
  PDM: any;
  expandCollapsed: boolean = false;
  possuiEmbalagem: boolean = false;
  saveUnidadeFornecimento: boolean = true;
  rowIndex: number;
  validateUnidadeFornecimento: String = "#formunidadefornecimento";
  validateCaracteristicas: String = "#buscarcaracteristica";
  validateUnidadeMedidaOpenModal: String = "#buscarunidademedida";
  validateValoresCaracteristicaModalOpen: String = "#buscarvalorescaracteristica";
  validateUnidadeFornecimentoOpenModal: String = "#buscarunidadefornecimento";
  validaUnidadeMedida: boolean = true;
  validaValoresCaracteristica: boolean = true;
  codigoNovoPdm: any = 0;
  modalRef1: BsModalRef;
  numMaxPDM = 100;
  numMaxAlias = 25;
  somenteNomeUnidadeMedidaFiltro: boolean = false;
  somenteSiglaUnidadeMedidaFiltro: boolean = false;
  somenteNomeUnidadeFornecimentoGeralFiltro: boolean = false;
  somenteSiglaUnidadeFornecimentoGeralFiltro: boolean = false;
  @ViewChild('PdmSucess', {static: false} ) PdmSucess: any;
  visible: boolean = false;


  ngOnInit(): void {
    this.caracteristicasEscolhidaArray = [];
    this.buscaService.obterClassesPDM().subscribe(classesTmp => this.classesApi = classesTmp);
    this.buscaService.obterCaracteristicaPDM().subscribe(tmp => this.caracteristicasApi = tmp);
    this.buscaService.obterUnidadeFornecimentoPDM().subscribe(z => this.unidadeFornecimentoPdmApiGeral = z);
    this.buscaService.obterUnidadeFornecimentoPdmPossuiEmbalagem('false').subscribe(z => this.unidadeFornecimentoPdmApi = z);
    // this.buscaService.obterConjuntoMaterial().subscribe(resp => this.conjuntoMaterialApi = resp);

  }

  zerarTudo(){
    window.location.reload();
  }

  voltarTelaInicial(): void {
    this.router.navigate(['/buscamaterial']);
  }

  filtrarClasse(): void {
    this.first = 0;
    console.log('filtrarClasse', this.classeFiltro);
    this.classesTela = [];
    for (const tmp of this.classesApi) {
      if (tmp.nomeClasse.toUpperCase().includes((this.classeFiltro.toUpperCase())) ||
          tmp.codigoClasse.toString().includes((this.classeFiltro)) ||
          tmp.codigoGrupo.toString().includes((this.classeFiltro)) ||
          tmp.nomeGrupo.toUpperCase().includes((this.classeFiltro.toUpperCase()))){
        console.log(tmp.nomeClasse);
        this.classesTela.push(tmp);
      }
    }
  }

  limparFiltroClass(): void {
    this.first = 0;
    this.classesTela = [];
    this.classeFiltro = '';
    this.classesTela = this.classesApi.slice();
  }

  
  escolherClasse(item: any): void {
    console.log('classe escolhida ', item);
    this.classeEscolhida = new ClasseMaterial();
    for (const tmp of this.classesTela) {
      if ( tmp.codigoClasse === item.codigoClasse){
       this.classeEscolhida = tmp;
      }
    }
  }

  escolherCaracteristica(codigoCaracteristica: CaracteristicaPdm): void {
    let aux = 0;
    for(let i of this.caracteristicasEscolhidaArray){
      if(i.codigoCaracteristica == codigoCaracteristica.codigoCaracteristica){
        aux = 1;
      }
    }
    if(aux == 0){
      this.caracteristicasEscolhidaArray.push(codigoCaracteristica);
      this.caracteristicasEscolhidaNomes += codigoCaracteristica.nomeCaracteristica + ', ';
      this.mostrarBuscaClasse = false;
      this.textoAbrirFecharClassse = 'Abrir';
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Característica já cadastrada'
        });
    }
  }

  abrirBuscaClasse(): void {
    if (this.mostrarBuscaClasse === true){
      this.mostrarBuscaClasse = false;
      this.textoAbrirFecharClassse = 'Abrir';
    }else{
      this.mostrarBuscaClasse = true;
      this.textoAbrirFecharClassse = 'Fechar';
    }
  }

  abrirBuscaCaracteristica(): void {
    this.mostrarManterCaracteristicas = false;
    if (this.mostrarBuscarCaracteristicas === true){
      this.mostrarBuscarCaracteristicas = false;
      this.textoAbrirFecharCaracteristica = 'Abrir';
    }else{
      this.mostrarBuscarCaracteristicas = true;
      this.textoAbrirFecharCaracteristica = 'Fechar';
    }
  }

  editarCaracteristicas(): void {
    this.mostrarBuscarCaracteristicas = false;
    if (this.mostrarManterCaracteristicas === true){
      this.mostrarManterCaracteristicas = false;
    }else{
      this.mostrarManterCaracteristicas = true;
    }
  }

  editarCaracteristica(codigoCaracteristica: string): void {
    console.log('editarCaracteristica', codigoCaracteristica);
    // this.mostrarManterCaracteristicas = false;
    //   this.mostrarEditarCaracteristicas = true;
    this.caracteristicasEscolhidaEmEdicao = new CaracteristicaPdm();
    this.caracteristicasEscolhidaEmEdicao = this.caracteristicasEscolhidaArray.find(x => x.codigoCaracteristica === codigoCaracteristica);
    console.log(JSON.stringify(this.caracteristicasEscolhidaEmEdicao));
//     if(this.caracteristicasEscolhidaEmEdicao?.valoresCaracteristica.length < 40){
//       this.validaValoresCaracteristica = true;
//     } else {
//       this.validaValoresCaracteristica = false;
//     }
  }

  visualizarCaracteristica(codigoCaracteristica: string): void {
    console.log('visualizarCaracteristica ', codigoCaracteristica);

  }

  voltarListarCaracteristicas(): void {
    this.mostrarManterCaracteristicas = true;
    this.mostrarEditarCaracteristicas = false;
  }

  salvarValorCaracteristica(valorCaracteristica: ValorCaracteristica): void{
    let aux = 0;
    this.caracteristicasEscolhidaArray.forEach( item => {
      if(item.codigoCaracteristica == valorCaracteristica.codigoCaracteristica){
        for(let i of item.valoresCaracteristica){
          if(i.codigoValorCaracteristica == valorCaracteristica.codigoValorCaracteristica){
            console.log(i.codigoValorCaracteristica);
            aux = 1;
          }
        }
      }
    });
    if( aux == 0 ){
      this.caracteristicasEscolhidaArray.forEach( item => {
        if (item.codigoCaracteristica === valorCaracteristica.codigoCaracteristica){
          item.valoresCaracteristica.push(valorCaracteristica);
        }
      });
    }
  }

  limparValoresPDM(): void {
    if(this.caracteristicasEscolhidaEmEdicao?.valoresCaracteristica.length < 40){
    this.validateValoresCaracteristicaModalOpen = "#buscarvalorescaracteristica";
    this.first = 0;
    this.buscaNomeValorCaracteristicaEntrada = null;
    this.valoresPdm = null;
    } else {
      this.validateValoresCaracteristicaModalOpen = "";
      this.validaValoresCaracteristica = false;
    }
  }

  editarItem(id: any): void {

  }

  excluirItem(id: any ): void {

  }

  openModalClasse(): void {
    this.first = 0;
    this.classeFiltro = '';
    this.classesTela =  this.classesApi.slice();
  }

  openModalCaracteristica(): void {
    if(this.caracteristicasEscolhidaArray.length < 40){
      this.validateCaracteristicas = "#buscarcaracteristica";
    } else {
      this.validateCaracteristicas = "";
      this.messageService.add({
        severity: 'warn',
        summary: 'Somente é possível incluir 40 características.'
        });
    }
  }

  editarUnidadeMedida(codigoCaracteristica: any): void {
    console.log('editarUnidadeMedida', codigoCaracteristica);
    this.caracteristicasEscolhidaEmEdicao = new CaracteristicaPdm();
    this.caracteristicasEscolhidaEmEdicao = this.caracteristicasEscolhidaArray.find(x => x.codigoCaracteristica === codigoCaracteristica);
    console.log(JSON.stringify(this.caracteristicasEscolhidaEmEdicao));
//     if(this.caracteristicasEscolhidaEmEdicao?.unidadesMedida.length < 40){
//       this.validaUnidadeMedida = true;
//     } else {
//       this.validaUnidadeMedida = false;
//     }
  }

  carregarUnidadesMedida(): void {
    if(this.caracteristicasEscolhidaEmEdicao?.unidadesMedida.length < 40){
      this.validateUnidadeMedidaOpenModal = "#buscarunidademedida";
      this.first = 0;
      this.unidadeMedidaFiltro = '';
      this.unidadeFornecimentoTela  = this.unidadeFornecimentoPdmApi.slice();
      this.somenteNomeUnidadeMedidaFiltro = false;
      this.somenteSiglaUnidadeMedidaFiltro = false;
    } else {
      this.validaUnidadeMedida = false;
      this.validateUnidadeMedidaOpenModal = "";
    }
  }

  carregarUnidadesFornecimento(): void {
    this.validateUnidadeFornecimentoOpenModal = "#buscarunidadefornecimento";
    this.first = 0;
    this.unidadeMedidaFiltro = '';
    this.unidadeFornecimentoTela  = this.unidadeFornecimentoPdmApi.slice();
    this.somenteNomeUnidadeMedidaFiltro = false;
    this.somenteSiglaUnidadeMedidaFiltro = false;
  }

  carregarUnidadesMedidaGeral(): void {
    this.first = 0;
    this.unidadeMedidaGeralFiltro = '';
    this.unidadeFornecimentoGeralTela  = this.unidadeFornecimentoPdmApiGeral.slice();
    this.somenteNomeUnidadeFornecimentoGeralFiltro = false;
    this.somenteSiglaUnidadeFornecimentoGeralFiltro = false;
  }

  escolherUnidadeFornecimento(siglaUnidadeFornecimento: any, nomeUnidadeFornecimento: any, possuiEmbalagem: any): void {
    let aux = 0;
    for(let i of this.caracteristicasEscolhidaEmEdicao.unidadesMedida){
      if(i.siglaUnidadeMedida == siglaUnidadeFornecimento){
        aux = 1;
      }
    }
    if(aux == 0){
      this.caracteristicasEscolhidaArray.forEach( item => {
        if (item.codigoCaracteristica === this.caracteristicasEscolhidaEmEdicao.codigoCaracteristica){
          let unidadeMedida = new UnidadeMedidaPDM();
          unidadeMedida.siglaUnidadeMedida = siglaUnidadeFornecimento;
          unidadeMedida.nomeUnidadeMedida = nomeUnidadeFornecimento;
          unidadeMedida.possuiEmbalagem = possuiEmbalagem;
          item.unidadesMedida.push(unidadeMedida);
         }
        // console.log(JSON.stringify(item));
      });
    }
    // console.log(this.caracteristicasEscolhidaEmEdicao.codigoCaracteristica);

  }

  filtrarUnidadeMedida(atualizaNome?: boolean, atualizaSigla?: boolean): void {
    if(atualizaNome != undefined){
      this.somenteNomeUnidadeMedidaFiltro = !this.somenteNomeUnidadeMedidaFiltro;
    }
    if(atualizaSigla != undefined){
      this.somenteSiglaUnidadeMedidaFiltro = !this.somenteSiglaUnidadeMedidaFiltro;
    }
    console.log(this.somenteNomeUnidadeMedidaFiltro)
    if(this.somenteNomeUnidadeMedidaFiltro == true && this.somenteSiglaUnidadeMedidaFiltro == true){
      this.first = 0;
      this.unidadeFornecimentoTela = [];
      for (const tmp of this.unidadeFornecimentoPdmApi) {
        if (tmp.nomeUnidadeFornecimento.toUpperCase().includes((this.unidadeMedidaFiltro.toUpperCase())) || tmp.siglaUnidadeFornecimento.toUpperCase().includes((this.unidadeMedidaFiltro.toUpperCase()))){
          this.unidadeFornecimentoTela.push(tmp);
        }
      }
    }
    else {
      if(this.somenteNomeUnidadeMedidaFiltro == false && this.somenteSiglaUnidadeMedidaFiltro == true){
        this.first = 0;
        this.unidadeFornecimentoTela = [];
        for (const tmp of this.unidadeFornecimentoPdmApi) {
          if (tmp.siglaUnidadeFornecimento.toUpperCase().includes((this.unidadeMedidaFiltro.toUpperCase()))){
            this.unidadeFornecimentoTela.push(tmp);
          }
        }
      } else {
        if(this.somenteNomeUnidadeMedidaFiltro == true && this.somenteSiglaUnidadeMedidaFiltro == false){
          this.first = 0;
          this.unidadeFornecimentoTela = [];
          for (const tmp of this.unidadeFornecimentoPdmApi) {
            if (tmp.nomeUnidadeFornecimento.toUpperCase().includes((this.unidadeMedidaFiltro.toUpperCase()))){
              this.unidadeFornecimentoTela.push(tmp);
            }
          }
        } else {
          this.first = 0;
          this.unidadeFornecimentoTela = [];
          for (const tmp of this.unidadeFornecimentoPdmApi) {
            if (tmp.nomeUnidadeFornecimento.toUpperCase().includes((this.unidadeMedidaFiltro.toUpperCase())) || tmp.siglaUnidadeFornecimento.toUpperCase().includes((this.unidadeMedidaFiltro.toUpperCase())) || tmp.descricaoUnidadeFornecimento.toUpperCase().includes((this.unidadeMedidaFiltro.toUpperCase()))){
              this.unidadeFornecimentoTela.push(tmp);
            }
          }
        }
      }
    }
  }

  limparFiltroUnidadeMedida(): void {
    this.first = 0;
    this.unidadeMedidaFiltro = '';
    this.unidadeFornecimentoTela = [];
    this.unidadeFornecimentoTela = this.unidadeFornecimentoPdmApi.slice();
  }

  filtrarUnidadeFornecimento(atualizaNome?: boolean, atualizaSigla?: boolean): void {
    if(atualizaNome != undefined){
      this.somenteNomeUnidadeFornecimentoGeralFiltro = !this.somenteNomeUnidadeFornecimentoGeralFiltro;
    }
    if(atualizaSigla != undefined){
      this.somenteSiglaUnidadeFornecimentoGeralFiltro = !this.somenteSiglaUnidadeFornecimentoGeralFiltro;
    }

    if(this.somenteNomeUnidadeFornecimentoGeralFiltro == true && this.somenteSiglaUnidadeFornecimentoGeralFiltro == true){
      this.first = 0;
      this.unidadeFornecimentoGeralTela = [];
      for (const tmp of this.unidadeFornecimentoPdmApiGeral) {
        if (tmp.nomeUnidadeFornecimento.toUpperCase().includes((this.unidadeMedidaGeralFiltro.toUpperCase())) || tmp.siglaUnidadeFornecimento.toString().toUpperCase().includes((this.unidadeMedidaGeralFiltro.toUpperCase()))){
          this.unidadeFornecimentoGeralTela.push(tmp);
        }
      }
    } else {
      if(this.somenteNomeUnidadeFornecimentoGeralFiltro == true && this.somenteSiglaUnidadeFornecimentoGeralFiltro == false){
        this.first = 0;
        this.unidadeFornecimentoGeralTela = [];
        for (const tmp of this.unidadeFornecimentoPdmApiGeral) {
          if (tmp.nomeUnidadeFornecimento.toUpperCase().includes((this.unidadeMedidaGeralFiltro.toUpperCase()))){
            this.unidadeFornecimentoGeralTela.push(tmp);
          }
        }
      } else {
        if(this.somenteNomeUnidadeFornecimentoGeralFiltro == false && this.somenteSiglaUnidadeFornecimentoGeralFiltro == true){
          this.first = 0;
          this.unidadeFornecimentoGeralTela = [];
          for (const tmp of this.unidadeFornecimentoPdmApiGeral) {
            if (tmp.siglaUnidadeFornecimento.toString().toUpperCase().includes((this.unidadeMedidaGeralFiltro.toUpperCase()))){
              this.unidadeFornecimentoGeralTela.push(tmp);
            }
          }
        } else {
          this.first = 0;
          this.unidadeFornecimentoGeralTela = [];
          for (const tmp of this.unidadeFornecimentoPdmApiGeral) {
            if (tmp.nomeUnidadeFornecimento.toUpperCase().includes((this.unidadeMedidaGeralFiltro.toUpperCase())) || tmp.siglaUnidadeFornecimento.toString().toUpperCase().includes((this.unidadeMedidaGeralFiltro.toUpperCase())) || tmp.descricaoUnidadeFornecimento.toString().toUpperCase().includes((this.unidadeMedidaGeralFiltro.toUpperCase()))){
              this.unidadeFornecimentoGeralTela.push(tmp);
            }
          }
        }
      }
    }
  }

  limparFiltroUnidadeFornecimento(): void {
    this.first = 0;
    this.unidadeMedidaGeralFiltro = '';
    this.unidadeFornecimentoGeralTela = [];
    this.unidadeFornecimentoGeralTela = this.unidadeFornecimentoPdmApiGeral.slice();
  }

  openFormUnidadeFornecimento(): void {
    console.log(this.unidadesFornecimentoPDM.length)
    if(this.unidadesFornecimentoPDM.length < 56){
      this.validateUnidadeFornecimento = "#formunidadefornecimento"
      this.first = 0;
      this.siglaUnidadeFornecimento = '';
      this.capacidadeUnidadeFornecimento = "0";
      this.siglaUnidadeMedida = '';
      this.unidadeTrue = true;
      this.saveUnidadeFornecimento = true;
    } else {
      this.validateUnidadeFornecimento = ""
      this.messageService.add({
        severity: 'warn',
        summary: 'Somente é possível incluir 56 unidades de fornecimento.'
        });
    }

  }

  escolherUnidadeFornecimentoParaPDM(nome: string, siglaUnidadeFornecimento: any,  possuiEmbalagem: any): void {
    if ( possuiEmbalagem == true ){
      this.siglaUnidadeFornecimento = siglaUnidadeFornecimento + " | " + nome;
      this.unidadeTrue = false;
      this.possuiEmbalagem = true;
      this.saveUnidadeFornecimento = true;
    }
    else{
      this.siglaUnidadeFornecimento = siglaUnidadeFornecimento + " | " + nome;
      this.siglaUnidadeMedida = '';
      this.capacidadeUnidadeFornecimento = "0";
      this.unidadeTrue = true;
      this.possuiEmbalagem = false;
      this.saveUnidadeFornecimento = false;
    }
  }

  saveUnidade(){
    if((this.siglaUnidadeFornecimento == null || this.siglaUnidadeFornecimento == "") ||
      (this.siglaUnidadeMedida == null || this.siglaUnidadeMedida == "") ||
      (this.capacidadeUnidadeFornecimento == null || this.capacidadeUnidadeFornecimento == "0" || this.capacidadeUnidadeFornecimento == null)){
        this.saveUnidadeFornecimento = true;
      }
    else{
      this.saveUnidadeFornecimento = false;
    }
  }

  adicionarUnidadeFornecimento(item: any){
    if(item?.capacidadeUnidadeFornecimento !== ""){
      let capacidade = item.capacidadeUnidadeFornecimento.toString().split(".");
      if(capacidade.length > 1){
        if(capacidade[1].length == 1){
          item.capacidadeUnidadeFornecimento = item.capacidadeUnidadeFornecimento + "0";
        }
      } else{
        item.capacidadeUnidadeFornecimento = item.capacidadeUnidadeFornecimento + ",00";
      }
      item.capacidadeUnidadeFornecimento = item.capacidadeUnidadeFornecimento.toString().replace(".",",");
    }
    let aux;
    let aux2 = 0;
    for(let i of this.unidadesFornecimentoPDM){
      if(item?.capacidadeUnidadeFornecimento!= "0" && item?.capacidadeUnidadeFornecimento != null){
        if(i.siglaUnidadeFornecimento == item.unidadeFornecimento.siglaUnidadeFornecimento && i.siglaUnidadeMedida == item.unidadeMedida?.siglaUnidadeMedida && i.capacidadeUnidadeFornecimento == item?.capacidadeUnidadeFornecimento){
          aux2 = 1;
        }
      }else{
        if(i.siglaUnidadeFornecimento == item.unidadeFornecimento.siglaUnidadeFornecimento && i.capacidadeUnidadeFornecimento == item?.capacidadeUnidadeFornecimento){
          aux2 = 1;
        }
      }

    }
    if(aux2 == 0){
      aux = this.unidadesFornecimentoPDM.length;
      this.unidadesFornecimentoPDM.push({
        index: aux + 1, 
        capacidadeUnidadeFornecimento: item?.capacidadeUnidadeFornecimento,
        descricaoUnidadeFornecimento: item.unidadeFornecimento.descricaoUnidadeFornecimento,
        nomeUnidadeFornecimento: item.unidadeFornecimento.nomeUnidadeFornecimento,
        numeroSequencialUnidadeFornecimento: "",
        possuiEmbalagem: item.unidadeFornecimento.possuiEmbalagem,
        siglaUnidadeFornecimento: item.unidadeFornecimento.siglaUnidadeFornecimento,
        siglaUnidadeMedida: item.unidadeMedida?.siglaUnidadeMedida,
        nomeUnidadeMedida: item.unidadeMedida?.nomeUnidadeMedida,
        statusUnidadeFornecimentoPdm: true
      });
    } else{
      this.messageService.add({
        severity: 'warn',
        summary: 'Unidade de fornecimento já cadastrada'
        });
    }
  }

  adicionaListAlias(){
    if(this.aliasArray.length < 10){
      let aux = 0;
      this.alias = this.alias.trim();
      if(this.alias != ""){
        for( let i of this.aliasArray){
          if(this.alias == i){
            aux = 1;
          }
        }
        if(aux == 0){
          this.aliasArray.push(this.alias);
          this.alias = "";
        } else{
          this.messageService.add({
            severity: 'warn',
            summary: 'Alias/palavra-chave já cadastrada'
            });
        }
      }
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Somente é possível incluir 10 alias/palavras-chaves.'
        });
    }
    this.numMaxAlias = 25;
  }

  removeListAlias(message: string){
    this.confirmationService.confirm({
      message: "Deseja excluir esse alias/palavra-chave?",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      key: message,
      defaultFocus: "none",
      accept: () => {
        for (let index in this.aliasArray) {
          if (
            message ==
            this.aliasArray[index]
          )
            this.aliasArray.splice(index, 1);
        }
      }
    });

  }

  adicionaListNatureza(){
    let aux = 0;
    let aux2 = Number(this.naturezaDespesa.substring(0,4));
    let aux3 = this.naturezaDespesa.length;
    this.naturezaDespesa = this.naturezaDespesa.trim();
    if(this.naturezaDespesa != "" &&  (aux3 == 9)){
      console.log(aux2)
      console.log(this.naturezaDespesa)
      if((aux2 >= 3390 && aux2 <= 3399) || (aux2 >= 4490 && aux2 <= 4499) || (aux2 >= 5590 && aux2 <= 5599)){
        for( let i of this.naturezaDespesaArray){
          if(this.naturezaDespesa == i){
            aux = 1;
          }
        }
        if(aux == 0){
          this.naturezaDespesaArray.push(this.naturezaDespesa);
          this.naturezaDespesa = "";
        } else{
          this.messageService.add({
            severity: 'warn',
            summary: 'Natureza de desespesa já cadastrada'
            });
        }
      }
      else{
        this.messageService.add({
          severity: 'error',
          summary: 'Natureza da despesa inválida'
          });
      }
    }
    else{
      this.messageService.add({
        severity: 'error',
        summary: 'Preencher corretamente o campo de natureza da despesa'
        });
    }
  }

  removeListNatureza(message: string) {
    this.confirmationService.confirm({
      message: "Deseja excluir essa natureza da despesa?",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      key: message,
      defaultFocus: "none",
      accept: () => {
        for (let index in this.naturezaDespesaArray) {
          if (
            message ==
            this.naturezaDespesaArray[index]
          )
            this.naturezaDespesaArray.splice(index, 1);
        }
      }
    });
  }

  openConjuntoMaterial(){
    this.conjuntoMaterialFiltro = '';
    this.conjuntoMaterialTela = this.conjuntoMaterialApi;
    this.first = 0;
  }

  escolherConjunto(item: any){
    this.conjuntoMaterial = item;
  }

  filtrarConjuntoMaterial(): void {
    this.conjuntoMaterialTela = [];
    for (const tmp of this.conjuntoMaterialApi) {
      if(tmp.nomeConjunto.toUpperCase().includes((this.conjuntoMaterialFiltro.toUpperCase())) || tmp.codigoConjunto.toString().toUpperCase().includes((this.conjuntoMaterialFiltro.toUpperCase()))){
        this.conjuntoMaterialTela.push(tmp);
      }
    }
    this.first = 0;
  }

  limparConjuntoMaterial(): void {
    this.conjuntoMaterialTela = [];
    this.conjuntoMaterialFiltro = '';
    this.conjuntoMaterialTela = this.conjuntoMaterialApi.slice();
    this.first = 0;
  }

  excluirUnidadeFornecimento(message: string, corpo: any){
    console.log(message)
    console.log(corpo)
    this.confirmationService.confirm({
      message: "Deseja excluir essa unidade de fornecimento?",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      key: message + "UnidadeFornecimento",
      defaultFocus: "none",
      accept: () => {
        for (let index in this.unidadesFornecimentoPDM) {
          if (message == this.unidadesFornecimentoPDM[index].index)
            this.unidadesFornecimentoPDM.splice(index, 1);
        }
        for (let index in this.unidadesFornecimentoPDM) {
          this.unidadesFornecimentoPDM[index].index = parseInt(index) + 1;
        }
      }
    });
  }

  excluirCaracteristica(message: string){
    this.confirmationService.confirm({
      message: "Deseja excluir essa característica?",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      key: message,
      defaultFocus: "none",
      accept: () => {
        let index: any;
        let aux: any = [];
        let deletarNome: any;
        for ( index in this.caracteristicasEscolhidaArray) {
          if (message == this.caracteristicasEscolhidaArray[index].codigoCaracteristica){
            deletarNome = this.caracteristicasEscolhidaArray[index].nomeCaracteristica;
            this.caracteristicasEscolhidaArray.splice(index, 1);
          }
        }
        aux = this.caracteristicasEscolhidaNomes.split(', ');
        this.caracteristicasEscolhidaNomes = "";
        let tamanho = aux.length
        tamanho = tamanho-1;
        for(let i in aux){
          if(tamanho == i){
            if(aux[i] == ""){

            }
          }
          else{
            if(aux[i] != deletarNome){
              this.caracteristicasEscolhidaNomes += aux[i] + ", ";
            }
          }
        }
      }
    });

  }

  excluirValorCaracteristica(message: string){
    this.confirmationService.confirm({
      message: "Deseja excluir esse valor de característica?",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      key: message,
      defaultFocus: "none",
      accept: () => {
        this.validaValoresCaracteristica = true;
        for(let i in this.caracteristicasEscolhidaEmEdicao.valoresCaracteristica){
          if(this.caracteristicasEscolhidaEmEdicao.valoresCaracteristica[i].codigoValorCaracteristica == message){
            this.caracteristicasEscolhidaEmEdicao.valoresCaracteristica.splice(i,1);
          }
        }
      }
    });
  }

  excluirUnidadeMedidaValor(message: string){
      this.confirmationService.confirm({
        message: "Deseja excluir essa unidade de medida?",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        key: message,
        defaultFocus: "none",
        accept: () => {
          this.validaUnidadeMedida = true;
          for(let i in this.caracteristicasEscolhidaEmEdicao.unidadesMedida){
            if(this.caracteristicasEscolhidaEmEdicao.unidadesMedida[i].siglaUnidadeMedida == message){
              this.caracteristicasEscolhidaEmEdicao.unidadesMedida.splice(i,1);
            }
          }
        }
      });
  }

  salvarPDM(){
    let erro = 0;
    let aux2 = [];
    let aux3 = [];
    let CodigoConjuntoMaterial = '';
    if(this.conjuntoMaterial != null){
      CodigoConjuntoMaterial = this.conjuntoMaterial.codigoConjunto;
    }
    for(let i of this.naturezaDespesaArray){
      let x = i.split("/",2);
      aux2.push(x[0]+x[1]);
    }
    for(let i of this.unidadesFornecimentoPDM){
      let siglaUnidadeMedida = i.siglaUnidadeMedida?.split(" |",1);
      let siglaUnidadeFornecimento = i.siglaUnidadeFornecimento.split(" |",1);
      let capacidadeUnidadeFornecimento = i.capacidadeUnidadeFornecimento?.replace(",",".");
      let x;
      if(siglaUnidadeMedida == undefined){
        x = {
          index: i.index,
          siglaUnidadeMedida: "",
          siglaUnidadeFornecimento: siglaUnidadeFornecimento[0],
          capacidadeUnidadeFornecimento: ""
        };
      }
      else {
        x = {
          index: i.index,
          siglaUnidadeMedida: siglaUnidadeMedida[0],
          siglaUnidadeFornecimento: siglaUnidadeFornecimento[0],
          capacidadeUnidadeFornecimento: capacidadeUnidadeFornecimento
        };
      }
      aux3.push(x);
    }
    if(this.nomePdm == null || this.nomePdm == ""){
      this.messageService.add({
        severity: 'error',
        summary: 'Nome do PDM é um campo obrigatório'
        });
        erro = 1;
    }
    if(this.classeEscolhida.codigoClasse == null){
      this.messageService.add({
        severity: 'error',
        summary: 'Classe é um campo obrigatório'
        });
        erro = 1;
    }
    if(this.caracteristicasEscolhidaArray.length == 0){
      this.messageService.add({
        severity: 'error',
        summary: 'É necessário ter pelo menos 1 caracterísca'
        });
        erro = 1;
    }
    if(this.unidadesFornecimentoPDM.length == 0){
      this.messageService.add({
        severity: 'error',
        summary: 'É necessário ter pelo menos 1 unidade de fornecimento'
        });
      erro = 1;
    }
    if(this.conjuntoMaterial == null){
      this.messageService.add({
        severity: 'error',
        summary: 'É necessário preencher o campo de conjunto material'
        });
        erro = 1;
    }
    if(erro == 0){
      this.visible = true;
      this.PDM = {
        nomePdm: this.nomePdm.trim(),
        codigoClasse: this.classeEscolhida.codigoClasse,
        caracteristicas: this.caracteristicasEscolhidaArray,
        alias: this.aliasArray,
        listaNaturezaDespesa: aux2,
        conjuntoMaterial: CodigoConjuntoMaterial,
        unidadeFornecimento: aux3
      };
      this.buscaService.incluirNovoMaterial(this.PDM).subscribe((resp) => {
        this.visible = false;
        if(resp){
          this.codigoNovoPdm = resp;
          this.PdmSucess.show();
        }
        console.log(resp)
      }, erro => {
        this.visible = false;
        if(typeof(erro.error) == "string"){
          this.messageService.add({
            severity: 'error',
            summary: 'Erro ao salvar os dados no servidor: ' + erro.error
            });
        }else{
          this.messageService.add({
            severity: 'error',
            summary: 'Não foi possível se conectar com o serviço neste momento, por favor tente mais tarde.'
            });
        }
      });
      console.log(this.PDM);
    }
  }

  adicionarUnidadeMedida(sigla: string, nome: string){
    this.siglaUnidadeMedida = '';
    this.siglaUnidadeMedida = sigla + " | " + nome;
    this.saveUnidade();
  }

  expandeOculta(){
    if(this.expandCollapsed){
       this.expandCollapsed = false;
    } else {
       this.expandCollapsed = true;
    }
  }

  addcapacidade(){
    if(this.capacidadeUnidadeFornecimento != null){
      let capacidade = this.capacidadeUnidadeFornecimento.toString().split(".");
      if(capacidade.length > 1){
        if(capacidade[1].length > 2){
          this.capacidadeUnidadeFornecimento = this.capacidadeUnidadeFornecimento.toString().slice(0,-1)
        }
      }
    }
    this.saveUnidade();
  }

  pressEnterAlias(evento: any, parametroMetodo: any){
  console.log(parametroMetodo);
    let tamanhoPermitidoAlias = 25;
    if(evento.key == "Enter"){
      this.numMaxAlias = tamanhoPermitidoAlias;
      this.adicionaListAlias();
      return;
    }
    this.numMaxAlias = tamanhoPermitidoAlias - parametroMetodo.length;
  }

  pressEnterNatureza(evento: any){
    if(evento.key == "Enter"){
      this.adicionaListNatureza();
    }
  }

  pressEnterPDM(evento: any, parametroMetodo: any){
    let tamanhoPermitidoPDM = 100;
    this.numMaxPDM = tamanhoPermitidoPDM - parametroMetodo.trim().length;
  }

  trimTextOnBlur(controle: any) {
    controle.value = controle.value.trim();
  }

  escolherUnidadeMedidaSelecionada(item: any): void {
    let aux = 0;
    console.log(this.caracteristicasEscolhidaArray);
    for(let i of this.caracteristicasEscolhidaEmEdicao.unidadesMedida){
       if(i.siglaUnidadeMedida == item.siglaUnidadeMedida){
         aux = 1;
       }
    }
    if(aux == 0){
      for (let i of this.caracteristicasEscolhidaArray) {
          if (i.codigoCaracteristica == this.caracteristicasEscolhidaEmEdicao.codigoCaracteristica){
             let unidadeMedida = new UnidadeMedidaPDM();
             unidadeMedida.siglaUnidadeMedida = item.siglaUnidadeMedida;
             unidadeMedida.nomeUnidadeMedida = item.nomeUnidadeMedida;
             unidadeMedida.possuiEmbalagem = item.embalagemUnidadeMedida;
             i.unidadesMedida.push(unidadeMedida);
          }
      }
    }
   }
}
