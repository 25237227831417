<div
    class="modal fade"
    id="conjuntoMaterial"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ConjuntoMaterial"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-cpc">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Selecionar um conjunto de material</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="container-lg">
          <div class=" row  mb-3 mt-3" style="justify-content: center;">
            <div class="col-sm-6 col-lg-6 mb-3 ">
              <div class="br-input">
                <input class="p-inputtext" type="text" placeholder="Digite o nome do conjunto do material para filtrar" [(ngModel)]="conjuntoMaterialFiltro" (keyup)="filtrarConjuntoMaterial()" />
              </div>
            </div>
            <!--<div class="col-sm-2 col-lg-2 mb-3  " >
              <button class="br-button secondary"  (click)="filtrarConjuntoMaterial()">Buscar</button>
            </div>
            <div class="col-sm-2 col-lg-2 mb-3"  >
              <button class="br-button secondary"  (click)="limparConjuntoMaterial()">Limpar</button>
            </div>!-->
          </div>
          <p-table [value]="conjuntoMaterialTela" [paginator]="true" [rows]="10" [rowsPerPageOptions]="[5, 10, 25, 50, 100]" responsiveLayout="scroll" [(first)]="first">
            <ng-template pTemplate="header">
              <tr class="row">
                <th  class="col-2">Código classe</th>
                <th pSortableColumn="codigoConjunto" class="col-1">Código<p-sortIcon field="codigoConjunto"></p-sortIcon></th>
                <th pSortableColumn="nomeConjunto" class="col-6">Conjunto de material<p-sortIcon field="nomeConjunto"></p-sortIcon></th>
                <th class="col-3">Ação</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr class="row">
                <td class="col-2">{{ item.codigoClasse }}</td>
                <td class="col-1">{{ item.codigoConjunto }}</td>
                <td class="col-6">{{ item.nomeConjunto }}</td>
                <td class="col-3">
                  <button
                    type="button"
                    class="br-button secondary small"
                    aria-label="Selecionar"
                    (click)="escolherConjuntoMaterial(item)"
                    data-dismiss="modal"
                    onclick="$('#conjuntoMaterial').modal('hide');"
                  >
                    Selecionar
                  </button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
