import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {Observable} from 'rxjs';
import {NovoItemMaterial} from '../busca-siasgnet/model/novo-item-material.model';
import {EnvironmentUtil} from '../../environments/EnviromentUtil';
import { NovoServico } from '../busca-siasgnet/model/novo-servico.model';


@Injectable({ providedIn: 'root' })// busca service novo em desenvolvimento
export class BuscaService {// manter serviço, material, etc
  endpoint: string;
  constructor(private httpClient: HttpClient) {
   this.endpoint = EnvironmentUtil.obterUrlBase();
  }

  obterClassesPDM(): Observable<any>{  // copia da versão atual
    const destino = this.endpoint +  'manterpdm/v1/classematerial';
    return this.httpClient.get<any[]>(destino, {});
  }

  obterCaracteristicaPDM(): Observable<any>{  // copia da versão atual
    const destino = this.endpoint +  'manterpdm/v1/caracteristicadetalhematerial';
    return this.httpClient.get<any[]>(destino, {});
  }


  obterCaracteristicaPdmPorPalavra(textoBusca: string): Observable<any> {
    console.log(textoBusca);
    let params = new HttpParams();
    params = params.set('texto', textoBusca);
   // const destino = this.endpoint +  'manterpdm/v1/caracteristicadetalhematerial';
     const destino = this.endpoint + 'mantercaracteristica/v1/caracteristicatexto';
    return this.httpClient.get<any[]>(destino, {params});
  }
  obterCaracteristicaPdmNovo(texto: string, inicioTexto: any, somenteNome: any, textoExato: any): Observable<any> {
      console.log(texto);
      let params = new HttpParams()
      .set('texto', texto)
      .set('inicioTexto', inicioTexto)
      .set('somenteNome', somenteNome)
      .set('textoExato', textoExato);
      const destino = this.endpoint + 'manterpdm/v1/caracteristicadetalhematerialtexto';
      return this.httpClient.get<any[]>(destino, {params});

    }


  obterValoresCaracteristicaPDM(texto: string, inicioTexto: any, somenteNome: any, textoExato: any): Observable<any>{  // copia da versão atual
    let params = new HttpParams()
      .set('inicioTexto', inicioTexto)
      .set('somenteNome', somenteNome)
      .set('texto', texto)
      .set('textoExato', textoExato);
    const destino = this.endpoint +  'manterpdm/v1/valorcaracteristicamaterialtexto';
    return this.httpClient.get<any[]>(destino, {params});
  }

  obterUnidadeFornecimentoPDM(): Observable<any>{  // busca todas as unidades de medida
    const destino = this.endpoint +  'manterpdm/v1/unidadefornecimentomaterial';
    return this.httpClient.get<any[]>(destino, {});
  }


  obterUnidadeFornecimentoPdmPossuiEmbalagem(parametro: string): Observable<any>{  // busca todas as unidades de medida
    const destino = this.endpoint +  'manterpdm/v1/unidadefornecimentomaterialpossuiembalagem';
    let params = new HttpParams();
    params = params.set('possuiEmbalagem', parametro);
    return this.httpClient.get<any[]>(destino, {params});
  }

  obterConjuntoMaterial(codigoClasse: number): Observable<any>{  // busca todas as unidades de medida
    let params = new HttpParams();
    params = params.set('codigoClasse', codigoClasse.toString());
    const destino = this.endpoint +  'manterpdm/v1/conjuntomaterial';
    return this.httpClient.get<any[]>(destino, {params});
  }

  obterUnidadeDeMedidaServico(): Observable<any>{  // busca todas as unidades de medida
    const destino = this.endpoint +  'manterservico/v1/unidademedidaservico';
    return this.httpClient.get<any[]>(destino, {});
  }

    obterUnidadeDeMedidaPorPalavra(texto: string): Observable<any>{  // busca todas as unidades de medida
      let params = new HttpParams();
      params = params.set('texto', texto);
      const destino = this.endpoint +  'manterunidade/v1/unidademedidatexto';
      return this.httpClient.get<any[]>(destino, {params});
    }

  obterAgrupamentoServico(): Observable<any>{  // busca todas as unidades de medida
    const destino = this.endpoint +  'manterservico/v1/agrupamentoservico';
    return this.httpClient.get<any[]>(destino, {});
  }

  obterClasseServico(): Observable<any>{  // busca todas as unidades de medida
    const destino = this.endpoint +  'manterservico/v1/classeservico';
    return this.httpClient.get<any[]>(destino, {});
  }





  incluirNovoServico(novoServico: NovoServico): Observable<any>{
    const destino = this.endpoint + 'manterservico/v1/gravardadosservicocompleto';
    return this.httpClient.post<any>(destino, {
      codigoAgrupamentoServico: novoServico.codigoAgrupamentoServico,
      codigoCpc: novoServico.codigoCpc,
     //  codigoCpvServico: novoServico.codigoCpvServico,
     //  cpfUsuario: novoServico.cpfUsuario,
     //  dadosComplementares: novoServico.dadosComplementares,
      descricaoServico: novoServico.descricaoServico,
      descricaoServicoAcentuado: novoServico.nomeServico,
      nomeServico: novoServico.nomeServico,
      servicoExclusivoCentralCompras: novoServico.servicoExclusivoCentralCompras,
      servicoSuspenso: novoServico.servicoSuspenso,
      statusServico: novoServico.statusServico,
      tipoServico: novoServico.tipoServico,
    //   dadosComplementaresTexto: novoServico.dadosComplementaresTexto,
       listaUnidadeMedida: novoServico.listaUnidadeMedida,
      listaNaturezaDespesa: novoServico.listaNaturezaDespesa,
      listaAlias: novoServico.listaAlias
    });
  }

  alterarServico(servico: any) {
    const destino = this.endpoint + 'manterservico/v1/atualizardadosservico';
    return this.httpClient.post<any>(destino, servico);
  }

  incluirNovoMaterial(novoMaterial: any): Observable<any>{
    const destino = this.endpoint + 'manterpdm/v1/gravardadospdmcompleto';
    return this.httpClient.post<any>(destino, {
      nomePdm: novoMaterial.nomePdm,
      codigoClasse: novoMaterial.codigoClasse,
      listaCaracteristica: novoMaterial.caracteristicas,
      listaAlias: novoMaterial.alias,
      listaNaturezaDespesa: novoMaterial.listaNaturezaDespesa,
      conjuntoMaterial: novoMaterial.conjuntoMaterial,
      listaUnidadeFornecimento: novoMaterial.unidadeFornecimento
    });
  }
   atualizarDadosPdmMaterial(alteradoMaterial: any): Observable<any>{
      const destino = this.endpoint + 'manterpdm/v1/atualizardadospdmcompleto';
      return this.httpClient.post<any>(destino, {
        codigoClasse: alteradoMaterial.codigoClasse,
        codigoConjunto: alteradoMaterial.conjuntoMaterial,
        codigoPdm : alteradoMaterial.codigoPdm,
        listaAliasPalavraChave: alteradoMaterial.alias,
        listaCaracteristicaPdmMaterial: alteradoMaterial.caracteristicas,
        listaNaturezaDespesaPmdMaterial: alteradoMaterial.listaNaturezaDespesa,
        listaUnidadeFornecimentoPmdMaterial: alteradoMaterial.unidadeFornecimento,
        nomePdm: alteradoMaterial.nomePdm,
        statusPdm: alteradoMaterial.statusPdm,
        codigoUnidadeGestora: null,
        cpfUsuario: null
      });
    }

  salvarCaracteristicaPDM(novaCaracteristica: any): Observable<any>{
    const destino = this.endpoint + 'mantercaracteristica/v1/gravardadoscaracteristica';
    return this.httpClient.post<any>(destino, novaCaracteristica);
  }

  atualizarCaracteristicaPDM(caracteristicaAtualizada: any): Observable<any>{
    const destino = this.endpoint + 'mantercaracteristica/v1/atualizardadoscaracteristica';
    return this.httpClient.post<any>(destino, caracteristicaAtualizada);
  }

  salvarValorCaracteristicaPDM(novoValor: any): Observable<any>{
    const destino = this.endpoint + 'mantercaracteristica/v1/gravardadosvalorcaracteristica';
    return this.httpClient.post<any>(destino, {
      nomeValorCaracteristica: novoValor.nomeValorCaracteristica,
      statusValorCaracteristica: novoValor.statusValorCaracteristica,
      descricaoValorCaracteristica: novoValor.descricaoValorCaracteristica,
      complementoValorCaracteristica: novoValor.complementoValorCaracteristica
    });
  }

  editarValoresCaracteristicaPDM(valorEditado: any): Observable<any>{

    const destino = this.endpoint + 'mantercaracteristica/v1/atualizardadosvalorcaracteristica';
    return this.httpClient.post<any>(destino, {
      codigoValorCaracteristica: valorEditado.codigoValorCaracteristica,
      nomeValorCaracteristica: valorEditado.nomeValorCaracteristica,
      statusValorCaracteristica: valorEditado.statusValorCaracteristica,
      descricaoValorCaracteristica: valorEditado.descricaoValorCaracteristica,
      complementoValorCaracteristica: valorEditado.complementoValorCaracteristica
    });
  }

  obterValoresCaracteristicaCompletoPDM(id: string): Observable<any>{  // copia da versão atual
    console.log(id);
    let params = new HttpParams();
    //params = params.set('texto', id);
    params = params.set('texto', encodeURIComponent(id));
    // const destino = this.endpoint +  'manterpdm/v1/valorcaracteristicamaterialtexto';
    const destino = this.endpoint +  'mantercaracteristica/v1/valorcaracteristicatexto';
    return this.httpClient.get<any[]>(destino, {params});
  }

  salvarUnidadeFornecimentoPDM(novoValor: any): Observable<any>{
    const destino = this.endpoint + 'manterunidade/v1/gravardadosunidadefornecimento';
    return this.httpClient.post<any>(destino, {
      siglaUnidadeFornecimento: novoValor.siglaUnidadeFornecimento,
      nomeUnidadeFornecimento: novoValor.nomeUnidadeFornecimento,
      descricaoUnidadeFornecimento: novoValor.descricaoUnidadeFornecimento,
      possuiEmbalagem: novoValor.possuiEmbalagem,
      statusUnidadeFornecimento: novoValor.statusUnidadeFornecimento,
      codigoOtan: novoValor.codigoOtan,
      nomeOtan: novoValor.nomeOtan
    });
  }

  editarUnidadeFornecimentoPDM(valorEditado: any): Observable<any>{
    const destino = this.endpoint + 'manterunidade/v1/atualizardadosunidadefornecimento';
    return this.httpClient.post<any>(destino, {
      siglaUnidadeFornecimento: valorEditado.siglaUnidadeFornecimento,
      nomeUnidadeFornecimento: valorEditado.nomeUnidadeFornecimento,
      descricaoUnidadeFornecimento: valorEditado.descricaoUnidadeFornecimento,
      possuiEmbalagem: valorEditado.possuiEmbalagem,
      statusUnidadeFornecimento: valorEditado.statusUnidadeFornecimento,
      codigoOtan: valorEditado.codigoOtan,
      nomeOtan: valorEditado.nomeOtan
    });
  }

  obterUnidadeFornecimentoCompletoPDM(id: string): Observable<any>{  // copia da versão atual
    console.log(id);
    let params = new HttpParams();
    params = params.set('texto', id);
    const destino = this.endpoint +  'manterunidade/v1/unidadefornecimentotexto';
    return this.httpClient.get<any[]>(destino, {params});
  }

  salvarUnidadeMedida(novaUnidadeMedida: any): Observable<any>{
    const destino = this.endpoint + 'manterunidade/v1/gravardadosunidademedida';
    return this.httpClient.post<any>(destino, novaUnidadeMedida);
  }

  editarUnidadeMedida(unidadeMedida: any): Observable<any>{
    const destino = this.endpoint + 'manterunidade/v1/atualizardadosunidademedida';
    return this.httpClient.post<any>(destino, unidadeMedida);
  }

  validaNomeExistenteCaracteristica(texto: string): Observable<any>{  // copia da versão atual
    console.log(texto);
    let params = new HttpParams();
    params = params.set('nomeCaracteristica', texto);
    const destino = this.endpoint +  'mantercaracteristica/v1/existeNomeCaracteristica';
    return this.httpClient.get<any[]>(destino, {params});
  }

  validaNomeExistenteValorCaracteristica(texto: string): Observable<any>{  // copia da versão atual
    let params = new HttpParams();
    params = params.set('nomeValorCaracteristica', texto);
    const destino = this.endpoint +  'mantercaracteristica/v1/existeNomeValorCaracteristica';
    return this.httpClient.get<any[]>(destino, {params});
  }

  validaSiglaExistenteUnidadeFornecimento(texto: string): Observable<any>{  // copia da versão atual
    let params = new HttpParams();
    params = params.set('siglaUnidadeFornecimento', texto);
    const destino = this.endpoint +  'manterunidade/v1/existeUnidadeFornecimento';
    return this.httpClient.get<any[]>(destino, {params});
  }

  validaNomeExistenteUnidadeFornecimento(texto: string): Observable<any>{  // copia da versão atual
    let params = new HttpParams();
    params = params.set('nomeUnidadeFornecimento', texto);
    const destino = this.endpoint +  'manterunidade/v1/existeNomeUnidadeFornecimento';
    return this.httpClient.get<any[]>(destino, {params});
  }

  validaSiglaExistenteUnidadeMedida(texto: string): Observable<any>{  // copia da versão atual
    let params = new HttpParams();
    params = params.set('siglaUnidadeMedida', texto);
    const destino = this.endpoint +  'manterunidade/v1/existeUnidadeMedida';
    return this.httpClient.get<any[]>(destino, {params});
  }

  validaNomeExistenteUnidadeMedida(texto: string): Observable<any>{  // copia da versão atual
    let params = new HttpParams();
    params = params.set('nomeUnidadeMedida', texto);
    const destino = this.endpoint +  'manterunidade/v1/existeNomeUnidadeMedida';
    return this.httpClient.get<any[]>(destino, {params});
  }

  obterDadosBasicosPDMPorCodigo(id: string): Observable<any>{  // copia da versão atual
    let params = new HttpParams();
    params = params.set('codigoPdm', id);
    const destino = this.endpoint +  'material/v1/dadosbasicospdmporcodigo';
    return this.httpClient.get<any[]>(destino, {params});
  }

  obterDadosCaracteristicaPDMPorCodigo(id: string): Observable<any>{  // copia da versão atual
    let params = new HttpParams();
    params = params.set('codigoPdm', id);
    //const destino = this.endpoint +  'material/v1/dadoscaracteristicapdmporcodigo';
    const destino = this.endpoint +  'material/v1/dadoscaracteristicaparcialpdmporcodigo';
    return this.httpClient.get<any[]>(destino, {params});
  }

  obterDadosAliasPDMPorCodigo(id: string): Observable<any>{  // copia da versão atual
    let params = new HttpParams();
    params = params.set('codigoPdm', id);
    const destino = this.endpoint +  'material/v1/dadosaliaspdmporcodigo';
    return this.httpClient.get<any[]>(destino, {params});
  }

  obterDadosNaturezaDespesaPDMPorCodigo(id: string): Observable<any>{  // copia da versão atual
    let params = new HttpParams();
    params = params.set('codigoPdm', id);
    const destino = this.endpoint +  'material/v1/dadosnaturezadespesapdmporcodigo';
    return this.httpClient.get<any[]>(destino, {params});
  }

  obterDadosUnidadeFornecimentoPDMPorCodigo(id: string): Observable<any>{  // copia da versão atual
    let params = new HttpParams();
    params = params.set('codigoPdm', id);
    const destino = this.endpoint +  'material/v1/dadosunidadefornecimentopdmporcodigo';
    return this.httpClient.get<any[]>(destino, {params});
  }

  obterDadosValorCaracteristicaPDMPorCodigo(idPdm: string, idCaracteristica: string): Observable<any>{  // copia da versão atual
    let params = new HttpParams()
        .set('codigoCaracteristica', idCaracteristica)
        .set('codigoPdm', idPdm);
    const destino = this.endpoint +  'material/v1/dadosvalorcaracteristicapdmporcodigo';
    return this.httpClient.get<any[]>(destino, {params});
  }

  obterDadosUnidadeMedidaCaracteristicaPDMPorCodigo(idPdm: string, idCaracteristica: string): Observable<any>{  // copia da versão atual
    let params = new HttpParams()
        .set('codigoCaracteristica', idCaracteristica)
        .set('codigoPdm', idPdm);
    const destino = this.endpoint +  'material/v1/dadosunidademedidacaracteristicapdmporcodigo';
    return this.httpClient.get<any[]>(destino, {params});
  }

  obterDadosBasicosServicoPorCodigo(id: string): Observable<any>{  // copia da versão atual
    let params = new HttpParams();
    params = params.set('codigo_servico', id);
    const destino = this.endpoint +  'servico/v1/servico';
    return this.httpClient.get<any[]>(destino, {params});
  }

  editarItemMaterial(itemMaterial: any): Observable<any>{
    const destino = this.endpoint + 'manterpdm/v1/atualizardadositemmaterial';
    return this.httpClient.post<any>(destino, itemMaterial);
  }

  novoItemMaterial(itemMaterial: any): Observable<any>{
    const destino = this.endpoint + 'manterpdm/v1/novoItem';
    return this.httpClient.post<any>(destino, itemMaterial);
  }

  obterCaracteristicasPorPDMSiglaAtiva(codigo: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('codigo_pdm', codigo);
    params = params.set('todos', 'true');
    params = params.set('naoRetornaSiglaUnidadeMedidaCaracteristica','false');
    const destino = this.endpoint + 'material/v1/caracteristicaPorCodigoPdmSiglaAtiva';
    return this.httpClient.get<any[]>(destino, {params});
  }
}
