import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { ABA } from 'src/app/arealogada/comprador/comprador.component';
import { PesquisaService } from 'src/app/arealogada/comprador/comprador.service';
import { Router } from '@angular/router';
import { AlertMessageService } from '@serpro/ngx-dsgovbr';
import { Table } from 'primeng/table';
import { FiltroService } from 'src/app/arealogada/service/filtro.service';

@Component({
  selector: 'app-tabela-pedidos',
  templateUrl: './tabela-pedidos.component.html',
  styleUrls: ['./tabela-pedidos.component.css']
})
export class TabelaPedidosComponent implements OnInit {

  @Input()
  aba: string;
  @Input()
  listaStateSession: any;

  @Input()
  dataFim: string;
  @Input()
  dataInicio: string;

  sit: string = '';
  tituloCatalogador: string = '';
  url: any;
  // filtro: any;

  // @Input()
  // get filtro(): FiltroPesquisa { return this._filtro; }
  // set filtro(filtro: FiltroPesquisa) {
  //   this._filtro = filtro;
  //   this.carregarPedidos();
  // }
  // private _filtro: FiltroPesquisa;

  @Input()
  get buscaPedido(): string { return this.buscaPedido }
  set buscaPedido(buscaPedido: string) {
    this.buscaPedido = buscaPedido;
    // this.carregarPedidos();
  }
  private _buscaPedido: string;

  @Input()
  get textoPesquisa(): string { return this._textoPesquisa }
  set textoPesquisa(textoPesquisa: string) {
    this._textoPesquisa = textoPesquisa;
    this.filtrar(textoPesquisa);
  }
  private _textoPesquisa: string;


  loading: boolean = false;
  itensPedidos: any;

  @ViewChild('tabela') tabela: Table;

  constructor(private service: PesquisaService,
    private messageService: MessageService,
    private router: Router,
    private filtroService: FiltroService) { }

  ngOnInit(): void {
    this.filtroService.filtroAlterado$.subscribe(filtro => this.carregarPedidos(null, filtro.dataFim, filtro.dataInicio));
  }

  carregarPedidos(event?: any, dataF?: any, dataI?:any) {
    this.loading = true;
    this.obtemSituacao();
    const abaMsg = this.aba === ABA.NAO_DISTRIBUIDO ? 'Não distribuído' :
                 this.aba === ABA.DEVOLVIDO ? 'Pedidos devolvidos' :
                 this.aba === ABA.PENDENTE ? 'Pedidos pendentes' :
                 'Pedidos aceitos';
    this.tituloCatalogador = this.aba !== ABA.NAO_DISTRIBUIDO ? 'nomeReponsavelAnalise' : '';
    this.service.obterPedidosLegados(
      dataF ? dataF : this.dataFim,
      dataI ? dataI : this.dataInicio,
      this.sit,
      '1')
      .subscribe(resp => {
        resp ? this.itensPedidos = resp : this.itensPedidos = [];
        this.filtrar(this.textoPesquisa);
        this.loading = false;
      },
        error => {
          this.itensPedidos = [];
          this.loading = false;
          if (error.status == 413) { //PAYLOAD_TOO_LARGE
            this.messageService.add({
              severity: 'warn',
              summary: "Pesquisa da " + abaMsg + " encontrou mais de 1000 pedidos. Use o filtro de Datas para refinar a pesquisa.",
              life: 5000
            });
          } else {
            this.messageService.add({
              severity: 'warn',
              summary: 'Erro no carregamento de ' + abaMsg
            });
          }
        });
  }

  filtrar(textoPesquisa: string) {
    if (this.tabela) {
      if (textoPesquisa) {
        this.tabela.filterGlobal(textoPesquisa.trim(), 'contains');
      } else {
        this.tabela.filterGlobal('', 'contains');
      }
    }
  }

  obtemSituacao() {
    switch (this.aba) {
      case ABA.NAO_DISTRIBUIDO: {
        this.sit = 'N'; break;
      }
      case ABA.PENDENTE: {
        this.sit = 'P'; break;
      }
      case ABA.DEVOLVIDO: {
        this.sit = 'D'; break;
      }
      case ABA.ACEITO: {
        this.sit = 'A'; break;
      }
    }
  }

  obtemPedidoPorId(id: any) {
    // this.router.navigate(['manter-pedidos', id]);
    this.url = this.obterUrl(id);
    this.router.navigate([]).then(result => {  window.open(this.url, '_blank'); });
  }

  obterUrl(id: any): string {
    const href: string = window.location.href;
    if (href.indexOf('localhost') > 0){
      return 'http://localhost:4200/manter-pedidos/' + id;
    }
    if (href.indexOf('des-cnbs') > 0 || href.indexOf('des.cnbs') > 0){
      return 'https://des-cnbs.np.estaleiro.serpro.gov.br/cnbs-web/manter-pedidos/' + id;
    }
    if (href.indexOf('tre.cnbs') > 0 || href.indexOf('treinamento.catalogo') > 0 || href.indexOf('tre-cnbs') > 0){
      return 'https://treinamento.catalogo.compras.gov.br/cnbs-web/manter-pedidos/' + id;
    }
    if (href.indexOf('hom.cnbs') > 0 || href.indexOf('hom-cnbs') > 0){
      return 'https://hom-cnbs.estaleiro.serpro.gov.br/cnbs-web/manter-pedidos/' + id;
    }
    return 'https://catalogo.compras.gov.br/cnbs-web/manter-pedidos/' + id;
  }
}
