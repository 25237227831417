import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BuscaService } from 'src/app/service/busca-service';

@Component({
  selector: 'app-selecionar-unidade-fornecimento',
  templateUrl: './selecionar-unidade-fornecimento.component.html',
  styleUrls: ['./selecionar-unidade-fornecimento.component.css']
})
export class SelecionarUnidadeFornecimentoComponent implements OnInit {

  unidadeFornecimentoTela: any;
  unidadeFornecimentoApi: any;
  unidadeFornecimentoFiltro: any;
  somenteNomeUnidadeFornecimentoFiltro: boolean;
  somenteSiglaUnidadeFornecimentoFiltro: boolean;
  first: any = 0;

  @Output() unidadeFornecimento: EventEmitter<any> = new EventEmitter<any>();

  constructor(private buscaService: BuscaService) { }

  ngOnInit(): void {
    this.buscaService.obterUnidadeFornecimentoPDM().subscribe(z => {this.unidadeFornecimentoApi = z; this.resetar()});
  }

  resetar(){
    this.first = 0;
    this.unidadeFornecimentoFiltro = "";
    this.unidadeFornecimentoTela = this.unidadeFornecimentoApi;
    this.somenteNomeUnidadeFornecimentoFiltro = false;
    this.somenteSiglaUnidadeFornecimentoFiltro = false;
  }

  filtrarUnidadeFornecimento(atualizaNome?: boolean, atualizaSigla?: boolean): void {
    if(atualizaNome != undefined){
      this.somenteNomeUnidadeFornecimentoFiltro = !this.somenteNomeUnidadeFornecimentoFiltro;
      if(this.somenteNomeUnidadeFornecimentoFiltro == true){
        this.somenteSiglaUnidadeFornecimentoFiltro = false;
      }
    }
    if(atualizaSigla != undefined){
      this.somenteSiglaUnidadeFornecimentoFiltro = !this.somenteSiglaUnidadeFornecimentoFiltro;
      if(this.somenteSiglaUnidadeFornecimentoFiltro == true){
        this.somenteNomeUnidadeFornecimentoFiltro = false
      }
    }

    if(this.somenteNomeUnidadeFornecimentoFiltro == true && this.somenteSiglaUnidadeFornecimentoFiltro == true){
      this.first = 0;
      this.unidadeFornecimentoTela = [];
      for (const tmp of this.unidadeFornecimentoApi) {
        if (tmp.nomeUnidadeFornecimento.toUpperCase().includes((this.unidadeFornecimentoFiltro.toUpperCase())) || tmp.siglaUnidadeFornecimento.toString().toUpperCase().includes((this.unidadeFornecimentoFiltro.toUpperCase()))){
          this.unidadeFornecimentoTela.push(tmp);
        }
      }
    } else {
      if(this.somenteNomeUnidadeFornecimentoFiltro == true && this.somenteSiglaUnidadeFornecimentoFiltro == false){
        this.first = 0;
        this.unidadeFornecimentoTela = [];
        for (const tmp of this.unidadeFornecimentoApi) {
          if (tmp.nomeUnidadeFornecimento.toUpperCase().includes((this.unidadeFornecimentoFiltro.toUpperCase()))){
            this.unidadeFornecimentoTela.push(tmp);
          }
        }
      } else {
        if(this.somenteNomeUnidadeFornecimentoFiltro == false && this.somenteSiglaUnidadeFornecimentoFiltro == true){
          this.first = 0;
          this.unidadeFornecimentoTela = [];
          for (const tmp of this.unidadeFornecimentoApi) {
            if (tmp.siglaUnidadeFornecimento.toString().toUpperCase().includes((this.unidadeFornecimentoFiltro.toUpperCase()))){
              this.unidadeFornecimentoTela.push(tmp);
            }
          }
        } else {
          this.first = 0;
          this.unidadeFornecimentoTela = [];
          for (const tmp of this.unidadeFornecimentoApi) {
            if (tmp.nomeUnidadeFornecimento.toUpperCase().includes((this.unidadeFornecimentoFiltro.toUpperCase())) || tmp.siglaUnidadeFornecimento.toString().toUpperCase().includes((this.unidadeFornecimentoFiltro.toUpperCase())) || tmp.descricaoUnidadeFornecimento.toString().toUpperCase().includes((this.unidadeFornecimentoFiltro.toUpperCase()))){
              this.unidadeFornecimentoTela.push(tmp);
            }
          }
        }
      }
    }
  }

  escolherUnidadeFornecimento(escolhido: any){
    this.unidadeFornecimento.emit(escolhido);
  }
  

}
