import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
declare var $ : any;

@Component({
  selector: 'app-adicionar-unidade-fornecimento',
  templateUrl: './adicionar-unidade-fornecimento.component.html',
  styleUrls: ['./adicionar-unidade-fornecimento.component.css']
})
export class AdicionarUnidadeFornecimentoComponent implements OnInit {

  siglaUnidadeMedida: any;
  siglaUnidadeFornecimento: any;
  capacidadeUnidadeFornecimento: any;
  first: any;
  unidadeTrue: boolean = true;
  saveUnidadeFornecimento: boolean = true;
  unidadeMedida: any;
  unidadeFornecimento: any;

  @Output() resultado: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  limpar(){
    this.unidadeFornecimento = "";
    this.unidadeMedida = "";
    this.unidadeTrue = true;
    this.saveUnidadeFornecimento = true;
    this.siglaUnidadeFornecimento = "";
    this.siglaUnidadeMedida = "";  
    this.capacidadeUnidadeFornecimento = "";
  }

  adicionarUnidadeFornecimento(){
    let aux = {
      unidadeMedida: this.unidadeMedida,
      unidadeFornecimento: this.unidadeFornecimento,
      capacidadeUnidadeFornecimento: this.capacidadeUnidadeFornecimento
    }
    this.resultado.emit(aux);
    this.limpar();
    $('#formunidadefornecimento').modal('toggle');
  }

  adicionaUnidadeMedida(item: any){
    this.unidadeMedida = item;
    this.siglaUnidadeMedida = item.siglaUnidadeMedida + " | " + item.nomeUnidadeMedida;
  }

  adicionaUnidadeFornecimento(item: any){
    this.limpar();
    this.unidadeFornecimento = item;
    this.siglaUnidadeFornecimento = item.siglaUnidadeFornecimento + " | " + item.nomeUnidadeFornecimento;
    if(item.possuiEmbalagem == true){
      this.unidadeTrue = false;
    }
    else{
      this.unidadeTrue = true;
      this.saveUnidadeFornecimento = false;
    }
  }

  saveUnidade(){
    if((this.siglaUnidadeFornecimento == null || this.siglaUnidadeFornecimento == "") ||
      (this.siglaUnidadeMedida == null || this.siglaUnidadeMedida == "") ||
      (this.capacidadeUnidadeFornecimento == null || this.capacidadeUnidadeFornecimento == "0" || this.capacidadeUnidadeFornecimento == null)){
        this.saveUnidadeFornecimento = true;
      }
    else{
      this.saveUnidadeFornecimento = false;
    }
  }

  addcapacidade(){
    if(this.capacidadeUnidadeFornecimento != null){
      let capacidade = this.capacidadeUnidadeFornecimento.toString().split(".");
      if(capacidade.length > 1){
        if(capacidade[1].length > 2){
          this.capacidadeUnidadeFornecimento = this.capacidadeUnidadeFornecimento.toString().slice(0,-1)
        }
      }
    }
    this.saveUnidade();
  }

}
