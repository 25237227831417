<!-- Página: historico-analise-component.html -->
<app-cabecalho-interno></app-cabecalho-interno>
<div class="row">
    <!-- <div class="br-breadcrumb col-9">
        <ul class="crumb-list">
            <li class="crumb home">
                <a title="Área de Trabalho" (click)="irParaAT()">
                    <span class="sr-only">Página inicial</span>
                    <i class="icon fas fa-home"></i>
                </a>
            </li>
            <li class="crumb pr-2">
                <i class="icon fas fa-chevron-right"></i>
                <a href="javascript:void(0)" (click)="irParaPedidos()">Pedidos
                </a>
            </li>
            <li class="crumb" data-active="active">
                <i class="icon fas fa-chevron-right"></i>
                <span>Pedido Nº {{dadosBasicos?.numeroPedido}}</span>
            </li>
        </ul>
    </div> -->
</div>

<div class="br-divider"></div>
<div class="row">
    <div class="col-md-6">
        <h3 class=" mt-0 mb-0 pt-3 pb-0">Comunicação</h3>
        <p></p>
    </div>
    <div class="col-md-6 text-right" style="align-self: center;">
        <a href="javascript:void()" onclick="window.close()">Fechar</a>
    </div>
</div>

<div class="br-divider mt-2"></div>
<div class="row mt-3">
    <div class="col-sm-3">
        <label>Responsável</label>
        <div style="font-weight: normal">
            {{dadosBasicos?.nomeCadastrador}}
        </div>
    </div>
    <!-- <div class="col-sm-3">
        <label>CPF</label>
        <div style="font-weight: normal" *ngIf="dadosBasicos.numeroCpfResponsavelAnalise > 0">
            {{dadosBasicos?.numeroCpfResponsavelAnalise}}
        </div>
    </div> -->
    <div class="col-sm-3">
        <label>Data da distribuição</label>
        <div *ngIf="dadosBasicos?.dataDistribuicao > 0" style="font-weight: normal">
            <span>{{dadosBasicos?.dataDistribuicao.substring(0,2) + '/' +
                dadosBasicos?.dataDistribuicao.substring(2,4) + '/' +
                dadosBasicos?.dataDistribuicao.substring(4,8)}}</span>
        </div>
    </div>
    <div class="col-sm-6" *ngIf="dadosBasicos?.numeroCpfResponsavelAnalise > 0">
        <label>Unidade Catalogadora</label>
        <div style="font-weight: normal">
            {{dadosBasicos?.codigoUasgCatalogadora}}-{{dadosBasicos?.nomeUnidadeGestora}}
        </div>
    </div>
</div>
<div class="row mt-3">
    <div class="col-sm-6">
        <label *ngIf="dadosBasicos?.dataAceitacao > 0">
            Data da {{dadosBasicos?.situacao}} do Pedido</label>
        <div style="font-weight: normal" *ngIf="dadosBasicos?.dataAceitacao > 0">
            <span>{{dadosBasicos?.dataAceitacao.substring(0,2) + '/' +
                dadosBasicos?.dataAceitacao.substring(2,4) + '/' +
                dadosBasicos?.dataAceitacao.substring(4,8)}}</span>
        </div>
    </div>
</div>
<div class="row mt-3">
    <div class="col-sm-12">
        <label *ngIf="dadosBasicos?.motivo.length > 0">Motivo da {{dadosBasicos?.situacao}}</label>
        <div style="font-weight: normal">
            <span>{{dadosBasicos?.motivo}}</span>
        </div>
    </div>
</div>
<hr>

<!-- HISTÓRICO -->
<!-- <p-table [value]="dadosBasicos">
    <ng-template pTemplate="body" let-dadoBasico>
        <tr>
            <td>
                <label>Data</label>
                {{dadoBasico?.data}}
            </td>
            <td>
                <label>Situação</label>
                {{dadoBasico?.situacao}}
            </td>
        </tr>
        <tr>
            <td>
                <label>Parecer</label>
                {{dadoBasico?.motivo}}
            </td>
        </tr>
    </ng-template>
</p-table> -->
