import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { PedidoForm } from '../../model/pedido-form.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DesignarPedidoComponent } from '../designar-pedido/designar-pedido.component';
import { AuthService } from 'src/app/security/services/auth-service.service';
import { PedidoService } from '../../service/pedido.service';
import { PesquisaService } from '../pedido-catalogador.service';

@Component({
  selector: 'app-informacoes-pedido',
  templateUrl: './informacoes-pedido.component.html',
  styleUrls: ['./informacoes-pedido.component.css']
})

export class InformacoesPedidoComponent implements OnInit {

  token: any;
  usrProfile:any;
  usrRoles:{[id:string]:boolean};

  @ViewChild(DesignarPedidoComponent) designarComponent:DesignarPedidoComponent;

  formulario: FormGroup;
  tipoPedido: any;
  codArea: any;
  motivoFinalizacao: {value: string, descricao: string};
  novaSituacao: {value: string, descricao: string};
  @Input() pedido: PedidoForm;
  validado: boolean = true;
  uasgsCatalogadoras: any[];
  numMaxJustificaItemSustentavel: number = 2000;
  numMaxNome: number = 200;
  numMaxDescricaoPedido: number = 5000;
  numMaxLinkReferencia: number = 2000;
  maxLengthNCM: number = 8;

  optionsSimNao: any = [
    { value: false, nomeSustentavel: "Não"},
    { value: true, nomeSustentavel: "Sim" }
  ]

  constructor(
    public authService: AuthService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private service: PesquisaService,
    private pedidoService: PedidoService
  ) { }

  ngOnInit(): void {
    this.token = this.authService.token;
    if (this.token){
      this.usrProfile=this.authService.getUserProfile(this.token);
      this.usrRoles=this.authService.getUserRoles(this.token);
    }

    if (this.pedido.situacaoPedido==='RASCUNHO') this.listaUasgsCatalogadoras();

    this.novaSituacao = {value: this.pedido.situacaoPedido, descricao: this.pedido.descricaoSituacaoPedido};
    this.motivoFinalizacao = {value: this.pedido.motivoFinalizacao, descricao: this.pedido.descricaoMotivoFinalizacao};

    if(this.pedido.nomePedido !== null) {
      this.numMaxNome = this.numMaxNome - this.pedido.nomePedido.trim().length;
    }
    if(this.pedido.detalhePedido !== null) {
      this.numMaxDescricaoPedido = this.numMaxDescricaoPedido - this.pedido.detalhePedido.trim().length;
    }
    if(this.pedido.justificativaSustentavel !== null) {
      this.numMaxJustificaItemSustentavel = this.numMaxJustificaItemSustentavel - this.pedido.justificativaSustentavel.trim().length;
    }
    if(this.pedido.linkReferencia !== null) {
      this.numMaxLinkReferencia = this.numMaxLinkReferencia - this.pedido.linkReferencia.trim().length;
    }
  }

  ngAfterViewInit() {
    this.pedidoService.setHash(this.pedido);
  }

  salvarPedido(){
    if(this.pedido.situacaoPedido==='RASCUNHO' && this.novaSituacao.value==='RASCUNHO'){
      // Clique em salvar: Apenas alterações nos dados básicos do pedido em rascunho
      this.atualizaDadosUasg();
      this.patchPedido();
    } else if(this.pedido.situacaoPedido==='RASCUNHO' && this.novaSituacao.value==='ABERTO' && this.usrProfile === 'SOLICITANTE'){// Abertura pelo usuario
      this.enviarPedido()
    } else if(this.novaSituacao.value==='ABERTO'){// Designação do pedido pelo catalogador
      // TODO: Decidir qual componente terá a responsabilidade de mudar o estado nos casos de designação
      // Designar p/ uasg
      // TODO: Antes de designar verificar se nova UASG é diferente da UASG atual
      this.pedido.situacaoPedido = this.novaSituacao.value
      this.designarComponent.designar();

    }  else if(this.novaSituacao.value==='EM_ANALISE'){// Catalogador "pega para si" um pedido que estava em aberto
      //designar para o próprio catalogador
      this.authService.obterDadosUsuarioLogado().subscribe(data=>{
        this.pedido.situacaoPedido = this.novaSituacao.value;
        this.pedido.cpfCatalogador = String(data.cpf);
        this.patchPedido();
      })
    } else if(this.novaSituacao.value==='DESIGNADO'){
      // Designar p/ catalogador
      // TODO: Antes de designar verificar se o novo catalogador é diferente do atual
      this.pedido.situacaoPedido = this.novaSituacao.value;
      this.designarComponent.designar();

    } else if (this.novaSituacao.value==='FINALIZADO') {
      if( confirm("Deseja confirmar a FINALIZAÇÃO do pedido de catalogação? Após sua finalização, o pedido não poderá mais sofrer alterações e a comunicação entre Solicitante e Catalogador será encerrada.") ){
        this.pedido.situacaoPedido = this.novaSituacao.value;
        this.pedido.motivoFinalizacao = this.motivoFinalizacao.value;
        this.patchPedido();
      }
    } else if (this.pedido.situacaoPedido != this.novaSituacao.value) {
      this.pedido.situacaoPedido = this.novaSituacao.value;
      this.patchPedido();
    }
  }

  enviarPedido() {
    // Mapeamento do botão de click ENVIAR PEDIDO: Usado pelo solicitante quando vai abrir o pedido ou retornar p/ catalogador
    if( confirm("Deseja confirmar o ENVIO do pedido de catalogação para a Unidade Catalogadora? Após o envio, a alteração do pedido estará condicionada à análise do Catalogador.") ){
      // Abertura do pedido: Se o usuário clica em "Enviar", salva e envia
      if(this.pedido.situacaoPedido==='RASCUNHO'){
        this.novaSituacao.value='ABERTO';
        this.atualizaDadosUasg();
        let validado = this.validarDados();
        if (validado) {
          this.pedido.situacaoPedido = this.novaSituacao.value
          this.patchPedido();
        }
      }
      // Pedido em análise, aguardando solicitante
      else if(this.pedido.situacaoPedido==='AGUARDANDO_SOLICITANTE'){
        this.novaSituacao.value='EM_ANALISE';
        this.pedido.situacaoPedido = this.novaSituacao.value;
        this.patchPedido();
      }
    }
  }

  limparRegistroAnvisa() {
    if (this.pedido.registroAnvisa) {
      this.pedido.registroAnvisa = this.onlyNumber(this.pedido.registroAnvisa, 13)
    }
  }

  limparNcm() {
    if (this.pedido.codigoNcm) {
      this.pedido.codigoNcm = this.onlyNumber(this.pedido.codigoNcm, 8)
    }
  }

  onlyNumber(string: string, max: number) : string {
    string = string.replace(/\D/g,'');
    return string.length > max ? string.substring(0, max) : string;
  }

  cancelarPedido(){
    // Pedido de cancelamento de pedido pelo usuário solicitante
    if(confirm(`Deseja realmente cancelar o pedido número ${this.pedido.numeroPedido}?`)){
      this.pedido.situacaoPedido = 'FINALIZADO';
      this.pedido.motivoFinalizacao=this.pedido.motivoFinalizacao='CANCELADO';
      this.patchPedido();
    }
  }

  zerar(){
    this.formulario.reset();
  }

  areaDaSaude() : boolean {
    if (this.pedido.tipoPedido === 'S') {
      return false
    }

    if (this.pedido.codigoUasgCatalogador !== 250005){
      this.pedido.isentoRegistroAnvisa = undefined;
      this.pedido.registroAnvisa = undefined;
      return false
    }

    return true;
  }

  patchPedido() {
    this.service.alterarPedido(this.pedido).subscribe(pedido => {
      if (pedido){
        if (this.pedido.situacaoPedido === 'RASCUNHO'){
          this.pedidoService.setHash(this.pedido);
        } else {
          window.location.reload()
        }
      } else {
        this.messageService.add({severity: 'error', summary: 'Não foi possível alterar o pedido.', detail: ''});
      }
      }, error => {
        this.messageService.add({severity: 'error', summary: 'Não foi possível alterar o pedido.', detail: 'Entre em contato com área responsável ou tente mais tarde.'});
      }
    );
  }

  formularioReadonly() : boolean {
    if (this.pedido.situacaoPedido === 'RASCUNHO') {
      return false
    }

    return true
  }

  getClassSituacao() : string {
    if (this.pedido.situacaoPedido === 'RASCUNHO' || this.pedido.situacaoPedido === 'AGUARDANDO_SOLICITANTE') {
      return 'bg-yellow-vivid-30'
    } else if (this.pedido.situacaoPedido === 'ABERTO') {
      return 'bg-info'
    } else if (this.pedido.situacaoPedido === 'DESIGNADO') {
      return 'bg-blue-vivid-50'
    } else if (this.pedido.situacaoPedido === 'EM_ANALISE') {
      return 'bg-green-cool-vivid-30'
    } else if (this.pedido.situacaoPedido === 'FINALIZADO'
                && (this.pedido.motivoFinalizacao === 'CATALOGADO'
                  || this.pedido.motivoFinalizacao === 'ORIENTADO')) {
      return 'bg-success'
    } else if (this.pedido.situacaoPedido === 'FINALIZADO'
                && (
                  this.pedido.motivoFinalizacao === 'CANCELADO'
                  || this.pedido.motivoFinalizacao === 'PEDIDO_DUPLICADO'
                  || this.pedido.motivoFinalizacao === 'PRAZO_EXPIRADO'
                )) {
      return 'bg-danger'
    }

    return 'bg-info'
  }

  visivelAoSolicitante() : boolean {
    return this.usrProfile==='SOLICITANTE' && (
      this.pedido.situacaoPedido === 'RASCUNHO'
      || this.pedido.situacaoPedido === 'ABERTO' // Solicitante ainda poderá cancelar
      || this.pedido.situacaoPedido === 'AGUARDANDO_SOLICITANTE'
    );
  }

  gravarAnexo(){}

  isentoRegAnvisa(){
    this.pedido.registroAnvisa = undefined;
  }

  validarDados() : boolean {
    this.validado = true;
    if(!this.pedido.emailSolicitante || this.pedido.emailSolicitante.length == 0){
      this.messageService.add({
        severity: 'warn',
        summary: 'Email obrigatório'
      });
      this.validado = false;
    }

    if (this.pedido.emailSolicitante && !validarEmail(this.pedido.emailSolicitante)) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Email inválido'
        });
        this.validado = false;
    }
    if(!this.pedido.nomePedido || this.pedido.nomePedido.length == 0){
      if(this.tipoPedido == 'M'){
        this.messageService.add({
          severity: 'warn',
          summary: 'Material obrigatório'
          });
          this.validado = false;
      } else {
        this.messageService.add({
          severity: 'warn',
          summary: 'Serviço obrigatório'
          });
          this.validado = false;
      }
    }
    if(this.pedido.sustentavel && (!this.pedido.justificativaSustentavel || this.pedido.justificativaSustentavel.length == 0)){
      this.messageService.add({
        severity: 'warn',
        summary: 'Justificativa Sustentável obrigatória'
        });
        this.validado = false;
    }
    if(!this.pedido.detalhePedido || this.pedido.detalhePedido.length == 0){
      this.messageService.add({
        severity: 'warn',
        summary: 'Detalhes do Pedido obrigatório'
        });
        this.validado = false;
    }
    if(this.areaDaSaude() && !this.pedido.isentoRegistroAnvisa) {
      if (!this.pedido.registroAnvisa) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Registro Anvisa obrigatório'
          });
        this.validado = false;
      }
    }

    // Retirado a pedido do cliente - 04/06/2024
    // if (this.pedido.cnae && (this.pedido.cnae > 2147483647)) {
    //   this.messageService.add({
    //     severity: 'warn',
    //     summary: 'CNAE deve ser menor que 2.147.483.647'
    //     });
    //   this.validado = false;
    // }

    

    if (this.pedido.codigoUasgCatalogador == 0 || this.pedido.codigoUasgCatalogador == undefined) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Unidade Catalogadora Obrigatória'
        });
      this.validado = false;
    }

    function validarEmail(email: any) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    }

    return this.validado;
  }

  public formataTelefone(){
    if (this.pedido.telefoneSolicitante){
      let value = this.pedido.telefoneSolicitante.toString();
      value = value.replace(/[^0-9]/g, '');
      if (value.length <= 11) {
        value = value.replace(/\D/g,'')
        value = value.replace(/(\d{2})(\d)/,"($1) $2")
        value = value.replace(/(\d)(\d{4})$/,"$1-$2")
        this.pedido.telefoneSolicitante = value;
      }
    }
  }

  listaUasgsCatalogadoras(){
    // Popula o seletor de uasg (designação inicial, para o solicitante)
    this.pedidoService.listarUasgs().subscribe(data=>{
      this.uasgsCatalogadoras=data;
    });
  }

  atualizaDadosUasg(){
    // Atualiza nome da uasg (e outros dados necessários)
    // Dispara com a troca de uasgs em rascunho e na designação inicial, pelo solicitante
    if (this.pedido.codigoUasgCatalogador > 0) {
      this.pedido.nomeUasgCatalogador = this.pedidoService.buscaUasgPorCodigo(this.pedido.codigoUasgCatalogador, this.uasgsCatalogadoras)['nomeDesignacao']
    }
  }

  pressEnterNome(evento: any, parametroMetodo: any){
    let tamanhoPermitidoNome = 200;
    this.numMaxNome = tamanhoPermitidoNome - parametroMetodo.trim().length;
  }

  pressEnterDetalhePedido(evento: any, parametroMetodo: any){
    let tamanhoPermitidoDetalhePedido = 5000;
    this.numMaxDescricaoPedido = tamanhoPermitidoDetalhePedido - parametroMetodo.trim().length;
  }

  pressEnterJustificativa(evento: any, parametroMetodo: any){
    let tamanhoPermitidoJustificativa = 2000;
    this.numMaxJustificaItemSustentavel = tamanhoPermitidoJustificativa - parametroMetodo.trim().length;
  }

  pressEnterLinkReferencia(evento: any, parametroMetodo: any){
    let tamanhoPermitidoLinkReferencia = 2000;
    this.numMaxLinkReferencia = tamanhoPermitidoLinkReferencia - parametroMetodo.trim().length;
  }
}
