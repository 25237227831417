<!-- Página: efetuar-pedido-component.html -->
<app-cabecalho-interno></app-cabecalho-interno>

<br /><br /><br />


<div class="container pl-0 area-pedidos mb-3">
    <div class="row">
      <div [ngClass]= "{'col-3': !escondeMenu, 'col-1': escondeMenu, 'menu-fechado':escondeMenu }" class="pl-0 pr-0">
        <app-menu-pedido (changeIndexEvent)="changeIndex($event)"
          (changeEstadoMenuEvent)="changeEstadoMenu($event)"
          [usrProfile]="usrProfile">
        </app-menu-pedido>
      </div>

      <!-- Lado direito -->
      <div class="col">

        <div class="div">
          <!-- Área ação-->
          <div class="d-flex justify-content-end my-2">
            <div class="text-right" style="align-self:center;">
              <a href="javascript:void()" (click)="onClickFechar()">Fechar</a>
              <br-confirmation  #BackAlert popupTitle="Deseja fechar a janela?" acceptLabel="Sim" rejectLabel="Não"
                      question="Pode haver dados que não foram salvos." (accept)="closeWindow()" (reject)="BackAlert.hide()">
              </br-confirmation>
            </div>
          </div>
          <!-- fim área ação -->
        </div>

      <div class="br-divider"></div>
      <!-- Principal -->
      <div class="col px-0">

        <div *ngIf="index == 0">
          <app-informacoes-pedido #secaoItens
            [pedido]="pedido"></app-informacoes-pedido>
        </div>

        <div *ngIf="index == 1">
          <app-historico-analise-pedido #secaoItens
            [pedido]="pedido"
            [token]="token"
            [usrProfile]="usrProfile"
            [usrRoles]="usrRoles"></app-historico-analise-pedido>
        </div>

        <div *ngIf="index == 2">
          <app-historico-pedido #secaoItens
            [pedido]="pedido"
            [token]="token"
            [usrProfile]="usrProfile"
            [usrRoles]="usrRoles"></app-historico-pedido>
        </div>

        <div *ngIf="index == 3">
          <app-anexos-pedido #secaoItens
            ></app-anexos-pedido>
        </div>
      </div>

      <!-- fim principal -->

    </div>
  </div>
</div>
