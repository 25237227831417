import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BuscaService } from 'src/app/service/busca-service';
import { CaracteristicaPdm } from '../../../model/caracteristica-pdm';
//TODO: remover quando solução temporária de fechamento do modal for removida.
declare var $ : any;

@Component({
  selector: 'app-selecionar-caracteristica',
  templateUrl: './selecionar-caracteristica.component.html',
  styleUrls: ['./selecionar-caracteristica.component.css']
})
export class SelecionarCaracteristicaComponent implements OnInit {

  caracateristicaFiltro: string;
  first = 0;
  caracteristicaTela: CaracteristicaPdm[];
  caracteristicasApi: CaracteristicaPdm[];
  somenteNomeCaracteristicaFiltro: boolean = false;

  inicioTexto: boolean = false;
  somenteNome: boolean = false;
  textoExato: boolean = false;
  flagRecarregar : boolean = false;

  @Output() onCaracteristicaSelecionada: EventEmitter<CaracteristicaPdm> = new EventEmitter<CaracteristicaPdm>();

  constructor(private buscaService: BuscaService) { }

  ngOnInit(): void {
    this.flagRecarregar  = true;
    this.recarregarCaracteristicas();
  }

  recarregarCaracteristicas() {
    this.buscaService.obterCaracteristicaPDM().subscribe((tmp) => {this.caracteristicasApi = tmp; this.limparFiltroCaracteristica();});
  }

  fechar() {
    this.limparFiltroCaracteristica();
  }

  limparFiltroCaracteristica(): void {
    this.first = 0;
    this.caracteristicaTela = [];
    this.caracateristicaFiltro = '';
    this.somenteNomeCaracteristicaFiltro = false;
    this.caracteristicaTela = this.caracteristicasApi.slice();
    this.inicioTexto = false;
    this.somenteNome = false;
    this.textoExato  = false;
        // TODO: modal não fecha ao selecionar
        // se lista for limpa
        // então solução temporária abaixo.
    if (this.flagRecarregar == false){
       $('#buscarcaracteristica').modal('toggle');
    }else{ // TODO: veio do ngOnInit()
      this.flagRecarregar = false;
    }
  }

  filtrarCaracteristica(atualizaNome?: boolean): void {
    if(atualizaNome != undefined){
      this.somenteNomeCaracteristicaFiltro = !this.somenteNomeCaracteristicaFiltro;
    }
    if(this.somenteNomeCaracteristicaFiltro == true){
      this.first = 0;
      this.caracteristicaTela = [];
      for (const tmp of this.caracteristicasApi) {
        // @ts-ignore
        if (tmp.nomeCaracteristica.toUpperCase().includes((this.caracateristicaFiltro.toUpperCase()))){
          this.caracteristicaTela.push(tmp);
        }
      }
    } else {
      this.first = 0;
      this.caracteristicaTela = [];
      for (const tmp of this.caracteristicasApi) {
        // @ts-ignore
        if (tmp.nomeCaracteristica.toUpperCase().includes((this.caracateristicaFiltro.toUpperCase())) || tmp.codigoCaracteristica.toUpperCase().includes((this.caracateristicaFiltro.toUpperCase())) || tmp.detalheCaracteristica.toUpperCase().includes((this.caracateristicaFiltro.toUpperCase()))){
          this.caracteristicaTela.push(tmp);
        }
      }
    }
  }

  escolherCaracteristica(codigoCaracteristica: string): void {
    for (const tmp of this.caracteristicasApi) {
      if ( tmp.codigoCaracteristica === codigoCaracteristica){
        const caracteristicaPdm  = new  CaracteristicaPdm();
        caracteristicaPdm.codigoCaracteristica = tmp.codigoCaracteristica;
        caracteristicaPdm.nomeCaracteristica = tmp.nomeCaracteristica;
        caracteristicaPdm.ativo = true;
        caracteristicaPdm.obrigatorio = false;
        this.onCaracteristicaSelecionada.emit(caracteristicaPdm);
        this.limparFiltroCaracteristica();
        break;
      }
    }
  }

  escolherNovaCaracteristica(tmp: CaracteristicaPdm): void {
      const caracteristicaPdm  = new  CaracteristicaPdm();
      caracteristicaPdm.codigoCaracteristica = tmp.codigoCaracteristica;
      caracteristicaPdm.nomeCaracteristica = tmp.nomeCaracteristica;
      caracteristicaPdm.ativo = true;
      caracteristicaPdm.obrigatorio = false;
      this.onCaracteristicaSelecionada.emit(caracteristicaPdm);
      this.limparFiltroCaracteristica();
  }

  buscarCaracteristica(): void {
      this.first = 0;
      this.buscaService.
      obterCaracteristicaPdmNovo(this.caracateristicaFiltro.toUpperCase(), this.inicioTexto, this.somenteNome, this.textoExato).
      subscribe((tmp) => this.caracteristicaTela = tmp);
      this.caracteristicasApi = this.caracteristicaTela;

    }
  pressEnterCaracteristicas(evento: any){
     if(evento.key == "Enter"){
        this.buscarCaracteristica()
     }
  }
  marcador(inicio: string, somente: string, exato: string){
      if(inicio == "inicioTexto"){
        if(this.inicioTexto == false){
          this.somenteNome = false;
          this.textoExato = false;
        }
      }
      if(somente == "somenteNome"){
        if(this.somenteNome == false){
          this.inicioTexto = false;
          this.textoExato = false;
        }
      }
      if(exato == "textoExato"){
        if(this.textoExato == false){
          this.somenteNome = false;
          this.inicioTexto = false;
        }
      }
    }

}
