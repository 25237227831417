<app-cabecalho *ngIf="!isUsuarioAutenticado()"></app-cabecalho>
<div *ngIf="isUsuarioAutenticado()">
  <app-cabecalho-interno></app-cabecalho-interno>
  <br>
  <br>
  <br>
</div>
<div >
  <div class="mb-4">
     <div class="container-lg">
      <div class="row">
        <div class="col-md-10">
          <h2 class="ajuste mb-0" (click)="voltaTelaInicial()"><span class="mouseover">Catálogo</span></h2>

        </div>
        <div class="col-md-2 text-right">
          <div class="buttons-header">
            <button (click)="display = true" class="br-button secondary circle mr-1" pTooltip="O carrinho tem {{getItensAtualizado().length}} item(s)" type="button" aria-label="Ícone ilustrativo">
              <i class="fas fa-cart-arrow-down" aria-hidden="true"></i>
            </button>
            <div *ngIf= "getItensAtualizado().length > 0" style="margin-left: -20px; font-size: 12px;" class="br-tag count danger small"><span>{{getItensAtualizado().length}}</span>
            </div>
            <button pTooltip="Saiba mais" class="br-button secondary circle" type="button" onClick="window.open('//www.gov.br/compras/pt-br/sistemas/catalogo')" aria-label="Saiba mais">
              <i class="fas fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="row ">
        <div class="col-md-12">
          <h6 class="mt-1 mb-1 busca-texto">
            Busque aqui bens e serviços e crie sua lista para utilização nas contratações públicas
          </h6>
        </div>
      </div>

      <div class="row pesquisa mt-3 d-flex" >
<!--        <div class="col-md-2 mt-2 mb-2">-->
<!--          <p-dropdown [options]="filtros" [(ngModel)]="filtro1" optionLabel="name"></p-dropdown>-->
<!--        </div>-->
        <div class="col-md-12 mt-2 mb-2">
          <div class="br-input label">
           <p-autoComplete #hint [(ngModel)]="textoBusca" [suggestions]="results" (completeMethod)="searchHint($event)"
                          (keyup)="liberarPesquisa($event)"  (keyup.enter)="pesquisarEnter($event)"
                          placeholder="Digite aqui o material ou serviço a ser pesquisado" field="nome"
                          scrollHeight="500px" [disabled]="pesquisando"
                          >

            <ng-template  let-item pTemplate="item">
              <div (click)="buscaEscolhido(item.codigo, item.tipo, item.nome)" data-disabled="pesquisando">
                {{item.tipo}} - {{item.nome}}
              </div>
            </ng-template>
          </p-autoComplete>

          <button (click)="pesquisarLupa()" [disabled]="pesquisando" style="position: absolute; z-index: 1; margin-left: -40px; margin-top: 8px;" class="br-button circle small" type="button" aria-label="Ícone ilustrativo">
            <i class="fas fa-search"></i>
          </button>
          </div>
        </div>
      </div>
    </div>

  </div><!-- cabeçalho -->
  <div *ngIf="telaTabView">
  <div class="container-lg container mt-3" >

<!--    <p-tabView [(activeIndex)]="indexpainel" *ngIf="servicos?.length > 0 || materiais?.length > 0">-->
    <p-tabView [(activeIndex)]="indexpainel" *ngIf="servicos?.length > 0 || materiais?.length > 0">
      <!-- painel do serviçco -->
      <p-tabPanel header="Serviços" *ngIf="servicos?.length > 0"  >
        <div class="row">
          <div class="col-md-3 col-sm-12 card " style="margin-left: -4px; margin-right: 4px;" *ngIf="servicos?.length > 0">
            <div><h6 class="mb-1"><i class="fas fa-sliders-h mr-1"></i>Filtros</h6></div>
            <div><h6 class="text-uppercase mb-2" style="font-size: 12px;"> Grupos de Serviços</h6></div>
            <hr>
            <div class="pt-1 pb-1">
              <p-listbox [listStyle]="{'max-height':'650px'}"  [options]="gruposServico"
                            [(ngModel)]="selectedGrupo" optionLabel="name"
                            (onClick)="grupoEscolhido($event)">

                 <ng-template let-itens pTemplate="item">
                    <div *ngIf="itens.name == 'TODOS OS GRUPOS'"><STRONG>{{itens.name}}</STRONG></div>
                    <div *ngIf="itens.name != 'TODOS OS GRUPOS'">{{itens.name}}</div>
                 </ng-template>

              </p-listbox>

<!--          <p-listbox [listStyle]="{'max-height':'600px'}"  [options]="gruposServico"
                         [(ngModel)]="selectedGrupo" optionLabel="name"
                         (onClick)="grupoEscolhido($event)">
              </p-listbox>  -->
            </div>
        </div>

          <div class="col-md-9 col-sm-12 card" style="margin-left: 4px; margin-right: -4px;" *ngIf="servicos?.length > 0">

            <div style="display: flex; flex-direction: column;">
              <div class="d-flex">
                <h6 class="p-1 m-0" style="font-size: 14px;">Foram encontrados {{ servicos?.length }} serviços para os filtros </h6>
                <button *ngIf="isUsuarioAutenticado() && usrProfile==='SOLICITANTE'" type="button" pTooltip="Para solicitar um novo pedido de catalogação de item de serviço, clique aqui." class="br-button primary ml-auto mr-2 mb-2" (click)="iniciarNovoPedido('S')">Pedido de Serviço</button>
              </div>
              <div class="br-tag interaction-select small" *ngIf="selectedGrupo.name">
                <span class="texto-overflow">{{ selectedGrupo.name }}</span>
                <i class="fas fa-times" aria-hidden="true" (click)="limparGrupo()"></i>
              </div>
            </div>

            <div class="col-md-12">
            <p-table [value]="servicos"  (sortFunction)="customSort($event)" [customSort]="true" [paginator]="paginarServico" [rows]="rows"
                     [rows]="10" [rowsPerPageOptions]="[5,10,25,50,100]" >

              <ng-template pTemplate="header">
                <tr class="row">
                 <th class="col-2" pSortableColumn="codigoGrupo" >Grupo <p-sortIcon field="codigoGrupo"></p-sortIcon></th>
                  <th class="col-2" pSortableColumn="codigoServico" >Serviço <p-sortIcon field="codigoServico"></p-sortIcon></th>
                  <th class="col-6" pSortableColumn="descricaoServicoAcentuado" >Descrição <p-sortIcon field="descricaoServicoAcentuado"></p-sortIcon></th>
                  <th class="col-2" pSortableColumn="statusServico">Ações <p-sortIcon field="statusServico"></p-sortIcon></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-servicos >
                <tr class="row">
                  <td class="col-2">{{servicos.codigoGrupo}}   </td>
                  <td class="col-2">{{servicos.codigoServico}}  </td>
                  <td class="col-6">{{ servicos.descricaoServicoAcentuado }}</td>
                  <td class="col-2">
                    <button *ngIf="servicos.statusServico == true && servicos.suspenso == false" type="button" class="br-button  secondary  small" aria-label="Selecionar"
                      (click)="detalheServico(servicos.codigoServico, servicos.descricaoServicoAcentuado)"  >
                      Selecionar
                    </button>

<!--                JMD - 06/12/2021 -->
                    <button *ngIf="servicos.suspenso == true && servicos.suspenso == true" type="button" class="br-button  secondary  small" aria-label="Suspenso"
                            (click)="detalheServico(servicos.codigoServico, servicos.descricaoServicoAcentuado)"  disabled>
                      Suspenso
                    </button>

                    <button *ngIf="servicos.statusServico == false" type="button" class="br-button  secondary  small" aria-label="Inativo"
                    (click)="detalheServico(servicos.codigoServico, servicos.descricaoServicoAcentuado)"  disabled>
                    Inativo
                  </button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          </div>

        </div>

        <div>
          <div class="col-12 card" *ngIf="servicos?.length <= 0">
            <div class="d-flex justify-content-center">

              <div class="row " *ngIf="!servicos?.length">
                <div class="col-12 ">
                  <div style="display: flex; flex-direction: column; align-items: center;">
                    <img class="imagem align-content-center" src="assets/imagem/empty.svg" width="250" alt="Imagem de Busca"/>
                    <h5>Nenhum serviço a ser exibido. </h5>
                    Utilize o campo de busca para pesquisar materiais ou serviços e visualizar as opções.
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </p-tabPanel>

      <!-- fim dp painel do serviçco -->
      <!-- painel do material -->
      <p-tabPanel header="Materiais" *ngIf="materiais?.length > 0" >

        <div class="row" *ngIf="materiais?.length > 0">
              <div class="col-md-3 col-sm-12 card" style="margin-left: -4px; margin-right: 4px;">
                <div><h6 class="mb-1"><i class="fas fa-sliders-h mr-1"></i>Filtros</h6></div>
                <div><h6 class="text-uppercase mb-2" style="font-size: 12px;"> Classes de  Materiais</h6></div>
                <hr>
                <div class="pt-1 pb-1">

                  <p-listbox [listStyle]="{'max-height':'650px'}"  [options]="classesMaterial"
                             [(ngModel)]="selectedClasse" optionLabel="name"
                             (onClick)="classeEscolhida($event)">

                       <ng-template let-itens pTemplate="item">
                          <div *ngIf="itens.name == 'TODAS AS CLASSES'"><STRONG>{{itens.name}}</STRONG></div>
                          <div *ngIf="itens.name != 'TODAS AS CLASSES'">{{itens.name}}</div>
                       </ng-template>

                  </p-listbox>

<!--              <p-listbox [listStyle]="{'max-height':'650px'}"  [options]="classesMaterial"
                             [(ngModel)]="selectedClasse" optionLabel="name"
                             (onClick)="classeEscolhida($event)">
                  </p-listbox> -->

                </div>
              </div>

              <div class="col-md-9 col-sm-12 card" style="margin-left: 4px; margin-right: -4px;">

                <div style="display: flex; flex-direction: column;" *ngIf="materiais?.length > 0">
                  <div class="d-flex">
                    <h6 class="p-1 m-0" style="font-size: 14px;">Foram encontrados {{ materiais?.length }} pdms para os filtros <i class="fas fa-question-circle"></i></h6>
                    <button *ngIf="isUsuarioAutenticado() && usrProfile==='SOLICITANTE'" type="button" pTooltip="Para solicitar um novo pedido de catalogação de item de material, clique aqui." class="br-button primary  ml-auto mr-2 mb-2" (click)="iniciarNovoPedido('M')">Pedido de Material</button>
                  </div>
                  <!--<div class="br-tag interaction-select selected">
                    <label for="tag01"><span>{{ texto }}</span>
                    </label>
                  </div>
                  <div class="br-tag interaction-select selected">
                    <label for="tag01"><span>{{ selectedClasse.name }}</span>
                    </label>
                  </div>-->
                </div>

            <p-table [value]="materiais" [paginator]="paginarMaterial" [rows]="rows"  (sortFunction)="customSort($event)" [customSort]="true"
                     [rows]="10" [rowsPerPageOptions]="[5,10,25,50,100]">

              <ng-template pTemplate="header">
                <tr>
                  <th class="col-2" pSortableColumn="codigoClasse" >Classe <p-sortIcon field="codigoClasse"></p-sortIcon></th>
                  <th class="col-2" pSortableColumn="codigoPdm" >Código <p-sortIcon field="codigoPdm"></p-sortIcon></th>
                  <th class="col-6" pSortableColumn="nomePdm" >Padrão Descritivo de Material<p-sortIcon field="nomePdm"></p-sortIcon></th>
                  <th class="col-2" pSortableColumn="statusPDM">Ações <p-sortIcon field="statusPDM"></p-sortIcon></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-materiais >
                <tr>
                  <td class="col-2">{{materiais.codigoClasse}}  </td>
                  <td class="col-2">{{materiais.codigoPdm}}  </td>
                  <td class="col-6">{{ materiais.nomePdm }}</td>
                  <td class="col-2">
                    <button *ngIf="materiais.statusPDM == true" type="button" class="br-button  secondary  small" aria-label="Selecionar"
                            (click)="detalheMaterial(materiais.codigoPdm, materiais.nomePdm, materiais.codigoClasse)"  >
                            Selecionar
                    </button>
                    <button *ngIf="materiais.statusPDM == false" type="button" class="br-button  secondary  small" aria-label="Inativo"
                    (click)="detalheMaterial(materiais.codigoPdm, materiais.nomePdm, materiais.codigoClasse)"  disabled>
                    Inativo
                    </button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>

      </p-tabPanel>
      <!-- fim painel do material -->
    </p-tabView>
  </div>
  </div>

  <app-detalhe-material *ngIf="detalheMaterialVisivel" [isUsuarioGovernoLogado]="isUsuarioAutenticado()" [material]="material" (atualizarLista)="atualizarLista()" (aoVoltar)="voltar($event)" ></app-detalhe-material>
  <app-detalhe-servico *ngIf="detalheServicolVisivel" [isUsuarioGovernoLogado]="isUsuarioAutenticado()" [servico]="servico" (atualizarLista)="atualizarLista()"  (aoVoltar)="voltar($event)" ></app-detalhe-servico>
  <!----------fim  novo container------------------ -->
</div>
<div class="container-lg">
  <div class="row" *ngIf="telaMensagemVazia">
    <div class="col-12 card">
      <div class="justify-content-center">
        <div class="row" >
          <div class="col-12 ">
            <div style="display: flex; flex-direction: column; align-items: center;">
              <img class="imagem align-content-center" src="assets/imagem/empty.svg" width="250" alt="Imagem de Busca"/>
              <!--<h6>Você sabia?</h6><p>Ao pesquisar materiais e serviços, você pode adicioná-los ao carrinho para exportação dos itens em formatos xml, csv, json ou txt.</p>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-lg" *ngIf="exibirMenssagemVazia">
  <div class="col-12 card" >
      <div class="justify-content-center">
        <div class="row" >
          <div class="col-12 ">
            <div style="display: flex; flex-direction: column; align-items: center;">
              <img class="imagem align-content-center" src="assets/imagem/empty.svg" width="250" alt="Imagem de Busca"/>
              <h4><p>Não foram encontrados materiais ou serviços com o critério informado!</p></h4>
              <div class="row d-flex justify-content-center" *ngIf="isUsuarioAutenticado() && usrProfile==='SOLICITANTE'">
                <button type="button" pTooltip="Para solicitar um novo pedido de catalogação de item de material, clique aqui." class="br-button primary mt-3 ml-5" (click)="iniciarNovoPedido('M')">Pedido de Material</button>
                <button type="button" pTooltip="Para solicitar um novo pedido de catalogação de item de serviço, clique aqui." class="br-button primary mt-3 ml-5" (click)="iniciarNovoPedido('S')">Pedido de Serviço</button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
</div>
<!-- Carregar Footer aqui -->
<footer class="br-footer [inverted]">
  <div class="container-lg">
    <div class="d-none d-sm-block">
      <div class="row align-items-end justify-content-between py-5">
        <div class="col social-network">
          <p class="text-up-01 text-extra-bold text-uppercase">
            Redes Sociais
          </p>
          <a href="https://www.twitter.com/mineconomia" target="_blank">
            <i class="fab fa-twitter pr-5"></i>
          </a>
          <a href="https://www.youtube.com/mpstreaming" target="_blank">
            <i class="fab fa-youtube pr-5"></i>
          </a>
          <a href="https://www.facebook.com/ministeriodaeconomia" target="_blank">
            <i class="fab fa-facebook-f pr-4"></i>
          </a>
          <a href="https://www.instagram.com/ministeriodaeconomia/" target="_blank">
            <i class="fab fa-instagram pr-5"></i>
          </a>
        </div>
        <div class="col assigns text-right">
          <a href="http://www.acessoainformacao.gov.br/" target="_blank">
            <img
              class="ml-4"
              src="assets/imagem/acesso.png"
              alt="Texto alternativo"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</footer>

<p-sidebar [(visible)]="display" position="right" [style]="{width:'80em'}">
  <app-exibir-selecionados [itens]="itensCarrinho" (atualizarLista)="atualizarLista()"></app-exibir-selecionados>
</p-sidebar>
<ng-template #template1>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Você digitou um código de item que tem material e serviço associado a ele</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef1.hide()">
      <span aria-hidden="true">&times;</span></button>

  </div>
  <div class="modal-body">
    <p>Deseja seguir para material ou serviço?</p>
  </div>
  <div class="modal-footer ">
    <button type="button" class="br-button primary" (click)="seguirParaMaterial()">Material</button>
    <button type="button" class="br-button primary"(click)="seguirParaServico()">Serviço</button>
  </div>
</ng-template>
