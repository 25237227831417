<div class="modal fade" id="formunidadefornecimento">
  <div class="modal-dialog modal-lg modal-cpc modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Incluir unidades de fornecimento</h4>
        <button type="button" class="close" data-dismiss="modal">
          &times;
        </button>
      </div>

      <div class="container-lg">
        <div class="row">
          <div class="col-8 col-sm-8 col-lg-8 mb-3 mt-3">
            <div class="br-input">
              <label> Sigla unidade de fornecimento:</label>
              <input
                class="p-inputtext"
                type="text"
                placeholder="Incluir a sigla da unidade de fornecimento"
                [(ngModel)]="siglaUnidadeFornecimento"
                readonly
              />
            </div>
          </div>
          <div
            class="col-2 col-sm-2 col-lg-2 mb-3 mt-3"
            style="align-self: center"
          >
            <button
              type="button"
              class="br-button secondary"
              data-toggle="modal"
              data-target="#buscarunidadefornecimentototal"
            >
              <i class="fas fa-plus-circle" aria-hidden="true"> </i>Pesquisar
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-8 col-sm-8 col-lg-8 mb-3">
            <div class="br-input">
              <label> Sigla unidade de medida:</label>
              <input
                class="p-inputtext"
                type="text"
                placeholder="Incluir a sigla da unidade de medida"
                [(ngModel)]="siglaUnidadeMedida"
                [disabled]="unidadeTrue"
                readonly
              />
            </div>
          </div>
          <div
            class="col-2 col-sm-2 col-lg-2 mb-3 mt-3"
            style="align-self: center"
          >
            <button
              type="button"
              class="br-button secondary"
              data-toggle="modal"
              data-target="#buscarunidademedidatotal"
              [disabled]="unidadeTrue"
            >
              <i class="fas fa-plus-circle" aria-hidden="true"> </i>Pesquisar
            </button>
          </div>
        </div>
        <div>
          <div>
            <div class="col-8 col-sm-8 col-lg-8 mb-3">
              <label>Capacidade unidade de fornecimento:</label>
            </div>
            <div class="br-input">
              <input
                class="p-inputtext"
                type="number"
                placeholder="Incluir a capacidade da unidade de fornecimento"
                [(ngModel)]="capacidadeUnidadeFornecimento"
                [disabled]="unidadeTrue"
                (keyup)="addcapacidade()"
                (keydown)="addcapacidade()"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-10 col-sm-10 col-lg-10 mb-3"></div>
          <div class="col-2 col-sm-2 col-lg-2 mb-5 mt-5 mx-auto">
            <div>
              <button
                class="br-button primary"
                (click)="adicionarUnidadeFornecimento()"
                [disabled]="saveUnidadeFornecimento"
                data-dismiss="modal"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-selecionar-unidade-medida-form (onUnidadeMedidaSelecionada)="adicionaUnidadeMedida($event)"></app-selecionar-unidade-medida-form>
<app-selecionar-unidade-fornecimento (unidadeFornecimento)="adicionaUnidadeFornecimento($event)"></app-selecionar-unidade-fornecimento>