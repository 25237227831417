<app-cabecalho-interno></app-cabecalho-interno>
<br /><br /><br />
<div class="container-lg">
  <div class="row">
    <div class="col-sm-10" id="cabecalho">
        <h2 class="mb-0" aria-label="Catálogo - Cesta de Itens" (click)="voltaTelaInicial()">
          <span class="mouseover">Área do Catalogador</span>
        </h2>
        <h6 style="font-weight: 400;" class="mb-0 mt-1"> Busque aqui materiais ou serviços</h6>
    </div>
    <div class="col-sm-2 mt-4 text-right" >
    </div>
  </div>
</div>

<div><!-- https://www.primefaces.org/primeng/showcase/#/menu -->
  <button type="button" class="br-button primary" style="height: 40px; width:160px; font-size:18px" id="botaocriar" pButton pRipple icon="pi " label="+ Criar/Editar "
          (click)="menu.toggle($event)" ></button>
  <p-menu #menu [popup]="true" [model]="itemsmenubotao"></p-menu>
</div>
<!-- <br /><br /><br><br /><br />
<hr/>
<br />
<app-busca-component (aoChegarResultado)="resultadobusca($event)"></app-busca-component>
<br />
<br />
<app-resultado [materiaisInput]="resultadoMaterial" [servicosInput]="resultadoServico" (aoRetornarId)="retornaId($event)"></app-resultado> -->




