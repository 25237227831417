<app-cabecalho-interno></app-cabecalho-interno> <br /><br /><br />
<div class="container-lg" *ngIf="dadosBasicosPdm">
  <div class="mb-4 mt-2">
    <!-- cabeçalho -->
    <div class="row">
      <div class="col-8" id="cabecalho">
        <h2 class="mb-0" aria-label="Manter Material">
          <span class="mouseover"
            >Catálogo - Padrão descritivo de material</span
          >
        </h2>
        <h6 style="font-weight: 400" class="mb-0 mt-1">
          Use esta área para editar o padrão descritivo de material
        </h6>
      </div>
      <div class="col-4 text-right row" style="align-content: end">
        <div class="col-6 card border-0">
          <br-confirmation  #BackAlert popupTitle="Deseja voltar a tela incial?" acceptLabel="Sim" rejectLabel="Não"
                      question="Pode haver dados que não foram salvos." (accept)="voltarTelaInicial()" (reject)="BackAlert.hide()">
          </br-confirmation>
          <button
            class="br-button is-secondary"
            type="button"
            (click)="openAlert()"
          >
            Voltar
          </button>
        </div>
        <div class="col-6 card border-0">
          <br-loading [visible]="visible" label="Salvando ..."></br-loading>
          <button class="br-button is-primary" type="button" (click)="confirmCont.show() ">Salvar</button>
        </div>
      </div>
    </div>
    <br-confirmation  #confirmCont popupTitle="Confirme a alteração" acceptLabel="Sim" rejectLabel="Não"
                      question="Deseja realmente alterar este padrão descritivo de material?" (accept)="salvarPDM()">
    </br-confirmation>
    <br-confirmation  #PdmSucess popupTitle="PDM alterado com sucesso!" acceptLabel="Permanecer" rejectLabel="Voltar"
                      question="Código do padrão descritivo de material: {{dadosBasicosPdm.codigoPdm}}" (accept)="permanecerPagina()" (reject)="voltarTelaInicial()">
    </br-confirmation>
  </div>
  <hr />

  <div class="mb-4 row">
    <h5 class="col-8">
      Código do padrão descritivo em edição:
      <span style="color: red">{{ materialParams.id }}</span>
    </h5>
    <div class="row col-3" style="justify-content: flex-end">
      <div class="br-switch">
        <input
          id="ativo"
          type="checkbox"
          [(ngModel)]="dadosBasicosPdm.statusPdm"
          (click)="teste()"
        />
        <label for="ativo">Ativo</label>
      </div>
    </div>
    <div class="col-1" style="align-self: center;">
      <br-confirmation  #BackAlertItem popupTitle="Deseja ir para edição de itens deste material?" acceptLabel="Sim" rejectLabel="Não"
                        question="Pode haver dados que não foram salvos." (accept)="voltarEditarItem()" (reject)="BackAlertItem.hide()">
      </br-confirmation>
      <button
        class="br-button"
        type="button"
        (click)="editarItens()"
        pTooltip="Itens de material"
        tooltipPosition="bottom" 
      >
      <i class="fas fa-box" aria-hidden="true"></i>
      </button>
    </div>
  </div>

  <br-fieldset
    [collapsible]="true"
    fieldsetTitle="Dados base"
    [collapsed]="expandCollapsed"
  >
    <div class="br-input">
      <label> Nome:</label>
      <label class="texto-direita">Caracteres restantes: <span>{{numMaxPDM}}</span></label>
      <input
        #PDM
        maxlength="100"
        class="p-inputtext"
        type="text"
        name="PDM"
        placeholder="Digite o nome"
        [(ngModel)]="dadosBasicosPdm.nomePdm"
        (keyup)="pressEnterPDM($event, PDM.value)"
        disabled="{{!dadosBasicosPdm.statusPdm}}"
      />
    </div>
    <div class="row mt-4">
      <div class="br-input col-10">
        <label> Classe: {{ dadosBasicosPdm.codigoClasse }}</label>
        <input
          class="p-inputtext"
          type="text"
          name="PDM"
          placeholder="Escolha a classe"
          [(ngModel)]="dadosBasicosPdm.nomeClasse"
          readonly
          disabled="{{!dadosBasicosPdm.statusPdm}}"
        />
      </div>
      <div class="col-2" style="align-self: flex-end; text-align: end">
        <button class="br-button secondary" type="button" data-toggle="modal" data-target="#buscarclasse"
          [disabled]="!dadosBasicosPdm.statusPdm">Pesquisar</button>
      </div>
    </div>
    <div class="br-input mt-4">
      <label> Grupo: {{ dadosBasicosPdm.codigoGrupo }}</label>
      <input
        class="p-inputtext"
        type="text"
        name="PDM"
        placeholder="Escolha uma classe para o grupo ser preenchido"
        readonly
        [(ngModel)]="dadosBasicosPdm.nomeGrupo"
        disabled="{{!dadosBasicosPdm.statusPdm}}"
      />
    </div>
    <div class="row mt-4 mb-4">
      <div class="br-input col-10">
        <label> Conjunto material: {{ dadosBasicosPdm.codigoConjunto }}</label>
        <input
          class="p-inputtext"
          type="text"
          name="PDM"
          placeholder="Escolha o conjunto Material"
          readonly
          [(ngModel)]="dadosBasicosPdm.nomeAcentuadoConjunto"
          disabled="{{!dadosBasicosPdm.statusPdm}}"
        />
      </div>
      <div class="col-2" style="align-self: flex-end; text-align: end">
        <button class="br-button secondary" type="button" data-toggle="modal" data-target="#conjuntoMaterial"
        [disabled]="!dadosBasicosPdm.statusPdm">Pesquisar</button>
      </div>
    </div>
  </br-fieldset>
  <br-second-tab-set>
    <br-second-tab
      name="Características"
      [active]="true"
      (activeChange)="carregaArray('caracteristica')"
    >
      <div class="row col-12 mt-4">
        <div class="col-10">
          <h6>Lista de características</h6>
        </div>
        <div class="col-2" style="align-self: flex-end; text-align: end; margin-bottom: 15px;">
          <button class="br-button secondary" type="button" #buscarcaracteristica data-toggle="modal"
          [attr.data-target]="validateCaracteristicas"
          (click)="openModalCaracteristica()" [disabled]="!dadosBasicosPdm.statusPdm"
          >Adicionar
          </button>
        </div>
      </div>

      <p-table
        class="col-12 col-sm-12 col-lg-12"
        [value]="caracteristicasArray"
        [reorderableColumns]="true"
        responsiveLayout="scroll"
        (onRowReorder)="teste()"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="col-1">Ordem</th>
            <th class="col-1">Código</th>
            <th class="col-3">Nome</th>
            <th class="col-1">Ativo</th>
            <th class="col-2">Obrigatório</th>
            <th class="col-3">Ação</th>
            <th class="col-1">Ordenar</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-index="rowIndex">
          <tr [pReorderableRow]="index">
            <td class="col-1">
              <span [ngClass]="dadosBasicosPdm.statusPdm  == false ? 'cnbs-disabled-text-color' : 'cnbs-enabled-text-color'">{{ index + 1 }}</span>
            </td>
            <td class="col-1">
              <span [ngClass]="dadosBasicosPdm.statusPdm  == false ? 'cnbs-disabled-text-color' : item.statusCaracteristica == false ? 'cnbs-disabled-text-color' : 'cnbs-enabled-text-color'" >{{ item.codigoCaracteristica }}</span>
            </td>
            <td class="col-3">
              <span [ngClass]="dadosBasicosPdm.statusPdm  == false ? 'cnbs-disabled-text-color' : item.statusCaracteristica == false ? 'cnbs-disabled-text-color' : 'cnbs-enabled-text-color'" >{{ item.nomeCaracteristica }}</span>
            </td>
            <td class="col-1">
              <br-switch [(ngModel)]="item.statusCaracteristica" (click)="teste()"
              [disabled]="!dadosBasicosPdm.statusPdm"></br-switch>
            </td>
            <td class="col-2">
              <br-switch
                [disabled]="!item.statusCaracteristica || !dadosBasicosPdm.statusPdm"
                (click)="teste()"
                [(ngModel)]="item.caracteristicaObrigatoria"
              ></br-switch>
            </td>
            <td class="col-3">
              <div class="row">

                <button
                  [disabled]="!item.statusCaracteristica || !dadosBasicosPdm.statusPdm"
                  class="br-button"
                  type="button"
                  pTooltip="Valores"
                  tooltipPosition="top"
                  (click)="buscaValorCaracteristica(index)"
                  data-toggle="modal"
                  data-target="#editarvalorescaracteristicas"
                >
                  <i class="fas fa-calculator" aria-hidden="true"></i>
                </button>

                <button
                  [disabled]="!item.statusCaracteristica || !dadosBasicosPdm.statusPdm"
                  class="br-button"
                  type="button"
                  pTooltip="Unidade de medida"
                  tooltipPosition="top"
                  (click)="buscaUnidadeMedida(index)"
                  data-toggle="modal"
                  data-target="#editarunidademedida"
                >
                  <i class="fas fa-ruler-combined" aria-hidden="true"></i>
                </button>
              </div>
            </td>
            <td class="col-1">
              <div *ngIf="dadosBasicosPdm.statusPdm">
                <span
                  class="pi pi-bars"
                  [pReorderableRowHandle]="rowIndex"
                ></span>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </br-second-tab>
    <br-second-tab
      name="Alias/ Palavra-chave"
      (activeChange)="carregaArray('alias')"
    >
      <div class="row mt-4 mb-4">
        <div class="br-input col-sm-10">
          <label> Alias/ Palavra-chave:</label>
          <label class="texto-direita">Caracteres restantes: <span>{{numMaxAlias}}</span></label>
          <input
            #Alias
            class="p-inputtext"
            type="text"
            placeholder="Digite uma palavra para adicionar"
            [(ngModel)]="alias"
            (keyup)="pressEnterAlias($event, Alias.value)"
            maxlength="25"
            [disabled]="!dadosBasicosPdm.statusPdm"
          />
        </div>
        <div class="col-2" style="align-self: flex-end; text-align: end">
          <button
            class="br-button secondary"
            type="button"
            (click)="adicionaListAlias()"
            [disabled]="!dadosBasicosPdm.statusPdm"
          >
            Adicionar
          </button>
        </div>
      </div>
      <div *ngFor="let alias of aliasArray; let i = index" class="row">
        <div class="col-2"></div>
        <label class="col-5" [ngClass]="dadosBasicosPdm.statusPdm  == false ? 'cnbs-disabled-text-color' : 'cnbs-enabled-text-color'">{{ alias.nomeAliasPdmMaterial }}</label>
        <p-confirmDialog
          header="Confirmar exclusão"
          icon="far fa-question-circle"
          key="{{ alias.nomeAliasPdmMaterial }}"
        ></p-confirmDialog>
        <button
          class="br-button col-sm-2"
          type="button"
          (click)="removeListAlias(alias.nomeAliasPdmMaterial)"
          [disabled]="!dadosBasicosPdm.statusPdm"
        >
          Excluir
        </button>
      </div>
    </br-second-tab>
    <br-second-tab
      name="Natureza da despesa"
      (activeChange)="carregaArray('natureza')"
    >
      <div class="row mb-4 mt-4">
        <div class="br-input col-sm-10">
          <label>Natureza da despesa:</label>
          <p-inputMask
            mask="999999/99"
            placeholder="Digite uma natureza da despesa para adicionar"
            [(ngModel)]="naturezaDespesa"
            (keyup)="pressEnterNatureza($event)"
            [disabled]="!dadosBasicosPdm.statusPdm"
          ></p-inputMask>
        </div>
        <div class="col-2" style="align-self: flex-end; text-align: end">
          <button
            class="br-button secondary"
            type="button"
            (click)="adicionaListNatureza()"
            [disabled]="!dadosBasicosPdm.statusPdm"
          >
            Adicionar
          </button>
        </div>
      </div>
      <p-table
        class="col-6"
        [value]="naturezaDespesaArray"
        responsiveLayout="scroll"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="col-3"></th>
            <th class="col-4">Natureza da despesa</th>
            <th class="col-3">Ativo</th>
            <th class="col-1"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td class="col-3"></td>
            <td class="col-4">
              <span [ngClass]="dadosBasicosPdm.statusPdm  == false ? 'cnbs-disabled-text-color' : item.statusClassificacaoContabil == false ? 'cnbs-disabled-text-color' : 'cnbs-enabled-text-color'">
                {{ item.classificacaoContabilMaterial }}</span>
            </td>
            <td class="col-3">
              <br-switch
                (click)="teste()"
                [(ngModel)]="item.statusClassificacaoContabil"
                [disabled]="!dadosBasicosPdm.statusPdm"
              ></br-switch>
            </td>
            <td class="col-1"></td>
          </tr>
        </ng-template>
      </p-table>
    </br-second-tab>
    <br-second-tab
      name="Unidade de fornecimento"
      (activeChange)="carregaArray('fornecimento')"
    >
      <div class="col-12 row mt-4">
        <h6 class="col-10">Lista de unidade de fornecimento</h6>
        <div class="col-2" style="align-self: flex-end; text-align: end; margin-bottom: 15px;">
          <button class="br-button secondary" type="button" [attr.data-target]="validateUnidadeFornecimento" data-toggle="modal"
          [disabled]="!dadosBasicosPdm.statusPdm" (click)="verificaFornecimento()" >Adicionar</button>
        </div>
      </div>
      <p-table
        class="col-12 col-sm-12 col-lg-12"
        responsiveLayout="scroll"
        [rows]="10"
        [paginator]="true"
        [rowHover]="true"
        dataKey="id"
        [value]="unidadeFornecimentoArray"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="col-1">Ordem</th>
            <th pSortableColumn="siglaUnidadeFornecimento" class="col-3" style="text-align: center">
              Sigla unidade de fornecimento
              <p-sortIcon field="siglaUnidadeFornecimento"></p-sortIcon>
            </th>
            <th pSortableColumn="capacidadeUnidadeFornecimento" class="col-4" style="text-align: center">
              Capacidade unidade de fornecimento
              <p-sortIcon field="capacidadeUnidadeFornecimento"></p-sortIcon>
            </th>
            <th pSortableColumn="siglaUnidadeMedida" class="col-3" style="text-align: center">
              Sigla unidade de medida
              <p-sortIcon field="siglaUnidadeMedida"></p-sortIcon>
            </th>
            <th class="col-1">Ativo</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-index="rowIndex">
          <tr>
            <td class="col-1">
              <span [ngClass]="dadosBasicosPdm.statusPdm  == false ? 'cnbs-disabled-text-color' : 'cnbs-enabled-text-color'">{{ index + 1 }}</span>
            </td>
            <td class="col-3" style="text-align: center">
              <span [ngClass]="dadosBasicosPdm.statusPdm  == false ? 'cnbs-disabled-text-color' : item.statusUnidadeFornecimentoPdm == false ? 'cnbs-disabled-text-color' : 'cnbs-enabled-text-color'">
                {{ item.siglaUnidadeFornecimento }} | {{ item.nomeUnidadeFornecimento }}</span>
            </td>
            <td class="col-4" style="text-align: center">
              <span [ngClass]="dadosBasicosPdm.statusPdm  == false ? 'cnbs-disabled-text-color' : item.statusUnidadeFornecimentoPdm == false ? 'cnbs-disabled-text-color' : 'cnbs-enabled-text-color'">
                {{
                  item.capacidadeUnidadeFornecimento
                    ? item.capacidadeUnidadeFornecimento
                    : "--"
                }}
              </span>
            </td>
            <td class="col-3" style="text-align: center">
              <span *ngIf="item.siglaUnidadeMedida" [ngClass]="dadosBasicosPdm.statusPdm  == false ? 'cnbs-disabled-text-color' : item.statusUnidadeFornecimentoPdm == false ? 'cnbs-disabled-text-color' : 'cnbs-enabled-text-color'">
                {{ item.siglaUnidadeMedida }} | {{ item.nomeUnidadeMedida }}</span>
                <span *ngIf="!item.siglaUnidadeMedida" [ngClass]="dadosBasicosPdm.statusPdm  == false ? 'cnbs-disabled-text-color' : item.statusUnidadeFornecimentoPdm == false ? 'cnbs-disabled-text-color' : 'cnbs-enabled-text-color'">
                  -- </span>
            </td>
            <td class="col-1">
              <br-switch
                (click)="teste()"
                [(ngModel)]="item.statusUnidadeFornecimentoPdm"
                [disabled]="!dadosBasicosPdm.statusPdm"
              ></br-switch>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </br-second-tab>
  </br-second-tab-set>
  <div style="margin-bottom: 100px"></div>

  <!--Modals-->

  <app-selecionar-classe
    (onClasseSelecionada)="escolherClasse($event)"></app-selecionar-classe>

  <app-selecionar-caracteristica
    (onCaracteristicaSelecionada)="escolherCaracteristica($event)">
  </app-selecionar-caracteristica>

  <app-selecionar-valores-caracteristica
    (onSalvarValorCaracteristica)="escolherValorCaracteristica($event)"
    [caracteristicasEmEdicao]="{codigoCaracteristica: caracteristicasArray[caracteristicaEscolhidaEmEdicao]?.codigoCaracteristica, nomeCaracteristica: caracteristicasArray[caracteristicaEscolhidaEmEdicao]?.nomeCaracteristica}">
  </app-selecionar-valores-caracteristica>

  <app-selecionar-unidade-medida
    (onUnidadeMedidaSelecionada)="escolherUnidadeMedida($event)"
    [unidadeMedidaEmEdicao]="{codigoCaracteristica: caracteristicasArray[caracteristicaEscolhidaEmEdicao]?.codigoCaracteristica}">
  </app-selecionar-unidade-medida>

  <app-selecionar-conjunto
    (conjuntoMaterial)="escolherConjunto($event)"
    [classeEscolhida]="dadosBasicosPdm.codigoClasse"
  ></app-selecionar-conjunto>

  <app-adicionar-unidade-fornecimento
    (resultado)="adicionarUnidadeFornecimento($event)"
  ></app-adicionar-unidade-fornecimento>

  <div
    class="modal fade"
    id="editarvalorescaracteristicas"
    style="max-height: 90%"
  >
    <div class="modal-dialog modal-lg modal-cpc modal-dialog-centered ">
      <div class="modal-content">
        <div class="container-lg">
          <div class="modal-header">
            <h4 class="modal-title">Valores para característica</h4>
<!--            <button type="button" class="close" data-dismiss="modal">-->
<!--              &times;-->
<!--            </button>-->
          </div>
          <div class="modal-body">
            <div class="row mb-3 mt-3">
              <div class="col-sm-5 col-lg-5">
                <div>
                  Nome:
                  {{
                    caracteristicasArray[caracteristicaEscolhidaEmEdicao]
                      ?.nomeCaracteristica
                  }}
                </div>
                <div>
                  Código:
                  {{
                    caracteristicasArray[caracteristicaEscolhidaEmEdicao]
                      ?.codigoCaracteristica
                  }}
                </div>
              </div>
              <div class="col-3">
                <label *ngIf="validaValorCaracteristica == false" style="color: red; text-align: center; font-size: 10px;">
                  Número máximo de valores de características atingido.
                </label>
              </div>
              <div class="col-sm-4 col-lg-4">
                <button
                  #buscarvalorescaracteristica
                  type="button"
                  class="br-button secondary"
                  data-toggle="modal"
                  [attr.data-target]="validateValoresCaracteristicas"
                  (click)="openModalbuscarvalorcaracteristica()"
                >
                  <i class="fas fa-plus-circle" aria-hidden="true"> </i
                  >Adicionar
                </button>
                &nbsp;
                <button
                  class="br-button primary"
                  type="button"
                  data-dismiss="modal"
                >
                  <i class="fas fa-arrow-left" aria-hidden="true"> </i>Fechar
                </button>
              </div>
            </div>
            <p-table
              [rowHover]="true"
              dataKey="id"
              responsiveLayout="scroll"
              [value]="valorCaracteristicaArray"
              [scrollable]="true"
              scrollHeight="300px"
            >
              <ng-template pTemplate="header" class="row">
                <tr>
                  <th pSortableColumn="codigoValorCaracteristica" class="col-sm-1 col-lg-1">
                    Código
                    <p-sortIcon field="codigoValorCaracteristica"></p-sortIcon>
                  </th>
                  <th pSortableColumn="nomeValorCaracteristica" class="col-sm-2 col-lg-2">
                    Valor de característica
                    <p-sortIcon field="nomeValorCaracteristica"></p-sortIcon>
                  </th>
                  <th pSortableColumn="statusValorCaracteristica" class="col-sm-2 col-lg-2">
                    Ativo
                    <p-sortIcon field="statusValorCaracteristica"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-valor class="row">
                <tr>
                  <td class="col-sm-1 col-lg-1">
                    <span *ngIf="valor.statusValorCaracteristica  == true" style="color: black;">{{ valor.codigoValorCaracteristica }}</span>
                    <span *ngIf="valor.statusValorCaracteristica  == false" style="color: #9f9a9a;">{{ valor.codigoValorCaracteristica }}</span>
                  </td>
                  <td class="col-sm-2 col-lg-2">
                    <span *ngIf="valor.statusValorCaracteristica  == true" style="color: black;">{{ valor.nomeValorCaracteristica }}</span>
                    <span *ngIf="valor.statusValorCaracteristica  == false" style="color: #9f9a9a;">{{ valor.nomeValorCaracteristica }}</span>
                  </td>
                  <td class="col-2">
                    <br-switch
                      (click)="teste()"
                      [(ngModel)]="valor.statusValorCaracteristica"
                    ></br-switch>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
        <!--
        <div class="modal-footer">
          <div class="col-sm-2 col-lg-2">
            <button
              class="br-button primary"
              type="button"
              data-dismiss="modal"
            >
              <i class="fas fa-arrow-left" aria-hidden="true"> </i>Fechar
            </button>
          </div>
        </div>
        -->
      </div>
    </div>
  </div>

  <div class="modal fade" id="editarunidademedida" style="max-height: 90%">
    <div class="modal-dialog modal-lg modal-cpc modal-dialog-centered">
      <div class="modal-content">
        <div class="container-lg">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Unidades de medida</h4>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row mb-3 mt-3">
              <div class="col-6">
                <div>
                  Nome:
                  {{ caracteristicasArray[caracteristicaEscolhidaEmEdicao]?.nomeCaracteristica }}
                </div>
                <div>
                  Código:
                  {{ caracteristicasArray[caracteristicaEscolhidaEmEdicao]?.codigoCaracteristica }}
                </div>
              </div>
              <div class="col-3">
                <label *ngIf="validaUnidadeMedida == false" style="color: red; text-align: center; font-size: 10px;">
                  Número máximo de unidades de medida atingido.
                </label>
              </div>
              <div class="col-3 mb-3">
                <button
                  #buscarunidademedida
                  type="button"
                  class="br-button secondary"
                  data-toggle="modal"
                  [attr.data-target]="validateUnidadeMedida"
                  (click)="openModalbuscarunidademedida()"
                >
                  <i class="fas fa-plus-circle" aria-hidden="true"> </i
                  >Adicionar
                </button>
              </div>
            </div>
            <p-table
              [value]="unidadeMedidaArray"
              [scrollable]="true"
              scrollHeight="300px"
            >
              <ng-template pTemplate="header" class="row">
                <tr>
                  <th class="col-3">Sigla</th>
                  <th class="col-4">Nome</th>
                  <th class="col-3">Ativo</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-unidade class="row">
                <tr>
                  <td class="col-3">
                    <span *ngIf="unidade.statusUnidadeMedidaCaracteristica  == true" style="color: black;">{{ unidade.siglaUnidadeMedida }}</span>
                    <span *ngIf="unidade.statusUnidadeMedidaCaracteristica  == false" style="color: #9f9a9a;">{{ unidade.siglaUnidadeMedida }}</span>
                  </td>
                  <td class="col-4">
                    <span *ngIf="unidade.statusUnidadeMedidaCaracteristica  == true" style="color: black;">{{ unidade.nomeUnidadeMedida }}</span>
                    <span *ngIf="unidade.statusUnidadeMedidaCaracteristica  == false" style="color: #9f9a9a;">{{ unidade.nomeUnidadeMedida }}</span>
                  </td>
                  <td class="col-3">
                    <br-switch
                      (click)="teste()"
                      [(ngModel)]="unidade.statusUnidadeMedidaCaracteristica"
                    ></br-switch>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="br-button primary" data-dismiss="modal">
            <i class="fas fa-arrow-left" aria-hidden="true"> </i>Fechar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
