import { ToastModule } from 'primeng/toast';
import { Component, OnInit, ViewChild} from '@angular/core';
import {BuscaService} from '../../../service/busca-service';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-visualizar-material',
  templateUrl: './visualizar-material.component.html',
  styleUrls: ['./visualizar-material.component.css'],
  providers: [ConfirmationService]
})
export class VisualizarMaterialComponent implements OnInit {
  constructor(private buscaService: BuscaService, private modalService: BsModalService,
    private router: Router, private messageService: MessageService,
    private confirmationService: ConfirmationService, private route: ActivatedRoute) {
  }

  materialParams: any;
  visible: boolean = false;
  expandCollapsed: boolean = false;
  rowIndex: number;
  caracteristicaEscolhidaEmEdicao: any;

  dadosBasicosPdm: any;
  caracteristicasArray: any = [];
  caracteristicasArrayTela: any = [];
  caracteristicasAtivasArray: any = [];
  aliasArray: any;
  naturezaDespesaArray: any = [];
  unidadeFornecimentoArray: any;
  valorCaracteristicaArray: any;
  unidadeMedidaArray: any;

  naturezaDespesa: string = "";

  ativos: boolean = false;

  //Carrega os Arrays iniciais

  ngOnInit(): void {
    this.route.params.subscribe(
    id => {
       this.materialParams = id;
  }
  );
    this.buscaService.obterDadosBasicosPDMPorCodigo(this.materialParams.id).subscribe(resp => this.dadosBasicosPdm = resp);
    this.buscaService.obterDadosCaracteristicaPDMPorCodigo(this.materialParams.id).subscribe(
      resp => {
        this.caracteristicasArray = resp;
        this.caracteristicasArrayTela = resp;
        for(let i of this.caracteristicasArray){
          if(i.statusCaracteristica == true){
            this.caracteristicasAtivasArray.push(i);
          }
        }
      });
  }

  carregaArray(valor: string){
    if(this.aliasArray == null){
      this.buscaService.obterDadosAliasPDMPorCodigo(this.dadosBasicosPdm.codigoPdm).subscribe(resp => this.aliasArray = resp);
    }

    if(this.naturezaDespesaArray.length == 0){
      this.buscaService.obterDadosNaturezaDespesaPDMPorCodigo(this.dadosBasicosPdm.codigoPdm).subscribe(resp => {
      let aux = "";
      this.naturezaDespesaArray = [];
      for (let i of resp){
          aux = i.classificacaoContabilMaterial.toString().substring(0,6) + "/" + i.classificacaoContabilMaterial.toString().substring(6);
          this.naturezaDespesaArray.push({classificacaoContabilMaterial: aux, statusClassificacaoContabil: i.statusClassificacaoContabil});
          }
      });
    }

    if(this.unidadeFornecimentoArray == null){
      this.buscaService.obterDadosUnidadeFornecimentoPDMPorCodigo(this.dadosBasicosPdm.codigoPdm)
      .subscribe(resp => {
        for(let i of resp){
          if(i?.capacidadeUnidadeFornecimento !== 0){
            let capacidade = i.capacidadeUnidadeFornecimento.toString().split(".");
            if(capacidade.length > 1){
              if(capacidade[1].length == 1){
                i.capacidadeUnidadeFornecimento = i.capacidadeUnidadeFornecimento + "0";
              }
            } else{
              i.capacidadeUnidadeFornecimento = i.capacidadeUnidadeFornecimento + ",00";
            }
            i.capacidadeUnidadeFornecimento = i.capacidadeUnidadeFornecimento.toString().replace(".",",");
          }
        }
        this.unidadeFornecimentoArray = resp;
      });
    }
  }

  //Navegação

  voltarTelaInicial(): void {
    this.router.navigate(['/buscamaterial']);
  }

  editarMaterial(){
    this.router.navigate(['/mantermaterial', this.materialParams.id]);
  }

  //Caracateristica

  BuscaValorCaracteristica(codigo: any){
    this.caracteristicaEscolhidaEmEdicao = codigo;
    this.buscaService.obterDadosValorCaracteristicaPDMPorCodigo(this.dadosBasicosPdm.codigoPdm, this.caracteristicasArray[codigo].codigoCaracteristica).subscribe(resp => this.valorCaracteristicaArray = resp);
  }

  BuscaUnidadeMedida(codigo: any){
    this.caracteristicaEscolhidaEmEdicao = codigo;
    this.buscaService.obterDadosUnidadeMedidaCaracteristicaPDMPorCodigo(this.dadosBasicosPdm.codigoPdm, this.caracteristicasArray[codigo].codigoCaracteristica).subscribe(resp => this.unidadeMedidaArray = resp);
  }

  teste(){
    console.log(this.caracteristicasArray)
    console.log(this.aliasArray)
    console.log(this.dadosBasicosPdm)
    console.log(this.naturezaDespesaArray)
    console.log(this.unidadeFornecimentoArray)
  }

  somenteAtivos(){
    if(this.ativos == false){
      this.caracteristicasArrayTela = this.caracteristicasAtivasArray;
    } else {
      this.caracteristicasArrayTela = this.caracteristicasArray;
    }
  }
}




