<app-cabecalho-interno></app-cabecalho-interno>
<br /><br /><br />
<div class="container-lg">

  <div class="row">
    <div class="br-breadcrumb col-9">
      <ul class="crumb-list">
        <li class="crumb home">
          <a title="Área de Trabalho" (click)="irParaAT()">
            <span class="sr-only">Página inicial</span>
            <i class="icon fas fa-home"></i>
          </a>
        </li>
        <li class="crumb" data-active="active">
          <i class="icon fas fa-chevron-right"></i>
          <span>Pedidos legados</span>
        </li>
      </ul>
    </div>
  </div>


  <div class="br-divider"></div>
  <div class="mb-3 pt-0 mt-0"><!-- cabeçalho -->
    <div class="row">
      <div class="col-9" id="cabecalho">
        <h3 class="pb-0 mt-2 mb-2">Pedidos legados</h3>
        <p>Esta área é apenas para visualização dos pedidos da base legada.</p>
      </div>

      <div><!-- https://www.primefaces.org/primeng/showcase/#/menu -->
        <!-- <button type="button" class="br-button primary mt-3 ml-5" style="height: 40px; width:158px; font-size:18px" id="botaocriar" pButton pRipple icon="pi " label="+ Criar/Editar "
                (click)="menu.toggle($event)" ></button>
        <p-menu #menu [popup]="true" [model]="itemsmenubotao"></p-menu> -->
      </div>

    </div>
  </div>

  <div class="painel-pesquisa">
    <div class="row negrito mt-2">
      <div class="col-6">
        <span>Pesquisa de Pedido ( Número ou Termo ou Uasg</span>
        <span *ngIf="!abaNaoDistribuido"> ou Catalogador</span> )
      </div>
    </div>
    <div class="row">
      <div class="br-input pl-2 col-11 label">
        <input id="filtro-item" class="p-inputtext" [(ngModel)]="filtroItem" placeholder="Pesquise pelo número ou termo ou uasg"
          field="filtroItem" />
      </div>
      <div class="col-1">
        <button class="br-button circle small text-overflow-descricao" (click)="abrirModalFiltroPedido()" type="button"
          aria-label="Filtrar por data" tooltip="Filtrar por data" placement="top" id="bt-tooltip">
          <i class="fas fa-filter pr-1" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>

  <ng-template #modalFiltroPedido>
    <p-toast position="top-center">
      <ng-template let-message pTemplate="message">
          <div class="row" style="flex: 1">
              <div class="col-12">
                  <i class="fas fa-exclamation-triangle fa-lg mr-2" aria-hidden="true"></i>
                  <strong class="mr-1">{{message.summary}}</strong>
                  <span>{{message.detail}}</span>
              </div>
          </div>
      </ng-template>
    </p-toast>
    <div class="br-divider"></div>
    <div class="mb-3 pt-0 mt-0"><!-- cabeçalho -->
      <div class="row">
        <div class="col-11" id="cabecalho">
          <h4 class="pb-0 mt-2 mb-2 ml-2 negrito">Filtrar Pesquisas</h4>
        </div>
        <div class="col-1">
         <button type="button" class="close mr-3 azul" data-dismiss="modal"
         (click)="fechaModalFiltro()">x</button>
        </div>
        <hr>
        <div class="col-5 negrito ml-2">
          Início
        </div>
        <div class="col-6 negrito">
          Fim
        </div>
      </div>
      <div class="row">
        <div class="br-input ml-2 col-5">
          <input id="filtro-data-ini" class="p-inputtext" type="date" [(ngModel)]="filtroDataIni" placeholder="data inicial"
            field="filtroDataIni" />
        </div>
        <div class="br-input pl-2 col-6">
          <input id="filtro-data-fim" class="p-inputtext" type="date" [(ngModel)]="filtroDataFim" placeholder="data final"
            field="filtroDataFim" />
        </div>
      </div>
      <div class="row">
        <div class="row col-8"></div>
        <div class="col-2" style="text-align: end; align-self: center">
          <button class="br-button is-secondary mt-2" type="button" (click)="limparFiltrosDatas()">
            Limpar
          </button>
        </div>
        <div class="col-2" style="text-align: end; align-self: center">
          <button class="br-button is-primary mt-2" type="button" (click)="aplicarFiltrosDatas(); fechaModalFiltro()">
            Aplicar
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <br-second-tab-set>
    <br-second-tab name="Não Distribuído" [active]="true" (activeChange)="$event ? ativarAba(NAO_DISTRIBUIDO) : ''">
      <app-tabela-pedidos #listaNaoDistribuido [aba]="NAO_DISTRIBUIDO"
        [listaStateSession]="'lista-nao-distribuido'" [dataFim]="dataFim" [dataInicio]="dataInicio" [textoPesquisa]="filtroItem"></app-tabela-pedidos>
    </br-second-tab>
    <br-second-tab name="Pendente" (activeChange)="$event ? ativarAba(PENDENTE) : ''">
      <app-tabela-pedidos #listaPendente [aba]="PENDENTE" [listaStateSession]="'lista-pendente'" [dataFim]="dataFim" [dataInicio]="dataInicio" [textoPesquisa]="filtroItem"></app-tabela-pedidos>
    </br-second-tab>
    <br-second-tab name="Devolvido" (activeChange)="$event ? ativarAba(DEVOLVIDO) : ''">
      <app-tabela-pedidos #listaDevolvido [aba]="DEVOLVIDO"
        [listaStateSession]="'lista-devolvido'" [dataFim]="dataFim" [dataInicio]="dataInicio" [textoPesquisa]="filtroItem"></app-tabela-pedidos>
    </br-second-tab>
    <br-second-tab name="Aceito" (activeChange)="$event ? ativarAba(ACEITO) : ''">
      <app-tabela-pedidos #listaAceito [aba]="ACEITO" [listaStateSession]="'lista-aceito'" [dataFim]="dataFim" [dataInicio]="dataInicio" [textoPesquisa]="filtroItem"></app-tabela-pedidos>
    </br-second-tab>
  </br-second-tab-set>
</div>
