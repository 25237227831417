<app-cabecalho-interno></app-cabecalho-interno>
<br /><br /><br />
<div class="container-lg">
  <div class="mb-4 mt-2" ><!-- cabeçalho -->
    <div class="row">
      <div class="col-8" id="cabecalho">
        <h2 class="mb-0" aria-label="Manter Material">
          <span class="mouseover">Catálogo - Característica</span>
        </h2>
        <h6 style="font-weight: 400;" class="mb-0 mt-1"> Use esta área para criar/editar Característica</h6>
      </div>
      <div class="col-4 text-right row" style="align-content: end;">
        <div class=" col-6 card border-0">
          <button class="br-button is-secondary"  type="button" (click)="voltarTelaInicial()">Voltar</button>
        </div>
        <div class="col-6 card border-0">
          <br-loading [visible]="visible" label="Salvando ..."></br-loading>
          <button class="br-button is-primary" type="button" data-toggle="modal" (click)="abrirCriar()" data-target="#incluirCaracteristica" >Incluir </button>
        </div>
      </div>
    </div>
  </div>

  <hr />

  <br-fieldset [collapsible]="true" fieldsetTitle="Pesquisar característica" [collapsed]="expandCollapsed">
    <div class="row" >
      <div class="col-md-10 mt-2 mb-2">
        <div class="br-input label">
          <input   [(ngModel)]="textoBusca" class="p-inputtext" placeholder="Digite aqui uma palavra para pesquisar " field="nome"
                  (keyup)="enterPesquisar($event)"/>
        </div>
      </div>
      <div class="col-md-2 mt-3 card border-0">
        <button (click)="pesquisar()" class="br-button primary  justify-self-end " type="button">Pesquisar</button>
      </div>
    </div>
  </br-fieldset>

  <hr />

  <div *ngIf="escondeValor != true" >
    <p-table [value]="listaCaracteristicaTela" [rows]="10" [paginator]="true" responsiveLayout="scroll"
             [rowHover]="true" dataKey="id"
             [rowsPerPageOptions]="[5, 10, 25, 50, 100]"
             currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} entradas" [showCurrentPageReport]="true">
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between row">
          <h5 class="ml-3 mt-0">Características</h5>

          <div class="br-checkbox">
            <input id="somenteAtivos" name="somente-ativos" type="checkbox" aria-label="somente ativos" (click)="somenteAtivos()" [(ngModel)]="ativos"/>
            <label for="somenteAtivos">Mostrar somente características ativas</label>
          </div>

          <div class="br-input col-5 mb-3"><span class="mt-5"> </span>
            <input [(ngModel)]="filtroCaracteristica" (keyup)="filtrarCaracteristica()" class="p-inputtext" placeholder="Digite uma palavra para filtrar o resultado "/>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="codigoCaracteristica">Código  <p-sortIcon field="codigoCaracteristica"></p-sortIcon></th>
          <th pSortableColumn="nomeCaracteristica">Nome Característica<p-sortIcon field="nomeCaracteristica"></p-sortIcon></th>
          <th pSortableColumn="statusCaracteristica">Ativo <p-sortIcon field="statusCaracteristica"></p-sortIcon></th>
          <th>Ações</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-caracteristica>
        <tr>
         <!--  caracteristica ativa        -->
            <td >
              <span *ngIf="caracteristica.statusCaracteristica == true" style="color: black;" >{{caracteristica.codigoCaracteristica}}</span>
              <span *ngIf="caracteristica.statusCaracteristica == false" style="color: #9f9a9a" >{{caracteristica.codigoCaracteristica}}</span>
            </td>
            <td >
              <span *ngIf="caracteristica.statusCaracteristica == true" style="color: black;" >{{caracteristica.nomeCaracteristica}}</span>
              <span *ngIf="caracteristica.statusCaracteristica == false" style="color: #9f9a9a" >{{caracteristica.nomeCaracteristica}}</span>
            </td>
            <td >
              <span *ngIf="caracteristica.statusCaracteristica == true" style="color: black;" >{{caracteristica.statusCaracteristica ? "Sim" : "Não"}}</span>
              <span *ngIf="caracteristica.statusCaracteristica == false" style="color: #9f9a9a" >{{caracteristica.statusCaracteristica ? "Sim" : "Não"}}</span>
            </td>

          <td>
            <button class="br-button" type="button" data-toggle="modal"
                    data-target="#visualizarCaracteristica"
                    aria-label="Botão de ação"
                    pTooltip="Visualizar"
                    (click)="pegarDados('visualizar', caracteristica)"
                    tooltipPosition="top">
              <i class="fas fa-eye" aria-hidden="true" ></i>
            </button>
            <button class="br-button"
                    type="button"
                    data-toggle="modal"
                    data-target="#editarCaracteristica"
                    pTooltip="Editar"
                    (click)="pegarDados('editar',caracteristica)"
                    tooltipPosition="top">
              <i class="fas fa-edit" aria-hidden="true" ></i>
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>



  <div id="incluirCaracteristica" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="Classe" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-media">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Incluir característica</h4>
          <button type="button" class="close" data-dismiss="modal">x</button>
        </div>
        <div class="container-lg">
          <div class="row mt-5 ">
            <label *ngIf="!existeNome"  class="col-8" >Nome da característica<span class="asterisco">*</span></label>
            <label *ngIf="existeNome"  class="col-4" >Nome da característica<span class="asterisco">*</span></label>
            <label *ngIf="existeNome"  class="col-4" style="color: red;">Nome já cadastrado.</label>
            <label class="col-4 texto-menor" style="text-align: end; ">Caracteres restantes: <span>{{numMaxNome}}</span></label>
          </div>
          <div class="br-input  ">
            <input #campoNome
              (blur)="validaNome(campoNome)"
              [(ngModel)]="incluirNome"  (keydown)="enableSave()" (keyup)="decrementaTexto('nome')"
              placeholder="Digite o nome da característica"
              class="p-inputtext" maxlength="40"   />
          </div>
          <div class="row mt-5 ">
            <label class="col-8">Descrição da característica</label>
            <label class="col-4 texto-menor" style="text-align: end;">Caracteres restantes: <span>{{numMaxDescricao}}</span></label>
          </div>
          <div  class="br-textarea  ">
            <textarea
              [(ngModel)]="incluirDetalhe" placeholder="Descrição  da característica"  rows="4" maxlength="200"
              (keydown)="enableSave()"
              (keyup)="decrementaTexto('descricao')"
              ></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <div><button class="br-button secondary" data-dismiss="modal">Voltar</button></div>
          <div>
            <button [disabled]="disableSave" (click)="salvarCaracteristica()"
                    class="br-button primary" data-dismiss="modal"
            >Salvar</button>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div id="editarCaracteristica" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="Classe" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-media">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Editar Característica</h4>
          <button type="button" class="close" data-dismiss="modal">
           X
          </button>
        </div>
        <div class="container-lg">
          <div class="row">
            <div class="br-input mt-3 col-md-8">
              <label>Código</label>
              <input [(ngModel)]="editarCodigo" disabled/>
            </div>
            <div class="col-md-4 align-self-end" style="text-align: end;">
              <div class="br-switch medium switch-basic" style="margin-left: 10px;">
                <input   [(ngModel)]="editarStatus" id="status" type="checkbox" class="p-inputtext"/>
                <label  for="status" >Ativo</label>
              </div>
            </div>
          </div>
          <div class="br-input mt-3">
            <label>Nome da caracteristica</label>
            <input
              [(ngModel)]="editarNome"
              class="p-inputtext"  disabled/>
          </div>

          <div class="mt-3 row">
            <label class="col-8">Descrição da característica</label>
            <label class="col-4 texto-menor" style="text-align: end;">Caracteres restantes: <span>{{numMaxDescricao}}</span></label>
          </div>
          <div class="br-textarea mb-5 ">
            <textarea
              [(ngModel)]="editarDetalhe" (keyup)="decrementaTexto('descricaoeditar')"
              placeholder="Descrição  da característica"></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <div>
            <button class="br-button secondary" data-dismiss="modal">Fechar</button>
          </div>
          <div>
            <button class="br-button primary" (click)="atualizarCaracteristica()" data-dismiss="modal">Salvar</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="visualizarCaracteristica" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="Classe" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-media">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Visualizar  característica</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="container-lg">
          <div class="row">
            <div class="br-input mt-3 col-md-8">
              <label>Código da Característica</label>
              <input [(ngModel)]="visualizaCodigo" readonly class="p-inputtext" />
            </div>
            <div class="col-md-4 align-self-end" style="text-align: end;">
              <div class="br-switch medium switch-basic" style="margin-left: 10px;">
                <input type="checkbox" [(ngModel)]="visualizaStatus" readonly/>
                <label >Ativo</label>
              </div>
            </div>
          </div>
          <div class="br-input mt-3">
            <label>Nome da Característica</label>
            <input [(ngModel)]="visualizaNome" readonly/>
          </div>

          <div class="br-textarea mt-3 mb-5">
            <label>Descrição da Característica</label>
            <textarea [(ngModel)]="visualizaDetalhe" placeholder="Descrição  da característica" readonly></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <div>
            <button class="br-button secondary" data-dismiss="modal">Voltar</button>
          </div>
        </div>
      </div>
    </div>
  </div>


  <ng-template #caracteristicaSucessoCriar>
    <div>
      <div class="modal-header">
        <h4 class="modal-title">Característica incluída com sucesso</h4>
<!--        <button type="button" class="close" (click)="modalRef1.hide()">-->
<!--          X-->
<!--        </button>-->
        <button type="button" class="close" (click)="sairModalSalvar()">
          X
        </button>
      </div>
      <div class="container-lg">
        <div class="flex justify-content-center mb-5 mt-5" style="text-align: center">
          <label>Característica incluída com sucesso.</label><br />
          <label>O código da característica criada é: {{ novoCodigo }}</label>
        </div>
      </div>
      <div class="modal-footer">
        <div>
<!--          <button class="br-button secondary" (click)="modalRef1.hide()">-->
<!--            Fechar-->
<!--          </button>-->
          <button class="br-button secondary" (click)="sairModalSalvar()">
            Fechar
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #caracteristicaSucessoEditar>
    <div>
      <div class="modal-header">
        <h4 class="modal-title">Característica editado com sucesso</h4>
        <button type="button" class="close" (click)="sairModalEditar()">
          &times;
        </button>
      </div>
      <div class="container-lg">
        <div
          class="flex justify-content-center mb-5 mt-5"
          style="text-align: center"
        >
          <label>Característica editada com sucesso.</label>
        </div>
      </div>
      <div class="modal-footer">
        <div>
          <button class="br-button secondary" (click)="sairModalEditar()">
            Fechar
          </button>
        </div>
      </div>
    </div>
  </ng-template>

</div>



