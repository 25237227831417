import {NovoItemMaterialCaracteristica} from './novo-item-material-caracteristica.model';

export class NovoItemMaterial {
  pdm: string;
  classe: string;
  novoItemMaterialCaracteristica: NovoItemMaterialCaracteristica[] = [];
  sustentavel: any;
  exclusivoCentralCompras: any;
}




