import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, ChildActivationStart } from '@angular/router';
import { BuscaService } from 'src/app/service/busca-service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-manter-servico',
  templateUrl: './manter-servico.component.html',
  styleUrls: ['./manter-servico.component.css'],
  providers: [ConfirmationService]
})

export class ManterServicoComponent implements OnInit {

  servicoParams: any;
  visible: boolean = false;
  numMaxServico = 120;
  numMaxDescricao = 750;
  numMaxAlias = 25;
  unidadeMedida: any;
  dadosBasicos: any;
  expandCollapsed: boolean = false;
  flag: any = 0;
  agrupamento: any;
  agrupamentoSelecionado: any;
  tipo: any = [{ codigoTipo : 1, nomeTipo : '1 - Serviços de Obras e Engenharia' },
    { codigoTipo : 2, nomeTipo : '2 - Outros Serviços' }];

  @ViewChild('ServicoSucess', { static: false }) ServicoSucess: any;
  @ViewChild('BackAlert', {static: false }) BackAlert: any;
  @ViewChild('confirmCont', {static: false }) confirmCont: any;

  naturezaDespesa: string = '';
  alias: string = '';

  constructor(private router: Router,
              private buscaService: BuscaService,
              private route: ActivatedRoute,
              private messageService: MessageService,
              private confirmationService: ConfirmationService) {}

  ngOnInit(): void {
    this.route.params.subscribe(id => this.servicoParams = id);

    this.buscaService
      .obterUnidadeDeMedidaServico()
      .subscribe((resp) => (this.unidadeMedida = resp));

    this.buscaService
       .obterDadosBasicosServicoPorCodigo(this.servicoParams.id)
       .subscribe(resp => {
       this.dadosBasicos = resp;
       this.pressEnterNome(event);
       this.pressEnterDescricao(event);
       // flag para o alerta do save Não TIRAR
       this.flag = 0;
    });
    this.buscaService
      .obterAgrupamentoServico()
      .subscribe((resp) => (this.agrupamento = resp));
    this.flag = 0;
  }

  openAlert(){
    console.log(this.flag)
    if(this.flag == 1){
      this.BackAlert.show();
    }
    else{
      this.voltarTelaInicial();
      this.flag = 0;
    }
  }

  permanecerPagina(){
    window.location.reload();
  }

  selectCPC(cpc: any): void {
    this.dadosBasicos.codigoCpc = cpc.codigoCpc;
    this.dadosBasicos.nomeCpc = cpc.nomeCpc;
//     this.formulario.value.cpc = cpc.codigoCpc;
  }

  voltarTelaInicial(){
    this.router.navigate(['/buscaservico']);
  }

  pressEnterNome(evento: any) {
    this.flag = 1;
    this.numMaxServico = 120 - this.dadosBasicos.descricaoServicoAcentuado.trim().length;
  }

  pressEnterDescricao(evento: any) {
    this.flag = 1;
    this.numMaxDescricao = 750 - this.dadosBasicos.descricaoServico.trim().length;
  }

  pressEnterAlias(evento: any, parametroMetodo: any) {
    this.numMaxAlias = 25 - parametroMetodo.trim().length;
    if (evento.key == 'Enter') {
      this.adicionaListAlias();
    }
  }

  carregaArray(valor: string) {
    if (valor = "alias") {
      if (!this.dadosBasicos.aliases) {
        this.dadosBasicos.aliases = [];
      }
    }
    if (valor = "natureza") {
      if (this.dadosBasicos.naturezas) {
        let aux = '';
        for (let i of this.dadosBasicos.naturezas) {
          i.codigoClassificacaoContabilMascarado =
            i.codigoClassificacaoContabil.toString().substring(0, 6) + '/' +
            i.codigoClassificacaoContabil.toString().substring(6);
        }
      } else {
        this.dadosBasicos.naturezas = [];
      }
    }
  }

  pressEnterNatureza(evento: any) {
    if (evento.key == 'Enter') {
      this.adicionaListNatureza();
    }
  }

  adicionaListAlias() {
    if (this.dadosBasicos.aliases.length < 10) {
      let aux = 0;
      this.alias = this.alias.trim();
      if (this.alias != '') {
        for (let i of this.dadosBasicos.aliases) {
          if (this.alias == i.nomeAliasServico) {
            aux = 1;
          }
        }
        if (aux == 0) {
          this.flag = 1;
          this.dadosBasicos.aliases.push({ nomeAliasServico: this.alias });
          this.alias = '';
          this.numMaxAlias = 25;
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Alias/palavra-chave já cadastrada',
          });
        }
      }
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Somente é possível incluir 10 alias/palavras-chaves.',
      });
    }
  }

  removeListAlias(message: string) {
    this.confirmationService.confirm({
      message: 'Deseja excluir esse alias/palavra-chave?',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      key: message,
      defaultFocus: 'none',
      accept: () => {
        for (let index in this.dadosBasicos.aliases) {
          if (message == this.dadosBasicos.aliases[index].nomeAliasServico){
            this.flag = 1;
            this.dadosBasicos.aliases.splice(index, 1);
          }
        }
      },
    });
  }

  adicionaListNatureza() {
    let aux = 0;
    let aux2 = Number(this.naturezaDespesa.substring(0, 4));
    let aux3 = this.naturezaDespesa.length;
    this.naturezaDespesa = this.naturezaDespesa.trim();
    if (this.naturezaDespesa != '' && aux3 == 9) {
      if (
        (aux2 >= 3390 && aux2 <= 3399) ||
        (aux2 >= 4490 && aux2 <= 4499) ||
        (aux2 >= 5590 && aux2 <= 5599)
      ) {
        for (let i of this.dadosBasicos.naturezas) {
          if (this.naturezaDespesa == i.codigoClassificacaoContabilMascarado) {
            aux = 1;
          }
        }
        if (aux == 0) {
          this.flag = 1;
          this.dadosBasicos.naturezas.push({
            codigoClassificacaoContabilMascarado: this.naturezaDespesa,
            codigoClassificacaoContabil: Number(this.naturezaDespesa.replace("/", "")),
            statusClassificacaoContabil: true,
          });
          this.naturezaDespesa = '';
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Natureza de desespesa já cadastrada',
          });
        }
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Natureza da despesa inválida',
        });
      }
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Preencher corretamente o campo de natureza da despesa',
      });
    }
  }

  adicionaListUnidade(evento: any) {
    let aux = 0;
    for (let i of this.dadosBasicos.unidadesDeMedidas) {
      if (evento == i.siglaUnidadeMedida) {
        aux = 1;
      }
    }
    if (aux == 0) {
      this.flag = 1;
      for (let unidade of this.unidadeMedida) {
        if (unidade.siglaUnidadeMedida == evento) {
          this.dadosBasicos.unidadesDeMedidas.push({
            nomeUnidadeMedida: unidade.nomeUnidadeMedida,
            siglaUnidadeMedida: unidade.siglaUnidadeMedida,
            statusUnidadeMedida: true
          });
        }
      }
    } else{
      this.messageService.add({
        severity: 'warn',
        summary: 'Unidade de medida já cadastrada'
        });
    }
  }

  editarServico(){
    this.router.navigate(['/manterservico', this.dadosBasicos.codigoServico]);
  }

  teste(){
    console.log(this.dadosBasicos);
  }

  Alterou(){
    this.flag = 1;
  }

  confirmaSalvar() {
     if (this.flag == 1) {
        this.confirmCont.show();
     }
  }

  salvar() {
    this.visible = true;
    this.buscaService.alterarServico(this.dadosBasicos).subscribe(
      resp => {
        if (resp) {
          this.visible = false;
          if (resp) {
            this.ServicoSucess.show();
            this.flag = 0;
          }
        }
      }, erro => {
        if(typeof(erro.error) == "string"){
          this.messageService.add({
            severity: 'error',
            summary: 'Erro ao salvar os dados no servidor: ' + erro.error
            });
        }else{
          this.messageService.add({
            severity: 'error',
            summary: 'Não foi possível se conectar com o serviço neste momento, por favor tente mais tarde.'
            });
        }
      });
  }
}
