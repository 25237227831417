import {Pipe, PipeTransform} from '@angular/core';
import {ClasseMaterial} from '../arealogada/model/classe-material';

@Pipe({
  name: 'filtroClasse'
})
export class FiltroClassePipe implements PipeTransform {

  // tslint:disable-next-line:typedef
  transform(classesPdm: ClasseMaterial[], descriptionQuery: string) {
    descriptionQuery = descriptionQuery
      .trim()
      .toLowerCase();

    if (descriptionQuery) {
      return classesPdm.filter(classe =>
        classe.nomeClasse.toLowerCase().includes(descriptionQuery)
      );
    } else {
      return classesPdm;
    }
  }

}
