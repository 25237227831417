import {Injectable} from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class AplicacaoService {

  public usuario = 'usuario';
  public counter = 600;
  public counter_blink = 180;
  constructor() {}


}
