import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SessaoService } from 'src/app/service/sessao.service';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { PesquisaService } from 'src/app/arealogada/comprador/comprador.service';

@Component({
  selector: 'app-manter-pedidos',
  templateUrl: './manter-pedidos.component.html',
  styleUrls: ['./manter-pedidos.component.css']
})

export class ManterPedidosComponent implements OnInit {

  formulario: FormGroup;
  id: any;
  escondeMenu: boolean = false;
  dadosBasicos: any;
  index: number = 0;
  desabil: any;
  loading: boolean = false;
  enviaTipoTela: string = 'catalogador';

  @ViewChild('secaoItens') secaoItens: any;

  constructor(
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private sessaoService: SessaoService,
    private service: PesquisaService) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => this.id = params['id']);
    this.carregarPedidosPorNumero();
  }

  carregarPedidosPorNumero(event?: LazyLoadEvent) {
    let numPedido = sessionStorage.getItem('numeroPedido');
    this.loading = true;
    this.service.obterPedidoLegadoPorNumero(this.id)
      .subscribe(resp => {
        this.dadosBasicos = resp;
        this.loading = false;
      },
        error => {
          this.loading = false;
          this.messageService.add({
            severity: 'warn',
            summary: 'Erro no carregamento dos dados'
          });
        });
  }

  mudaEstadoMenu() {
    this.escondeMenu = !this.escondeMenu;
  }

  mudaIndex(tipo: string) {
    if (tipo == 'info') {
      this.index = 0;
    } else {
      if (tipo == 'historico') {
        this.index = 1;
        this.secaoItens.voltarLista();
      }
    }
  }

  // irParaAT() {
  //   this.sessaoService.irParaAT();
  // }

  // irParaPedidos() {
  //   this.router.navigate(['areacatalogador']);
  // }

  // voltar() {
  //   this.router.navigate(['areacatalogador']);
  // }

  zerar() {
    this.formulario.reset();
  }

}
