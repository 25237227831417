<button
  class="br-button is-primary"
  type="button"
  (click)="limpar(); incluirValor.show();"
>
  Incluir
</button>
<div
    id="incluirValor"
    bsModal
    #incluirValor="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="Classe"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-media">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Incluir valor de característica</h4>
          <button type="button" class="close" (click)="limpar(); incluirValor.hide();">
            &times;
          </button>
        </div>
        <div class="container-lg">
          <div class="row mt-3">
            <label *ngIf="!existeNome" class="col-8">
              Nome do valor de característica<span class="asterisco">*</span>
            </label>
            <label *ngIf="existeNome" class="col-4">
              Nome do valor de característica<span class="asterisco">*</span>
            </label>
            <label *ngIf="existeNome" class="col-4" style="color: red;">
              Nome já cadastrado
            </label>
            <label class="col-4 texto-menor" style="text-align: end"
            >Caracteres restantes: <span>{{ numMaxNome }}</span></label
            >
          </div>
          <div class="br-input">
            <input #campoNome
                   (blur)="validaNome(campoNome)"
                   [(ngModel)]="incluirNome"
                   placeholder="Digite o nome do valor de característica"
                   (keydown)="enableSave()"
                   (keyup)="decrementaTexto('nome')"
                   maxlength="50"
            />
          </div>
          <div class="row mt-3">
            <label class="col-8">Descrição do valor de característica</label>
            <label class="col-4 texto-menor" style="text-align: end"
            >Caracteres restantes:
              <span>{{ numMaxDescricaoIncluir }}</span></label
            >
          </div>
          <div class="br-textarea">
            <textarea
              [(ngModel)]="incluirDescricao"
              placeholder="Descrição do valor de característica"
              (keyup)="decrementaTexto('descricaoIncluir')"
              maxlength="200"
            ></textarea>
          </div>
          <div class="row mt-3">
            <label class="col-8">Complemento do valor de característica</label>
            <label class="col-4 texto-menor" style="text-align: end"
            >Caracteres restantes:
              <span>{{ numMaxComplementoIncluir }}</span></label
            >
          </div>
          <div class="br-textarea mb-5">
            <textarea
              [(ngModel)]="incluirComplemento"
              placeholder="Complemento do valor de característica"
              (keyup)="decrementaTexto('complementoIncluir')"
              maxlength="1500"
            ></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <div>
            <button class="br-button secondary" (click)="limpar(); incluirValor.hide();">
              Voltar
            </button>
          </div>
          <div>
            <button
              class="br-button primary"
              (click)="salvarValorCaracteristica()"
              [disabled]="disableSave"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>

  <!-- <ng-template #valorCaracteristicaSucess> -->
    <div *ngIf="isModalShown" [config]="{ show: true }"
    id="valorCaracteristicaSucess"
    bsModal
    #valorCaracteristicaSucess="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="Classe"
    aria-hidden="true">

      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Valor incluído com sucesso</h4>
            <!-- <button type="button" class="close" (click)="limpar(); sairModalSalvar();"> -->
              <button type="button" class="close" (click)="limpar(); sairModalSalvar(); valorCaracteristicaSucess.hide(); incluirValor.hide(); isModalShown = false;" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div class="container-lg">
            <div
              class="flex justify-content-center mb-5 mt-5"
              style="text-align: center"
            >
              <label>Valor de característica incluido com sucesso.</label><br/>
              <label>O código do valor de característica é: {{ novoCodigo }}</label>
            </div>
          </div>
          <div class="modal-footer">
            <div>
              <!-- <button class="br-button secondary" (click)="limpar(); sairModalSalvar();">             -->
              <button class="br-button secondary" (click)="limpar(); sairModalSalvar(); valorCaracteristicaSucess.hide(); incluirValor.hide(); isModalShown = false;" data-dismiss="modal">
                Voltar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- </ng-template> -->

</div>
