import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {BuscaService} from '../../../service/busca-service';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-manter-caracteristica',
  templateUrl: './manter-caracteristica.component.html',
  styleUrls: ['./manter-caracteristica.component.css']
})
export class ManterCaracteristicaComponent implements OnInit {

  textoBusca = '';
  listaCaracteristicaTela: any;
  listaCaracteristicaApi: any;
  filtroCaracteristica: string;
  escondeValor: boolean = true;
  expandCollapsed: boolean = false;
  visible: boolean = false;
  existeNome: boolean = false;
  // campos incluir
  incluirNome: any;
  incluirDetalhe: any;
  // campos editar
  editarCodigo: any;
  editarStatus: any;
  editarNome: any;
  editarDetalhe: any;
  // campos visualiza
  visualizaCodigo: any;
  visualizaStatus: any;
  visualizaNome: any;
  visualizaDetalhe: any;
  novoCodigo = '000000';
  modalRef1: BsModalRef;
  numMaxNome = 40;
  numMaxDescricao = 200;
  disableSave = true;
  ativos: boolean = false;

  @ViewChild('caracteristicaSucessoCriar', {static: false} ) caracteristicaSucessoCriar: any;
  @ViewChild('caracteristicaSucessoEditar', {static: false} ) caracteristicaSucessoEditar: any;
  @ViewChild('focusNomeCriar', {static: false}) focusNomeCriar: ElementRef;

  constructor(private router: Router, private modalService: BsModalService,
              private buscaService: BuscaService, private messageService: MessageService) { }

  ngOnInit(): void {
  }

  enterPesquisar(evento: any): void{
    if (evento.key === 'Enter'){
      this.pesquisar();
    }
  }
  pesquisar(): void {
    this.listaCaracteristicaApi = [];
    this.listaCaracteristicaTela = [];
    if ( this.textoBusca !== '' ){
      this.buscaService.obterCaracteristicaPdmPorPalavra(this.textoBusca).subscribe(tmp => {
        this.listaCaracteristicaApi = tmp;
        this.listaCaracteristicaTela = tmp;
      });
    }else{
      this.buscaService.obterCaracteristicaPdmPorPalavra('').subscribe(tmp => {
        this.listaCaracteristicaApi = tmp;
        this.listaCaracteristicaTela = tmp;
      });
    }
    this.escondeValor = false;
  }

  voltarTelaInicial(): void {
    this.router.navigate(['/arealogada']);
  }


  pegarDados(tipo: string, caracteristica: any): void{

    if ( tipo === 'editar'){
      console.log('editar');
      this.numMaxDescricao = 200;
      this.numMaxDescricao = this.numMaxDescricao  - caracteristica.detalheCaracteristica.length;
      this.editarCodigo = caracteristica.codigoCaracteristica;
      this.editarNome = caracteristica.nomeCaracteristica;
      this.editarDetalhe = caracteristica.detalheCaracteristica;
      this.editarStatus = caracteristica.statusCaracteristica;
    } else {
      this.visualizaCodigo = caracteristica.codigoCaracteristica;
      this.visualizaNome = caracteristica.nomeCaracteristica;
      this.visualizaDetalhe = caracteristica.detalheCaracteristica;
      this.visualizaStatus = caracteristica.statusCaracteristica;
    }

  }

  filtrarCaracteristica(): void {
    this.ativos = false;
    let aux: string;
    if (this.filtroCaracteristica !== undefined){
      aux = this.filtroCaracteristica.toUpperCase();
    } else {
      aux = '';
    }
    this.listaCaracteristicaTela = [];
    if (aux !== ''){
      for ( const i of this.listaCaracteristicaApi){
        if (i.codigoCaracteristica.toString().includes(aux) || i.nomeCaracteristica.toUpperCase().includes(aux)){
          this.listaCaracteristicaTela.push(i);
        }
      }
    }else{
      this.limparFiltroCaracteristica();
    }

  }

  somenteAtivos(){
    if(this.ativos == false){
      let auxArray = [];
      for(let i of this.listaCaracteristicaTela){
        if(i.statusCaracteristica == true){
          auxArray.push(i);
        }
      }
      this.listaCaracteristicaTela = auxArray;
    } else {
      this.filtrarCaracteristica();
    }
  }

  private limparFiltroCaracteristica(): void {
    this.filtroCaracteristica = '';
    this.listaCaracteristicaTela = this.listaCaracteristicaApi;
  }

  salvarCaracteristica(): void {
    let modalOptions: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.visible = true;
    this.buscaService.salvarCaracteristicaPDM({
      nomeCaracteristica: this.incluirNome.trim(),
      detalheCaracteristica: this.incluirDetalhe.trim(),
      statusCaracteristica: true,
    }).subscribe((tmp) => {
        this.visible = false;
        console.log('sucesso');
        console.log(tmp.codigoCaracteristica);
        console.log(JSON.stringify(tmp));
        this.novoCodigo = tmp.codigoCaracteristica;
        this.incluirNome = '';
        this.incluirDetalhe = '';
        this.modalRef1 = this.modalService.show(this.caracteristicaSucessoCriar, modalOptions);
      },
      erro => {
        this.visible = false;
        if (typeof(erro.error) === 'string'){
          this.messageService.add({
            severity: 'error',
            summary: 'Erro ao salvar os dados no servidor: ' + erro.error
          });
        }else{
          this.messageService.add({
            severity: 'error',
            summary: 'Não foi possível se conectar com o serviço neste momento, por favor tente mais tarde.'
          });
        }


      });
  }

  atualizarCaracteristica(): void {
    let modalOptions: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.visible = true;
    this.buscaService.atualizarCaracteristicaPDM({
      codigoCaracteristica: this.editarCodigo,
      nomeCaracteristica: this.editarNome.trim(),
      detalheCaracteristica: this.editarDetalhe.trim(),
      statusCaracteristica: this.editarStatus,
    }).subscribe((tmp) => {
        this.visible = false;
        this.modalRef1 = this.modalService.show(this.caracteristicaSucessoEditar, modalOptions);
        // console.log('sucesso ao atualizar caracteristica');
        // console.log(tmp.codigoCaracteristica);
        // console.log(JSON.stringify(tmp));
        this.editarStatus =  false;
        this.editarDetalhe = '';
      }, erro => {
        this.visible = false;
       // console.log('erro');console.log(erro);
        if (typeof(erro.error) === 'string'){
          this.messageService.add({
            severity: 'error',
            summary: 'Erro ao salvar os dados no servidor: ' + erro.error
          });
        }else{
          this.messageService.add({
            severity: 'error',
            summary: 'Não foi possível se conectar com o serviço neste momento, por favor tente mais tarde.'
          });
        }
      });
  }


  abrirCriar(): void {
    console.log('abrir');

    this.incluirDetalhe = '';
    this.incluirNome = '';
    this.numMaxNome = 40;
    this.numMaxDescricao = 200;
    this.existeNome = false;

    this.limpaTela();
  }

  limpaTela(): void {
    this.escondeValor = true;
    this.filtroCaracteristica = '';
    this.textoBusca = '';
    this.listaCaracteristicaTela = [];
    this.listaCaracteristicaApi = [];
  }

  sairModalEditar(){
    this.modalRef1.hide();
    this.pesquisar();
  }

  sairModalSalvar(): void {
    window.location.reload();
  }

  decrementaTexto(campo: string): void {
    if ( campo === 'nome'){
      this.numMaxNome = 40;
      this.numMaxNome = this.numMaxNome - (this.incluirNome.length);
    }

    if ( campo === 'descricao'){
      this.numMaxDescricao = 200;
      this.numMaxDescricao = this.numMaxDescricao - (this.incluirDetalhe.length);
    }

    if ( campo === 'descricaoeditar'){
      this.numMaxDescricao = 200;
      this.numMaxDescricao = this.numMaxDescricao - (this.editarDetalhe.length);
    }
  }

  enableSave(): void {
    if (this.incluirNome === '' ){
      this.disableSave = true;
    } else{
      this.disableSave = false;
    }

  }

  validaNome(campo: any){
    let aux = this.incluirNome.trim();
    this.buscaService.validaNomeExistenteCaracteristica(aux).subscribe((tmp)=>{
      if(tmp > 0){
        this.existeNome = true;
        this.disableSave = true;
        this.incluirNome = "";
        campo.focus();
      }
      else {
        this.existeNome = false;
      }
    })
  }
}
