import {registerLocaleData} from '@angular/common';
import localeptBR from '@angular/common/locales/br';
import localeptBRExtra from '@angular/common/locales/extra/br';
// Configuracoes necessarias para o date picker funcionar corretamente
import {defineLocale} from 'ngx-bootstrap/chronos';
import {ptBrLocale, } from 'ngx-bootstrap/locale';
import {BrowserModule} from '@angular/platform-browser';
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {GovBrModule} from 'src/app/dsgovbr.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ErrorsModule} from './errors/errors.module';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {BuscaModule} from './busca/busca.module';
import {ToastModule} from 'primeng/toast';
import {TooltipModule} from 'primeng/tooltip';

import {PaginaModule} from './shared/pagina/pagina.module';
import {BuscaSiasgnetModule} from './busca-siasgnet/busca-siasgnet.module';
import {BuscaSiasgnetLoteModule} from './busca-siasgnet-lote/busca-siasgnet-lote.module';
import { MessageService } from 'primeng/api';
import {ArealogadaModule} from './arealogada/arealogada.module';
import {ComprasnetCabecalhoGovernoModule} from '@serpro/comprasnet-cabecalho-governo';
import {httpInterceptorProviders} from './interceptors';
import { CalendarModule } from "primeng/calendar";

registerLocaleData(localeptBR, localeptBRExtra);

defineLocale('pt-br', ptBrLocale);


@NgModule({

    declarations: [
      AppComponent,
    ],
    imports: [
      HttpClientModule,
      BrowserModule,
      BrowserAnimationsModule,
      FormsModule,
      ReactiveFormsModule,
      AppRoutingModule,
      ErrorsModule,
      BuscaModule,
      BuscaSiasgnetModule,
      BuscaSiasgnetLoteModule,
      ArealogadaModule,
      PaginaModule,
      GovBrModule,
      ToastModule,
      TooltipModule,
      ComprasnetCabecalhoGovernoModule,
      PaginaModule,
      CalendarModule

    ],
    providers: [

      { provide: LOCALE_ID, useValue: 'pt-BR' },
      { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL'},
      MessageService,
      HttpClient,
      HttpClientModule,
      httpInterceptorProviders,


    ],

    bootstrap: [AppComponent]



  })
  export class AppModule { }
