import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { EnvironmentUtil } from 'src/environments/EnviromentUtil';

@Injectable({
  providedIn: 'root',
})
export class FiltroService {
  // Observable any sources
  private filtroAlteradoSource = new Subject<any>();

  // Observable any streams
  filtroAlterado$ = this.filtroAlteradoSource.asObservable();

  // Service message commands
  anunciarFiltroAlterado(filtro: any) {
    this.filtroAlteradoSource.next(filtro);
  }

  endpoint: string;

  constructor(private httpClient: HttpClient) {
    this.endpoint = EnvironmentUtil.obterUrlBase();
  }

  listarCatalogadores(): Observable<any[]> {
    const destino = this.endpoint + 'pedido/v1/designarPedido/catalogador';
    let params = new HttpParams()
    // params = params.set('somenteAtivos','true');
    params = params.append('somenteMesmaUasg','true');
    return this.httpClient.get<any[]>(destino, {params});
  }

  listarUasgs(): Observable<any[]>{
    const destino = this.endpoint + 'pedido/v1/designarPedido/uasg';
    let params = new HttpParams()
    params = params.set('somenteCatalogadoras','true');
    return this.httpClient.get<any[]>(destino, {params});
  }
}
