

<button class="br-button is-primary ml-2 mr-2" type="button" (click)="abrirCriar(); incluirCaracteristica.show();" >Incluir </button>
<div id="incluirCaracteristica" #incluirCaracteristica="bs-modal" bsModal class="modal fade" tabindex="-1" role="dialog" aria-labelledby="Classe" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-media">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Incluir característica</h4>
        <button type="button" class="close" (click)="incluirCaracteristica.hide()">x</button>
      </div>
      <div class="container-lg">
        <div class="row mt-5 ">
          <label *ngIf="!existeNome"  class="col-8" >Nome da característica<span class="asterisco">*</span></label>
          <label *ngIf="existeNome"  class="col-4" >Nome da característica<span class="asterisco">*</span></label>
          <label *ngIf="existeNome"  class="col-4" style="color: red;">Nome já cadastrado.</label>
          <label class="col-4 texto-menor" style="text-align: end; ">Caracteres restantes: <span>{{numMaxNome}}</span></label>
        </div>
        <div class="br-input  ">
          <input #campoNome
            (blur)="validaNome(campoNome)"
            [(ngModel)]="incluirNome"  (keydown)="enableSave()" (keyup)="decrementaTexto('nome')"
            placeholder="Digite o nome da característica"
            class="p-inputtext" maxlength="40"   />
        </div>
        <div class="row mt-5 ">
          <label class="col-8">Descrição da característica</label>
          <label class="col-4 texto-menor" style="text-align: end;">Caracteres restantes: <span>{{numMaxDescricao}}</span></label>
        </div>
        <div  class="br-textarea  ">
          <textarea
            [(ngModel)]="incluirDetalhe" placeholder="Descrição  da característica"  rows="4" maxlength="200"
            (keydown)="enableSave()"
            (keyup)="decrementaTexto('descricao')"
            ></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <div><button class="br-button secondary" (click)="incluirCaracteristica.hide()">Voltar</button></div>
        <div>
          <button [disabled]="disableSave" (click)="salvarCaracteristica()"
                  class="br-button primary"
          >Salvar</button>
        </div>
      </div>
    </div>
  </div>


  <ng-template #caracteristicaSucessoCriar>
    <div>
      <div class="modal-header">
        <h4 class="modal-title">Característica incluída com sucesso</h4>
        <button type="button" class="close" (click)="sairModalSalvar(); incluirCaracteristica.hide();">
          X
        </button>
      </div>
      <div class="container-lg">
        <div class="flex justify-content-center mb-5 mt-5" style="text-align: center">
          <label>Característica incluída com sucesso.</label><br />
          <label>O código da característica criada é: {{ novoCodigo }}</label>
        </div>
      </div>
      <div class="modal-footer">
        <div>
          <button class="br-button secondary" (click)="sairModalSalvar(); incluirCaracteristica.hide();">
            Fechar
          </button>
        </div>
      </div>
    </div>
  </ng-template>

</div>

