<div class="modal fade" id="buscarcaracteristica" >
  <div class="modal-dialog modal-xl modal-dialog-centered modal-cpc" >
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Selecionar a característica</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="fechar()">&times;</button>
      </div>

      <!-- <div  class="container-lg" > -->
      <!-- Modal body -->
      <div class="modal-body">
        <div class="row col-12">
          <div class="col-6">
            <div class="br-input">
            <!--<div class="br-input">
              <input id="caracateristicaentrada" class="p-inputtext" type="text" placeholder="Digite o nome da caracteristica para filtrar"
                    [(ngModel)]="caracateristicaFiltro" (keyup)="filtrarCaracteristica()" />
            </div> -->
                <label>Característica: </label>
                <input id="caracateristicaentrada" class="p-inputtext mb-2" type="text" placeholder="Nome da caracteristica"
                    [(ngModel)]="caracateristicaFiltro" (keyup)="pressEnterCaracteristicas($event)" />
            </div>
          </div>
          <!--<div class="column" style="align-self: center;">
            <div class="br-checkbox">
              <input id="somenteNomeCaracteristica" name="somente-nome" type="checkbox" aria-label="somente nome" (click)="filtrarCaracteristica(true)" [(ngModel)]="somenteNomeCaracteristicaFiltro"/>
              <label for="somenteNomeCaracteristica">Pesquisar somente pelo nome da característica</label>
            </div>
          </div> -->
          <div class="col-3">
            <div class="br-checkbox">
              <input
                id="somenteNome"
                name="somenteNome"
                type="checkbox"
                aria-label="somenteNome"
                (click)="marcador('','somenteNome','')"
                [(ngModel)]="somenteNome"
              />
              <label for="somenteNome">Somente pelo nome</label>
            </div>
            <div class="br-checkbox">
              <input
                id="inicioTexto"
                name="inicioTexto"
                type="checkbox"
                aria-label="inicioTexto"
                (click)="marcador('inicioTexto','','')"
                [(ngModel)]="inicioTexto"
              />
              <label for="inicioTexto">Começa com</label>
            </div>
            <div class="br-checkbox">
              <input
                id="textoExato"
                name="textoExato"
                type="checkbox"
                aria-label="textoExato"
                (click)="marcador('','','textoExato')"
                [(ngModel)]="textoExato"
              />
              <label for="textoExato">Resultado exato</label>
            </div>
          </div>
          <div class="col-3 mb-3" style="align-self: flex-end; text-align: center;">
            <button
              class="br-button primary"
              type="button"
              (click)="buscarCaracteristica()"
              aria-label="Botão de ação"
            >
              Buscar
            </button>
            <app-manutencao-caracteristica (novaCaracteristicaCriada)="escolherNovaCaracteristica($event)"></app-manutencao-caracteristica>
          </div>

          <!--<div class="col-sm-2 col-lg-2 mb-3" style="align-self: center;">
            <button class="br-button secondary" type="reset" (click)="filtrarCaracteristica()">Buscar</button>
          </div>
          <div class="col-sm-2 col-lg-2 mb-3" style="align-self: center;">
            <button class="br-button secondary" type="reset" (click)="limparFiltroCaracteristica()">Limpar</button>
          </div>!-->

        <p-table  [value]="caracteristicaTela" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
                  currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} " [rowsPerPageOptions]="[10,25,50]" [(first)]="first">
          <ng-template pTemplate="header">
            <tr class="row">
              <th pSortableColumn="codigoCaracteristica" class="col-sm-2 col-lg-2">Código <p-sortIcon field="codigoCaracteristica"></p-sortIcon></th>
              <th pSortableColumn="nomeCaracteristica" class="col-sm-2 col-lg-2">Característica<p-sortIcon field="nomeCaracteristica"></p-sortIcon></th>
              <th pSortableColumn="detalheCaracteristica" class="col-sm-6 col-lg-6">Descrição da característica <p-sortIcon field="detalheCaracteristica"></p-sortIcon></th>
              <th class="col-sm-2 col-lg-2">Ações</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr class="row">
              <td class="col-sm-2 col-lg-2">{{item.codigoCaracteristica}}</td>
              <td class="col-sm-2 col-lg-2">{{item.nomeCaracteristica}}</td>
              <td class="col-sm-6 col-lg-6">{{item.detalheCaracteristica}}</td>
              <td class="col-sm-2 col-lg-2">
                <button  type="button" class="br-button secondary  small "
                        (click)="escolherCaracteristica(item.codigoCaracteristica)" aria-label="Selecionar"
                        data-dismiss="modal"
                >Selecionar
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
