
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {DetalheServicoSiasgnetComponent} from './detalhe-servico-siasgnet/detalhe-servico-siasgnet.component';
import {BuscaSiasgnetComponent} from './busca-siasgnet/busca-siasgnet.component';
import {DetalheMaterialSiasgnetComponent} from './detalhe-material-siasgnet/detalhe-material-siasgnet.component';
import {ErroComponent} from './erro/erro.component';

const routes: Routes = [
  { path: '', component: BuscaSiasgnetComponent},
  { path: 'detalheservicosiasgnet', component: DetalheServicoSiasgnetComponent },
  { path: 'detalhematerialsiasgnet', component: DetalheMaterialSiasgnetComponent },
  { path: 'erro', component: ErroComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class BuscaSiasgnetRoutingModule{}
