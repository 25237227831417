<app-cabecalho-interno></app-cabecalho-interno>

<div class="container-lg mb-5">


  <div class="p-grid">
    <div class="campo p-col-12">
      <div class="col pt-5 mt5 container-main">
        <div class="pt-5 pb-2">
          <p class="h2">Login Desenvolvimento </p>
          <p class="h5">Catalogo</p>
        </div>
        <div>
          <div class="feedback" role="alert">
            <div class="icon"><i class="fas fa-exclamation-triangle fa-lg" aria-hidden="true"></i>
            </div><strong>Atenção. </strong> Esta é uma tela de login de teste de desenvolvimento.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="p-col-4">
    <label >Usuário Governo</label>
    <p-dropdown id="userGov" [options]="usuariosGoverno" optionLabel="label" placeholder="Selecione Usuario Governo"
      [(ngModel)]="usuarioGovernoSelected">
    </p-dropdown>
    <div class="row mb-4" style="padding-top: 20px;">
      <div class="campo col-10">
        <button class="br-button is-primary" tooltip="Logar como governo" type="button" (click)="irParaDashboardGoverno()">
          Efetuar Login
        </button>
      </div>
    </div>
  </div>

  <div class="campo p-col-2"></div>

</div>
