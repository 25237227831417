import { Injectable } from '@angular/core';
import { RoutesRecognized, ActivatedRouteSnapshot } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class RouteDataService {
  private data: Map<any, any>;

  constructor() {
    this.data = new Map();
  }

  get(chave: any): any {
    return this.data.get(chave);
  }

  // tslint:disable-next-line:typedef
  private reset() {
    this.data.clear();
  }

  // tslint:disable-next-line:typedef
  populate(event: RoutesRecognized) {
    this.reset();
    // @ts-ignore
    this.percorrerSnapshot(event.state.root.firstChild);
  }

  // tslint:disable-next-line:typedef
  private percorrerSnapshot(snapshot: ActivatedRouteSnapshot) {
    this.percorrerChilds(snapshot);
    this.percorrerData(snapshot);
  }
// tslint:disable-next-line:typedef
  private percorrerChilds(snapshot: ActivatedRouteSnapshot) {
    snapshot.children.forEach(child => {
      this.percorrerSnapshot(child);
    });
  }
// tslint:disable-next-line:typedef
  private percorrerData(snapshot: ActivatedRouteSnapshot) {
    let chave: any;
    for (chave in snapshot.data) {
      this.data.set(chave, snapshot.data[chave]);
    }
  }
}
