import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {BuscaService} from '../../../service/busca-service';
import {MessageService} from 'primeng/api';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-manter-unidade-medida',
  templateUrl: './manter-unidade-medida.component.html',
  styleUrls: ['./manter-unidade-medida.component.css']
})
export class ManterUnidadeMedidaComponent implements OnInit {

  textoBusca = "";
  listaUnidadeMedidaTela: any;
  listaUnidadeMedidaApi: any;
  filtroUnidadeMedida: string;
  escondeValor: boolean = true;
  expandCollapsed: boolean = false;
  disableSave: boolean = true;
  visible: boolean = false;
  existeSigla: boolean = true;
  existeNome: boolean = true;

  incluirSigla: any;
  incluirNome: any;
  incluirDescricao: any;

  editarSigla: any;
  editarStatus: any;
  editarNome: any;
  editarDescricao: any;

  visualizaSigla: any;
  visualizaStatus: any;
  visualizaNome: any;
  visualizaDescricao: any;
  valorTela: any;
  numMaxSigla = 7;
  numMaxNome = 20;
  numMaxDescricao = 600;
  novoCodigo: any;
  modalRef1: BsModalRef;
  ativos: boolean = false;

  @ViewChild('unidadeMedidaSucessoCriar', {static: false} ) unidadeMedidaSucessoCriar: any;
  @ViewChild('unidadeMedidaSucessoEditar', {static: false} ) unidadeMedidaSucessoEditar: any;
  constructor(private router: Router, private buscaService: BuscaService, private modalService: BsModalService,
              private messageService: MessageService) { }

  ngOnInit(): void {
  }

  enterPesquisar(evento: any): void{
    if (evento.key === 'Enter'){
      this.pesquisar();
    }
  }

  pesquisar(): void {
    this.listaUnidadeMedidaApi = [];
    this.listaUnidadeMedidaTela = [];
    if ( this.textoBusca !== '' ){
      this.buscaService.obterUnidadeDeMedidaPorPalavra(this.textoBusca).subscribe(tmp => {
      this.listaUnidadeMedidaApi = tmp;
      this.listaUnidadeMedidaTela = tmp;
      console.log(tmp);
    });
    }else{
      this.buscaService.obterUnidadeDeMedidaPorPalavra('').subscribe(tmp => {
        this.listaUnidadeMedidaApi = tmp;
        this.listaUnidadeMedidaTela = tmp;
      });
    }
    this.escondeValor = false;
  }

  voltarTelaInicial(): void {
    this.router.navigate(['/arealogada']);
  }

   pegarDados(tipo: string, unidadeMedida: any): void{

     if ( tipo === 'edita'){
       this.editarSigla = unidadeMedida.siglaUnidadeMedida;
       this.editarNome = unidadeMedida.nomeUnidadeMedida;
       this.editarDescricao = unidadeMedida.descricaoUnidadeMedida;
       this.editarStatus = unidadeMedida.statusUnidadeMedida;

       this.numMaxDescricao = 600 - unidadeMedida.descricaoUnidadeMedida.length;
     } else {
       this.visualizaSigla = unidadeMedida.siglaUnidadeMedida;
       this.visualizaNome = unidadeMedida.nomeUnidadeMedida;
       this.visualizaDescricao = unidadeMedida.descricaoUnidadeMedida;
       this.visualizaStatus = unidadeMedida.statusUnidadeMedida;
     }

   }

   limpaCampos(){
     this.disableSave = true;
     this.incluirSigla = "";
     this.incluirNome = "";
     this.incluirDescricao = "";
     this.numMaxSigla = 7;
     this.numMaxNome = 20;
     this.numMaxDescricao = 600;
   }

  filtrarUnidadeMedida(){
    this.ativos = false;
    let aux: string;

    if (this.filtroUnidadeMedida !== undefined){
      aux = this.filtroUnidadeMedida.trim();
      aux = this.filtroUnidadeMedida.toUpperCase();
    } else {
      aux = '';
    }

    this.listaUnidadeMedidaTela = [];
    if(aux != ""){
      for( let i of this.listaUnidadeMedidaApi){
        if(i.siglaUnidadeMedida.toString().includes(aux) || i.nomeUnidadeMedida.toUpperCase().includes(aux)){
          this.listaUnidadeMedidaTela.push(i);
        }
      }
    }else{
      this.limparFiltroUnidadeMedida();
    }
  }

  somenteAtivos(){
    if(this.ativos == false){
      let auxArray = [];
      for(let i of this.listaUnidadeMedidaTela){
        if(i.statusUnidadeMedida == true){
          auxArray.push(i);
        }
      }
      this.listaUnidadeMedidaTela = auxArray;
    } else {
      this.filtrarUnidadeMedida();
    }
  }


  private limparFiltroUnidadeMedida(){
    this.filtroUnidadeMedida = "";
    this.listaUnidadeMedidaTela = this.listaUnidadeMedidaApi;
  }

  pressEnterSigla(){
    this.numMaxSigla = 7 - this.incluirSigla.length;
  }

  pressEnterNome(){
    this.numMaxNome = 20 - this.incluirNome.length;
  }

  pressEnterDescricao(){
    this.numMaxDescricao = 600 - this.incluirDescricao.length;
  }

  pressEditarDescricao(){
    this.numMaxDescricao = 600 - this.editarDescricao.length;
  }



  salvarNovaUnidadeMedida(): void {
    let modalOptions: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.visible = true;
    this.buscaService.salvarUnidadeMedida({
      siglaUnidadeMedida: this.incluirSigla.trim(),
      nomeUnidadeMedida: this.incluirNome.trim(),
      descricaoUnidadeMedida: this.incluirDescricao.trim(),
      statusUnidadeMedida: true
    }).subscribe((tmp) => {
        this.visible = false;
        this.novoCodigo = tmp.siglaUnidadeMedida;
        this.incluirNome = '';
        this.incluirDescricao = '';
        this.modalRef1 = this.modalService.show(this.unidadeMedidaSucessoCriar, modalOptions);
      },
      erro => {
        this.visible = false;
        if (typeof(erro.error) === 'string'){
          this.messageService.add({
            severity: 'error',
            summary: 'Erro ao salvar os dados no servidor: ' + erro.error
          });
        }else{
          this.messageService.add({
            severity: 'error',
            summary: 'Não foi possível se conectar com o serviço neste momento, por favor tente mais tarde.'
          });
        }
      });
  }

  atualizarUnidadeMedida(): void {
    let modalOptions: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.visible = true;
    this.buscaService.editarUnidadeMedida({
      siglaUnidadeMedida: this.editarSigla.trim(),
      nomeUnidadeMedida: this.editarNome.trim(),
      descricaoUnidadeMedida: this.editarDescricao.trim(),
      statusUnidadeMedida: this.editarStatus
    }).subscribe((tmp) => {
        this.visible = false;
        this.novoCodigo = tmp.siglaUnidadeMedida;
        this.incluirNome = '';
        this.incluirDescricao = '';
        this.modalRef1 = this.modalService.show(this.unidadeMedidaSucessoEditar, modalOptions);
      },
      erro => {
        this.visible = false;
        if (typeof(erro.error) === 'string'){
          this.messageService.add({
            severity: 'error',
            summary: 'Erro ao salvar os dados no servidor: ' + erro.error
          });
        }else{
          this.messageService.add({
            severity: 'error',
            summary: 'Não foi possível se conectar com o serviço neste momento, por favor tente mais tarde.'
          });
        }
      });
  }

  abrirCriar(): void {

    this.limpaCampos();

    this.limpaTela();
  }

  limpaTela(): void {
    this.escondeValor = true;
    this.existeSigla = false;
    this.existeNome = false;
    this.filtroUnidadeMedida = '';
    this.textoBusca = '';
    this.listaUnidadeMedidaApi = [];
    this.listaUnidadeMedidaTela = [];
  }

  sairModalEditar(){
    this.modalRef1.hide();
    this.pesquisar();
  }

  sairModalSalvar(): void {
    window.location.reload();
  }

  enableSave(){
    if(this.incluirNome == "" || this.incluirSigla == ""){
      this.disableSave = true;
    } else{
      this.disableSave = false;
    }
  }

  validaSigla(campo: any){
    let aux = this.incluirSigla.trim();
    this.buscaService.validaSiglaExistenteUnidadeMedida(aux).subscribe((tmp)=>{
      console.log(tmp)
      if(tmp > 0){
        this.existeSigla = true;
        this.disableSave = true;
        this.incluirSigla = "";
        campo.focus();
      }
      else {
        this.existeSigla = false;
      }
    })
  }

  validaNome(campo: any){
    let aux = this.incluirNome.trim();
    this.buscaService.validaNomeExistenteUnidadeMedida(aux).subscribe((tmp)=>{
      if(tmp > 0){
        this.existeNome = true;
        this.disableSave = true;
        this.incluirNome = "";
        campo.focus();
      }
      else {
        this.existeNome = false;
      }
    })
  }
}
