 import { Uasg } from 'src/app/shared/model/uasg';
// import { FornecedorRepresentation } from 'src/app/shared/model/fornecedor';
 import { CpfCnpjUtils } from 'src/app/shared/utils/cpf-cnpj-utils';
 import { TipoUsuarioEnum } from './tipo-usuario-enum';

 export class UsuarioRepresentation {
  tipoUsuario: string;
  cpf: number;
  nome: string;
  login: string;
  uasg: Uasg;
  // fornecedor: FornecedorRepresentation;
   fornecedor: any;
  //
  // // campos dinamicos
   isTipoUsuarioFornecedor: boolean;
   isTipoUsuarioGoverno: boolean;
   meEpp: boolean;
  //
  constructor(obj: any) {
    Object.assign(this, obj);
    // this.isTipoUsuarioFornecedor = this.tipoUsuario === TipoUsuarioEnum.FORNECEDOR;
    this.isTipoUsuarioGoverno = this.tipoUsuario === TipoUsuarioEnum.GOVERNO;
    // if (this.isTipoUsuarioFornecedor) {
    //   this.fornecedor = new FornecedorRepresentation(obj.fornecedor);
    //   this.meEpp = this.fornecedor.meEpp;
    // }
  }
  //
  cpfFormatado(): string {
    return CpfCnpjUtils.formatCpf(this.cpf);
  }
}
