
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {BuscaSiasgnetLoteComponent} from './busca-siasgnet-lote/busca-siasgnet-lote.component';
import {DetalheServicoSiasgnetLoteComponent} from './detalhe-servico-siasgnet-lote/detalhe-servico-siasgnet-lote.component';
import {DetalheMaterialSiasgnetLoteComponent} from './detalhe-material-siasgnet-lote/detalhe-material-siasgnet-lote.component';
import {ExibirSelecionadosSiasgnetLoteComponent} from './exibir-selecionados-siasgnet-lote/exibir-selecionados-siasgnet-lote.component';
import {ErroComponent} from '../busca-siasgnet-lote/erro/erro.component';

const routes: Routes = [
  { path: '', component: BuscaSiasgnetLoteComponent},
  { path: 'detalheservicosiasgnetlote', component: DetalheServicoSiasgnetLoteComponent },
  { path: 'detalhematerialsiasgnetlote', component: DetalheMaterialSiasgnetLoteComponent },
  { path: 'detalheservicosiasgnetlote', component: DetalheServicoSiasgnetLoteComponent },
 { path: 'exibirselecionadossiasgnetlote', component: ExibirSelecionadosSiasgnetLoteComponent },
  { path: 'erro', component: ErroComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class BuscaSiasgnetLoteRoutingModule{}
