import {Component, Inject, OnDestroy, OnInit,  HostListener} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import { takeUntil, filter, map, first } from 'rxjs/operators';
import { UsuarioRepresentation } from 'src/app/security/model/usuario';
import {UsuarioStore} from '../../../security/state/usuario.store';
import {UsuarioQuery} from '../../../security/state/usuario.query';
import {AuthService} from '../../../security/services/auth-service.service';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-cabecalho-interno',
  templateUrl: './cabecalho-interno.component.html',
  styleUrls: ['./cabecalho-interno.component.css']
})
export class CabecalhoInternoComponent implements OnInit, OnDestroy {
  usuario: UsuarioRepresentation; // token
  usuario$: Observable<UsuarioRepresentation>; // retoken
  tokenCarregado$: Observable<boolean>;
  destroySubject$: Subject<void> = new Subject();
  public navIsFixed = false;
  usuarioLogado: any;
  exibeTrocarSenha: any;

  constructor(
    private usuarioStore: UsuarioStore,
    private usuarioQuery: UsuarioQuery,
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService
  ) {
    const configurationOptions = {
      required: { e: -3, i: 11, f: -3, o: -3, s: -3, c: -3 },
      newwindow: true,
      insecure: true
    };
  }

  ngOnInit(): void {
    // console.log('---- CabecalhoInternoComponent.ngOnInit ---- ');
    this.usuario$ = this.usuarioQuery.usuario$;
    this.tokenCarregado$ = this.usuarioQuery.tokenCarregado$;
    this.usuario$.pipe(takeUntil(this.destroySubject$)).subscribe(usuario => {
     //  console.log('this.usuario.pipe');
      this.usuario = usuario;
      this.usuarioLogado = usuario;
    });
    this.tokenCarregado$.pipe(takeUntil(this.destroySubject$)).subscribe(tokenCarregado => {
        if (tokenCarregado) {
        //  console.log('tokenCarregado');
          this.authService
            .obterDadosUsuarioLogado()
            .pipe(takeUntil(this.destroySubject$))
            .subscribe();
        }
    });
  }

  ngOnDestroy(): void {
    this.destroySubject$.next();
  }
  logout(): void {
    // console.log('logout logout');
    this.authService.logout('sessao-encerrada');
  }
  // tslint:disable-next-line:typedef
  onRealizarLogoutCabecalho() {
    this.authService.logout('sessao-encerrada');
  }
  // tslint:disable-next-line:typedef
  onTrocarSenhaCabecalho() {
    // não implementado para esta aplicação
  }
}
