<app-cabecalho-interno></app-cabecalho-interno> <br /><br /><br />
<div class="container-lg" *ngIf="dadosBasicosPdm">
  <div class="mb-4 mt-2">
    <!-- cabeçalho -->
    <div class="row">
      <div class="col-8" id="cabecalho">
        <h2 class="mb-0" aria-label="Manter Material">
          <span class="mouseover"
          >Catálogo - Padrão descritivo de material</span
          >
        </h2>
        <h6 style="font-weight: 400" class="mb-0 mt-1">
          Use esta área para visualizar o padrão descritivo de material
        </h6>
      </div>
      <div class="col-4 text-right row" style="align-content: end">
        <div class="col-6 card border-0">
          <button
            class="br-button is-secondary"
            type="button"
            (click)="voltarTelaInicial()"
          >
            Voltar
          </button>
        </div>
        <div class="col-6 card border-0">
          <button
            class="br-button is-secondary"
            type="button"
            (click)="editarMaterial()"
          >
            Editar
          </button>
        </div>
      </div>
    </div>
  </div>
  <hr />

  <div class="mb-4 row">
    <h5 class="col-9">
      Código do padrão descritivo:
      <span style="color: red">{{ materialParams.id }}</span>
    </h5>
    <div class="row col-3" style="justify-content: flex-end">
        <label for="ativo">Ativo:
          <span style="font-weight: bold" *ngIf="dadosBasicosPdm.statusPdm == true">Sim</span>
          <span style="font-weight: bold" *ngIf="dadosBasicosPdm.statusPdm == false">Nao</span>
        </label>
    </div>
  </div>

  <br-fieldset
    [collapsible]="true"
    fieldsetTitle="Dados base"
    [collapsed]="expandCollapsed"
  >
    <div class="br-input">
      <label> Nome:</label>
      <input
        class="p-inputtext"
        type="text"
        name="PDM"
        [(ngModel)]="dadosBasicosPdm.nomePdm"
        readonly
      />
    </div>
    <div class="row mt-4">
      <div class="br-input col-12">
        <label> Classe:</label>
        <input
          class="p-inputtext"
          type="text"
          name="PDM"
          [(ngModel)]="dadosBasicosPdm.nomeClasse"
          readonly
        />
      </div>
    </div>
    <div class="br-input mt-4">
      <label> Grupo:</label>
      <input
        class="p-inputtext"
        type="text"
        name="PDM"
        [(ngModel)]="dadosBasicosPdm.nomeGrupo"
        readonly
      />
    </div>
    <div class="row mt-4 mb-4">
      <div class="br-input col-12">
        <label> Conjunto material:</label>
        <input
          class="p-inputtext"
          type="text"
          name="PDM"
          [(ngModel)]="dadosBasicosPdm.nomeAcentuadoConjunto"
          readonly
        />
      </div>
    </div>
  </br-fieldset>
  <br-second-tab-set>
    <br-second-tab
      name="Características"
      [active]="true"
      (activeChange)="carregaArray('caracteristica')"
    >
      <div class="row col-12 mt-4">
        <div class="col-4">
          <h6>Lista de características</h6>
        </div>
        <div class="br-checkbox col-8" style="align-self: flex-end; text-align: end;">
          <input id="somenteAtivos" name="somente-ativos" type="checkbox" aria-label="somente ativos" (click)="somenteAtivos()" [(ngModel)]="ativos"/>
          <label for="somenteAtivos">Mostrar somente características ativas</label>
        </div>
      </div>

      <p-table
        class="col-12 col-sm-12 col-lg-12"
        [value]="caracteristicasArrayTela"
        [reorderableColumns]="true"
        responsiveLayout="scroll"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="col-1">Ordem</th>
            <th class="col-1">Código</th>
            <th class="col-4">Nome</th>
            <th class="col-1">Ativo</th>
            <th class="col-2">Obrigatório</th>
            <th class="col-3">Ação</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-index="rowIndex">
          <tr [pReorderableRow]="index">
            <td class="col-1">
              <span>{{ index + 1 }}</span>
            </td>
            <td class="col-1">{{ item.codigoCaracteristica }}</td>
            <td class="col-4">{{ item.nomeCaracteristica }}</td>
            <td class="col-1">
              <span *ngIf="item.statusCaracteristica == true">Sim</span>
              <span *ngIf="item.statusCaracteristica == false">Nao</span>
            </td>
            <td class="col-2">
              <span *ngIf="item.caracteristicaObrigatoria == true">Sim</span>
              <span *ngIf="item.caracteristicaObrigatoria == false">Nao</span>
            </td>
            <td class="col-3">
              <div class="row">
                <button
                  class="br-button"
                  type="button"
                  pTooltip="Valores"
                  tooltipPosition="top"
                  (click)="BuscaValorCaracteristica(index)"
                  data-toggle="modal"
                  data-target="#editarvalorescaracteristicas"
                >
                  <i class="fas fa-calculator" aria-hidden="true"></i>
                </button>
                <button
                  class="br-button"
                  type="button"
                  pTooltip="Unidade de medida"
                  tooltipPosition="top"
                  (click)="BuscaUnidadeMedida(index)"
                  data-toggle="modal"
                  data-target="#editarunidademedida"
                >
                  <i class="fas fa-ruler-combined" aria-hidden="true"></i>
                </button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </br-second-tab>
    <br-second-tab
      name="Alias/ Palavra-chave"
      (activeChange)="carregaArray('alias')"
    >
      <br>
       <div *ngFor="let alias of aliasArray; let i = index" class="row">
        <div class="mb-4 mt-4"></div>
        <label class="col-sm-10" style="Margin-left:40px">
        {{ alias.nomeAliasPdmMaterial }}</label>
       </div>
    </br-second-tab>
    <br-second-tab
      name="Natureza da despesa"
      (activeChange)="carregaArray('natureza')"
    >
      <p-table
        class="col-6"
        [value]="naturezaDespesaArray"
        responsiveLayout="scroll"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="col-3"></th>
            <th class="col-4">
              Natureza da despesa
            </th>
            <th class="col-3">
              Ativo
            </th>
            <th class="col-1"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td class="col-3"></td>
            <td class="col-4">
              {{item.classificacaoContabilMaterial}}
            </td>
            <td class="col-3">
              Sim
            </td>
            <td class="col-1"></td>
          </tr>
        </ng-template>
      </p-table>
    </br-second-tab>
    <br-second-tab
      name="Unidade de fornecimento"
      (activeChange)="carregaArray('fornecimento')"
    >
      <div class="col-12 row mt-4">
        <h6 class="col-10">Lista de unidade de fornecimento</h6>
      </div>
      <p-table
        [value]="unidadeFornecimentoArray"
        responsiveLayout="scroll"
        [rows]="10"
        [paginator]="true"
        [rowHover]="true"
        dataKey="id"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} entradas"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[5, 10, 25, 50, 100]"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="col-1">
              Ordem
            </th>
            <th pSortableColumn="siglaUnidadeFornecimento" class="col-3" style="text-align: center;">
              Sigla unidade de fornecimento
              <p-sortIcon field="siglaUnidadeFornecimento"></p-sortIcon>
            </th>
            <th pSortableColumn="capacidadeUnidadeFornecimento" class="col-4" style="text-align: center;">
              Capacidade unidade de fornecimento
              <p-sortIcon field="capacidadeUnidadeFornecimento"></p-sortIcon>
            </th>
            <th pSortableColumn="siglaUnidadeMedida" class="col-3" style="text-align: center;">
              Sigla unidade de medida
              <p-sortIcon field="siglaUnidadeMedida"></p-sortIcon>
            </th>
            <th class="col-1">
              Ativo
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-index="rowIndex">
          <tr>
            <td class="col-1">
              {{ index + 1 }}
            </td>
            <td class="col-3" style="text-align: center">
                {{ item.siglaUnidadeFornecimento }} | {{ item.nomeUnidadeFornecimento }}
            </td>
            <td class="col-4" style="text-align: center">
                {{
                  item.capacidadeUnidadeFornecimento
                    ? item.capacidadeUnidadeFornecimento
                    : "--"
                }}
            </td>
            <td class="col-3" style="text-align: center">
              <span *ngIf="item.siglaUnidadeMedida">{{ item.siglaUnidadeMedida }} | {{ item.nomeUnidadeMedida }}</span>
                <span *ngIf="!item.siglaUnidadeMedida"> -- </span>
            </td>
            <td class="col-1">
              <span *ngIf="item.statusUnidadeFornecimentoPdm == true">Sim</span>
              <span *ngIf="item.statusUnidadeFornecimentoPdm == false">Nao</span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </br-second-tab>
  </br-second-tab-set>
  <div style="margin-bottom: 100px">
  </div>

  <!--Modals-->

  <div
    class="modal fade"
    id="editarvalorescaracteristicas"
    style="max-height: 80%"
  >
    <div class="modal-dialog modal-lg modal-cpc modal-dialog-centered">
      <div class="modal-content">
        <div class="container-lg">
          <div class="modal-header">
            <h4 class="modal-title">Valores para característica</h4>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-3 mt-3">
              <div class="col-sm-6 col-lg-6">
                <div *ngIf="caracteristicasArray != undefined">
                  <div>
                    Nome:
                    {{
                    caracteristicasArray[caracteristicaEscolhidaEmEdicao]
                    ?.nomeCaracteristica
                    }}
                  </div>
                  <div>
                    Código:
                    {{
                    caracteristicasArray[caracteristicaEscolhidaEmEdicao]
                    ?.codigoCaracteristica
                    }}
                  </div>
                </div>
              </div>
              <div class="col-6"></div>
            </div>
            <p-table
              [value]="valorCaracteristicaArray"
              [scrollable]="true"
              scrollHeight="400px"
            >
              <ng-template pTemplate="header" class="row">
                <tr>
                  <th class="col-sm-1 col-lg-1">Código</th>
                  <th class="col-sm-2 col-lg-2">Valor de característica</th>
                  <th class="col-sm-2 col-lg-2">Ativo</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-valor class="row">
                <tr>
                  <td class="col-sm-1 col-lg-1">
                    {{ valor.codigoValorCaracteristica }}
                  </td>
                  <td class="col-sm-2 col-lg-2">
                    {{ valor.nomeValorCaracteristica }}
                  </td>
                  <td class="col-2">
                    <span *ngIf="valor.statusValorCaracteristica == true">Sim</span>
                    <span *ngIf="valor.statusValorCaracteristica == false">Nao</span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-sm-2 col-lg-2">
            <button
              class="br-button primary"
              type="button"
              data-dismiss="modal"
            >
              <i class="fas fa-arrow-left" aria-hidden="true"> </i>Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="editarunidademedida" style="max-height: 80%">
    <div class="modal-dialog modal-lg modal-cpc modal-dialog-centered">
      <div class="modal-content">
        <div class="container-lg">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Unidades de medida</h4>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row mb-3 mt-3">
              <div class="col-6">
                <div *ngIf="caracteristicasArray != undefined">
                  <div>
                    Nome:
                    {{ caracteristicasArray[caracteristicaEscolhidaEmEdicao]?.nomeCaracteristica }}
                  </div>
                  <div>
                    Código:
                    {{ caracteristicasArray[caracteristicaEscolhidaEmEdicao]?.codigoCaracteristica }}
                  </div>
                </div>
              </div>
              <div class="col-6">
              </div>
            </div>
            <p-table
              [value]="unidadeMedidaArray"
              [scrollable]="true"
              scrollHeight="400px"
            >
              <ng-template pTemplate="header" class="row">
                <tr>
                  <th class="col-3">Sigla</th>
                  <th class="col-4">Nome</th>
                  <th class="col-3">Ativo</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-unidade class="row">
                <tr>
                  <td class="col-3">
                    {{ unidade.siglaUnidadeMedida }}
                  </td>
                  <td class="col-4">
                    {{ unidade.nomeUnidadeMedida }}
                  </td>
                  <td class="col-3">
                    <span *ngIf="unidade.statusUnidadeMedidaCaracteristica == true">Sim</span>
                    <span *ngIf="unidade.statusUnidadeMedidaCaracteristica == false">Nao</span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="br-button primary" data-dismiss="modal">
            <i class="fas fa-arrow-left" aria-hidden="true"> </i>Fechar
          </button>
        </div>
      </div>
    </div>
  </div>

</div>
