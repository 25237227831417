import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import {EnvironmentUtil} from '../../../environments/EnviromentUtil';
import {AuthService} from '../services/auth-service.service';

@Injectable({ providedIn: 'root' })
export class LoginFakeGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  // tslint:disable-next-line:typedef
  canActivate() {
    console.log('LoginFakeGuard -> canActivate -> isLoginFakeAtivo', EnvironmentUtil.isLoginFakeAtivo());
    if (EnvironmentUtil.isLoginFakeAtivo()) {
      return true;
    }
    this.authService.logout('/acesso-nao-autorizado');
    return false;
  }
}
