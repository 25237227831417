import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentUtil } from 'src/environments/EnviromentUtil';


@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  endpoint: string;
  constructor(private httpClient: HttpClient) {
   this.endpoint = EnvironmentUtil.obterUrlBase();
  }


  public upload(idPedido: any, file: File): Observable<any>{

    const formData = new FormData();
    formData.append('arquivo', file, file.name);
    const destino = this.endpoint + 'v1/anexo-pedido/' + idPedido;
    return this.httpClient.put<any>(destino, formData);

  }

  public obterAnexos(idPedido: any): Observable<any>{

    const destino = this.endpoint + 'v1/anexo-pedido/' + idPedido;
    return this.httpClient.get<any>(destino);

  }

  public downloadAnexo(idAnexo: any) {
    const destino = this.endpoint + 'v1/anexo-pedido/' + idAnexo + "/download";
    return this.httpClient.get<any>(destino, { responseType: 'blob' as 'json'});
  }

  public deletarAnexo(idPedido: any, idAnexo: any) {
    const destino = this.endpoint + 'v1/anexo-pedido/' + idPedido + '/arquivo/' + idAnexo ;
    return this.httpClient.delete<any>(destino);
  }

}
