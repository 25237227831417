import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessaoService } from 'src/app/service/sessao.service';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { PesquisaService } from 'src/app/arealogada/comprador/comprador.service';

@Component({
  selector: 'app-historico-analise',
  templateUrl: './historico-analise.component.html',
  styleUrls: ['./historico-analise.component.css']
})
export class HistoricoAnaliseComponent implements OnInit {

  @Input() modoVisualizacao: boolean;
  @Input() infoDaPesquisa: any;
  @Input() recebeTipoTela: string;

  id: any;
  dadosBasicos: any;
  loading: boolean = false;
  voltarTela: any;

  constructor(
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private sessaoService: SessaoService,
    private service: PesquisaService) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => this.id = params['id']);
    this.carregarPedidosPorNumero();
  }

  voltar() {
    this.router.navigate(['arealogada']);
  }

  carregarPedidosPorNumero(event?: LazyLoadEvent) {
    let numPedido = sessionStorage.getItem('numeroPedido');
    this.loading = true;
    this.service.obterPedidoLegadoPorNumero(this.id)
      .subscribe(resp => {
        this.dadosBasicos = resp;
        this.loading = false;
      },
        error => {
          this.loading = false;
          this.messageService.add({
            severity: 'warn',
            summary: 'Erro no carregamento dos dados'
          });
        });
  }

  // irParaAT() {
  //   this.sessaoService.irParaAT();
  // }

  // irParaPedidos() {
  //   if (this.recebeTipoTela === 'comprador') {
  //     this.router.navigate(['areacomprador']);
  //   } else {
  //     this.router.navigate(['areacatalogador']);
  //   }
  // }

}
