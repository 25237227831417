<app-cabecalho-interno></app-cabecalho-interno> <br/><br/><br/>
<div class="container-lg">
  <div class="mb-4 mt-2">
    <!-- cabeçalho -->
    <div class="row">
      <div class="col-8" id="cabecalho">
        <h2 class="mb-0" aria-label="Manter Material">
          <span class="mouseover">Catálogo - Valor de característica</span>
        </h2>
        <h6 style="font-weight: 400" class="mb-0 mt-1">
          Use esta área para criar/editar o valor de característica
        </h6>
      </div>
      <div class="col-4 mt-4 text-right row" style="align-items: end;">
        <div class="col-sm-6 card border-0">
          <button
            class="br-button is-secondary"
            type="button"
            (click)="voltarTelaInicial()"
          >
            Voltar
          </button>
        </div>
        <div class="col-sm-6 card border-0">
          <br-loading [visible]="visible" label="Salvando ..."></br-loading>
          <button
            class="br-button is-primary"
            type="button"
            data-toggle="modal"
            data-target="#incluirValor"
            (click)="limpaPesquisa()"
          >
            Incluir
          </button>
        </div>
      </div>
    </div>
  </div>

  <hr/>

  <br-fieldset [collapsible]="true" fieldsetTitle="Pesquisar Valor de característica" [collapsed]="expandCollapsed">
    <div class="row">
      <div class="col-10 mt-2 mb-2">
        <div class="br-input label">
          <input
            [(ngModel)]="buscaValorCaracteristica"
            (keyup)="enterPesquisar($event)"
            placeholder="Digite aqui uma palavra para pesquisar"
            field="nome"
          />
        </div>
      </div>
      <div class="col-md-2 mt-2 card border-0">
        <button
          (click)="pesquisar()"
          class="br-button primary justify-self-end"
          type="button"
        >
          Pesquisar
        </button>
      </div>
    </div>
  </br-fieldset>

  <hr/>

  <div *ngIf="escondeValor != true">
    <p-table
      [value]="valorCaracteristicaTela"
      [rows]="10"
      [paginator]="true"
      responsiveLayout="scroll"
      [rowHover]="true"
      dataKey="id"
      [(first)]="first"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} entradas"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[5, 10, 25, 50, 100]"
    >
      <ng-template pTemplate="caption">
        <div class="row flex align-items-center justify-content-between mb-3">
          <div class="ml-3">
            <h5 class="m-0">Valores de característica</h5>
          </div>

          <div class="br-checkbox">
            <input id="somenteAtivos" name="somente-ativos" type="checkbox" aria-label="somente ativos" (click)="somenteAtivos()" [(ngModel)]="ativos"/>
            <label for="somenteAtivos">Mostrar somente valores ativos</label>
          </div>

          <div class="br-input col-5">
            <input
              [(ngModel)]="filtroValorCaracteristica"
              (keyup)="filtrarValorCaracteristica()"
              placeholder="Digite uma palavra para filtrar o resultado"
            />
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="codigoValorCaracteristica">
            Código
            <p-sortIcon field="codigoValorCaracteristica"></p-sortIcon>
          </th>
          <th pSortableColumn="nomeValorCaracteristica">
            Nome
            <p-sortIcon field="nomeValorCaracteristica"></p-sortIcon>
          </th>
          <th pSortableColumn="statusValorCaracteristica">
            Ativo
            <p-sortIcon field="statusValorCaracteristica"></p-sortIcon>
          </th>
          <th>Ações</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-valor>
        <tr>
          <td>
            <span *ngIf="valor.statusValorCaracteristica  == true" style="color: black;">{{ valor.codigoValorCaracteristica }}</span>
            <span *ngIf="valor.statusValorCaracteristica  == false" style="color: #9f9a9a;">{{ valor.codigoValorCaracteristica }}</span>
          </td>
          <td>
            <span *ngIf="valor.statusValorCaracteristica  == true" style="color: black;">{{ valor.nomeValorCaracteristica }}</span>
            <span *ngIf="valor.statusValorCaracteristica  == false" style="color: #9f9a9a;">{{ valor.nomeValorCaracteristica }}</span>
          </td>
          <td>
            <span *ngIf="valor.statusValorCaracteristica  == true" style="color: black;">{{ valor.statusValorCaracteristica ? "Sim" : "Não" }}</span>
            <span *ngIf="valor.statusValorCaracteristica  == false" style="color: #9f9a9a;">{{ valor.statusValorCaracteristica ? "Sim" : "Não" }}</span>
          </td>
          <td>
            <button
              class="br-button"
              type="button"
              data-toggle="modal"
              data-target="#visualizarValor"
              aria-label="Botão de ação"
              pTooltip="Visualizar"
              (click)="pegarDados('visualiza', valor)"
              tooltipPosition="top"
            >
              <i class="fas fa-eye" aria-hidden="true"></i>
            </button>
            <button
              class="br-button"
              type="button"
              data-toggle="modal"
              data-target="#editarValor"
              pTooltip="Editar"
              (click)="pegarDados('edita', valor)"
              tooltipPosition="top"
            >
              <i class="fas fa-edit" aria-hidden="true"></i>
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div
    id="incluirValor"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="Classe"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-media">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Incluir valor de característica</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="container-lg">
          <div class="row mt-3">
            <label *ngIf="!existeNome" class="col-8">
              Nome do valor de característica<span class="asterisco">*</span>
            </label>
            <label *ngIf="existeNome" class="col-4">
              Nome do valor de característica<span class="asterisco">*</span>
            </label>
            <label *ngIf="existeNome" class="col-4" style="color: red;">
              Nome já cadastrado
            </label>
            <label class="col-4 texto-menor" style="text-align: end"
            >Caracteres restantes: <span>{{ numMaxNome }}</span></label
            >
          </div>
          <div class="br-input">
            <input #campoNome
                   (blur)="validaNome(campoNome)"
                   [(ngModel)]="incluirNome"
                   placeholder="Digite o nome do valor de característica"
                   (keydown)="enableSave()"
                   (keyup)="decrementaTexto('nome')"
                   maxlength="50"
            />
          </div>
          <div class="row mt-3">
            <label class="col-8">Descrição do valor de característica</label>
            <label class="col-4 texto-menor" style="text-align: end"
            >Caracteres restantes:
              <span>{{ numMaxDescricaoIncluir }}</span></label
            >
          </div>
          <div class="br-textarea">
            <textarea
              [(ngModel)]="incluirDescricao"
              placeholder="Descrição do valor de característica"
              (keyup)="decrementaTexto('descricaoIncluir')"
              maxlength="200"
            ></textarea>
          </div>
          <div class="row mt-3">
            <label class="col-8">Complemento do valor de característica</label>
            <label class="col-4 texto-menor" style="text-align: end"
            >Caracteres restantes:
              <span>{{ numMaxComplementoIncluir }}</span></label
            >
          </div>
          <div class="br-textarea mb-5">
            <textarea
              [(ngModel)]="incluirComplemento"
              placeholder="Complemento do valor de característica"
              (keyup)="decrementaTexto('complementoIncluir')"
              maxlength="1500"
            ></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <div>
            <button class="br-button secondary" data-dismiss="modal">
              Voltar
            </button>
          </div>
          <div>
            <button
              class="br-button primary"
              (click)="salvarValorCaracteristica()"
              data-dismiss="modal"
              [disabled]="disableSave"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    id="editarValor"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="Classe"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-media">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Editar valor de característica</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="container-lg">
          <div class="row">
            <div class="br-input mt-4 col-md-8">
              <label>Código do valor de característica</label>
              <input [(ngModel)]="editarCodigo" disabled/>
            </div>
            <div class="col-md-4 align-self-end" style="text-align: end">
              <div
                class="br-switch medium switch-basic"
                style="margin-left: 10px"
              >
                <input id="status" type="checkbox" [(ngModel)]="editarStatus"/>
                <label for="status">Ativo</label>
              </div>
            </div>
          </div>
          <div class="br-input mt-4">
            <label>Nome do valor de característica</label>
            <input [(ngModel)]="editarNome" disabled/>
          </div>
          <div class="row mt-4">
            <label class="col-8">Descrição do valor de característica</label>
            <label class="col-4 texto-menor" style="text-align: end"
            >Caracteres restantes:
              <span>{{ numMaxDescricaoEditar }}</span></label
            >
          </div>
          <div class="br-textarea">
            <textarea
              [(ngModel)]="editarDescricao"
              placeholder="Descrição do valor de característica"
              (keyup)="decrementaTexto('descricaoEditar')"
              maxlength="200"
            ></textarea>
          </div>
          <div class="row mt-4">
            <label class="col-8">Complemento do valor de característica</label>
            <label class="col-4 texto-menor" style="text-align: end"
            >Caracteres restantes:
              <span>{{ numMaxComplementoEditar }}</span></label
            >
          </div>
          <div class="br-textarea mb-5">
            <textarea
              [(ngModel)]="editarComplemento"
              placeholder="Complemento do valor de característica"
              (keyup)="decrementaTexto('complementoEditar')"
              maxlength="1500"
            ></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <div>
            <button class="br-button secondary" data-dismiss="modal">
              Voltar
            </button>
          </div>
          <div>
            <button
              class="br-button primary"
              (click)="editarValorCaracteristica()"
              data-dismiss="modal"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    id="visualizarValor"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="Classe"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-media">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Visualizar valor de característica</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="container-lg">
          <div class="row">
            <div class="br-input mt-3 col-md-8">
              <label>Código do valor de característica</label>
              <input [(ngModel)]="visualizaCodigo" readonly/>
            </div>
            <div class="col-md-4 align-self-end" style="text-align: end">
              <div
                class="br-switch medium switch-basic"
                style="margin-left: 10px"
              >
                <input
                  id="status"
                  type="checkbox"
                  [(ngModel)]="visualizaStatus"
                  readonly
                />
                <label for="status">Ativo</label>
              </div>
            </div>
          </div>
          <div class="br-input mt-3">
            <label>Nome do valor de característica</label>
            <input [(ngModel)]="visualizaNome" readonly/>
          </div>
          <div class="br-textarea mt-3">
            <label>Descrição do valor de característica</label>
            <textarea
              [(ngModel)]="visualizaDescricao"
              placeholder="Descrição do valor de característica"
              readonly
            ></textarea>
          </div>

          <div class="br-textarea mt-3 mb-5">
            <label>Complemento do valor de característica</label>
            <textarea
              [(ngModel)]="visualizaComplemento"
              placeholder="Complemento do valor de característica"
              readonly
            ></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <div>
            <button class="br-button secondary" data-dismiss="modal">
              Voltar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #valorCaracteristicaSucess>
    <div>
      <div class="modal-header">
        <h4 class="modal-title">Valor incluído com sucesso</h4>
        <button type="button" class="close" (click)="sairModalSalvar()">
          &times;
        </button>
      </div>
      <div class="container-lg">
        <div
          class="flex justify-content-center mb-5 mt-5"
          style="text-align: center"
        >
          <label>Valor de característica incluido com sucesso.</label><br/>
          <label>O código do valor de característica é: {{ novoCodigo }}</label>
        </div>
      </div>
      <div class="modal-footer">
        <div>
          <button class="br-button secondary" (click)="sairModalSalvar()">
            Voltar
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #valorCaracteristicaEditado>
    <div>
      <div class="modal-header">
        <h4 class="modal-title">Valor editado com sucesso</h4>
        <button type="button" class="close" (click)="sairModalEditar()">
          &times;
        </button>
      </div>
      <div class="container-lg">
        <div
          class="flex justify-content-center mb-5 mt-5"
          style="text-align: center"
        >
          <label>Valor de característica editado com sucesso.</label>
        </div>
      </div>
      <div class="modal-footer">
        <div>
          <button class="br-button secondary" (click)="sairModalEditar()">
            Voltar
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
