import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { MessageService } from 'primeng/api';
import { BuscaService } from 'src/app/service/busca-service';

@Component({
  selector: 'app-manutencao-caracteristica',
  templateUrl: './manutencao-caracteristica.component.html',
  styleUrls: ['./manutencao-caracteristica.component.css']
})
export class ManutencaoCaracteristicaComponent implements OnInit {

  visible: boolean = false;
  existeNome: boolean = false;
  // campos incluir
  incluirNome: any;
  incluirDetalhe: any;

  novoCodigo = '000000';
  modalRef1: BsModalRef;
  numMaxNome = 40;
  numMaxDescricao = 200;
  disableSave = true;

  caracteristicaCriada: any = null;

  @Output() novaCaracteristicaCriada: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('caracteristicaSucessoCriar', {static: false} ) caracteristicaSucessoCriar: any;

  constructor(private router: Router, private modalService: BsModalService,
    private buscaService: BuscaService, private messageService: MessageService) { }

  ngOnInit(): void {
  }

  abrirCriar() {
    this.incluirDetalhe = '';
    this.incluirNome = '';
    this.numMaxNome = 40;
    this.numMaxDescricao = 200;
    this.existeNome = false;
  }

  salvarCaracteristica(): void {
    let modalOptions: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.visible = true;
    this.buscaService.salvarCaracteristicaPDM({
      nomeCaracteristica: this.incluirNome.trim(),
      detalheCaracteristica: this.incluirDetalhe.trim(),
      statusCaracteristica: true,
    }).subscribe((tmp) => {
        this.visible = false;
        this.caracteristicaCriada = tmp;
        this.novoCodigo = tmp.codigoCaracteristica;
        this.incluirNome = '';
        this.incluirDetalhe = '';
        this.modalRef1 = this.modalService.show(this.caracteristicaSucessoCriar, modalOptions);
      },
      erro => {
        this.visible = false;
        if (typeof(erro.error) === 'string'){
          this.messageService.add({
            severity: 'error',
            summary: 'Erro ao salvar os dados no servidor: ' + erro.error
          });
        }else{
          this.messageService.add({
            severity: 'error',
            summary: 'Não foi possível se conectar com o serviço neste momento, por favor tente mais tarde.'
          });
        }


      });
  }

  decrementaTexto(campo: string): void {
    if ( campo === 'nome'){
      this.numMaxNome = 40;
      this.numMaxNome = this.numMaxNome - (this.incluirNome.length);
    }

    if ( campo === 'descricao'){
      this.numMaxDescricao = 200;
      this.numMaxDescricao = this.numMaxDescricao - (this.incluirDetalhe.length);
    }
  }

  enableSave(): void {
    if (this.incluirNome === '' ){
      this.disableSave = true;
    } else{
      this.disableSave = false;
    }

  }

  validaNome(campo: any){
    let aux = this.incluirNome.trim();
    this.buscaService.validaNomeExistenteCaracteristica(aux).subscribe((tmp)=>{
      if(tmp > 0){
        this.existeNome = true;
        this.disableSave = true;
        this.incluirNome = "";
        campo.focus();
      }
      else {
        this.existeNome = false;
      }
    })
  }

  sairModalSalvar(): void {
    this.novaCaracteristicaCriada.emit(this.caracteristicaCriada);
    this.modalRef1.hide();
  }

}
