import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {Observable} from 'rxjs';
import {NovoItemMaterial} from '../busca-siasgnet/model/novo-item-material.model';
import {EnvironmentUtil} from '../../environments/EnviromentUtil';


@Injectable({ providedIn: 'root' })  // busca service inicial
export class Service {
  endpoint: string;
  constructor(private httpClient: HttpClient) {
   this.endpoint = EnvironmentUtil.obterUrlBase();
  }

  // tslint:disable-next-line:typedef
  obterItens(texto: string): Observable<any>{
    let params = new HttpParams();
    params = params.set('palavrapesquisada', texto);
    const destino = this.endpoint +  'item/v1/materialservico';
    return this.httpClient.get<any[]>(destino, {params});
  }

  // tslint:disable-next-line:typedef
  obterUnidadeMedida(texto: string): Observable<any>{
    let params = new HttpParams();
    params = params.set('codigo_servico', texto);
    const destino = this.endpoint + 'servico/v1/unidadeMedidaPorCodigo';
    return this.httpClient.get<any[]>(destino, {params});
  }

  // tslint:disable-next-line:typedef
  obterServicosPorPalavra(texto: any, apenasAtivos?: string) {
    if (isNaN(texto)){
      let params = new HttpParams();
      params = params.set('palavra', texto);
      // const destino = this.endpoint + 'servico/v1/servicoPorPalavraPostgre';
      if (apenasAtivos && 'nao' === apenasAtivos) {
        params = params.set('apenasAtivos', apenasAtivos);
      }
      const destino = this.endpoint + 'servico/v1/palavra';
      return this.httpClient.get<any[]>(destino, {params});
    } else {
      let params = new HttpParams();
      params = params.set('codigo_servico', texto);
      const destino = this.endpoint + 'servico/v1/servicoPorCodigoCompleto';
      return this.httpClient.get<any[]>(destino, {params});
    }
  }

  // tslint:disable-next-line:typedef
  obterPDMPorPalavra(texto: any, apenasAtivos?: string, buscaPdm?: any, buscaClasse?:any) {
    if (isNaN(texto)){
        let params = new HttpParams();
        params = params.set('palavra', texto);
        if (apenasAtivos && 'nao' === apenasAtivos) {
          params = params.set('apenasAtivos', apenasAtivos);
        }
      const destino = this.endpoint + 'material/v1/palavra';
      return this.httpClient.get<any[]>(destino, {params});
    }
    else{
      let params = new HttpParams();
      params = params.set('codigoPdmClasse', texto);
      params = params.set('buscaPdm', buscaPdm);
      params = params.set('buscaClasse', buscaClasse);
      const destino = this.endpoint + 'material/v1/codigoPdmClasse';
      return this.httpClient.get<any[]>(destino, {params});
    }
  }
  // tslint:disable-next-line:typedef
  obterPDMeServicoPorPalavra(texto: any) {
    let params = new HttpParams();
    params = params.set('palavra', texto);
    const destino = this.endpoint + 'item/v1/hint';
    return this.httpClient.get<any[]>(destino, {params});
  }

  obterClassificacaoContabilPorCodigo(idservico: string): Observable<any> {
      let params = new HttpParams();
      params = params.set('codigo_servico', idservico);
      const destino = this.endpoint + 'servico/v1/classificacaoContabilPorCodigo';
      return this.httpClient.get<any[]>(destino, {params});
  }

  obterServicoPorCodigo(codigo: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('codigo_servico', codigo);
    const destino = this.endpoint + 'servico/v1/dadosServicoPorCodigo';
    return this.httpClient.get<any[]>(destino, {params});
  }
  obterMaterialPorCodigo(codigo: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('codigo_item_material', codigo);
    const destino = this.endpoint + 'material/v1/recuperaDadosItemMaterialPorCodigo';
    return this.httpClient.get<any[]>(destino, {params});
  }

  obterCaracteristicasPorPDM(codigo: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('codigo_pdm', codigo);
    params = params.set('todos', 'true');
    params = params.set('naoRetornaSiglaUnidadeMedidaCaracteristica',' false');
    const destino = this.endpoint + 'material/v1/caracteristicaPorCodigoPdm';
    return this.httpClient.get<any[]>(destino, {params});
  }

  obterItemMaterialCaracateríticasValorPorPDM(codigo: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('codigo_pdm', codigo);
    const destino = this.endpoint + 'material/v1/materialCaracteristcaValorporPDM';
    return this.httpClient.get<any[]>(destino, {params});
  }

  obterItemMaterialCaracateríticasValorPorPDMSemFiltro(codigo: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('codigo_pdm', codigo);
    const destino = this.endpoint + 'material/v1/materialCaracteristicaValorPdmSemFiltro';
    return this.httpClient.get<any[]>(destino, {params});
  }

  obterUnidadeFornecimentoPorPDM(codigo: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('codigo_pdm', codigo);
    const destino = this.endpoint + 'material/v1/unidadeFornecimentoPorCodigoPdm';
    return this.httpClient.get<any[]>(destino, {params});
  }
  obterClassificacaoContabilPorCodigoPdm(codigo: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('codigo_pdm', codigo);
    const destino = this.endpoint + 'material/v1/classificacaoContabilPorCodigoPdm';
    return this.httpClient.get<any[]>(destino, {params});
  }
  criarNovoItemMaterial(novoItemMaterial: NovoItemMaterial): Observable<any> {
   // console.log(JSON.stringify(novoItemMaterial));
    const destino = this.endpoint + 'material/v1/novoItem';
    return this.httpClient.post<any>(destino, {
      pdm: novoItemMaterial.pdm,
      classe: novoItemMaterial.classe,
      sustentavel: novoItemMaterial.sustentavel,
      exclusivoCentralCompras: novoItemMaterial.exclusivoCentralCompras,
      materialCaracteristicas: novoItemMaterial.novoItemMaterialCaracteristica
    });
  }

  dadosBasicosPdmPorCodigo(codigo: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('codigoPdm', codigo);
    const destino = this.endpoint + 'material/v1/dadosbasicospdmporcodigo';
    return this.httpClient.get<any[]>(destino, {params});
  }

}
