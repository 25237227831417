import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SessaoService } from 'src/app/service/sessao.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { MenuItem, MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { FiltroService } from '../service/filtro.service';
import { TabelaPedidosComponent } from './tabela-pedidos/tabela-pedidos/tabela-pedidos.component';


@Component({
  selector: 'app-comprador',
  templateUrl: './comprador.component.html',
  styleUrls: ['./comprador.component.css']
})
export class CompradorComponent implements OnInit, AfterViewInit {

  modalFiltroPedidosRef: BsModalRef;
  @ViewChild('modalFiltroPedido', { static: false }) modalFiltroPedido: any;

  @ViewChild('listaNaoDistribuido', { static: false }) listaNaoDistribuido: TabelaPedidosComponent;
  @ViewChild('listaPendente', { static: false }) listaPendente: TabelaPedidosComponent;
  @ViewChild('listaDevolvido', { static: false }) listaDevolvido: TabelaPedidosComponent;
  @ViewChild('listaAceito', { static: false }) listaAceito: TabelaPedidosComponent;

  abaAtiva: string = ABA.NAO_DISTRIBUIDO;

  textoBusca: string = '';
  buscaCotacao: any;
  filtroItem: any;
  filtroDataIni: string;
  filtroDataFim: string;
  // itemsmenubotao: MenuItem[];
  abaNaoDistribuido: boolean = false;

  dataFim: string;// = '20231129';
  dataInicio: string;// = '20181129';

  NAO_DISTRIBUIDO = ABA.NAO_DISTRIBUIDO;
  PENDENTE = ABA.PENDENTE;
  DEVOLVIDO = ABA.DEVOLVIDO;
  ACEITO = ABA.ACEITO;

  constructor(
    private router: Router,
    private sessaoService: SessaoService,
    private modalService: BsModalService,
    private messageService: MessageService,
    private filtroService: FiltroService
  ) { }

  ativarAba(aba: any) {
    this.abaAtiva = aba;
    this.abaNaoDistribuido = false;
    if (aba === this.NAO_DISTRIBUIDO) {
      this.listaNaoDistribuido.carregarPedidos(null, this.dataFim, this.dataInicio);
      this.abaNaoDistribuido = true;
    } else if (aba === this.PENDENTE) {
      this.listaPendente.carregarPedidos(null, this.dataFim, this.dataInicio);
    } else if (aba === this.DEVOLVIDO) {
      this.listaDevolvido.carregarPedidos(null, this.dataFim, this.dataInicio);
    } else if (aba === this.ACEITO) {
      this.listaAceito.carregarPedidos(null, this.dataFim, this.dataInicio);
    }
  }

  ngAfterViewInit(): void {
    this.ativarAba(this.NAO_DISTRIBUIDO);
  }

  ngOnInit(): void {

    this.aplicarDatasIniciais();

    // this.itemsmenubotao = [{
    //   items: [
    //     {
    //       label: 'Serviço',
    //       command: () => {
    //         this.manterServico();
    //       }
    //     },
    //     {
    //       label: 'Material',
    //       command: () => {
    //         this.manterMaterial();
    //       }
    //     },
    //     {
    //       label: 'Característica',
    //       command: () => {
    //         this.manterCaracteristica();
    //       }
    //     },
    //     {
    //       label: 'Valor de característica',
    //       command: () => {
    //         this.manterValorCaracteristica();
    //       }
    //     },
    //     {
    //       label: 'Unidade de Fornecimento',
    //       command: () => {
    //         this.manterUnidadeFornecimento();
    //       }
    //     },
    //     {
    //       label: 'Unidade de Medida',
    //       command: () => {
    //         this.manterUnidadeMedida();
    //       }
    //     }
    //   ]
    // }
    // ];
  }

  // private manterServico() {
  //   this.router.navigate(['/buscaservico']);
  // }
  // // tslint:disable-next-line:typedef
  // private manterMaterial() {
  //   console.log('buscaMaterial');
  //   this.router.navigate(['/buscamaterial']);
  // }

  // private manterCaracteristica(): void {
  //   console.log('criar caracteristica');
  //   this.router.navigate(['/mantercaracteristica']);
  // }

  // private manterValorCaracteristica(): void {
  //   console.log('criar valor caracteristica');
  //   this.router.navigate(['/mantervalorcaracteristica']);
  // }

  // private manterUnidadeFornecimento(): void {
  //   console.log('criar unidade fornecimento');
  //   this.router.navigate(['/manterunidadefornecimento']);
  // }

  // private manterUnidadeMedida(): void {
  //   console.log('criar unidade de medida');
  //   this.router.navigate(['/manterunidademedida']);
  // }

  // this.router.navigate(['/busca']);

// resultadobusca($event: any): void {
//   const resultado = $event;
//   console.log(JSON.stringify($event));
//   this.resultadoMaterial = resultado.resultadoMaterial;
//   this.resultadoServico = resultado.resultadoServico;
// }

irParaAT() {
  this.sessaoService.irParaAT();
}

enterPesquisar($event: KeyboardEvent) {
  if ($event.key === 'Enter') {
    this.buscarPorTexto();
  }
}

buscarPorTexto() {
  // if (this.textoBusca && this.textoBusca.trim() !== '') {
  //   this.buscaCotacao = this.textoBusca.trim();
  //   sessionStorage.setItem('buscaPesquisaCotacao', this.buscaCotacao);
  // } else {
  //   this.buscaCotacao = null;
  //   this.textoBusca = '';
  //   sessionStorage.removeItem('buscaPesquisaCotacao');
  // }
}

limparFiltrosDatas() {
  this.aplicarDatasIniciais();
  this.modalService.hide();
  this.filtroService.anunciarFiltroAlterado({dataFim: this.dataFim, dataInicio: this.dataInicio});
}

limparFiltrosTexto() {
  this.filtroItem = '';
  this.ngOnInit();
}

abrirModalFiltroPedido() {
  const modalOptions: ModalOptions = { class: 'modal-lg' };
  this.modalFiltroPedidosRef = this.modalService.show(this.modalFiltroPedido, modalOptions);
}

aplicarFiltrosDatas() {
  if (!this.filtroDataIni) {
    this.messageService.add({
      severity: 'error',
      summary: 'A data inicial deve ser informada'
    });
    return;
  }
  if (!this.filtroDataFim) {
    this.messageService.add({
      severity: 'error',
      summary: 'A data final deve ser informada'
    });
    return;
  }
  const hoje = new Date();
  hoje.setHours(0,0,0,0);
  let time = this.filtroDataIni.split('-');
  const ini = new Date(parseInt(time[0]), parseInt(time[1]) - 1, parseInt(time[2]));
  ini.setHours(0,0,0,0);
  time = this.filtroDataFim.split('-');
  const fim = new Date(parseInt(time[0]), parseInt(time[1]) - 1, parseInt(time[2]));
  fim.setHours(0,0,0,0);
  if (fim.getTime() > hoje.getTime()) {
    this.messageService.add({
      severity: 'error',
      summary: 'A data final não pode ser maior que hoje'
    });
    return;
  }
  if (fim.getTime() < ini.getTime()) {
    this.messageService.add({
      severity: 'error',
      summary: 'A data final não pode ser menor que a data inicial'
    });
    return;
  }
  const diffTime = Math.abs(fim.getTime() - ini.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  let bissexto: any;
  if (ini.getFullYear() % 4 === 0) {
    bissexto = 366
  } else {
    bissexto = 365
  }
  if (diffDays > bissexto) {
    this.messageService.add({
      severity: 'error',
      summary: 'A diferença entre datas não poderá ser maior que um ano'
    });
    return;
  }

  const pipe = new DatePipe('en-US');
  const sini = pipe.transform(ini, 'yyyyMMdd');
  const sfim = pipe.transform(fim, 'yyyyMMdd');
  sini ? this.dataInicio = sini : this.dataInicio = '';
  sfim ? this.dataFim = sfim : this.dataFim = '';

  this.modalService.hide();
  this.filtroService.anunciarFiltroAlterado({dataFim: this.dataFim, dataInicio: this.dataInicio});
}

aplicarDatasIniciais() {
  const pipe = new DatePipe('en-US');
  const fim = new Date();//hoje
  const ini = new Date(fim);
  ini.setFullYear(fim.getFullYear() - 1);

  let sini = pipe.transform(ini, 'yyyy-MM-dd');
  let sfim = pipe.transform(fim, 'yyyy-MM-dd');

  sini ? this.filtroDataIni = sini : this.filtroDataIni = '';
  sfim ? this.filtroDataFim = sfim : this.filtroDataFim = '';

  sini = pipe.transform(ini, 'yyyyMMdd');
  sfim = pipe.transform(fim, 'yyyyMMdd');

  sini ? this.dataInicio = sini : this.dataInicio = '';
  sfim ? this.dataFim = sfim : this.dataFim = '';
}

aplicarFiltrosTexto() {
  // let hoje = new Date();
  // console.log(hoje);
  // if (this.filtroDataFim > hoje) {
  //   this.messageService.add({
  //     severity: 'warn',
  //     summary: 'A data final não pode ser maior que hoje'
  //   });
  // }
  // if (this.diferencaEmAnos(this.filtroDataIni, this.filtroDataFim) > 1) {
  //   this.messageService.add({
  //     severity: 'warn',
  //     summary: 'A diferença entre as datas deve ser menor que um ano'
  //   });
  // }
}

fechaModalFiltro() {
  this.modalService.hide();
}

diferencaEmAnos(dataInicial: Date, dataFinal: Date): number {
  const diferencaEmMilissegundos = dataFinal.getTime() - dataInicial.getTime();
  const milissegundosEmUmAno = 1000 * 60 * 60 * 24 * 365.25; // Considera anos bissextos
  const diferencaEmAnos = diferencaEmMilissegundos / milissegundosEmUmAno;
  return diferencaEmAnos;
}

}

export enum ABA {

  NAO_DISTRIBUIDO = "NAO_DISTRIBUIDO",
  PENDENTE = "PENDENTE",
  DEVOLVIDO = "DEVOLVIDO",
  ACEITO = "ACEITO",

}
