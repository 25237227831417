import { Component, OnInit } from '@angular/core';
import { SessaoService } from 'src/app/service/sessao.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadFileService } from 'src/app/upload-file/upload-file.service';
import { AnexoFile } from 'src/app/anexo-file';
import { MessageService } from 'primeng/api';
import { PedidoForm } from '../../model/pedido-form.model';
import { PesquisaService } from '../pedido-catalogador.service';
import { AuthService } from '../../../security/services/auth-service.service';
import { UsuarioRepresentation } from 'src/app/security/model/usuario';

@Component({
  selector: 'app-anexos-pedido',
  templateUrl: './anexos-pedido.component.html',
  styleUrls: ['./anexos-pedido.component.css']
})
export class AnexosPedidoComponent implements OnInit {

  constructor(
    private sessaoService: SessaoService,
    private route: ActivatedRoute,
    private router: Router,
    private uploadService: UploadFileService,
    private messageService: MessageService,
    private service: PesquisaService,
    private authService: AuthService
  ) {}

  token: any;
  tokenPayload: any;
  eAdministrador = false;
  // cpfUsuarioPedido: any;
  uasgSolicitantePedido: any;
  // cpfUsuarioLogado: any;
  uasgUsuarioLogado: any;
  idPedido: any;
  situacaoPedido: any;
  numeroPedido: any;
  pedido: PedidoForm;
  fileList: FileList;
  guardaFiles: Set<File> = new Set();
  anexos: AnexoFile[] = [];
  pageSize = 10;
  currentPage = 1;
  acceptedFiles: string = '.bmp,.gif,.heic,.heif,.jpg,.jpeg,.png,.svg,.tiff,.webp,.doc,.docx,.html,.odb,.odc,.odf,.odg,.odp,.ods,.odt,.pdf,.ppt,.pptx,.rtf,.sxc,.sxd,.sxi,.sxm,.sxw,.txt,.xhtml,.xls,.xlsx,.xml,.7z,.rar,.zip';

  ngOnInit(): void {
    this.route.params.subscribe((params) => this.numeroPedido = params['numeroPedido']);
    this.service.obterPedidoPorNumero(this.numeroPedido).subscribe((pedido) => {
      this.pedido = pedido;
      this.idPedido = pedido.id;
      this.situacaoPedido = pedido.situacaoPedido;
      // this.cpfUsuarioPedido = pedido.cpfSolicitante;
      this.uasgSolicitantePedido = pedido.codigoUasgSolicitante;
      const ur:UsuarioRepresentation = this.authService.getUsuarioLogado();
      // this.cpfUsuarioLogado = ur.cpf;
      this.uasgUsuarioLogado = ur.uasg.numero;
      this.carregaAnexos();
      this.token = this.authService.token;
      this.tokenPayload = this.authService.getTokenPayload(this.token);
      this.eAdministrador = this.authService.hasMnemonicosAdministrador(this.tokenPayload.mnemonicos);
    }, (error) => {
      this.messageService.add({
        severity: 'error',
        summary: 'Erro ao recuperar pedido. Tente novamente.'
      });
    });
  }

  irParaAT() {
    this.sessaoService.irParaAT();
  }

  irParaPedidos() {
    this.router.navigate(['areacomprador']);
  }

  confirmarAnexo(form: { reset: () => void }) {
    for (let i = 0; i < this.fileList.length; i++) {
      this.guardaFiles.add(this.fileList[i]);
    }

    form.reset();
    this.subirAnexoServidor();
  }
  subirAnexoServidor() {
    this.guardaFiles.forEach((file) => {
      this.uploadService
        .upload(this.idPedido, file)
        .subscribe(
          (resp) => {
            this.anexos.push(resp);
            this.messageService.add({
              severity: 'success',
              summary: 'Anexo incluído com sucesso',
            });
          },
          (error) => {
            if (error && typeof error.error === 'string') {
              this.messageService.add({
                severity: 'error',
                summary: error.error,
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Erro ao subir anexo.',
              });
            }
          }
        );
    });
    this.guardaFiles = new Set();
  }

  downloadAnexo(anexo: any) {
    this.uploadService.downloadAnexo(anexo.idArquivo).subscribe(
      (response) => {
        let blob = new Blob([response], {
          type: response.type,
        });

        // IE
        // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //   window.navigator.msSaveOrOpenBlob(blob);
        //   return;
        // }

        // Outros browsers
        const data = window.URL.createObjectURL(blob);

        var link = document.createElement('a');
        link.href = data;
        link.download = anexo.nomeArquivo;
        // Necessário, pois link.click() não funciona com firefox mais novo
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );

        setTimeout(function () {
          // Para o Firefox é necessário atrasar o revoke do ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      },
      (error) => {
        if (error.error && typeof error.error.mensagem === 'string') {
          this.messageService.add({
            severity: 'error',
            summary: error.error.mensagem,
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro ao realizar download.',
          });
        }
      }
    );
  }

  deletarAnexo(idAnexo: any) {
    this.uploadService
      .deletarAnexo(this.idPedido, idAnexo)
      .subscribe((resp) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Anexo excluido com sucesso.',
        });
        this.carregaAnexos();
      });
  }

  carregaAnexos() {
    this.uploadService
      .obterAnexos(this.idPedido)
      .subscribe((resp) => {
        this.anexos = resp;
      });
  }

  podeDeletar(){
    if ((this.eAdministrador === true && this.situacaoPedido != 'FINALIZADO') ||
        (this.uasgSolicitantePedido == this.uasgUsuarioLogado && this.situacaoPedido == 'RASCUNHO')){ return true; }
    else{ return false; }
  }

  podeAnexar(){
    if (this.uasgSolicitantePedido == this.uasgUsuarioLogado && (this.situacaoPedido == 'RASCUNHO' || this.situacaoPedido == 'AGUARDANDO_SOLICITANTE')){ return true }
    else{ return false }
  }
}
