
<div class = 'br-header cabecalho compact no-search no-subtitle'  >
  <div class="container-lg ">
    <div class=" row">
      <div class="col-3 col-md-2 col-lg-2 pt-2 pl-1   p-0 m-0  mb-2">
<!--        <img src="./assets/imagens/logo.svg "   title="Compras.gov.br" alt="Compras.gov.br" />-->
        <img src="assets/imagem/logo.svg" height="45px">
      </div>
      <div class="col-9 col-md-10 col-lg-10 p-0 m-0  ">
        <div class=" d-flex justify-content-end align-items-center">
          <div class="mt-2  pr-0 mr-0"  *ngIf="usuarioLogado?.isTipoUsuarioGoverno">
            <ul>
              <a href="javascript:void(0);"  >{{ usuarioLogado?.uasg?.nome }} | {{ usuarioLogado?.uasg?.numero }}</a>
            </ul>
          </div>
          <div class=" mt-3 ">
            <span *ngIf="usuarioLogado" >
                <lib-comprasnet-hub-acesso-sistemas
                  [usuario]="usuarioLogado"
                  [exibeTrocarSenha]="exibeTrocarSenha"
                  (realizarLogout)="onRealizarLogoutCabecalho()"
                  (trocarSenha)="onTrocarSenhaCabecalho()"
                >
                </lib-comprasnet-hub-acesso-sistemas>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<header>-->
<!--  <div class="header-caixa" >-->
<!--  <h1>-->
<!--    <img src="./assets/imagens/logo.svg "   title="Compras.gov.br" alt="Compras.gov.br" />-->
<!--  </h1>-->

<!--    <nav>-->
<!--      <ul>-->
<!--        <li><a></a></li>-->
<!--        <li><a></a></li>-->
<!--        <li><a>-->
<!--          <a href="javascript:void(0);"  >{{ usuarioLogado?.uasg?.nome }} | {{ usuarioLogado?.uasg?.numero }}</a>-->
<!--        </a>-->
<!--        </li>-->
<!--        <li><span *ngIf="usuarioLogado" >&ndash;&gt;-->
<!--          <lib-comprasnet-hub-acesso-sistemas>-->
<!--            [usuario]="usuarioLogado"-->
<!--            [exibeTrocarSenha]="exibeTrocarSenha"-->
<!--            (realizarLogout)="onRealizarLogoutCabecalho()"-->
<!--            (trocarSenha)="onTrocarSenhaCabecalho()"-->
<!--            >-->
<!--          </lib-comprasnet-hub-acesso-sistemas>-->
<!--        </span>-->
<!--        </li>-->
<!--      </ul>-->
<!--    </nav>-->
<!--  </div>-->
<!--</header>-->



<!--<div class="d-flex justify-content-between br-header cabecalho compact no-search no-subtitle ">-->
<!--  <div class="p-2 "><img src="./assets/imagens/logo.svg "  class="col-5    " title="Compras.gov.br" alt="Compras.gov.br" /></div>-->
<!--  <div class=" ">-->
<!--    <div class=" text-right"  *ngIf="usuarioLogado?.isTipoUsuarioGoverno">-->
<!--      <ul>-->
<!--        <a href="javascript:void(0);"  >{{ usuarioLogado?.uasg?.nome }} | {{ usuarioLogado?.uasg?.numero }}</a>-->
<!--      </ul>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="p-2 ">-->
<!--    <span *ngIf="usuarioLogado" >-->
<!--                      <lib-comprasnet-hub-acesso-sistemas-->
<!--                        [usuario]="usuarioLogado"-->
<!--                        [exibeTrocarSenha]="exibeTrocarSenha"-->
<!--                        (realizarLogout)="onRealizarLogoutCabecalho()"-->
<!--                        (trocarSenha)="onTrocarSenhaCabecalho()"-->
<!--                      >-->
<!--                      </lib-comprasnet-hub-acesso-sistemas>-->
<!--      </span>-->




<!--  </div>-->
<!--</div>-->
