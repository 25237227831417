import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { Servico } from 'src/app/busca/model/servico.model';
import {ActivatedRoute, Router} from '@angular/router';
import {Service} from '../../service/service';
// import {Material} from "../model/material.model";
import {Item} from '../model/item.model';
import {MessageService} from 'primeng/api';
import {AplicacaoService} from '../../service/aplicacao.service';
import {interval, Subscription} from 'rxjs';

@Component({
  selector: 'app-detalhe-servico-siasgnet-lote',
  templateUrl: './detalhe-servico-siasgnet-lote.component.html',
  styleUrls: ['./detalhe-servico-siasgnet-lote.component.css']
})
export class DetalheServicoSiasgnetLoteComponent implements OnInit, OnDestroy{
  @Output() aoVoltar = new EventEmitter<any>();
  duplicado: any;
  id_servico: any;
  nome_servico: any;
  unidMedida: any [] = [];
  classificaContabil: any [] = [];
  selected: any;
  item: Item;
  itens: Item[] = [];
  private subscription: Subscription;

  @Input()
  servico: any;

  constructor(private route: ActivatedRoute, private service: Service, private router: Router,
              private messageService: MessageService, public app: AplicacaoService) { }

  ngOnInit() {

    this.id_servico = this.servico.id;
    this.nome_servico = this.servico.nome;

    this.pesquisar();
    this.getItens();

    // this.subscription = interval(1000).subscribe(x => {
    //   this.app.counter = --this.app.counter;
    //   if ( this.app.counter === this.app.counter_blink){
    //     this.messageService.add({severity: 'info', summary: 'Salve seu trabalho.', detail: 'Tempo de Conexão se esgotando.'});
    //   } else if (this.app.counter === 0) {
    //     this.messageService.add({severity: 'info', summary: 'Tempo de Conexão Esgotado.', detail: ''});
    //
    //   }
    // });
  }

  ngOnDestroy(): void {
     //  this.subscription.unsubscribe();
  }

   pesquisar(): void {
    // this.service.obterUnidadeMedida(this.id_servico).subscribe(unidadeMedida => this.unidMedida = unidadeMedida);
    this.service.obterUnidadeMedida(this.id_servico).subscribe(unidadeMedida => {
      this.unidMedida = unidadeMedida ;
      if (unidadeMedida){
        for (const tmp of unidadeMedida) {
          this.unidMedida.push();
        }
      }
      if (this.unidMedida.length === 1){
        this.selected = this.unidMedida[0];
      }
    });
    this.service.obterClassificacaoContabilPorCodigo(this.id_servico).subscribe(classContabil => this.classificaContabil = classContabil);
  }
  escolher(): void {
    if (this.selected == null){
      this.messageService.add({severity: 'warn', summary: 'Selecione ao menos uma unidade de fornecimento.', detail: ''});

      return;
    }
//     if (this.naovalidar() ){
//       this.messageService.add({severity:'info', summary: 'Item já existente nos Selecionados.', detail: ''});
//       return;
//     }
    this.item = new Item();
    this.item.id = this.id_servico;
    this.item.nome = this.nome_servico;
    this.item.tipo = 'SERVIÇO';
    this.item.descricao = this.nome_servico;
    this.item.descricaoDetalhada = this.nome_servico;
    this.item.unidade = this.selected;
    this.item.type = 'S';
    this.item.nomeUnidadeMedida = this.selected.nomeUnidadeMedida;
    this.item.siglaUnidadeMedida = this.selected.siglaUnidadeMedida;

    this.itens.push(this.item);
    localStorage.setItem('itens', JSON.stringify(this.itens));

    this.messageService.add({
      severity: 'success',
      summary: 'Item armazenado na lista.'
    });
  }

  naovalidar(): boolean{
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.itens.length; i++){
      if (this.itens[i].id == this.id_servico  && this.itens[i].unidade.nomeUnidadeMedida == this.selected.nomeUnidadeMedida) {
        return true;
      }
    }
    return false;
  }

  selecionados(): void {
    this.router.navigate(['/exibirselecionadossiasgnetlote']);
  }


  getItens(): Item[]{
    const saved = localStorage.getItem('itens');
    if (saved != null){
      this.itens = JSON.parse(saved);
    }
    return this.itens;
  }
   voltar(): void{
     // this.router.navigate(['/buscasiasgnetlote']);
     this.aoVoltar.emit();

   }
}
