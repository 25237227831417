import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BuscaService } from 'src/app/service/busca-service';

@Component({
  selector: 'app-selecionar-cpc',
  templateUrl: './selecionar-cpc.component.html',
  styleUrls: ['./selecionar-cpc.component.css']
})
export class SelecionarCpcComponent implements OnInit {

  paginarClasse: boolean = true;
  rows = 10;
  classeFiltrada: any = [];
  first: any;
  classeServico: any;

  @Output() cpcSelecionadoEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private buscaService: BuscaService) { }

  ngOnInit(): void {
    this.buscaService
      .obterClasseServico()
      .subscribe((resp) => {
        this.classeServico = resp;
        this.openModal()
      });
  }

  openModal() {
    this.classeFiltrada = this.classeServico;
    this.first = 0;
  }

  filtrarClasse(evento: string) {
    this.classeFiltrada = [];
    let aux1;
    let aux2;
    for (let i of this.classeServico) {
      aux1 = i.nomeClasse.toLowerCase();
      aux2 = i.codigoClasse.toString();
      if (aux1.includes(evento.toLowerCase()) || aux2.includes(evento.toLowerCase())) {
        this.classeFiltrada.push(i);
      }
    }
    this.first = 0;
  }

  selectCPC(codigo: any, nomeCpc: any): void {
    this.cpcSelecionadoEvent.emit({
      codigoCpc : codigo,
      nomeCpc : nomeCpc
    });
  }

}
