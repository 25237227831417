<app-cabecalho-interno></app-cabecalho-interno> <br /><br /><br />
<div class="container-lg">
  <div class="mb-4 mt-2">
    <!-- cabeçalho -->
    <div class="row">
      <div class="col-8" id="cabecalho">
        <h2 class="mb-0" aria-label="Manter Unidade de Fornecimento">
          <span class="mouseover">Catálogo - Unidade de medida</span>
        </h2>
        <h6 style="font-weight: 400" class="mb-0 mt-1">
          Use esta área para criar/editar a unidade de medida
        </h6>
      </div>
      <div class="col-4 text-right row" style="align-items: end">
        <div class="col-sm-6 card border-0">
          <button
            class="br-button is-secondary"
            type="button"
            (click)="voltarTelaInicial()"
          >
            Voltar
          </button>
        </div>
        <div class="col-sm-6 card border-0">
          <br-loading [visible]="visible" label="Salvando ..."></br-loading>
          <button
            class="br-button is-primary"
            type="button"
            data-toggle="modal"
            (click)="abrirCriar()"
            data-target="#incluirUnidadeMedida"
          >
            Incluir
          </button>
        </div>
      </div>
    </div>
  </div>

  <hr />

  <br-fieldset
    [collapsible]="true"
    fieldsetTitle="Pesquisar unidade de medida"
    [collapsed]="expandCollapsed"
  >
    <div class="row">
      <div class="col-10 mt-2 mb-2">
        <div class="br-input label">
          <input
            [(ngModel)]="textoBusca"
            placeholder="Digite aqui uma palavra para pesquisar"
            field="nome"
            (keyup)="enterPesquisar($event)"
          />
        </div>
      </div>
      <div class="col-md-2 mt-3 card border-0">
        <button
          (click)="pesquisar()"
          class="br-button primary justify-self-end"
          type="button"
        >
          Pesquisar
        </button>
      </div>
    </div>
  </br-fieldset>

  <hr />

  <div *ngIf="escondeValor != true">
    <p-table
      [value]="listaUnidadeMedidaTela"
      [rows]="10"
      [paginator]="true"
      responsiveLayout="scroll"
      [rowHover]="true"
      dataKey="id"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} entradas"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[5, 10, 25, 50, 100]"
    >
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between row">
          <h5 class="ml-3 mt-0">Valores das unidades de medida</h5>

          <div class="br-checkbox">  <!-- A FAZER - JMD -->
            <input id="somenteAtivos" name="somente-ativos" type="checkbox" aria-label="somente ativos" (click)="somenteAtivos()" [(ngModel)]="ativos"/>
            <label for="somenteAtivos">Mostrar somente valores ativos</label>
          </div>

          <div class="br-input col-5 mb-3">
            <span class="mt-5"> </span>
            <input
              [(ngModel)]="filtroUnidadeMedida"
              (keyup)="filtrarUnidadeMedida()"
              class="p-inputtext"
              placeholder="Digite uma palavra para filtrar o resultado "
            />
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="siglaUnidadeMedida">
            Sigla<p-sortIcon field="siglaUnidadeMedida"></p-sortIcon>
          </th>
          <th pSortableColumn="nomeUnidadeMedida">
            Nome <p-sortIcon field="nomeUnidadeMedida"></p-sortIcon>
          </th>
          <th pSortableColumn="statusUnidadeMedida">
            Ativo <p-sortIcon field="statusUnidadeMedida"></p-sortIcon>
          </th>
          <th>Ações</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-unidadeMedida>
        <tr>
          <td>
            <span *ngIf="unidadeMedida.statusUnidadeMedida  == true" style="color: black;" >{{ unidadeMedida.siglaUnidadeMedida }}</span>
            <span *ngIf="unidadeMedida.statusUnidadeMedida  == false" style="color: #9f9a9a" >{{ unidadeMedida.siglaUnidadeMedida }}</span>
          </td>
          <td>
            <span *ngIf="unidadeMedida.statusUnidadeMedida  == true" style="color: black;" >{{ unidadeMedida.nomeUnidadeMedida }}</span>
            <span *ngIf="unidadeMedida.statusUnidadeMedida  == false" style="color: #9f9a9a;" >{{ unidadeMedida.nomeUnidadeMedida }}</span>
          </td>
          <td>
            <span *ngIf="unidadeMedida.statusUnidadeMedida  == true" style="color: black;" >{{ unidadeMedida.statusUnidadeMedida ? "Sim" : "Não" }}</span>
            <span *ngIf="unidadeMedida.statusUnidadeMedida  == false" style="color: #9f9a9a;" >{{ unidadeMedida.statusUnidadeMedida ? "Sim" : "Não" }}</span>
          </td>
          <td>
            <button
              class="br-button"
              data-toggle="modal"
              data-target="#visualizarUnidadeMedida"
              aria-label="Botão de ação"
              pTooltip="Visualizar"
              (click)="pegarDados('visualiza', unidadeMedida)"
              tooltipPosition="top"
            >
              <i class="fas fa-eye" aria-hidden="true"></i>
            </button>
            <button
              class="br-button"
              data-toggle="modal"
              type="button"
              data-target="#editarUnidadeMedida"
              pTooltip="Editar"
              (click)="pegarDados('edita', unidadeMedida)"
              tooltipPosition="top"
            >
              <i class="fas fa-edit" aria-hidden="true"></i>
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <!--  MODAIS  -->

  <div
    id="incluirUnidadeMedida"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="Classe"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-media">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Incluir unidade de medida</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="container-lg">
          <div class="row mt-3">
            <label *ngIf="!existeSigla" class="col-9">
              Sigla<span class="asterisco">*</span>
            </label>
            <label *ngIf="existeSigla" class="col-3">
              Sigla<span class="asterisco">*</span>
            </label>
            <label *ngIf="existeSigla" class="col-6" style="color: red;">
              Sigla já cadastrada
            </label>
            <label
              class="col-3 texto-menor"
              style="text-align: end;">
              Caracteres restantes: <span>{{ numMaxSigla }}</span>
            </label>
          </div>
          <div class="br-input">
            <input #campoSigla
              (blur)="validaSigla(campoSigla)"
              [(ngModel)]="incluirSigla"
              placeholder="Digite a sigla da unidade de medida"
              (keyup)="pressEnterSigla()"
              (keydown)="enableSave()"
              maxlength="7"
            />
          </div>
          <div class="row mt-3">
            <label *ngIf="!existeNome" class="col-9">
              Nome<span class="asterisco">*</span>
            </label>
            <label *ngIf="existeNome" class="col-3">
              Nome<span class="asterisco">*</span>
            </label>
            <label *ngIf="existeNome" class="col-6" style="color: red;">
              Nome já cadastrado
            </label>
            <label
              class="col-3 texto-menor"
              style="text-align: end; font-size: 12px"
              >Caracteres restantes: <span>{{ numMaxNome }}</span></label
            >
          </div>
          <div class="br-input">
            <input #campoNome
              (blur)="validaNome(campoNome)"
              [(ngModel)]="incluirNome"
              placeholder="Digite o nome da unidade de medida"
              (keyup)="pressEnterNome()"
              (keydown)="enableSave()"
              maxlength="20"
            />
          </div>
          <div class="row mt-3">
            <label class="col-9">Descrição</label>
            <label
              class="col-3 texto-menor"
              style="text-align: end; font-size: 12px"
              >Caracteres restantes: <span>{{ numMaxDescricao }}</span></label
            >
          </div>
          <div class="br-textarea mb-5">
            <textarea
              [(ngModel)]="incluirDescricao"
              placeholder="Descrição  da unidade de medida"
              (keyup)="pressEnterDescricao()"
              maxlength="600"
            ></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <div>
            <button
              class="br-button secondary"
              data-dismiss="modal"
              (click)="limpaCampos()"
            >
              Voltar
            </button>
          </div>
          <div>
            <button
              class="br-button primary"
              [disabled]="disableSave"
              (click)="salvarNovaUnidadeMedida()"
              data-dismiss="modal"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    id="editarUnidadeMedida"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="Classe"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-media">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Editar unidade de medida</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <br />
        <div class="container-lg">
          <div class="row">
            <div class="br-input col-8">
              <label>Sigla</label>
              <input [(ngModel)]="editarSigla" disabled />
            </div>
            <div class="align-self-end col-4" style="text-align: end">
              <div
                class="br-switch medium switch-basic"
              >
                <input id="status" type="checkbox" [(ngModel)]="editarStatus" />
                <label for="status">Ativo</label>
              </div>
            </div>
          </div>
          <div class="br-input mt-3">
            <label>Nome</label>
            <input [(ngModel)]="editarNome" #nome disabled />
          </div>
          <div class="mt-3 row">
            <label class="col-9">Descrição</label>
            <label
              class="col-3 texto-menor"
              style="text-align: end;"
              >Caracteres restantes: <span>{{ numMaxDescricao }}</span></label
            >
          </div>
          <div class="br-textarea mb-5">
            <textarea
              [(ngModel)]="editarDescricao"
              (keyup)="pressEditarDescricao()"
              maxlength="600"
            ></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <div>
            <button class="br-button secondary" data-dismiss="modal">
              Voltar
            </button>
          </div>
          <div>
            <button
              class="br-button primary"
              (click)="atualizarUnidadeMedida()"
              data-dismiss="modal"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    id="visualizarUnidadeMedida"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="Classe"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-media">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Visualizar unidade de medida</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="container-lg">
          <div class="row">
            <div class="br-input mt-3 col-md-8">
              <label>Sigla</label>
              <input [(ngModel)]="visualizaSigla" readonly />
            </div>
            <div class="col-md-4 align-self-end" style="text-align: end">
              <div
                class="br-switch medium switch-basic"
                style="margin-left: 10px"
              >
                <input type="checkbox" [(ngModel)]="visualizaStatus" readonly />
                <label for="status">Ativo</label>
              </div>
            </div>
          </div>
          <div class="br-input mt-3">
            <label>Nome</label>
            <input [(ngModel)]="visualizaNome" readonly />
          </div>
          <div class="br-textarea mt-3 mb-5">
            <label>Descrição</label>
            <textarea [(ngModel)]="visualizaDescricao" readonly></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <div>
            <button class="br-button secondary" data-dismiss="modal">
              Voltar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #unidadeMedidaSucessoCriar>
    <div>
      <div class="modal-header">
        <h4 class="modal-title">Unidade de medida incluída com sucesso</h4>
        <button type="button" class="close" (click)="sairModalSalvar()">
          &times;
        </button>
      </div>
      <div class="container-lg">
        <div
          class="flex justify-content-center mb-5 mt-5"
          style="text-align: center"
        >
          <label>Unidade de medida incluída com sucesso.</label><br />
          <label>O código da unidade de medida é: {{ novoCodigo }}</label>
        </div>
      </div>
      <div class="modal-footer">
        <div>
          <button class="br-button secondary" (click)="sairModalSalvar()">
            Voltar
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #unidadeMedidaSucessoEditar>
    <div>
      <div class="modal-header">
        <h4 class="modal-title">Unidade de medida editada com sucesso</h4>
        <button type="button" class="close" (click)="sairModalEditar()">
          &times;
        </button>
      </div>
      <div class="container-lg">
        <div
          class="flex justify-content-center mb-5 mt-5"
          style="text-align: center"
        >
          <label>Unidade de medida editada com sucesso.</label>
        </div>
      </div>
      <div class="modal-footer">
        <div>
          <button class="br-button secondary" (click)="sairModalEditar()">
            Voltar
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
