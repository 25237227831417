import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ValorCaracteristica } from 'src/app/arealogada/model/caracteristica-pdm';
import { BuscaService } from 'src/app/service/busca-service';
//TODO: remover quando solução temporária de fechamento do modal for removida.
declare var $ : any;

@Component({
  selector: 'app-selecionar-valores-caracteristica',
  templateUrl: './selecionar-valores-caracteristica.component.html',
  styleUrls: ['./selecionar-valores-caracteristica.component.css']
})
export class SelecionarValoresCaracteristicaComponent implements OnInit {

  first = 0;
  buscaNomeValorCaracteristicaEntrada: any;
  valoresPdm: any;
  inicioTexto: boolean = false;
  somenteNome: boolean = false;
  textoExato: boolean = false;

  @Input("caracteristicasEmEdicao") caracteristicasEscolhidaEmEdicao: any;

  @Output() onSalvarValorCaracteristica: EventEmitter<ValorCaracteristica> = new EventEmitter<ValorCaracteristica>();

  constructor(private buscaService: BuscaService) { }

  ngOnInit(): void {
  }

  pressEnterValoresCaracteristicas(evento: any){
    if(evento.key == "Enter"){
      this.buscarValoresCaracteristica()
    }
  }

  buscarValoresCaracteristica(): void {
    this.first = 0;
    this.buscaService.
    obterValoresCaracteristicaPDM(this.buscaNomeValorCaracteristicaEntrada.toUpperCase(), this.inicioTexto, this.somenteNome, this.textoExato).
    subscribe(tmp => this.valoresPdm = tmp);
  }

  salvarValorCaracteristica(codigoValorCaracteristica: string, nomeValorCaracteristica: string , codigoCaracteristica: string): void{
          const valorCaracteristica = new ValorCaracteristica();
          valorCaracteristica.codigoValorCaracteristica = codigoValorCaracteristica;
          valorCaracteristica.nome = nomeValorCaracteristica;
          valorCaracteristica.ativo = true;
          valorCaracteristica.codigoCaracteristica = codigoCaracteristica;
          this.onSalvarValorCaracteristica.emit(valorCaracteristica);
          this.limpar();
  }

  salvarNovoValorCaracteristica(novoValor: any) {
    const valorCaracteristica = new ValorCaracteristica();
    valorCaracteristica.codigoValorCaracteristica = novoValor.codigoValorCaracteristica;
    valorCaracteristica.nome = novoValor.nomeValorCaracteristica;
    valorCaracteristica.ativo = true;
    valorCaracteristica.codigoCaracteristica = this.caracteristicasEscolhidaEmEdicao?.codigoCaracteristica;
    this.onSalvarValorCaracteristica.emit(valorCaracteristica);
    this.limpar();
  }

  limpar(): void {
    this.buscaNomeValorCaracteristicaEntrada = null;
    this.first = 0;
    this.valoresPdm = null;
    // TODO: modal não fecha ao selecionar
    // se lista for limpa
    // então solução temporária abaixo.
    $('#buscarvalorescaracteristica').modal('toggle');
  }

  fechar() {
    this.limpar();
  }

  marcador(inicio: string, somente: string, exato: string){
    if(inicio == "inicioTexto"){
      if(this.inicioTexto == false){
        this.somenteNome = false;
        this.textoExato = false;
      }
    }
    if(somente == "somenteNome"){
      if(this.somenteNome == false){
        this.inicioTexto = false;
        this.textoExato = false;
      }
    }
    if(exato == "textoExato"){
      if(this.textoExato == false){
        this.somenteNome = false;
        this.inicioTexto = false;
      }
    }
  }

}
