import { ToastModule } from 'primeng/toast';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BuscaService } from '../../../service/busca-service';
import { Router, ActivatedRoute, ChildActivationStart } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CaracteristicaPdm, UnidadeMedidaPDM, ValorCaracteristica } from '../../model/caracteristica-pdm';
@Component({
  selector: 'app-manter-material',
  templateUrl: './manter-material.component.html',
  styleUrls: ['./manter-material.component.css'],
  providers: [ConfirmationService],
})
export class ManterMaterialComponent implements OnInit {
  constructor(
    private buscaService: BuscaService,
    private modalService: BsModalService,
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute
  ) {}

  materialParams: any;
  visible: boolean = false;
  expandCollapsed: boolean = false;
  rowIndex: number;
  caracteristicaEscolhidaEmEdicao: any;
  caracteristicasEscolhidaArray: CaracteristicaPdm[];
  numMaxPDM = 100;
  numMaxAlias = 25;
  validateCaracteristicas: String = "#buscarcaracteristica";
  validateValoresCaracteristicas: String = "#buscarvalorescaracteristica";
  validateUnidadeMedida: String = "#buscarunidademedida";
  validateUnidadeFornecimento: String = "#formunidadefornecimento";
  validaUnidadeMedida: boolean = true;
  validaValorCaracteristica: boolean = true;

  //arrays

  dadosBasicosPdm: any;
  caracteristicasArray: any = [];
  aliasArray: any;
  naturezaDespesaArray: any = [];
  unidadeFornecimentoArray: any;
  valorCaracteristicaArray: any;
  unidadeMedidaArray: any;
  caracteristicaCompletaApi: any = [];

  //inputs

  naturezaDespesa: string = '';
  alias: string = '';

  PDM: any;
  @ViewChild('PdmSucess', { static: false }) PdmSucess: any;
  @ViewChild('BackAlert', {static: false }) BackAlert: any;
  @ViewChild('BackAlertItem', {static: false }) BackAlertItem: any;
  flag: any = 0;

  conjuntoMaterial: any;
  codigoClasse: number;

  //Carrega os Arrays iniciais

  ngOnInit(): void {
    this.caracteristicasEscolhidaArray = [];

    this.route.params.subscribe((id) => {
      this.materialParams = id;
    });
    this.buscaService
      .obterDadosBasicosPDMPorCodigo(this.materialParams.id)
      .subscribe((resp) => {
        this.dadosBasicosPdm = resp;
        this.pressEnterPDM(event, this.dadosBasicosPdm.nomePdm);
        // flag para o alerta do save Não TIRAR
        this.flag = 0;
      });
    this.buscaService
      .obterDadosCaracteristicaPDMPorCodigo(this.materialParams.id)
      .subscribe((resp) => {
        this.caracteristicasArray = resp;
        this.testeInit();
      });
    this.buscaService
      .obterDadosAliasPDMPorCodigo(this.materialParams.id)
      .subscribe((resp) => (this.aliasArray = resp));
    this.buscaService
      .obterDadosNaturezaDespesaPDMPorCodigo(this.materialParams.id)
      .subscribe((resp) => {
        let aux = '';
        this.naturezaDespesaArray = [];
        for (let i of resp) {
          aux =
            i.classificacaoContabilMaterial.toString().substring(0, 6) +
            '/' +
            i.classificacaoContabilMaterial.toString().substring(6);
          this.naturezaDespesaArray.push({
            classificacaoContabilMaterial: aux,
            statusClassificacaoContabil: i.statusClassificacaoContabil,
          });
        }
      });
    this.buscaService
      .obterDadosUnidadeFornecimentoPDMPorCodigo(this.materialParams.id)
      .subscribe((resp) => {
        for(let i of resp){
          if(i?.capacidadeUnidadeFornecimento !== 0){
            let capacidade = i.capacidadeUnidadeFornecimento.toString().split(".");
            if(capacidade.length > 1){
              if(capacidade[1].length == 1){
                i.capacidadeUnidadeFornecimento = i.capacidadeUnidadeFornecimento + "0";
              }
            } else{
              i.capacidadeUnidadeFornecimento = i.capacidadeUnidadeFornecimento + ",00";
            }
            i.capacidadeUnidadeFornecimento = i.capacidadeUnidadeFornecimento.toString().replace(".",",");
          }
        }
        (this.unidadeFornecimentoArray = resp)
      });
    // flag para o alerta do save Não TIRAR
    this.flag = 0;
  }

  adicionarUnidadeFornecimento(item: any){
    this.flag = 1;
    if(item?.capacidadeUnidadeFornecimento !== ""){
      let capacidade = item.capacidadeUnidadeFornecimento.toString().split(".");
      if(capacidade.length > 1){
        if(capacidade[1].length == 1){
          item.capacidadeUnidadeFornecimento = item.capacidadeUnidadeFornecimento + "0";
        }
      } else{
        item.capacidadeUnidadeFornecimento = item.capacidadeUnidadeFornecimento + ",00";
      }
      item.capacidadeUnidadeFornecimento = item.capacidadeUnidadeFornecimento.toString().replace(".",",");
    }
    let aux = {
      capacidadeUnidadeFornecimento: item?.capacidadeUnidadeFornecimento,
      descricaoUnidadeFornecimento: item.unidadeFornecimento.descricaoUnidadeFornecimento,
      nomeUnidadeFornecimento: item.unidadeFornecimento.nomeUnidadeFornecimento,
      numeroSequencialUnidadeFornecimento: "",
      possuiEmbalagem: item.unidadeFornecimento.possuiEmbalagem,
      siglaUnidadeFornecimento: item.unidadeFornecimento.siglaUnidadeFornecimento,
      siglaUnidadeMedida: item.unidadeMedida?.siglaUnidadeMedida,
      nomeUnidadeMedida: item.unidadeMedida?.nomeUnidadeMedida,
      statusUnidadeFornecimentoPdm: true
    }
    let x = 0;
    for(let i of this.unidadeFornecimentoArray){
      if(i.siglaUnidadeMedida == aux.siglaUnidadeMedida 
        && i.siglaUnidadeFornecimento == aux.siglaUnidadeFornecimento 
        && i.capacidadeUnidadeFornecimento == aux.capacidadeUnidadeFornecimento){
          x = 1;
      }
    }
    if(x == 0){
      this.unidadeFornecimentoArray.push(aux);
    }
    else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Unidade de fornecimento já cadastrada.',
      });
    }
    
  }

  carregaArray(valor: string) {
    if (this.aliasArray == null) {
      this.buscaService
        .obterDadosAliasPDMPorCodigo(this.dadosBasicosPdm.codigoPdm)
        .subscribe((resp) => (this.aliasArray = resp));
    }

    if (this.naturezaDespesaArray.length == 0) {
      this.buscaService
        .obterDadosNaturezaDespesaPDMPorCodigo(this.dadosBasicosPdm.codigoPdm)
        .subscribe((resp) => {
          let aux = '';
          this.naturezaDespesaArray = [];
          for (let i of resp) {
            aux =
              i.classificacaoContabilMaterial.toString().substring(0, 6) +
              '/' +
              i.classificacaoContabilMaterial.toString().substring(6);
            this.naturezaDespesaArray.push({
              classificacaoContabilMaterial: aux,
              statusClassificacaoContabil: i.statusClassificacaoContabil,
            });
          }
        });
    }

    if (this.unidadeFornecimentoArray == null) {
      this.buscaService
        .obterDadosUnidadeFornecimentoPDMPorCodigo(
          this.dadosBasicosPdm.codigoPdm
        )
        .subscribe((resp) => (this.unidadeFornecimentoArray = resp));
    }
  }

  //Navegação

  openAlert(){
    console.log(this.flag)
    if(this.flag == 1){
      this.BackAlert.show();
    }
    else{
      this.voltarTelaInicial();
    }
  }

  editarItens(){
    console.log(this.flag)
    if(this.flag == 1){
      this.BackAlertItem.show();
    }
    else{
      this.voltarEditarItem();
    }
  }

  voltarEditarItem(){
    this.router.navigate(['/manteritemmaterial', this.dadosBasicosPdm.codigoPdm, this.dadosBasicosPdm.nomePdm]);
  }

  voltarTelaInicial(): void {
    this.router.navigate(['/buscamaterial']);

  }

  permanecerPagina(){
    window.location.reload();
  }

  openModalCaracteristica(): void {
    if(this.caracteristicasArray.length < 40){
      this.validateCaracteristicas = "#buscarcaracteristica";
    } else {
      this.validateCaracteristicas = "";
      this.messageService.add({
        severity: 'warn',
        summary: 'Somente é possível incluir 40 características.'
        });
    }
  }

  openModalbuscarvalorcaracteristica(): void {
    if(this.valorCaracteristicaArray.length >= 120){
      this.validaValorCaracteristica = false;
      this.validateValoresCaracteristicas = "";
    } else {
      this.validateValoresCaracteristicas = "#buscarvalorescaracteristica";
      this.validaValorCaracteristica = true;
    }

  }

  openModalbuscarunidademedida(): void {
    if(this.unidadeMedidaArray.length >= 191){
      this.validaUnidadeMedida = false;
      this.validateUnidadeMedida = "";
    } else{
      this.validaUnidadeMedida = true;
      this.validateUnidadeMedida = "#buscarunidademedida";
    }

  }

  escolherValorCaracteristica(valorCaracteristica: any) {
      let aux = 0;
      let valorCarac = {
          codigoValorCaracteristica: valorCaracteristica.codigoValorCaracteristica,
          nomeValorCaracteristica: valorCaracteristica.nome,
          statusValorCaracteristica: true
      }
      for (let item of this.valorCaracteristicaArray) {
        if(item.codigoValorCaracteristica == valorCaracteristica.codigoValorCaracteristica){
          console.log(item.codigoValorCaracteristica);
          aux = 1;
        }
      }
      if( aux == 0 ){
        this.flag = 1;
         this.valorCaracteristicaArray.push(valorCarac);
      }
  }

  escolherUnidadeMedida(item: any): void {
    let aux = 0;
    console.log(this.unidadeMedidaArray);
    for(let i of this.unidadeMedidaArray){
       if(i.siglaUnidadeMedida == item.siglaUnidadeMedida){
         aux = 1;
       }
    }
    if(aux == 0){
       let unidadeMedida = {
          siglaUnidadeMedida: item.siglaUnidadeMedida,
          nomeUnidadeMedida: item.nomeUnidadeMedida,
          possuiEmbalagem: item.embalagemUnidadeMedida,
          statusUnidadeMedidaCaracteristica: true
       }
       this.flag = 1;
       this.unidadeMedidaArray.push(unidadeMedida);
    }
  }

  escolherCaracteristica(codigoCaracteristica: CaracteristicaPdm): void {
    let aux = 0;
    let caracDevolvida = {
       codigoCaracteristica: codigoCaracteristica.codigoCaracteristica,
       nomeCaracteristica: codigoCaracteristica.nomeCaracteristica,
       statusCaracteristica: codigoCaracteristica.ativo,
       caracteristicaObrigatoria: codigoCaracteristica.obrigatorio,
       exemploCaracteristica:  "",
       tipoCaracteristica: "A",
       numeroCaracteristica: "",
       listaUnidadeMedidaPdmMaterial: [],
       listaValorCaracteristicaPdmMaterial: []
    }
    for(let i of this.caracteristicasArray){
      if(i.codigoCaracteristica == codigoCaracteristica.codigoCaracteristica){
        aux = 1;
      }
    }
    if(aux == 0){
      this.flag = 1;
      this.caracteristicasArray.push(caracDevolvida);
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Característica já cadastrada'
        });
    }
  }

  //Natureza de Despesa

  adicionaListNatureza() {
    let aux = 0;
    let aux2 = Number(this.naturezaDespesa.substring(0, 4));
    let aux3 = this.naturezaDespesa.length;
    this.naturezaDespesa = this.naturezaDespesa.trim();
    if (this.naturezaDespesa != '' && aux3 == 9) {
      if (
        (aux2 >= 3390 && aux2 <= 3399) ||
        (aux2 >= 4490 && aux2 <= 4499) ||
        (aux2 >= 5590 && aux2 <= 5599)
      ) {
        for (let i of this.naturezaDespesaArray) {
          if (this.naturezaDespesa == i.classificacaoContabilMaterial) {
            aux = 1;
          }
        }
        if (aux == 0) {
          this.flag = 1;
          this.naturezaDespesaArray.push({
            classificacaoContabilMaterial: this.naturezaDespesa,
            statusClassificacaoContabil: true,
          });
          this.naturezaDespesa = '';
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Natureza de desespesa já cadastrada',
          });
        }
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Natureza da despesa inválida',
        });
      }
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Preencher corretamente o campo de natureza da despesa',
      });
    }
  }

  pressEnterNatureza(evento: any) {
    if (evento.key == 'Enter') {
      this.adicionaListNatureza();
    }
  }

  //Alias/Palavra-chave

  pressEnterAlias(evento: any, parametroMetodo: any) {
    this.numMaxAlias = 25 - parametroMetodo.trim().length;
    if (evento.key == 'Enter') {
      this.adicionaListAlias();
    }
  }

  adicionaListAlias() {
    if (this.aliasArray.length < 10) {
      let aux = 0;
      this.alias = this.alias.trim();
      if (this.alias != '') {
        for (let i of this.aliasArray) {
          if (this.alias == i.nomeAliasPdmMaterial) {
            aux = 1;
          }
        }
        if (aux == 0) {
          this.flag = 1;
          this.aliasArray.push({ nomeAliasPdmMaterial: this.alias });
          this.alias = '';
          this.numMaxAlias = 25;
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Alias/palavra-chave já cadastrada',
          });
        }
      }
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Somente é possível incluir 10 alias/palavras-chaves.',
      });
    }
  }

  removeListAlias(message: string) {
    this.confirmationService.confirm({
      message: 'Deseja excluir esse alias/palavra-chave?',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      key: message,
      defaultFocus: 'none',
      accept: () => {
        for (let index in this.aliasArray) {
          if (message == this.aliasArray[index].nomeAliasPdmMaterial){
            this.flag = 1;
            this.aliasArray.splice(index, 1);
          }

        }
      },
    });
  }

  //Caracateristica

  testeInit() {
    this.caracteristicaCompletaApi = this.caracteristicasArray;

    for (let i in this.caracteristicasArray) {

      this.buscaService.obterDadosValorCaracteristicaPDMPorCodigo(this.materialParams.id, this.caracteristicaCompletaApi[i].codigoCaracteristica).subscribe(resp => this.caracteristicaCompletaApi[i].listaValorCaracteristicaPdmMaterial = resp);
      this.buscaService.obterDadosUnidadeMedidaCaracteristicaPDMPorCodigo(this.materialParams.id, this.caracteristicaCompletaApi[i].codigoCaracteristica).subscribe(resp => this.caracteristicaCompletaApi[i].listaUnidadeMedidaPdmMaterial = resp);

    }
  }

  buscaValorCaracteristica(codigo: any) {

    this.caracteristicaEscolhidaEmEdicao = codigo;
    this.valorCaracteristicaArray = this.caracteristicaCompletaApi[codigo].listaValorCaracteristicaPdmMaterial;
    this.validaValorCaracteristica = true;
  }

  buscaUnidadeMedida(codigo: any) {

    this.caracteristicaEscolhidaEmEdicao = codigo;
    this.unidadeMedidaArray = this.caracteristicaCompletaApi[codigo].listaUnidadeMedidaPdmMaterial;
    this.validaUnidadeMedida = true;
  }

  //Classe

  escolherClasse(item: any) {
    this.flag = 1;
    this.dadosBasicosPdm.codigoClasse = item.codigoClasse;
    this.dadosBasicosPdm.nomeClasse = item.nomeClasse;
    this.dadosBasicosPdm.codigoGrupo = item.codigoGrupo;
    this.dadosBasicosPdm.nomeGrupo = item.nomeGrupo;
    this.dadosBasicosPdm.codigoConjunto = "";
    this.dadosBasicosPdm.nomeAcentuadoConjunto = "";
  }

  escolherConjunto(item: any) {
    this.flag = 1;
    this.dadosBasicosPdm.codigoConjunto = item.codigoConjunto;
    this.dadosBasicosPdm.nomeAcentuadoConjunto = item.nomeConjunto;
  }

  pressEnterPDM(evento: any, parametroMetodo: any) {
    this.flag = 1;
    this.numMaxPDM = 100 - parametroMetodo.trim().length;
  }

  salvarPDM() {
    let erro = 0;
    let aux2 = [];
    let aux3 = [];

    for (let i of this.naturezaDespesaArray) {
      let x = i.classificacaoContabilMaterial.split('/', 2);
      let ND = {
        statusClassificacaoContabil: i.statusClassificacaoContabil,
        classificacaoContabilMaterial: x[0] + x[1],
      };
      aux2.push(ND);
    }
    for (let i of this.unidadeFornecimentoArray) {
      let siglaUnidadeMedida: any;
      let capacidadeUnidadeFornecimento: any;
      let aux;
      let capacidade;

      if (i.siglaUnidadeMedida != null) {
        siglaUnidadeMedida = i.siglaUnidadeMedida.split(' |', 1);
        aux = siglaUnidadeMedida[0];
      } else {
        aux = "";
        siglaUnidadeMedida = '';
      }

      let siglaUnidadeFornecimento = i.siglaUnidadeFornecimento.split(' |', 1);

      if (i.capacidadeUnidadeFornecimento != null) {
        capacidadeUnidadeFornecimento = i.capacidadeUnidadeFornecimento
          .toString()
          .replace(',', '.');
        capacidade = capacidadeUnidadeFornecimento;
        if(aux == ""){
          capacidade = "";
        }
      } else {
        capacidade = "";
        capacidadeUnidadeFornecimento = '';
      }

      let x = {
        siglaUnidadeMedida: aux,
        siglaUnidadeFornecimento: siglaUnidadeFornecimento[0],
        numeroSequencialUnidadeFornecimento: i.numeroSequencialUnidadeFornecimento,
        capacidadeUnidadeFornecimento: capacidade,
        statusUnidadeFornecimentoPdm: i.statusUnidadeFornecimentoPdm,
      };
      aux3.push(x);
    }
    console.log(
      'CARACTERISTICAS: ' + JSON.stringify(this.caracteristicasArray)
    );

    if(this.dadosBasicosPdm.nomePdm.trim() == ""){
      erro = 1;
      this.messageService.add({
        severity: 'error',
        summary:
          'É preciso ter um nome para o PDM.',
      });
    }

    if(this.dadosBasicosPdm.codigoConjunto == ""){
      erro = 1;
      this.messageService.add({
        severity: 'error',
        summary:
          'É preciso preencher um conjunto material.',
      });
    }

    if (erro == 0) {
      this.visible = true;
      this.PDM = {
        codigoPdm: this.dadosBasicosPdm.codigoPdm,
        codigoClasse: this.dadosBasicosPdm.codigoClasse,
        nomePdm: this.dadosBasicosPdm.nomePdm.trim(),
        statusPdm: this.dadosBasicosPdm.statusPdm,
        caracteristicas: this.caracteristicaCompletaApi,
        alias: this.aliasArray,
        listaNaturezaDespesa: aux2,
        conjuntoMaterial: this.dadosBasicosPdm.codigoConjunto,
        unidadeFornecimento: aux3,
      };
      this.buscaService.atualizarDadosPdmMaterial(this.PDM).subscribe(
        (resp) => {
          this.visible = false;
          if (resp) {
            this.PdmSucess.show();
          }
          console.log(resp);
        },
        (erro) => {
          this.visible = false;
          if (typeof erro.error == 'string') {
            this.messageService.add({
              severity: 'error',
              summary: 'Erro ao salvar os dados no servidor: ' + erro.error,
            });
          } else {
            this.messageService.add({
              severity: 'error',
              summary:
                'Não foi possível se conectar com o serviço neste momento, por favor tente mais tarde.',
            });
          }
        }
      );
      console.log(this.PDM);
    }
  }

  teste(){
    this.flag = 1;
  }

  verificaFornecimento(){
    let aux = this.unidadeFornecimentoArray.length;
    if(aux >= 56){
      this.validateUnidadeFornecimento = "";
      this.messageService.add({
        severity: 'error',
        summary:
          'Número máximo de unidades de fornecimento atingido.',
      });
    }
  }
}

