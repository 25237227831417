import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Service } from 'src/app/service/service';

@Component({
  selector: 'app-busca-material',
  templateUrl: './busca-material.component.html',
  styleUrls: ['./busca-material.component.css']
})
export class BuscaMaterialComponent implements OnInit {

  textoBusca: string = "";
  filtroMaterial: string = "";

  expandCollapsed: boolean = false;
  escondeValor: boolean = true;
  first: any = 0;

  materialTela: any = [];
  materialApi: any = [];

  somentePdm: boolean = false;
  somenteClasse: boolean = false;
  somenteItem: boolean = false;

  constructor(private router: Router, private service: Service) { }

  ngOnInit(): void {
    let pesquisa = sessionStorage.getItem('buscaMaterialCatalogador');
    let index = sessionStorage.getItem('buscaMaterialCatalogadorPagina')
    if(pesquisa != null){
      this.textoBusca = pesquisa;
      this.pesquisar();
      this.first = index;
      sessionStorage.removeItem('buscaMaterialCatalogador');
      sessionStorage.removeItem('buscaMaterialCatalogadorPagina')
    }

  }

  voltarTelaInicial(){
    this.router.navigate(['/arealogada']);
  }

  criarMaterial(){
    this.router.navigate(['/criarmaterial']);
  }

  visualizarPDM(valor: any){
    sessionStorage.setItem('buscaMaterialCatalogador', this.textoBusca);
    sessionStorage.setItem('buscaMaterialCatalogadorPagina', this.first);
    this.router.navigate(['/visualizarmaterial', valor.codigoPdm]);
  }

  editarPDM(valor: any){
    sessionStorage.setItem('buscaMaterialCatalogador', this.textoBusca);
    sessionStorage.setItem('buscaMaterialCatalogadorPagina', this.first);
    this.router.navigate(['/mantermaterial', valor.codigoPdm]);
  }

  itensPDM(valor: any){
    sessionStorage.setItem('buscaMaterialCatalogador', this.textoBusca);
    sessionStorage.setItem('buscaMaterialCatalogadorPagina', this.first);
    this.router.navigate(['/manteritemmaterial', valor.codigoPdm, valor.nomePdm]);
  }

  pesquisar(){
    if(this.textoBusca != ""){
      this.escondeValor = false;
      this.first = 0;
      this.filtroMaterial = "";
      this.service.obterPDMPorPalavra(this.textoBusca, 'nao', this.somentePdm, this.somenteClasse).subscribe((tmp)=>{
        this.materialApi = tmp;
        this.materialTela = tmp;
      });
    } else {
      this.escondeValor = true;
      this.materialApi = [];
      this.materialTela = [];
    }
  }

  enterPesquisar(event: any){
    if(event.key == "Enter"){
      this.pesquisar();
    }
  }

  filtrarMaterial(){
    let aux: string;
    this.first = 0;
    aux = this.filtroMaterial.trim();
    aux = aux.toUpperCase();
    this.materialTela = [];
    if(aux != ""){
      for(let i of this.materialApi){
        if(i.codigoPdm != null && i.nomePdm != null){
          if(i.codigoPdm.toString().includes(aux) || i.nomePdm.toUpperCase().includes(aux)){
            this.materialTela.push(i);
          }
        }
      }
    }
    else {
      this.resetaFiltroMaterial();
    }

  }

  resetaFiltroMaterial(){
    this.first = 0;
    this.filtroMaterial = "";
    this.materialTela = this.materialApi;
  }

  filtroAvancado(texto: any){
    if(texto == "pdm"){
      if(this.somentePdm == false){
        this.somenteClasse = false;
        this.somenteItem = false;
      }
    }
    if(texto == "classe"){
      if(this.somenteClasse == false){
        this.somentePdm = false;
        this.somenteItem = false;
      }
    }
    if(texto == "item"){
      if(this.somenteItem == false){
        this.somenteClasse = false;
        this.somentePdm = false;
      }
    }
  }
}
