import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../security/services/auth-service.service';

@Component({
  selector: 'app-iniciar-sessao',
  templateUrl: './iniciar-sessao.component.html',
  styleUrls: ['./iniciar-sessao.component.css']
})
export class IniciarSessaoComponent implements OnInit {

  constructor(private authService: AuthService , private router: Router, private route: ActivatedRoute) { }

  // tslint:disable-next-line:typedef
  ngOnInit() {

    console.log('IniciarSessaoComponent ngOnInit');
    const urlRetorno = this.route.snapshot.paramMap.get('urlRetorno');
    const cnetId = this.route.snapshot.paramMap.get('cnet-id');

    if (cnetId && urlRetorno) {
      console.log('IniciarSessaoComponent ngOnInit  if (cnetId && urlRetorno) ');
      this.autenticar(cnetId, urlRetorno);
    } else {
      console.log('this.encerrarSessao() ');
      this.encerrarSessao();
    }
  }
  //   this.authService.obterToken(cnetId)

  // tslint:disable-next-line:typedef
  autenticar(cnetId: string, urlRetorno: string) {
    console.log('IniciarSessaoComponent autenticar');
    // console.log('cnetId ' + cnetId);
    // console.log('urlRetorno ' + urlRetorno);
    const isLoginFake = urlRetorno.split('=', 2);
    console.log('isLoginFake', isLoginFake);
    // if (isLoginFake[1] === 'loginfake') {
    //   this.authService.obterTokenV1(cnetId).subscribe((autenticado: boolean) => {
    //     console.log('obter token V1' , autenticado);
    //     if (autenticado) {
    //       this.authService.setIsLoginProcessado(true);
    //       this.router.navigateByUrl(urlRetorno.toString(), { replaceUrl: true });
    //
    //     } else {
    //
    //       this.encerrarSessao();
    //     }
    //   });
    // } else {
    this.authService.obterTokenV2(cnetId).subscribe((autenticado: boolean) => {
        console.log('obter token V2');
        console.log(autenticado);
        if (autenticado) {
          console.log('if autenticado ' , autenticado);
          this.authService.setIsLoginProcessado(true);
          this.router.navigateByUrl(urlRetorno.toString(), { replaceUrl: true });
        } else {
          console.log('não autenticado ');
          this.encerrarSessao();
        }
      });
    // }
  }



  encerrarSessao(): void {
    this.authService.logout('/sessao-encerrada');
  }



}
