import { Component, OnInit } from '@angular/core';
import {MessageService, SelectItem} from 'primeng/api';
import {AuthService} from '../../security/services/auth-service.service';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-login-fake',
  templateUrl: './login-fake.component.html',
  styleUrls: ['./login-fake.component.css']
})
export class LoginFakeComponent implements OnInit {

  usuariosGoverno: SelectItem[];
  usuarioGovernoSelected: any;

  usuariosFornecedor: SelectItem[];
  usuarioFornecedorSelected: any;

  constructor(private authService: AuthService, private messageService: MessageService, private router: Router) { }

  ngOnInit(): void {
    if (this.authService.isAutenticado()) {
      this.authService.logout('');
    }

    this.usuariosGoverno = [
      {
        label: '200999 - Catalogador - TodosMnemonicos - 11111111111',
        value: {
          cpf: '11111111111',
          ip: '192.168.1.1',
          metodoAutenticacao: 'L',
          mnemonicos: [
            'CAT-ADM',
            'ADMBANCO',
            'ADMPREGAO',
            'ADMSERPRO',
            'ALTOFERTA',
            'ATASRP',
            'ALTPDM',
            'ALTSERVICO',
            'CENTRAL',
            'CONARQ',
            'CONCRONENT',
            'CONICMS',
            'CONOFERTA',
            'CONTEREDIT',
            'DIVULGA1',
            'DIVULGA2',
            'DIVULGA3',
            'DIVULGA4',
            'ENCCRONENT',
            'ENVEMPSUFU',
            'EQUIPREGAO',
            'EXCCRONENT',
            'EXCREACRON',
            'FASEINT1',
            'GESTOR-ATA',
            'HOMOL-RDC',
            'HOMPREGAO',
            'IALCRONENT',
            'IALREACRON',
            'IMPEXTCONV',
            'INCDEVMAT',
            'INCEDITAL',
            'INCOFERTA',
            'IRP',
            'LIBCRONENT',
            'LICITACOES',
            'PREGAO',
            'PRESID-RDC',
            'PRESIDENTE',
            'RDCDIVULG1',
            'RDCDIVULG2',
            'RDCDIVULG3',
            'RDCDIVULG4',
            'REGCONFUSU',
            'SICAF',
            'SUPRIDO'
          ],
          nivelAcesso: 7,
          nome: 'Super Usuário de Teste da UASG 200999',
          numeroUasg: 200999,
          numeroUasgAtuacao: 200999
        }
      },
      { label: '200999 - Outros - TodosMnemonicos - 22222222222', value: { cpf: '22222222222', ip: '192.168.1.1', metodoAutenticacao: 'L',
          mnemonicos:
            ['ADMBANCO', 'ADMPREGAO', 'ADMSERPRO', 'ALTOFERTA', 'ATASRP', 'CENTRAL', 'CONARQ', 'CONCRONENT',
            'CONICMS', 'CONOFERTA', 'CONTEREDIT', 'DIVULGA1', 'DIVULGA2', 'DIVULGA3', 'DIVULGA4', 'ENCCRONENT',
            'ENVEMPSUFU', 'EQUIPREGAO', 'EXCCRONENT', 'EXCREACRON', 'FASEINT1', 'GESTOR-ATA', 'HOMOL-RDC', 'HOMPREGAO',
            'IALCRONENT', 'IALREACRON', 'IMPEXTCONV', 'INCDEVMAT', 'INCEDITAL', 'INCOFERTA', 'IRP', 'LIBCRONENT',
            'LICITACOES', 'PREGAO', 'PRESID-RDC', 'PRESIDENTE', 'RDCDIVULG1', 'RDCDIVULG2', 'RDCDIVULG3', 'RDCDIVULG4',
            'REGCONFUSU', 'SICAF', 'SUPRIDO'], nivelAcesso: 7, nome: 'Super Usuário de Teste da UASG 200999',
          numeroUasg: 200999, numeroUasgAtuacao: 200999 }
      },
      { label: '70005 -TodosMnemonicos - 33333333333', value: { cpf: '33333333333', ip: '192.168.1.1', metodoAutenticacao: 'L',
          mnemonicos: ['ADMBANCO', 'ADMPREGAO', 'ADMSERPRO', 'ALTOFERTA', 'ATASRP', 'CENTRAL', 'CONARQ', 'CONCRONENT', 'CONICMS', 'CONOFERTA', 'CONTEREDIT', 'DIVULGA1', 'DIVULGA2', 'DIVULGA3', 'DIVULGA4', 'ENCCRONENT', 'ENVEMPSUFU', 'EQUIPREGAO', 'EXCCRONENT', 'EXCREACRON', 'FASEINT1', 'GESTOR-ATA', 'HOMOL-RDC', 'HOMPREGAO', 'IALCRONENT', 'IALREACRON', 'IMPEXTCONV', 'INCDEVMAT', 'INCEDITAL', 'INCOFERTA', 'IRP', 'LIBCRONENT', 'LICITACOES', 'PREGAO', 'PRESID-RDC', 'PRESIDENTE', 'RDCDIVULG1', 'RDCDIVULG2', 'RDCDIVULG3', 'RDCDIVULG4', 'REGCONFUSU', 'SICAF', 'SUPRIDO'], nivelAcesso: 7, nome: 'Super Usuário de Teste da UASG 70005', numeroUasg: 70005, numeroUasgAtuacao: 70005 } },
      { label: '806030-TodosMnemonicos - 44444444444', value: { cpf: '44444444444', ip: '192.168.1.1', metodoAutenticacao: 'L',
          mnemonicos: ['CAT-UASG','ADMBANCO', 'ADMPREGAO', 'ADMSERPRO', 'ALTOFERTA', 'ATASRP', 'CENTRAL', 'CONARQ', 'CONCRONENT', 'CONICMS', 'CONOFERTA', 'CONTEREDIT', 'DIVULGA1', 'DIVULGA2', 'DIVULGA3', 'DIVULGA4', 'ENCCRONENT', 'ENVEMPSUFU', 'EQUIPREGAO', 'EXCCRONENT', 'EXCREACRON', 'FASEINT1', 'GESTOR-ATA', 'HOMOL-RDC', 'HOMPREGAO', 'IALCRONENT', 'IALREACRON', 'IMPEXTCONV', 'INCDEVMAT', 'INCEDITAL', 'INCOFERTA', 'IRP', 'LIBCRONENT', 'LICITACOES', 'PREGAO', 'PRESID-RDC', 'PRESIDENTE', 'RDCDIVULG1', 'RDCDIVULG2', 'RDCDIVULG3', 'RDCDIVULG4', 'REGCONFUSU', 'SICAF', 'SUPRIDO'], nivelAcesso: 7, nome: 'Super Usuário de Teste da UASG 806030', numeroUasg: 806030, numeroUasgAtuacao: 806030 } },
      { label: '803090-TodosMnemonicos - 55555555555', value: { cpf: '55555555555', ip: '192.168.1.1', metodoAutenticacao: 'L',
          mnemonicos: ['ANALISAPED','ADMBANCO', 'ADMPREGAO', 'ADMSERPRO', 'ALTOFERTA', 'ATASRP', 'CENTRAL', 'CONARQ', 'CONCRONENT', 'CONICMS', 'CONOFERTA', 'CONTEREDIT', 'DIVULGA1', 'DIVULGA2', 'DIVULGA3', 'DIVULGA4', 'ENCCRONENT', 'ENVEMPSUFU', 'EQUIPREGAO', 'EXCCRONENT', 'EXCREACRON', 'FASEINT1', 'GESTOR-ATA', 'HOMOL-RDC', 'HOMPREGAO', 'IALCRONENT', 'IALREACRON', 'IMPEXTCONV', 'INCDEVMAT', 'INCEDITAL', 'INCOFERTA', 'IRP', 'LIBCRONENT', 'LICITACOES', 'PREGAO', 'PRESID-RDC', 'PRESIDENTE', 'RDCDIVULG1', 'RDCDIVULG2', 'RDCDIVULG3', 'RDCDIVULG4', 'REGCONFUSU', 'SICAF', 'SUPRIDO'], nivelAcesso: 7, nome: 'Super Usuário de Teste da UASG 803090', numeroUasg: 803090, numeroUasgAtuacao: 803090 } },
      { label: '803090-TodosMnemonicos - 66666666666', value: { cpf: '66666666666', ip: '192.168.1.1', metodoAutenticacao: 'L',
          mnemonicos: ['ADMBANCO', 'ADMPREGAO', 'ADMSERPRO', 'ALTOFERTA', 'ATASRP', 'CENTRAL', 'CONARQ', 'CONCRONENT', 'CONICMS', 'CONOFERTA', 'CONTEREDIT', 'DIVULGA1', 'DIVULGA2', 'DIVULGA3', 'DIVULGA4', 'ENCCRONENT', 'ENVEMPSUFU', 'EQUIPREGAO', 'EXCCRONENT', 'EXCREACRON', 'FASEINT1', 'GESTOR-ATA', 'HOMOL-RDC', 'HOMPREGAO', 'IALCRONENT', 'IALREACRON', 'IMPEXTCONV', 'INCDEVMAT', 'INCEDITAL', 'INCOFERTA', 'IRP', 'LIBCRONENT', 'LICITACOES', 'PREGAO', 'PRESID-RDC', 'PRESIDENTE', 'RDCDIVULG1', 'RDCDIVULG2', 'RDCDIVULG3', 'RDCDIVULG4', 'REGCONFUSU', 'SICAF', 'SUPRIDO'], nivelAcesso: 7, nome: 'Super Usuário de Teste da UASG 803090', numeroUasg: 803090, numeroUasgAtuacao: 803090 } },
      { label: '200999', value: { cpf: '11111111111', ip: '192.168.1.1', metodoAutenticacao: 'L',
          mnemonicos: ['RDCDIVULG1', 'PRESID-RDC', 'DIVULGA1', 'HOMPREGAO'], nivelAcesso: 7, nome: 'Usuário de Teste da UASG 200999', numeroUasg: 200999, numeroUasgAtuacao: 200999 } },
      { label: '70005', value: { cpf: '11111111111', ip: '192.168.1.1', metodoAutenticacao: 'L',
          mnemonicos: ['FASEINT1', 'RDCDIVULG1', 'PRESID-RDC', 'DIVULGA1', 'HOMPREGAO', 'HOMOL-RDC'], nivelAcesso: 7, nome: 'Usuário de Teste da UASG 70005', numeroUasg: 70005, numeroUasgAtuacao: 70005 } },
      { label: '806030', value: { cpf: '11111111111', ip: '192.168.1.1', metodoAutenticacao: 'L',
          mnemonicos: ['FASEINT1', 'RDCDIVULG1', 'PRESID-RDC', 'DIVULGA1', 'HOMPREGAO'], nivelAcesso: 7, nome: 'Usuário de Teste da UASG 806030', numeroUasg: 806030, numeroUasgAtuacao: 806030 } },
      { label: '803090', value: { cpf: '11111111111', ip: '192.168.1.1', metodoAutenticacao: 'L',
          mnemonicos: ['FASEINT1', 'RDCDIVULG1', 'PRESID-RDC', 'DIVULGA1', 'HOMPREGAO'], nivelAcesso: 7, nome: 'Usuário de Teste da UASG 803090', numeroUasg: 803090, numeroUasgAtuacao: 803090 } },
      { label: '70005 - Divulgação RDC', value: { cpf: '11111111111', ip: '192.168.1.1', metodoAutenticacao: 'L',
          mnemonicos: ['RDCDIVULG1'], nivelAcesso: 7, nome: 'Usuário Divulgador da UASG 70005', numeroUasg: 70005, numeroUasgAtuacao: 70005 } },
      { label: '70005 - Presidente RDC', value: { cpf: '11111111111', ip: '192.168.1.1', metodoAutenticacao: 'L',
          mnemonicos: ['PRESID-RDC'], nivelAcesso: 7, nome: 'Usuário Presidente da UASG 70005', numeroUasg: 70005, numeroUasgAtuacao: 70005 } },
      { label: '70005 - Autoridade Competente RDC', value: { cpf: '11111111111', ip: '192.168.1.1', metodoAutenticacao: 'L', mnemonicos: ['HOMOL-RDC'], nivelAcesso: 7, nome: 'Usuário Autoridade Competente da UASG 70005', numeroUasg: 70005, numeroUasgAtuacao: 70005 } }
    ];

    this.usuariosFornecedor = [
      { label: 'fornec1 - 71.397.831/0001-97', value: 'c82990d44e44347aeb9efacf5dab27' },
      { label: 'fornec2 - 42.146.902/0001-80', value: '7d04c1f800679da8c0c797f3ecd24c' },
      { label: 'fornec3 - 76.490.184/0026-35 - ME-EPP', value: 'f41b0df0f465b8a7eb3ba56a5dd2a7' },
      { label: 'fornec4 - 75.904.383/0030-66', value: '836b29e492a6f643b9a831464c4089' },
      { label: 'fornec5 - 04.165.142/0001-60 - ME-EPP', value: 'f68d2a9a73ebc4e89f0ad0e4a353e2' },
      { label: 'fornec6 - 534.058.976-53', value: '985197af1784f91f73718bb81fa5fb' },
      { label: 'fornec7 - 00.080.671/0001-00', value: '533f07c50ead0713a7e1fb86d6505e' },
      { label: 'fornec8 - 78.945.730/0001-52', value: '7e9de4b0007012dfb8f8ef87e38c50' },
      { label: 'fornec9 - 00.482.840/0001-38', value: '851dd376871bf4f501ed334c8bab26' },
      { label: 'fornec10 - 40.179.871/0001-39 - ME-EPP', value: '1698d2f6393484aabbe4b5e4b710c3' },
      { label: 'fornec11 - 04.297.410/0001-05 - ME-EPP', value: '0b11f6d85d7cd87f5ca0e549da1520' },
      { label: 'fornec12 - ESTRANG0000016', value: '2dcfbd9ca039ffb82bd6f13bad169a' }
    ];
  }

  initialize(): void { }

  private obterPrefixo(): string {
    const href: string = window.location.href;
    if (href.indexOf('estaleiro') > 0) {
      return '/comprasnet-web/';
    }
    return '/';
  }

  // tslint:disable-next-line:typedef
  irParaDashboardGoverno() {
    console.log('LoginFakeComponent ir para dashboard Govendo');
    console.log(this.authService.baseUrl);
    if (!this.usuarioGovernoSelected) {
      this.messageService.add({
        severity: 'error',
        detail: 'Por favor, selecione um usuário do governo para realizar o login',
        sticky: true,
        life: 5000
      });
    } else {
      this.authService.criarSessao(this.usuarioGovernoSelected.value).subscribe((idSessao: string) => {
        if (idSessao) {
          console.log('LoginFakeComponent criarSessao IF Sessão');
          this.authService.obterCnetId(idSessao).subscribe((cnetid: string) => {
            if (cnetid) {
              console.log('LoginFakeComponent criarSessao IF cnetid');
              console.log(' this.router.navigate -> arealogada ' + cnetid );
              this.router.navigate(['/arealogada'], {
                queryParams: {
                  ['cnet-id']: cnetid
                }
              });
            }
          });
        }
      });
    }
  }

  irParaDashboardFornecedor(): void {
    if (!this.usuarioFornecedorSelected) {
      this.messageService.add({
        severity: 'error',
        detail: 'Por favor, selecione um usuário do fornecedor para realizar o login',
        sticky: true,
        life: 5000
      });
    } else {
      this.router.navigate(['/seguro/fornecedor'], {
        queryParams: { ['cnetId']: this.usuarioFornecedorSelected.value, ['redirect-from']: 'login-fake' }
      });
    }
  }


  irParaAreaLogada(): void {
    if (!this.usuarioGovernoSelected) {
      this.messageService.add({
        severity: 'error',
        detail: 'Por favor, selecione um usuário do governo para realizar o login',
        sticky: true,
        life: 5000
      });
    } else {
      this.router.navigate(['/xxxxxxxx'], {
        queryParams: { ['cnetId']: this.usuarioGovernoSelected.value }
      });
    }
  }

}
