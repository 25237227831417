import {AfterContentChecked, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Servico } from 'src/app/busca/model/servico.model';
import {ActivatedRoute, Router} from '@angular/router';
import {Service} from '../../service/service';
import {Item} from '../model/item.model';
import {MessageService} from 'primeng/api';
import { AuthService } from 'src/app/security/services/auth-service.service';
import { PesquisaService } from 'src/app/arealogada/pedido/pedido-catalogador.service';

@Component({
  selector: 'app-detalhe-servico',
  templateUrl: './detalhe-servico.component.html',
  styleUrls: ['./detalhe-servico.component.css']
})
export class DetalheServicoComponent implements OnInit {
  @Output() atualizarLista = new EventEmitter<any>();
  @Output() aoVoltar = new EventEmitter<any>();
  duplicado: any;
  idServico: any;
  nomeServico: any;
  unidMedida: any [] = [];
  classificaContabil: any [] = [];
  selected: any;
  item: Item;
  itens: Item[] = [];
  display = false;
  url: any;
  caracteristicasPedido: any[] = [];
  @Input()
  isUsuarioGovernoLogado: any;

  @Input()
  servico: any;

  token: any;
  usrProfile:any;
  usrRoles:{[id:string]:boolean};

  constructor(public authService: AuthService,private route: ActivatedRoute, private service: Service, private router: Router,
              private messageService: MessageService, private pedidoService: PesquisaService) { }

  ngOnInit(): void {
    this.idServico = this.servico.id;
    this.nomeServico = this.servico.nome;
    this.buscarUnidadeFornecimentoClassificacaoContabil();
    this.getItens();

    this.token = this.authService.token;
    this.usrProfile=this.authService.getUserProfile(this.token);
    this.usrRoles=this.authService.getUserRoles(this.token);
  }

  buscarUnidadeFornecimentoClassificacaoContabil(): void {
    this.service.obterUnidadeMedida(this.idServico).subscribe(unidadeMedida => {
      this.unidMedida = unidadeMedida ;
      if (unidadeMedida){
        for (const tmp of unidadeMedida) {
          this.unidMedida.push();
        }
      }
      if (this.unidMedida.length === 1){
         this.selected = this.unidMedida[0];
      }
    });
    this.service.obterClassificacaoContabilPorCodigo(this.idServico).subscribe(classContabil => this.classificaContabil = classContabil);
  }

  escolher(): void {
    if (this.selected == null){
      this.messageService.add({severity: 'warn', summary: 'Selecione uma unidade de fornecimento.', detail: ''});
      return;
    }
    console.log(this.selected);
    this.item = new Item();
    this.item.id = this.idServico;
    this.item.nome = this.nomeServico;
    this.item.unidade = this.selected;
    this.item.tipo = 'SERVIÇO';
    this.getItens();
    this.itens.push(this.item);
    localStorage.setItem('itensbusca', JSON.stringify(this.itens));
    this.atualizarLista.emit();
    this.messageService.add({
      severity: 'success',
      summary: 'Item armazenado na lista.'
    });
  }
  naovalidar(): boolean{
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.itens.length; i++){
      if (this.itens[i].id === this.idServico  && this.itens[i].unidade.nomeUnidadeMedida === this.selected.nomeUnidadeMedida) {
        return true;
      }
    }
    return false;
  }

  getItens(): Item[]{
    const saved = localStorage.getItem('itensbusca');
    if (saved != null){
      this.itens = JSON.parse(saved);
    }
    return this.itens;
  }
   voltar(): void{
     this.aoVoltar.emit();
   }

  escolherUnidadeMedida(event: any): void{
    console.log(JSON.stringify(this.selected));
  }

  abrirEfetuarPedido() {
    this.montarArrayCaracteristicas();
    const detalhesPedido = {
      caracteristicas: this.caracteristicasPedido,
      tipoPedido: 'S',
      area: 'S'
    };
    this.pedidoService.criarPedido(detalhesPedido).subscribe((pedido) => {
      this.url = this.obterUrl();
      this.router.navigate([]).then(result =>
        {  window.open(this.url + pedido.numeroPedido, '_blank'); });
    }, (error) => {
      this.messageService.add({
        severity: 'error',
        summary: 'Erro ao criar pedido. Tente novamente.'
        });
    });
  }

  montarArrayCaracteristicas(){
    this.caracteristicasPedido = [];
    let auxUnidadeFornecimento;
    auxUnidadeFornecimento = 'Unidade de Fornecimento:';
    if(this.selected !== null && this.selected !== undefined) {
      auxUnidadeFornecimento = auxUnidadeFornecimento + ' ' + this.selected.nomeUnidadeMedida;
    }
    this.caracteristicasPedido.push(auxUnidadeFornecimento);
  }

  obterUrl(): string {
    const href: string = window.location.href;
    if (href.indexOf('localhost') > 0){
      return  'http://localhost:4200/pedido/'
    }
    if (href.indexOf('des-cnbs') > 0 || href.indexOf('des.cnbs') > 0){
      return 'https://des-cnbs.np.estaleiro.serpro.gov.br/cnbs-web/pedido/';
    }
    if (href.indexOf('tre.cnbs') > 0 || href.indexOf('treinamento.catalogo') > 0 || href.indexOf('tre-cnbs') > 0){
      return 'https://treinamento.catalogo.compras.gov.br/cnbs-web/pedido/';
    }
    if (href.indexOf('hom.cnbs') > 0 || href.indexOf('hom-cnbs') > 0){
      return 'https://hom-cnbs.estaleiro.serpro.gov.br/cnbs-web/pedido/';
    }
    return 'https://catalogo.compras.gov.br/cnbs-web/pedido/';
  }
}
