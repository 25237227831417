import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Grupo} from '../../../model/grupo.model';
import {Classe} from '../../../model/classe.model';
import {SelectItem} from 'primeng/api';

@Component({
  selector: 'app-resultado',
  templateUrl: './resultado.component.html',
  styleUrls: ['./resultado.component.css']
})
export class ResultadoComponent implements OnInit, OnChanges {

  @Input() materiaisInput: any;

  @Input() servicosInput: any;

  @Output() aoRetornarId = new EventEmitter<any>();


  materiais: any ;
  servicos: any ;
  telaTabView: true;
  indexpainel = 0 ;
  selectedGrupo: Grupo = new Grupo();
  selectedClasse: Classe = new Classe();
  rows: 10;
  paginarMaterial: boolean;
  paginarServico: boolean;
  sortOrder: number;
  sortField: string;
  loading: any;
  isDisabled = true;
  currentItem: any;
  sortKey: any;
  classesMaterial: Classe[] = new Array();
  gruposServico: Grupo[] = new Array();
  sortOptions: SelectItem[];

  constructor() { }

  ngOnInit(): void {
    console.log('ngOnInit');
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('ngOnChanges');
    this.gruposServico  = [];
    this.classesMaterial = [];

    if (this.servicosInput !== undefined) {
      this.servicos = [];
      const grupo = new Grupo();
      grupo.id = '0'; grupo.descricao = 'todos os grupos'; grupo.code = 0 ; grupo.name = 'TODOS OS GRUPOS';
      this.gruposServico.push(grupo);
      let tmp1;
      for (tmp1 of this.servicosInput) {
        this.servicos.push(tmp1);
        this.carregarGrupoServico(tmp1);
      }
    }

    if (this.materiaisInput !== undefined) {
      this.materiais = [];
      const classe = new Classe();
      classe.id = '0'; classe.descricao = 'todas as classes'; classe.name = 'TODAS AS CLASSES'; this.classesMaterial.push(classe);
      let tmp2;
      for (tmp2 of this.materiaisInput) {
        this.materiais.push(tmp2);
        this.carregarClasseMaterial(tmp2);
      }
    }
    this.paginarServicos();
    this.paginarMateriais();
  }

  voltarDadoAoChamador(id: any, tipo: any): void{ // console.log('voltar dados ao chamador');
    this.aoRetornarId.emit({id, tipo});
  }

  private recarregarFiltros(): void {
    if (this.materiais !== undefined) {
      let tmp1;
      for (tmp1 of this.materiais) {
        this.carregarClasseMaterial(tmp1);
      }
    }
    if (this.servicos !== undefined) {
      let tmp2;
      for (tmp2 of this.servicos) {
        this.carregarGrupoServico(tmp2);
      }
    }
  }
  private carregarGrupoServico( servico: any ): void {
    if (!this.gruposServico.some( (e: { id: any; }) => e.id === servico.codigoGrupo)) { // se o grupo na contem o código
      let grupo;
      grupo = new Grupo();
      grupo.id = servico.codigoGrupo;
      grupo.descricao = servico.nomeGrupo;
      grupo.code = servico.codigoGrupo;
      grupo.name = servico.codigoGrupo + ' - ' + servico.nomeGrupo;
      this.gruposServico.push(grupo);
    }
  }
  private carregarClasseMaterial( material: any ): void {
    if (!this.classesMaterial.some((e: { id: any; }) => e.id === material.codigoClasse)) { // se o grupo na contem o código
      let classe;
      classe = new Classe();
      classe.id = material.codigoClasse;
      classe.descricao = material.nomeClasse;
      classe.code = material.codigoClasse;
      classe.name = material.codigoClasse + '-' + material.nomeClasse ;
      this.classesMaterial.push(classe);
    }
  }

  detalheServico(idservico: any, nomeservico: any): void {
    this.voltarDadoAoChamador(idservico, 's');
  }

  detalheMaterial(codigopdm: any, nomepdm: any, classepdm: any): void {
    this.voltarDadoAoChamador(codigopdm, 'm');
  }

  grupoEscolhido(event: any): void {
    console.log( 'grupoEscolhido' + JSON.stringify(event.value.id));
    this.servicos = [];
    let servicotmp;
    if (event.value.id === '0'){
      for (servicotmp of this.servicosInput){
        this.servicos.push(servicotmp);
      }
    }else{
      for (servicotmp of this.servicosInput){
        if (servicotmp.codigoGrupo === event.value.id){
          this.servicos.push(servicotmp);
        }
      }
    }
    this.servicos.sort((a: any, b: any) => {
      if (a.statusServico === true && b.statusServico === true){
        return 0;
      }
      if (a.statusServico === true && b.statusServico === false){
        return -1;
      }
      return 1;
    });
    this.paginarServicos();
  }

  classeEscolhida(event: any): void {
    console.log(JSON.stringify(event.value.id));
    let materialtmp;
    this.materiais = [];
    if (event.value.id === '0'){
      for (materialtmp of this.materiaisInput){
        this.materiais.push(materialtmp);
      }
    }else{
      for (materialtmp of this.materiaisInput){
        if (materialtmp.codigoClasse === event.value.id){
          this.materiais.push(materialtmp);
        }
      }
    }
    this.materiais.sort((a: any, b: any) => {
      if (a.statusPDM === true && b.statusPDM === true){
        return 0;
      }
      if (a.statusPDM === true && b.statusPDM === false){
        return -1;
      }
      return 1;
    });
    this.paginarMateriais();
  }

  customSort(event: any): void {
    event.data.sort((data1: any, data2: any) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (event.field === 'id'){
        // tslint:disable-next-line:radix
        value1 = parseInt(value1);
        // tslint:disable-next-line:radix
        value2 = parseInt(value2);
      }
      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }

      return (event.order * result);
    });
  }

  onSortChange(event: any): void {
    const value = event.target.value;
    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    } else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  paginarMateriais(): void{
    if (this.materiais !== undefined ){
      if (this.materiais.length > 9){
        this.paginarMaterial = true;
      }else {
        this.paginarMaterial = false;
      }
    }
  }
  paginarServicos(): void{
    if (this.servicos !== undefined ){
      if (this.servicos.length > 9){
        this.paginarServico = true;
      }else{
        this.paginarServico = false;
      }
    }
  }


  limparGrupo(): void {
    this.indexpainel = 0;
    this.servicos = [];
    // let servicotmp;

    const grupo = new Grupo();
    grupo.id = '0';
    grupo.descricao = 'todos os grupos';
    grupo.code = 0 ;
    grupo.name = 'TODOS OS GRUPOS';

    this.selectedGrupo = grupo;

    if (grupo.id === '0'){
      // for (servicotmp of this.servicosRetornados){
      //   this.servicos.push(servicotmp);
      // }
    }

    // console.log(this.selectedGrupo);
  }


}
