import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {Service} from '../../../service/service';
import {Material} from '../../../model/material.model';
import {Router} from '@angular/router';
import {MenuItem, MessageService, SelectItem} from 'primeng/api';
import {AplicacaoService} from '../../../service/aplicacao.service';
import {AutoComplete} from 'primeng/autocomplete';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {AuthService} from '../../../security/services/auth-service.service';


@Component({
  selector: 'app-catalogador',
  templateUrl: './catalogador.component.html',
  styleUrls: ['./catalogador.component.css']
})
export class CatalogadorComponent implements OnInit {

  servicos: any ;   servicosRetornados: any ;
  materiais: any  ; materiaisRetornados: any  ;
  tipoSelecionado: string;
  filtros: Filtro[];
  filtro1: Filtro;

  results: any;
  detalheMaterialVisivel = false;
  detalheServicolVisivel = false;
  tela1 = false;
  telaVazia = true;
  exibirMenssagemVazia = false;
  itemsmenubotao: MenuItem[];
  resultadoMaterial: any;
  resultadoServico: any;
  modalRef1: BsModalRef;

  constructor( private service: Service, private router: Router,
               private messageService: MessageService,
               public app: AplicacaoService,
               private modalService: BsModalService,
               public authService: AuthService
   ) {
     this.filtros = [
       {name: 'Todos', code: 1},
       {name: 'Material', code: 2},
       {name: 'Serviço', code: 3}
     ];
     this.tipoSelecionado = '';
   }

  @ViewChild('hint', {static: true}) hint: AutoComplete;
  @ViewChild('template1', {static: false} ) template1: any;


  ngOnInit(): void {

    this.itemsmenubotao = [{
      items: [
            { label: 'Serviço',
              command: () => {
                this.manterServico();
              }
            },
            {label: 'Material',
              command: () => {
                this.manterMaterial();
              }
            },
            {label: 'Característica',
              command: () => {
                this.manterCaracteristica();
              }
            },
            {label: 'Valor de característica',
            command: () => {
              this.manterValorCaracteristica();
            }
          },
          {label: 'Unidade de Fornecimento',
              command: () => {
                this.manterUnidadeFornecimento();
              }
          },
          {label: 'Unidade de Medida',
              command: () => {
                this.manterUnidadeMedida();
              }
          }
      ]}
    ];
    this.filtro1 = new Filtro();
    this.filtro1.code = 1;
//
  }



  voltar($event: any): void {// limpar exibir home
    console.log('retorno no pai');
    this.tela1 = true;
    this.detalheMaterialVisivel = false;
    this.detalheServicolVisivel = false;
  }

  esconderTodos(): void {
    this.tela1 = false;
    this.detalheMaterialVisivel = false;
    this.detalheServicolVisivel = false;
    this.telaVazia = false;
    this.exibirMenssagemVazia = false;
  }

  resetArrays(): void{
    this.materiais = [];
    this.servicos = [];
    this.servicosRetornados = [];
    this.materiaisRetornados = [];
    // this.gruposServico  = [];
    // this.selectedGrupos = [];
   //  this.classesMaterial = [];
   //  this.selectedClasses = [];
    localStorage.setItem('Id', '');
    localStorage.setItem('Nome', '');
    localStorage.setItem(('codigo_escolhido'), '');
  }

  voltaTelaInicial(): void {
    console.log('test');
    this.resetArrays();
    this.esconderTodos();
    this.tela1 = true;
    this.telaVazia = true;
    // this.textoBusca = '';
  }


  seguirParaMaterial(): void {
   // this.obterMaterialPorCodigo();
    // this.modalRef1.hide();
  }

  seguirParaServico(): void {
   //  this.obterServicoPorCodigo();
    // this.modalRef1.hide();
  }

  // tslint:disable-next-line:typedef
  criar() {

  }
  // tslint:disable-next-line:typedef
  private manterServico() {
    this.router.navigate(['/buscaservico']);
  }
  // tslint:disable-next-line:typedef
  private manterMaterial() {
    console.log('buscaMaterial');
    this.router.navigate(['/buscamaterial']);
  }

  private manterCaracteristica(): void {
    console.log('criar caracteristica');
    this.router.navigate(['/mantercaracteristica']);
  }

  private manterValorCaracteristica(): void {
    console.log('criar valor caracteristica');
    this.router.navigate(['/mantervalorcaracteristica']);
  }

  private manterUnidadeFornecimento(): void {
    console.log('criar unidade fornecimento');
    this.router.navigate(['/manterunidadefornecimento']);
  }

  private manterUnidadeMedida(): void {
    console.log('criar unidade de medida');
    this.router.navigate(['/manterunidademedida']);
  }

  // tslint:disable-next-line:typedef
  resultadobusca($event: any) {
    const resultado = $event;
    // console.log(JSON.stringify($event));
    // console.log(JSON.stringify(resultado.resultadoMaterial));
    // console.log(JSON.stringify(resultado.resultadoServico));
    // console.log(resultado);
    this.resultadoMaterial = resultado.resultadoMaterial;
    this.resultadoServico = resultado.resultadoServico;
  }

  // tslint:disable-next-line:typedef
  retornaId(event: any) {
    const resultado = event;
    console.log('retornou id');
    console.log(JSON.stringify(event));
    if (resultado.tipo === 'm'){
      console.log('material');
      console.log(resultado.id);
    }

    if (resultado.tipo === 's'){
      console.log('serviço');
      console.log(resultado.id);
    }
  }
}

class Filtro {
   code: number;
   name: string;
}
